import interceptorInstance from "./Interceptor";

export async function getAllContractsByIntegrator(id){
    try{
        let dados = await interceptorInstance.get('contract/integrator/'+id);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getProductsByContractId(id){
    try{
        let dados = await interceptorInstance.get('contract/'+id+'/products');
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getContractById(id){
    try{
        let dados = await interceptorInstance.get(`contract/search/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getContractFileById(id, type){
    try{
        let dados = await interceptorInstance.get(`/contract/${id}/file/${type}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getTypesOfContract(){
    try{
        let dados = await interceptorInstance.get(`/contract/search/types`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function uploadFile(id, params){
    try{
        let dados = await interceptorInstance.post(`/contract/upload/${id}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function updateContractData(id, params){
    try{
        let dados = await interceptorInstance.put(`/contract/update/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getContractsByStatus(status){
    try{
        let dados = await interceptorInstance.get(`/contract/all/${status}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function changeContractStatus(id, status){
    try{
        let dados = await interceptorInstance.patch(`/contract/enable-disable/${id}/${status}`)
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function createContract(params){
    try{
        let dados = await interceptorInstance.post(`/contract/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}
