import React, { useState } from 'react';
import ImgMessageCarousel from './ImgMessageCarousel';
import If from '../Layout/If';

export default function Message({ message, status = '', sentName = '', type = 'user', messageType = 'text', date = '' }) {
    const [isImgCarouselOpen, setIsImgCarouselOpen] = useState(false);

    const float = type === 'user' ? 'right' : 'left'
    return (
        <div className="row m-1" style={{ maxWidth: '100%' }}>
            <ImgMessageCarousel isOpen={isImgCarouselOpen} setIsOpen={() => { setIsImgCarouselOpen(!isImgCarouselOpen) }} imgSrc={message}></ImgMessageCarousel>
            <div className="col-12">
                <div className={type === 'user' ? "bg-primary" : "bg-secondary"} style={{ borderRadius: '10px', padding: '10px', float: float, maxWidth: '100%' }}>
                    <If condition={type === 'other'}>
                        <h6 style={{ marginBottom: '0', fontSize: '0.6em' }}>{sentName}</h6>
                        <hr></hr>
                    </If>
                    <If condition={messageType === 'text'}>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{message}</p>
                    </If>
                    <If condition={messageType !== 'text'}>
                        <img alt='message_img' src={message} width={250} style={{ cursor: 'pointer', maxWidth: '100%', borderRadius: '5px' }} onClick={() => {
                            setIsImgCarouselOpen(!isImgCarouselOpen);
                        }}></img>
                    </If>
                    <hr style={{ padding: '0px', margin: '2px' }}></hr>
                    <p style={{ fontSize: '0.8rem', padding: '0px', margin: '0px' }} className='d-flex align-items-center justify-content-end'><small style={{ fontSize: '0.5rem', opacity: '0.7' }}>{date}</small><span className="badge badge-warning ml-2">{status}</span></p>
                </div>
            </div>
        </div>)
}