import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { insertContractValues } from '../../../../../services/ContractValues';
import Modal from '../../../../../common/Layout/Modal';

export default function CreateContractValues({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
    id_contrato = ''
}) {
    const INITIAL_STATE = {
        id_contrato: id_contrato,
        escopo: '',
        valor_chamado: '',
        valor_fora_hr_comercial: '',
        valor_chamado_improdutivo: '',
        valor_ha_diurna: '',
        valor_ha_noturna: '',
        valor_km_apos_51: '',
        observacoes: ''
    }
    const [formValoresTabela, setFormValoresTabela] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        const toastValues = toast.loading('Cadastrando dados, aguarde...');

        let dataReturn = await insertContractValues(formValoresTabela);
        if (dataReturn?.data?.response === 'success') {
            toast.update(toastValues, { render: 'Dados cadastrados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            updateParent();
            setFormValoresTabela(INITIAL_STATE);
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao cadastrar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title='Cadastrar Valores'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Valores Contrato</h5>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                            <label htmlFor="escopo">Escopo</label>
                            <input type="text" name="escopo" id="escopo" className="form-control" value={formValoresTabela?.escopo || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                            <label htmlFor="observacoes">Observações Diversas</label>
                            <input type="text" name="observacoes" id="observacoes" className="form-control" value={formValoresTabela?.observacoes || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="form-group">
                            <label htmlFor="valor_chamado">R$ Chamado</label>
                            <input type="number" min={"0"} step={".01"} name="valor_chamado" id="valor_chamado" className='form-control' value={formValoresTabela?.valor_chamado || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="form-group">
                            <label htmlFor="valor_fora_hr_comercial">Valor Fora Hrs Comercial</label>
                            <input type="number" min={"0"} step={".01"} name="valor_fora_hr_comercial" id="valor_fora_hr_comercial" className='form-control' value={formValoresTabela?.valor_fora_hr_comercial || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="form-group">
                            <label htmlFor="valor_chamado_improdutivo">Chamado Improdutivo</label>
                            <input type="number" min={"0"} step={".01"} name="valor_chamado_improdutivo" id="valor_chamado_improdutivo" className='form-control' value={formValoresTabela?.valor_chamado_improdutivo || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="form-group">
                            <label htmlFor="valor_ha_diurna">H.A (Diurna) Após 3h31m</label>
                            <input type="number" min={"0"} step={".01"} name="valor_ha_diurna" id="valor_ha_diurna" className='form-control' value={formValoresTabela?.valor_ha_diurna || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="form-group">
                            <label htmlFor="valor_ha_noturna">H.A (Noturna) Após 3h31m</label>
                            <input type="number" min={"0"} step={".01"} name="valor_ha_noturna" id="valor_ha_noturna" className='form-control' value={formValoresTabela?.valor_ha_noturna || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <div className="form-group">
                            <label htmlFor="valor_km_apos_51">R$ KM Após 51 Km</label>
                            <input type="number" min={"0"} step={".01"} name="valor_km_apos_51" id="valor_km_apos_51" className='form-control' value={formValoresTabela?.valor_km_apos_51 || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <div className="col-12 d-flex align-items-center">
                        <button type="submit" className='btn btn-primary'>Cadastrar</button>
                    </div>
                </div>
            </form>
        </Modal>);
}