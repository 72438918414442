import React, { useContext, useEffect, useState } from 'react';
import Content from '../../common/Layout/Content';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../common/Layout/Card';
import Table from '../../common/Layout/Table/Table';
import CustomMessageData from '../../common/Layout/CustomMessageData';
import { toast } from 'react-toastify';
import { enableDisableUser, getAllUsers } from '../../services/Users';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Context } from '../../App';
import If from '../../common/Layout/If';

export default function ShowUsers() {

    const { status } = useParams();
    const userData = useContext(Context);

    const columns = [
        {
            id: 'action_gerenciar_usuarios',
            name: 'Ações',
            cell: (row) => {
                if (userData?.userDataState?.login !== row.login_gerenciar_usuarios) {
                    return (<div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                        <div className="dropdown-menu">
                            <Link to={`/usuarios/detalhes/${row.id_gerenciar_usuarios}`} className='dropdown-item'>Visualizar</Link>
                            <button type='button' onClick={() => showChangeContractStatusSwal(row.id_gerenciar_usuarios, row.ativo_gerenciar_usuarios)} className='dropdown-item'>{row.ativo_gerenciar_usuarios === 'Ativo' ? 'Desativar' : 'Ativar'}</button>
                        </div>
                    </div>)
                }
            },
            sortable: true,
            wrap: true
        },
        {
            id: 'nome_completo_gerenciar_usuarios',
            name: 'Nome Completo',
            selector: row => row.nome_completo_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'email_gerenciar_usuarios',
            name: 'Email',
            selector: row => row.email_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'telefone_celular_gerenciar_usuarios',
            name: 'Telefone Celular',
            selector: row => row.telefone_celular_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'login_gerenciar_usuarios',
            name: 'Login',
            selector: row => row.login_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'grupos_acesso_gerenciar_usuarios',
            name: 'Grupos de Acesso',
            selector: row => row.grupos_acesso_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'observacao_gerenciar_usuarios',
            name: 'Observação',
            selector: row => row.observacao_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_registro_gerenciar_usuarios',
            name: 'Data Registro',
            selector: row => row.data_registro_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'ativo_gerenciar_usuarios',
            name: 'Usuário Ativo',
            selector: row => row.ativo_gerenciar_usuarios,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_gerenciar_usuarios',
            name: 'Status',
            selector: row => row.status_gerenciar_usuarios,
            sortable: true,
            wrap: true
        }
    ];

    const [data, setData] = useState([]);

    async function getUsers(status) {
        const toastUsers = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllUsers(status);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUsers, { render: "Dados encontrados!", type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data.map((item, idx) => {
                let gruposAcesso = item?.group.map((item, idx) => {
                    return item?.descricao;
                }) || [];

                gruposAcesso = gruposAcesso.join(", ");

                return ({
                    id_gerenciar_usuarios: item?.id || '',
                    nome_completo_gerenciar_usuarios: item?.nome || '',
                    email_gerenciar_usuarios: item?.email || '',
                    telefone_celular_gerenciar_usuarios: item?.telefone || '',
                    login_gerenciar_usuarios: item?.login || '',
                    grupos_acesso_gerenciar_usuarios: gruposAcesso || '',
                    observacao_gerenciar_usuarios: item?.observacao || '',
                    data_registro_gerenciar_usuarios: item?.data_registro || '',
                    ativo_gerenciar_usuarios: item?.ativo === 1 ? 'Ativo' : 'Inativo',
                    status_gerenciar_usuarios: item?.status_colaborador || ''
                })
            });

            setData(data);
        } else {
            toast.update(toastUsers, { render: "Sem dados!", type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
    }

    async function showChangeContractStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente alterar o status deste usuário?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeStatus(id, status);
        }
    }

    async function changeStatus(id, status) {
        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await enableDisableUser(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastChangeStatus, { render: 'Status alterado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let statusData = status === 'Ativo' ? 1 : 0;
            getUsers(statusData);
        } else {
            toast.update(toastChangeStatus, { render: 'Ocorreu um erro ao alterar o status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        let statusData = status === 'inativo' ? 0 : 1
        getUsers(statusData);
    }, [status]);

    return (
        <Content headerTitle='Gerenciar Usuários'>
            <SubHeaderSpecific subHeaderConfig='mam_usuarios'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <Link to='/usuarios/dados/ativo' className='btn btn-success'>Usuários Ativos</Link>
                        <Link to='/usuarios/dados/inativo' className='btn btn-warning m-1'>Usuários Inativos</Link>
                    </div>
                    <div className="col-12">
                        <If condition={data.length > 0}>
                            <Table columns={columns} data={data} id='gerenciar_usuarios_table'></Table>
                        </If>
                        <If condition={data.length === 0}>
                            <CustomMessageData message='Sem Dados'></CustomMessageData>
                        </If>
                    </div>
                </div>
            </Card>
        </Content>
    );
}