import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import Select from 'react-select';
import { ContratoConfirmadoOptions, ResponsavelEquipamentoOptions } from '../../../configs/GenericOptions';
import { createContract, getTypesOfContract } from '../../../services/Contracts';
import { toast } from 'react-toastify';
import { getAllIntegrators } from '../../../services/Integrators';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';

export default function CreateContract(props) {

    const initialState = {
        id: '',
        id_integradora: '',
        contrato_confirmado: '',
        contrato: '',
        email: '',
        tipo: '',
        responsavel_equipamento: '',
        descricao: '',
        mascara_email_abertura: '',
        mascara_email_fechamento: '',
        escopo_atendimento: '',
        ativo: '',
        data_registro: '',
        data_modificacao: '',
        centro_custo: '',
        conta_contabil: '',
        km_maximo: '',
        data_vencimento: '',
        id_usuario: '',
        rat_contrato: '',
        resumo_pop: '',
        arquivo_planta: '',
        arquivo_pop: '',
        url_arquivo_planta: '',
        url_arquivo_pop: '',
        integrator: ''
    }

    const [formContractData, setFormContractData] = useState(initialState);
    const [integratorsOptions, setIntegratorsOptions] = useState([]);
    const [contractTypeOptions, setContractTypeOptions] = useState([]);
    const navigate = useNavigate();

    async function submitForm(e) {
        e.preventDefault();

        let formData = {
            id_integradora: formContractData?.id_integradora?.value || '',
            contrato_confirmado: formContractData?.contrato_confirmado?.value || '',
            contrato: formContractData?.contrato || '',
            email: formContractData?.email || '',
            tipo: formContractData?.tipo?.value || '',
            responsavel_equipamento: formContractData?.responsavel_equipamento?.value || '',
            descricao: formContractData?.descricao || '',
            mascara_email_abertura: formContractData?.mascara_email_abertura || '',
            mascara_email_fechamento: formContractData?.mascara_email_fechamento || '',
            escopo_atendimento: formContractData?.escopo_atendimento || '',
            centro_custo: formContractData?.centro_custo || '',
            conta_contabil: formContractData?.conta_contabil || '',
            km_maximo: formContractData?.km_maximo || '',
            data_vencimento: formContractData?.data_vencimento || '',
            ativo: 1,
            rat_contrato: 0
        }
        const toastCreateForm = toast.loading("Cadastrando contrato, aguarde...");

        let dataReturn = await createContract(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastCreateForm, { render: 'Contrato cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            navigate("/cadastros/contratos/dados");
        } else {
            toast.update(toastCreateForm, { render: 'Ocorreu um erro ao cadastrar o contrato!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getIntegrators() {
        let dataReturn = await getAllIntegrators();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let integrators = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.nome }
            });
            setIntegratorsOptions(integrators);
        } else {
            setIntegratorsOptions([]);
        }
    }

    async function getContractTypes() {
        let dataReturn = await getTypesOfContract();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let dados = dataReturn?.data?.data.map((item, idx) => {
                return ({ value: item.nome, label: item.nome });
            })
            setContractTypeOptions(dados);
        } else {
            setContractTypeOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormContractData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getIntegrators();
        getContractTypes();
    }, []);

    return (
        <Content headerTitle="Cadastrar Contrato">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Integradora / Contrato</h5>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="nomeContrato">Nome do Contrato</label>
                                <input type="text" className="form-control" name="contrato" id="nomeContrato" value={formContractData?.contrato || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="integradora">Integradora</label>
                                <Select
                                    id='integradora'
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    name='id_integradora'
                                    placeholder="Selecione uma opção..."
                                    options={integratorsOptions}
                                    value={formContractData?.id_integradora || ''}
                                    onChange={(e) => handleSelectForm(e, 'id_integradora')}
                                    required
                                ></Select>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="text" className="form-control" name="email" id="email" value={formContractData?.email || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="tipoContrato">Tipo de Contrato</label>
                                <Select
                                    id='tipoContrato'
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    name='tipo'
                                    placeholder="Selecione uma opção..."
                                    options={contractTypeOptions}
                                    value={formContractData?.tipo || ''}
                                    onChange={(e) => handleSelectForm(e, 'tipo')}
                                ></Select>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="contratoConfirmado">Contrato confirmado?</label>
                                <Select
                                    id='contratoConfirmado'
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    name='contrato_confirmado'
                                    placeholder="Selecione uma opção..."
                                    options={ContratoConfirmadoOptions}
                                    value={formContractData?.contrato_confirmado || ''}
                                    onChange={(e) => handleSelectForm(e, 'contrato_confirmado')}
                                ></Select>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="centroCusto">Centro de Custo</label>
                                <input type="text" className="form-control" name="centro_custo" id="centroCusto" value={formContractData?.centro_custo || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="contaContabil">Conta Contábil</label>
                                <input type="text" className="form-control" name="conta_contabil" id="contaContabil" value={formContractData?.conta_contabil || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="kmMaximo">Km Máximo</label>
                                <input type="number" min="0" className="form-control" name="km_maximo" id="kmMaximo" value={formContractData?.km_maximo || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} required/>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="dataVencimentoContrato">Data de Vencimento do Contrato</label>
                                <input type="date" className="form-control" name="data_vencimento" id="dataVencimentoContrato" value={formContractData?.data_vencimento || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="responsavelEquipamento">Responsável Equipamento</label>
                                <Select
                                    id='responsavelEquipamento'
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    name='responsavel_equipamento'
                                    placeholder="Selecione uma opção..."
                                    options={ResponsavelEquipamentoOptions}
                                    value={formContractData?.responsavel_equipamento || ''}
                                    onChange={(e) => handleSelectForm(e, 'responsavel_equipamento')}
                                ></Select>
                            </div>
                        </div>
                    </div>
                    {/*<hr></hr>
                 <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-sticky-note"></i> Serviços</h5>
                    </div>
                    <div className="col-12">
                        <form>
                            <div className="row">
                                <div className="col-md-8 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="incluir_servico_input" id="incluirServico" className="form-control" placeholder='Incluir Serviço'  required />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <button type="button" className="btn btn-secondary" disabled={!updateData}>Inserir</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                        data.length === 0 ?
                            (
                                <CustomMessageData message="Sem Dados"></CustomMessageData>
                            )
                            :
                            (<div className='col-12'>
                                <Table columns={columns} data={data} needExport={false} id="table"></Table>
                            </div>)
                    }
                 </div> */}
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="escopoAtendimento">Escopo de Atendimento</label>
                                <textarea rows={5} className="form-control" name="escopo_atendimento" id="escopoAtendimento" value={formContractData?.escopo_atendimento || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} ></textarea>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="descricao">Observações</label>
                                <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formContractData?.descricao || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} ></textarea>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="mascaraEmailAbertura">Máscara de Email - Abertura</label>
                                <textarea rows={10} className="form-control" name="mascara_email_abertura" id="mascaraEmailAbertura" value={formContractData?.mascara_email_abertura || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} ></textarea>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="mascaraEmailFechamento">Máscara de Email - Fechamento</label>
                                <textarea rows={10} className="form-control" name="mascara_email_fechamento" id="mascaraEmailFechamento" value={formContractData?.mascara_email_fechamento || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Cadastrar</button>
                        </div>
                    </div>
                </form>
            </Card>
        </Content>
    );
}