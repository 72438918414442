import React, { useEffect, useState } from 'react';
import ApproveCostsModal from '../../../common/Modals/ApproveCostsModal';
import { getExpenseByTicketId, getPendingExpenseCountByTicketId } from '../../../services/EmployeeExpense';
import Card from '../../../common/Layout/Card';
import Table from '../../../common/Layout/Table/Table';
import { toast } from 'react-toastify';
import useGenerics from '../../../hooks/useGenerics';

export default function PendingExpenseTable({ id_ticket = '', data_inicial = '' }) {
    const { getCurrentDate } = useGenerics()

    const costsExpensesColumns = [
        {
            id: 'action_pendente',
            name: 'Ação',
            button: "true",
            cell: (row) => (
                <button type="button" className="btn btn-primary" onClick={() => openApproveCostsModal(row?.id_pendente)}><i className='fas fa-pen'></i></button>
            ),
            width: '50px'
        },
        {
            id: 'nome_pendente',
            name: 'Nome',
            selector: row => row?.nome_pendente,
            sortable: true,
            wrap: true
        },
                {
            id: 'cpf_cnpj_pendente',
            name: 'CPF/CNPJ',
            selector: row => row?.cpf_cnpj_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'banco_pendente',
            name: 'Banco',
            selector: row => row?.banco_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'agencia_pendente',
            name: 'Agência',
            selector: row => row?.agencia_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_conta_pendente',
            name: 'Tipo de Conta',
            selector: row => row?.tipo_conta_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'numero_titular_pendente',
            name: 'Número Titular',
            selector: row => row?.numero_titular_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'nome_titular_pendente',
            name: 'Nome Titular',
            selector: row => row?.nome_titular_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'cpf_titular_pendente',
            name: 'CPF Titular',
            selector: row => row?.cpf_titular_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_despesa_pendente',
            name: 'Tipo de Despesa',
            selector: row => row?.tipo_despesa_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_cadastro_pendente',
            name: 'Data do Cadastro',
            selector: row => row?.data_cadastro_pendente,
            sortable: true,
            wrap: true
        },
        {
            id: 'valor_pendente',
            name: 'Valor',
            selector: row => row?.valor_pendente,
            sortable: true,
            wrap: true
        }
    ]


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalId, setModalId] = useState('');
    const [pendingExpensesData, setPendingExpensesData] = useState([]);
    const [pendingExpenseCount, setPendingExpenseCount] = useState(0);

    function openApproveCostsModal(id) {
        setModalId(id);
        setIsModalOpen(prevState => (!prevState));
    }

    const getPendingExpensesByTicketId = async () => {
        let splitedDateTimeInicial = data_inicial.split(" ");
        let splitedDataInicial = splitedDateTimeInicial[0].split("/");
        let formattedDataInicial = `${splitedDataInicial[2]}-${splitedDataInicial[1]}-${splitedDataInicial[0]}`;
        let dataReturn = await getExpenseByTicketId(id_ticket, formattedDataInicial, getCurrentDate(), 'pendente');

        if(dataReturn?.data?.response === 'success'){
            let data = dataReturn?.data?.data?.map((item, idx)=>({
                tipo_despesa_pendente: item?.tipo_despesa || '',
                data_cadastro_pendente: item?.data_cadastro || '',
                detalhes_aprovacao_pendente: item?.detalhes_aprovacao || '',
                valor_pendente: `R$ ${item?.valor || 0.00}`,
                data_aprovado_pendente: item?.data_aprovado,
                status_pendente: item?.status_reembolso || '',
                id_pendente: item?.id || '',
                nome_pendente: item?.employee?.nome_tec || '',
                cpf_cnpj_pendente: item?.employee?.cpf_cnpj || '',
                banco_pendente: item?.employee?.banco || '',
                agencia_pendente: item?.employee?.agencia_banco || '',
                tipo_conta_pendente: item?.employee?.tipo_conta_banco || '',
                numero_titular_pendente: item?.employee?.num_conta_banco || '',
                cpf_titular_pendente: item?.employee?.cpf_titular2 || '',
                nome_titular_pendente: item?.employee?.titular_banco2 || '',
            })) || [];

            setPendingExpensesData(data);
        }else{
            toast.warning('Ocorreu um erro ao procurar pelos reembolsos pendentes!');
            setPendingExpensesData([]);
        }
    }

    const getCountPendingExpenseByTicketId = async () => {
        let dataReturn = await getPendingExpenseCountByTicketId(id_ticket);

        if(dataReturn?.data?.response === 'success'){
            setPendingExpenseCount(dataReturn?.data?.data?.contagem_pendente);
        }
    }

    function updateAllData(){
        getPendingExpensesByTicketId();
        getCountPendingExpenseByTicketId();
    }

    useEffect(()=>{
        if(id_ticket !== '' && data_inicial !== ''){
            updateAllData();
        }
    }, [id_ticket, data_inicial]);

    return (<>
        <ApproveCostsModal isOpen={isModalOpen} setModalOpen={() => (setIsModalOpen(!isModalOpen))} costId={modalId} mode={'edit'} setUpdateNewData={updateAllData}></ApproveCostsModal>
        <Card title={`Reembolsos Pendentes - ${pendingExpenseCount}`} icon='fa-credit-card' collapseId='reembolsosPendentesBody'>
            <Table columns={costsExpensesColumns} data={pendingExpensesData} id='reembolso_pendente_table'></Table>
        </Card>
    </>);
}