import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';

export default function CreateRetirada() {
    const initialState = {
        contrato: ''
    }
    const [formData, setFormData] = useState(initialState);

    return (
        <Content headerTitle={'Incluir Acionamento de Retirada'}>
            <SubHeaderSpecific subHeaderConfig='retirada'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form>
                    <div className='row'>
                        <div className="col-12">
                            <label htmlFor="">Anexar Acionamento - XLS</label>
                            <input type="file" name="" id="" className="form-control" accept='.xls, .xlsx' required />
                            <span style={{ fontSize: '0.8rem' }}>Obs: Para inclusão correta, por favor, verifique a existência de caracteres especiais (desconhecidos) ou aspas simples no arquivo a ser anexado.</span>
                        </div>
                        <div className="col-12">
                            <label htmlFor="">Anexar Nota Fiscal ref. - PDF ou ZIP</label>
                            <input type="file" name="" id="" className="form-control" accept='.pdf, .zip' required />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <button type="submit" className='btn btn-primary'><i className='fas fa-upload'></i> Enviar</button>
                        </div>
                    </div>
                </form>
            </Card>
        </Content>
    );
}