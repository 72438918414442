import React, { useRef, useState } from 'react';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import If from '../Layout/If';

const MIN_DIMENSION = 150;
const ASPECT_RATIO = 1;

export default function ImageCropper({ updateImage, setModalOpen }) {

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState();
    const [error, setError] = useState('');

    function setCanvasPreview(image, canvas, crop) {
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error("Sem contexto 2d");
        }

        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'high';
        ctx.save();

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        ctx.translate(-cropX, -cropY);
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
        );

        ctx.restore();

    }

    function onSelectFile(e) {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const imageElement = new Image();
            const imgURL = reader.result?.toString() || '';

            imageElement.src = imgURL;

            imageElement.addEventListener('load', (e) => {
                const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("A imagem deve ter pelo menos 150px x 150px");
                    return setImgSrc("");;
                }
            });
            setError('');
            setImgSrc(imgURL);
        });
        reader.readAsDataURL(file);
    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: '%', // Can be 'px' or '%'
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    }

    function base64toBlob(base64, contentType = 'image/jpeg', sliceSize = 512) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    return (<>
        <input
            type='file'
            accept='.jpeg, .png, .jpg, .gif'
            onChange={onSelectFile}
            className='form-control m-1'
        ></input>
        <If condition={error !== ''}>
            <span style={{ color: 'red', fontSize: '0.8em' }}>{error}</span>
        </If>
        <If condition={imgSrc !== ''}>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <ReactCrop
                    crop={crop}
                    onChange={
                        (pixelCrop, percentCrop) => setCrop(percentCrop)
                    }
                    circularCrop
                    keepSelection
                    aspect={ASPECT_RATIO}
                    minWidth={MIN_DIMENSION}
                >
                    <img src={imgSrc} alt="Upload" style={{ 'maxHeight': '70vh' }} onLoad={onImageLoad} ref={imgRef}></img>
                </ReactCrop>
                <button type='button' className='btn btn-primary mt-1' onClick={() => {
                    setCanvasPreview(
                        imgRef.current,
                        previewCanvasRef.current,
                        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
                    );
                    const dataUrl = previewCanvasRef.current.toDataURL();
                    const [header, base64Data] = dataUrl.split(',');
                    updateImage(base64toBlob(base64Data, header.match(/data:(.*?);base64/)[1]));
                    setModalOpen();
                }}>Atualizar Imagem</button>
            </div>
        </If>
        <If condition={crop !== ''}>
            <canvas ref={previewCanvasRef} className='mt-4' style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: 150,
                height: 150,
                display: 'none'
            }}></canvas>
        </If>
    </>)
}