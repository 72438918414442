import interceptorInstance from "./Interceptor";

export async function getNotifications(){
    try{
        let data = await interceptorInstance.get(`notification/`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getNotificationCount(){
    try{
        let data = await interceptorInstance.get(`notification/count/pending`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createNotification(params){
    try{
        let data = await interceptorInstance.post(`notification/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getNotificationById(id){
    try{
        let data = await interceptorInstance.get(`notification/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateNotificationById(id, params){
    try{
        let data = await interceptorInstance.patch(`notification/${id}/update`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteNotificationById(id){
    try{
        let data = await interceptorInstance.delete(`notification/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}