import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import Table from '../../../../common/Layout/Table/Table';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import InvoiceDetails from './InvoiceDetails';

export default function Invoice(){
    const [data, setData] = useState([]);
    const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const columns = [
        {
            id: 'id_produto',
            name: 'ID',
            selector: row => row.id_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_produto',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={()=>openInvoiceDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <button type='button' className="dropdown-item" onClick={()=>openInvoiceDetailsModal(row.id_produto, 'edit')}>Editar</button>
                        <button type='button' className="dropdown-item" onClick={()=>showCancelShipmentSwal(row.id_produto)}>Cancelar Emissão de NFe</button>
                    </div>
                </div>
            ),
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
            selector: row => row.nome_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_produto',
            name: 'Modelo',
            selector: row => row.modelo_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'serial_produto',
            name: 'Serial',
            selector: row => row.serial_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
            selector: row => row.urgencia_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
            selector: row => row.modal_envio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
            selector: row => row.tipo_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
            selector: row => row.status_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
            selector: row => row.nfe_saida_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'cep_produto',
            name: 'CEP',
            selector: row => row.cep_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'uf_produto',
            name: 'UF',
            selector: row => row.uf_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'cidade_produto',
            name: 'Cidade',
            selector: row => row.cidade_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'bairro_produto',
            name: 'Bairro',
            selector: row => row.bairro_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'rua_produto',
            name: 'Rua',
            selector: row => row.rua_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'numero_produto',
            name: 'Número',
            selector: row => row.numero_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'complemento_produto',
            name: 'Complemento',
            selector: row => row.complemento_produto,
            sortable: true,
            wrap: true
        },
    ];

    async function getInvoiceByStatus(){
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO NFE SAÍDA');

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data || [];
            
            let productData = products.map((item, idx)=> ({
                id_produto: item?.id || '',
                nome_produto: item?.product?.nome_produto || '',
                modelo_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                cep_produto: item?.cep || '',
                uf_produto: item?.uf || '',
                cidade_produto: item?.cidade || '',
                bairro_produto: item?.bairro || '',
                rua_produto: item?.rua || '',
                numero_produto: item?.numero || '',
                complemento_produto: item?.complemento || ''
            }));

            setData(productData);
        }else{
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
    }

    async function showCancelShipmentSwal(id){
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            text: 'O produto voltará para o status anterior ao estoque.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if(swal.isConfirmed){
            cancelShipment(id);
        }
    }

    async function cancelShipment(id){
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, {status_remessa: 'AGUARDANDO ENVIO'});

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastProducts, { render: 'Emissão cancelada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getInvoiceByStatus();
            setUpdateLogistic(true);
        }else{
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cancelar a emissão!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function openInvoiceDetailsModal(id, mode){
        setInvoiceId(id);
        setViewMode(mode);
        setOpenInvoiceDetails(true);
    }

    useEffect(()=>{
        getInvoiceByStatus(); 
    }, []);

    return (<Content headerTitle='Emissão de NFE'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <InvoiceDetails isOpen={openInvoiceDetails} setModalOpen={()=>setOpenInvoiceDetails(!openInvoiceDetails)} invoiceId={invoiceId} setUpdateProducts={getInvoiceByStatus} viewMode={viewMode} setUpdateLogistic={setUpdateLogistic}></InvoiceDetails>
        <Card title='Cadastro de Produtos - Emissão de NFE'>
            <Table columns={columns} data={data} id='productsInvoiceTable'></Table>
        </Card>
    </Content>)
}