export const UrgenciaOptions = [
    {value: 'Agendado', label: 'Agendado'},
    {value: 'Emergencial', label: 'Emergencial'}
];

export const PrioridadeOptions = [
    {value: 'Baixa', label: 'Baixa'},
    {value: 'Média', label: 'Média'},
    {value: 'Alta', label: 'Alta'},
]

export const PontoPartidaOptions = [
    {value: '', label: 'Selecione uma opção...'},
    {value: 'Casa do Técnico', label: 'Casa do Técnico'},
    {value: 'Outro', label: 'Outro'},
]

export const DocumentacaoEntregueOptions = [
    {value: 'Pendente de Validação', label: 'Pendente de Validação'},
    {value: 'Não - Relatório Pendente', label: 'Não - Relatório Pendente'},
    {value: 'Sim - Relatório OK', label: 'Sim - Relatório OK'},
]

export const PagamentoOptions = [
    {value: 'Pendente de Liberação do Pagamento', label: 'Pendente de Liberação do Pagamento'},
    {value: 'Liberar pagamento', label: 'Liberar pagamento'},
    {value: 'Não liberar o pagamento', label: 'Não liberar o pagamento'}
];

export const OcasiaoFechamento = [
    {value: '', label: 'Selecione...'},
    {value: 'Produtivo', label: 'Produtivo'},
    {value: 'Improdutivo', label: 'Improdutivo'}
]

export const EquipamentoRetorno = [
    {value: 'gerar_reversa', label: 'Gerar Reversa'},
    {value: 'spare_tecnico', label: 'Spare Técnico'}
]

export const SimNaoOptions = [
    {value: 'SIM', label: 'SIM'},
    {value: 'NÃO', label: 'NÃO'}
]

export const UfOptions = [
    { value: 'AC', label: 'AC' },
    { value: 'AL', label: 'AL' },
    { value: 'AP', label: 'AP' },
    { value: 'AM', label: 'AM' },
    { value: 'BA', label: 'BA' },
    { value: 'CE', label: 'CE' },
    { value: 'DF', label: 'DF' },
    { value: 'ES', label: 'ES' },
    { value: 'GO', label: 'GO' },
    { value: 'MA', label: 'MA' },
    { value: 'MT', label: 'MT' },
    { value: 'MS', label: 'MS' },
    { value: 'MG', label: 'MG' },
    { value: 'PA', label: 'PA' },
    { value: 'PB', label: 'PB' },
    { value: 'PR', label: 'PR' },
    { value: 'PE', label: 'PE' },
    { value: 'PI', label: 'PI' },
    { value: 'RJ', label: 'RJ' },
    { value: 'RN', label: 'RN' },
    { value: 'RS', label: 'RS' },
    { value: 'RO', label: 'RO' },
    { value: 'RR', label: 'RR' },
    { value: 'SC', label: 'SC' },
    { value: 'SP', label: 'SP' },
    { value: 'SE', label: 'SE' },
    { value: 'TO', label: 'TO' } 
];

export const ContratoConfirmadoOptions = [
    {value: 'Sim', label: 'Sim'},
    {value: 'Não', label: 'Não'}
];

export const ResponsavelEquipamentoOptions = [
    {value: 'Maminfo', label: 'Maminfo'},
    {value: 'Cliente', label: 'Cliente'},
    {value: 'Misto', label: 'Misto'},
    {value: 'Nenhum', label: 'Nenhum'},
]

export const EstadoCivilOptions = [
    {value: 'Solteiro', label: 'Solteiro'},
    {value: 'Casado', label: 'Casado'},
    {value: 'Separado', label: 'Separado'},
    {value: 'Divorciado', label: 'Divorciado'},
    {value: 'União Estável', label: 'União Estável'},
    {value: 'Outros', label: 'Outros'}
];

export const TipoAfastamentoOptions = [
    {value: 'Férias', label: 'Férias'},
    {value: 'Atestado', label: 'Atestado'},
    {value: 'INSS', label: 'INSS'},
    {value: 'Licença Parental', label: 'Licença Parental'},
    {value: 'Licença Matrimônial', label: 'Licença Matrimônial'},
    {value: 'Ausência Autorizada', label: 'Ausência Autorizada'},
    {value: 'Ausência Não Autorizada', label: 'Ausência Não Autorizada'},
];

export const StatusColaboradorOptions = [
    {value: 'Ativo', label: 'Ativo'},
    {value: 'De férias', label: 'De férias'},
    {value: 'Afastado', label: 'Afastado'}
];

export const StatusAprovacaoOptions = [
    {value: 'aprovado', label: 'Aprovado'},
    {value: 'rejeitado', label: 'Rejeitado'},
    {value: 'cancelado', label: 'Cancelado'},
]

export const ModalContratacaoOptions = [
    {value: 'CLT', label: 'CLT'},
    {value: 'PJ', label: 'PJ'},
]

export const CategoriaColaboradorOptions = [
    {value: 'Interno', label: 'Interno'},
    {value: 'Residente', label: 'Residente'},
    {value: 'Técnico', label: 'Técnico'},
    {value: 'Home Office', label: 'Home Office'},
]

export const N2StatusChamadoOptions = [
    {value: '', label: 'Todos'},
    {value: 'Suporte interno (Testes)', label: 'Suporte interno (Testes)'},
    {value: 'Suporte interno (Fila)', label: 'Suporte interno (Fila)'},
    {value: 'Suporte interno (Atendimento)', label: 'Suporte interno (Atendimento)'},
    {value: 'Suporte interno (Residentes OI)', label: 'Suporte interno (Residentes OI)'},
]

export const UrgenciaRemessaOptions = [
    {value: 'BAIXA', label: 'BAIXA'},
    {value: 'MÉDIA', label: 'MÉDIA'},
    {value: 'ALTA', label: 'ALTA'},
]

export const VolumeNfeOptions = ()=>{
    let volume = []
    for(let i = 1; i < 16; i++){
        volume.push({label: i, value: i});
    }

    return volume;
}

export const TipoRemessaOptions = [
    {value: 'envio_tecnico', label: 'ENVIO TÉCNICO'},
    {value: 'movimentacao_interna', label: 'MOVIMENTAÇÃO INTERNA'}
]

export const AprovadoValorComercialOptions = [
    {value: 'aprovado', label: 'Aprovado'},
    {value: 'reprovado', label: 'Reprovado'}
]

export const Options = {
    AprovadoValorComercialOptions,
    CategoriaColaboradorOptions,
    ContratoConfirmadoOptions,
    DocumentacaoEntregueOptions,
    EquipamentoRetorno,
    EstadoCivilOptions,
    ModalContratacaoOptions,
    N2StatusChamadoOptions,
    OcasiaoFechamento,
    PagamentoOptions,
    PontoPartidaOptions,
    PrioridadeOptions,
    ResponsavelEquipamentoOptions,
    SimNaoOptions,
    StatusAprovacaoOptions,
    StatusColaboradorOptions,
    TipoAfastamentoOptions,
    TipoRemessaOptions,
    UfOptions,
    UrgenciaOptions,
    UrgenciaRemessaOptions,
    VolumeNfeOptions
}