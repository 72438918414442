import React, { useEffect, useState } from 'react';
import { getProductByProductId, updateProductData } from '../../../../../services/Product';
import { getTechnicianById } from '../../../../../services/Technician';
import { toast } from 'react-toastify';
import Modal from '../../../../../common/Layout/Modal';
import GenericSelect from '../../../../../common/Selects/GenericSelect';
import IntegradoraSelect from '../../../../../common/Selects/IntegradoraSelect';
import FabricanteSelect from '../../../../../common/Selects/FabricanteSelect';
import ContratosSelect from '../../../../../common/Selects/ContratosSelect';
import ModuleOptionsSelect from '../../../../../common/Selects/ModuleOptionsSelect';
import RepresentanteSelect from '../../../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../../../common/Selects/TecnicosSelect';
import BoxSelect from '../../../../../common/Selects/BoxSelect';
import If from '../../../../../common/Layout/If';
import GedTable from '../../Ged/GedTable';
import Timeline from './Timeline';

export default function ProductDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, productId = '', viewMode = 'view' }) {
    const initialState = {
        nome_produto: '',
        serial: '',
        modelo: '',
        id_integradora: '',
        id_contrato: '',
        codigo_sap: '',
        valorado: '',
        nfe_entrada: '',
        data_nfe_entrada: '',
        peso_equipamento: '',
        preco_equipamento: '',
        mac: '',
        imei: '',
        id_status_produto: '',
        kit_spare: '',
        local: '',
        id_tecnico: '',
        tipo_tecnico: '',
        email: '',
        patrimonio: '',
        box: '',
        id_fabricante: '',
        id_corredor: '',
        id_estante: '',
        id_prateleira: '',
        id_representante: ''
    }

    const [formCadastroProduto, setFormCadastroProduto] = useState(initialState);
    const [updateParent, setUpdateParent] = useState(false);

    async function submitForm(e) {
        e.preventDefault();

        let formData = {
            nome_produto: formCadastroProduto.nome_produto || '',
            serial: formCadastroProduto.serial || '',
            modelo: formCadastroProduto.modelo || '',
            id_integradora: formCadastroProduto.id_integradora?.value || '',
            id_contrato: formCadastroProduto.id_contrato?.value || '',
            codigo_sap: formCadastroProduto.codigo_sap || '',
            valorado: formCadastroProduto.valorado?.value || '',
            nfe_entrada: formCadastroProduto.nfe_entrada || '',
            data_nfe_entrada: formCadastroProduto.data_nfe_entrada || '',
            peso_equipamento: formCadastroProduto.peso_equipamento || '',
            preco_equipamento: formCadastroProduto.preco_equipamento || '',
            mac: formCadastroProduto.mac || '',
            imei: formCadastroProduto.imei || '',
            id_status_produto: formCadastroProduto.id_status_produto?.value || '',
            kit_spare: formCadastroProduto.kit_spare?.value || '',
            local: formCadastroProduto.local?.value || '',
            id_tecnico: formCadastroProduto.id_tecnico?.value || '',
            tipo_tecnico: formCadastroProduto.tipo_tecnico || '',
            email: formCadastroProduto.email || '',
            patrimonio: formCadastroProduto.patrimonio || '',
            box: formCadastroProduto?.box?.value || '',
            id_fabricante: formCadastroProduto.id_fabricante?.value || '',
            id_corredor: formCadastroProduto.id_corredor?.value || '',
            id_estante: formCadastroProduto.id_estante?.value || '',
            id_prateleira: formCadastroProduto.id_prateleira?.value || '',
            id_representante: formCadastroProduto.id_representante?.value || ''
        }

        const toastCreateProduct = toast.loading("Alterando dados do produto, aguarde...");

        let dataReturn = await updateProductData(productId, formData);
        if (dataReturn?.data?.data?.message === 'Produto atualizado com sucesso!') {
            toast.update(toastCreateProduct, { render: 'Produto atualizado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setUpdateProducts();
            setUpdateParent(true);
        } else if (dataReturn?.data?.message === 'Já existe um produto com esse número de série.') {
            toast.update(toastCreateProduct, { render: 'Este serial já existe!', type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastCreateProduct, { render: 'Ocorreu um erro ao atualizar o produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getProductDataById() {
        const toastProducts = toast.loading('Carregando dados do Produto, aguarde...');

        let dataReturn = await getProductByProductId(productId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let product = dataReturn?.data?.data;
            setFormCadastroProduto(prevState => (
                {
                    ...prevState,
                    ...product,
                    kit_spare: { value: product?.kit_spare || '', label: product?.kit_spare || '' },
                    id_fabricante: { value: product?.id_fabricante || '', label: product?.manufacturer?.nome_fabricante || '' },
                    id_integradora: { value: product?.id_integradora || '', label: product?.contract?.integrator?.nome || '' },
                    id_contrato: { value: product?.id_contrato || '', label: product?.contract?.contrato || '' },
                    valorado: { value: product?.valorado || '', label: product?.valorado || '' },
                    local: { value: product?.local || '', label: product?.local || '' },
                    box: { value: product?.box || '', label: product?.box || '' },
                    id_corredor: { value: product?.id_corredor || '', label: product?.hallway?.valor_opcao || '' },
                    id_estante: { value: product?.id_estante || '', label: product?.stand?.valor_opcao || '' },
                    id_prateleira: { value: product?.id_prateleira || '', label: product?.shelf?.valor_opcao || '' },
                    id_representante: { value: product?.id_representante || '', label: product?.technician?.partner?.nome_representante || '' },
                    id_tecnico: { value: product?.id_tecnico || '', label: product?.technician?.nome_tec || '' },
                    id_status_produto: { value: product?.status?.id || '', label: product?.status?.valor_opcao || '' }
                }
            ));
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }
    }

    async function handleTechnician(value, name) {

        if(['', null, undefined].includes(value?.value)){
            setFormCadastroProduto(prevState => ({ ...prevState, [name]: '', email: '', tipo_tecnico: '' }));
            return;
        }

        let dataReturn = await getTechnicianById(value?.value);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn.data.data;
            let tipoTecnico = "Não Definido";

            if (data.id_representante !== null) {
                tipoTecnico = data.id_representante === 1 ? 'CLT' : 'Parceiro'
            }
            setFormCadastroProduto(prevState => ({ ...prevState, [name]: value, email: data?.email_tec || '', tipo_tecnico: tipoTecnico }));
        } else {
            toast.warning('Ocorreu um erro ao procurar os dados do técnico!');
        }
    }

    function setIntegradora(value, name) {
        setFormCadastroProduto(prevState => ({ ...prevState, [name]: value, id_contrato: '' }));
    }

    function setRepresentante(value, name) {
        setFormCadastroProduto(prevState => ({ ...prevState, [name]: value, id_tecnico: '', email: '', tipo_tecnico: '' }));
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(productId)) {
            setFormCadastroProduto(initialState);
            getProductDataById();
        }
    }, [isOpen, productId]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title={viewMode === 'view' ? 'Visualizar Produto' : 'Atualizar Produto'}
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '95vh',
            }}
        >
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role='tablist'>
                    <button type="button" className="nav-link active" id="nav-detalhes-tab" data-toggle='tab' data-target='#nav-detalhes' role='tab' aria-controls='nav-detalhes' aria-selected='true'>Detalhes do Produto</button>
                    <button type="button" className="nav-link" id="nav-ged-tab" data-toggle='tab' data-target='#nav-ged' role='tab' aria-controls='nav-ged' aria-selected='true'>GED</button>
                    <button type="button" className="nav-link" id="nav-timeline-tab" data-toggle='tab' data-target='#nav-timeline' role='tab' aria-controls='nav-timeline' aria-selected='true'>Linha do Tempo</button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-detalhes" role='tabpanel' aria-labelledby='nav-detalhes-tab'>
                    <form onSubmit={submitForm}>
                        <div className='row'>
                            <div className="col-md-4 col-sm-12">
                                <h5>Informações Básicas</h5>
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="nomeProduto">Nome do Produto</label>
                                        <input type="text" name="nome_produto" id="nomeProduto" className="form-control" placeholder="Nome do Produto" value={formCadastroProduto?.nome_produto || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} required />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="modeloProduto">Modelo</label>
                                        <input type="text" name="modelo" id="modeloProduto" className="form-control" placeholder="Modelo" value={formCadastroProduto?.modelo || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="serialProduto">Serial</label>
                                        <input type="text" name="serial" id="serialProduto" className="form-control" placeholder="Serial" value={formCadastroProduto?.serial || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} required />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="macProduto">MAC</label>
                                        <input type="text" name="mac" id="macProduto" className="form-control" placeholder="MAC" value={formCadastroProduto?.mac || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="imeiProduto">IMEI</label>
                                        <input type="text" name="imei" id="imeiProduto" className="form-control" placeholder="IMEI" value={formCadastroProduto?.imei || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="patrimonioProduto">Patrimônio</label>
                                        <input type="text" name="patrimonio" id="patrimonioProduto" className="form-control" placeholder="Patrimônio" value={formCadastroProduto?.patrimonio || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <h5>Informações de Entrada</h5>
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="codigoSapProduto">Código SAP</label>
                                        <input type="text" name="codigo_sap" id="codigoSapProduto" className="form-control" placeholder="Código SAP" value={formCadastroProduto?.codigo_sap || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="nfeEntradaProduto">NFe de Entrada</label>
                                        <input type="text" name="nfe_entrada" id="nfeEntradaProduto" className="form-control" placeholder="NFe de Entrada" value={formCadastroProduto?.nfe_entrada || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="dataNfeProduto">Data da NFe de Entrada</label>
                                        <input type="date" name="data_nfe_entrada" id="dataNfeProduto" className="form-control" value={formCadastroProduto?.data_nfe_entrada || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="pesoEquipamentoProduto">Peso do Equipamento (KG)</label>
                                        <input type="number" min="0" step="0.01" name="peso_equipamento" id="pesoEquipamentoProduto" className="form-control" placeholder="Peso do Equipamento" value={formCadastroProduto?.peso_equipamento || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="precoEquipamentoProduto">Preço do Equipamento</label>
                                        <input type="number" min="0" step="0.01" name="preco_equipamento" id="precoEquipamentoProduto" className="form-control" placeholder="Preço do Equipamento" value={formCadastroProduto?.preco_equipamento || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={viewMode === 'view'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <h5>Outras Informações</h5>
                                <GenericSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName={'kit_spare'} title={'Kit Spare'} isDisabled={viewMode === 'view'}></GenericSelect>
                                <FabricanteSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_fabricante' isDisabled={viewMode === 'view'}></FabricanteSelect>
                                <IntegradoraSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_integradora' isDisabled={viewMode === 'view'} advancedSetFormData={setIntegradora}></IntegradoraSelect>
                                <ContratosSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_contrato' dependsOnIntegradora={true} isDisabled={viewMode === 'view'} id_integradora_string={[formCadastroProduto?.id_integradora?.value].toString()}></ContratosSelect>
                                <GenericSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName={'valorado'} title={'Valorado/Não Valorado'} isDisabled={viewMode === 'view'}></GenericSelect>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <h5>Localização</h5>
                                <GenericSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} genericOption={'UfOptions'} fieldName={'local'} title={'Local (UF)'} isDisabled={viewMode === 'view'}></GenericSelect>
                                <BoxSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName={'box'} uf={formCadastroProduto?.local?.value} isDisabled={viewMode === 'view'}></BoxSelect>
                                <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_corredor'} fieldName={'id_corredor'} module={'produtos'} title={'Corredor'} isDisabled={viewMode === 'view'}></ModuleOptionsSelect>
                                <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_estante'} fieldName={'id_estante'} module={'produtos'} title={'Estante'} isDisabled={viewMode === 'view'}></ModuleOptionsSelect>
                                <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_prateleira'} fieldName={'id_prateleira'} module={'produtos'} title={'Prateleiras'} isDisabled={viewMode === 'view'}></ModuleOptionsSelect>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <h5>Técnico e Contato</h5>
                                <div className="row">
                                    <RepresentanteSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_representante' isDisabled={viewMode === 'view'} advancedSetFormData={setRepresentante}></RepresentanteSelect>
                                    <TecnicosSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_tecnico' id_representante={formCadastroProduto?.id_representante?.value || ''} isDisabled={viewMode === 'view'} advancedSetFormData={handleTechnician}></TecnicosSelect>
                                    <div className="col-12">
                                        <label htmlFor="tipoTecnicoProduto">Tipo de Técnico</label>
                                        <input type="text" name="tipo_tecnico" id="tipoTecnicoProduto" className="form-control" placeholder="Tipo de Técnico" value={formCadastroProduto?.tipo_tecnico || ''} readOnly />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="emailProduto">Email</label>
                                        <input type="text" name="email" id="emailProduto" className="form-control" placeholder="Email" value={formCadastroProduto?.email || ''} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <h5>Status do Produto</h5>
                                <div className="row">
                                    <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'} isDisabled={viewMode === 'view'}></ModuleOptionsSelect>
                                </div>
                            </div>
                        </div>
                        <If condition={viewMode === 'edit'}>
                            <div className="row">
                                <div className="col-12 mt-1">
                                    <button type="submit" className='btn btn-secondary float-right'>Salvar</button>
                                </div>
                            </div>
                        </If>
                    </form>
                </div>
                <div className="tab-pane fade" id="nav-ged" role='tabpanel' aria-labelledby='nav-ged-tab'>
                    <GedTable idProduto={productId} submodule='produtos' viewMode={viewMode} addTitle='Novo Documento' setUpdateParent={setUpdateParent}></GedTable>
                </div>
                <div className="tab-pane fade" id="nav-timeline" role='tabpanel' aria-labelledby='nav-timeline-tab'>
                    <Timeline idProduto={productId} setUpdateTimeline={setUpdateParent} updateTimeline={updateParent}></Timeline>
                </div>
            </div>
        </Modal>);
}