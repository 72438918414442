import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getOptionsByModuleField } from '../../services/Options';

export default function ModuleOptionsSelect({ formData, setFormData, fieldName, title, module, field, cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [moduleOptions, setModuleOptions] = useState([]);

    async function getCorredoresOptions() {
        let dataReturn = await getOptionsByModuleField(module, field);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data?.data.length > 0) {
            let optionsData = dataReturn?.data?.data?.data;
            let optionsArray = optionsData.map((item, idx) => {
                return ({ value: item.id, label: item.valor_opcao });
            });
            setModuleOptions(optionsArray);
        } else {
            setModuleOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getCorredoresOptions();
    }, [])

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>{title}</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={moduleOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}