import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate, useParams } from 'react-router-dom';
import { withMask } from 'use-mask-input';
import { getIntegratorById, updateIntegratorData } from '../../../services/Integrators';
import Cep from '../../../common/Cep/Cep';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import If from '../../../common/Layout/If';

export default function IntegratorDetails(props) {

    const { id } = useParams();
    const navigate = useNavigate();
    const initialState = {
        id: '',
        nome: '',
        nome_fantasia: '',
        cnpj: '',
        inscricao_estadual: '',
        inscricao_municipal: '',
        endereco_principal: '',
        cidade_principal: '',
        estado_principal: '',
        bairro_principal: '',
        cep_principal: '',
        descricao: '',
        ativo: '',
        nome_fin: '',
        email_fin: '',
        telefone_fixo_fin: '',
        celular_fin: '',
        nome_acio: '',
        email_acio: '',
        telefone_fixo_acio: '',
        celular_acio: '',
        nome_fech: '',
        email_fech: '',
        telefone_fixo_fech: '',
        celular_fech: '',
        endereco_cobranca: '',
        bairro_cobranca: '',
        cidade_cobranca: '',
        estado_cobranca: '',
        cep_cobranca: '',
        endereco_nf: '',
        bairro_nf: '',
        cidade_nf: '',
        estado_nf: '',
        cep_nf: ''
    }

    const [formIntegratorData, setFormIntegratorData] = useState(initialState);
    const [updateData, setUpdataData] = useState(false);

    async function getIntegrator() {
        const integratorDataToast = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getIntegratorById(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(integratorDataToast, { render: `Dados carregados com sucesso!`, type: "success", isLoading: false, autoClose: 1500 });
            let integratorData = dataReturn?.data?.data || initialState;
            setFormIntegratorData({
                ...integratorData,
                nome_fin: integratorData?.contacts[0]?.nome_fin || '',
                email_fin: integratorData?.contacts[0]?.email_fin || '',
                telefone_fixo_fin: integratorData?.contacts[0]?.telefone_fixo_fin || '',
                celular_fin: integratorData?.contacts[0]?.celular_fin || '',
                nome_acio: integratorData?.contacts[0]?.nome_acio || '',
                email_acio: integratorData?.contacts[0]?.email_acio || '',
                telefone_fixo_acio: integratorData?.contacts[0]?.telefone_fixo_acio || '',
                celular_acio: integratorData?.contacts[0]?.celular_acio || '',
                nome_fech: integratorData?.contacts[0]?.nome_fech || '',
                email_fech: integratorData?.contacts[0]?.email_fech || '',
                telefone_fixo_fech: integratorData?.contacts[0]?.telefone_fixo_fech || '',
                celular_fech: integratorData?.contacts[0]?.celular_fech || '',
                endereco_cobranca: integratorData?.billing_addresses[0]?.endereco_cobranca || '',
                bairro_cobranca: integratorData?.billing_addresses[0]?.bairro_cobranca || '',
                cidade_cobranca: integratorData?.billing_addresses[0]?.cidade_cobranca || '',
                estado_cobranca: integratorData?.billing_addresses[0]?.estado_cobranca || '',
                cep_cobranca: integratorData?.billing_addresses[0]?.cep_cobranca || '',
                endereco_nf: integratorData?.invoice_addresses[0]?.endereco_nf || '',
                bairro_nf: integratorData?.invoice_addresses[0]?.bairro_nf || '',
                cidade_nf: integratorData?.invoice_addresses[0]?.cidade_nf || '',
                estado_nf: integratorData?.invoice_addresses[0]?.estado_nf || '',
                cep_nf: integratorData?.invoice_addresses[0]?.cep_nf || ''
            });
        } else if (dataReturn?.data?.message.includes("No query results for model")) {
            toast.update(integratorDataToast, { render: `Este registro não existe!`, type: "warning", isLoading: false, autoClose: 1500 });
            navigate("/cadastros/integradoras/dados");
        } else {
            toast.update(integratorDataToast, { render: `Ocorreu um erro ao carregar os dados!`, type: "warning", isLoading: false, autoClose: 1500 });
            setFormIntegratorData(initialState);
        }
    }

    async function submitForm() {

        let formData = {
            id: id,
            nome: formIntegratorData?.nome,
            nome_fantasia: formIntegratorData?.nome_fantasia,
            cnpj: formIntegratorData?.cnpj,
            inscricao_estadual: formIntegratorData?.inscricao_estadual,
            inscricao_municipal: formIntegratorData?.inscricao_municipal,
            endereco_principal: formIntegratorData?.endereco_principal,
            cidade_principal: formIntegratorData?.cidade_principal,
            estado_principal: formIntegratorData?.estado_principal,
            bairro_principal: formIntegratorData?.bairro_principal,
            cep_principal: formIntegratorData?.cep_principal_,
            descricao: formIntegratorData?.descricao,
            ativo: formIntegratorData?.ativo,
            contatos: [
                {
                    nome_fin: formIntegratorData?.nome_fin,
                    email_fin: formIntegratorData?.email_fin,
                    telefone_fixo_fin: formIntegratorData?.telefone_fixo_fin,
                    celular_fin: formIntegratorData?.celular_fin,
                    nome_acio: formIntegratorData?.nome_acio,
                    email_acio: formIntegratorData?.email_acio,
                    telefone_fixo_acio: formIntegratorData?.telefone_fixo_acio,
                    celular_acio: formIntegratorData?.celular_acio,
                    nome_fech: formIntegratorData?.nome_fech,
                    email_fech: formIntegratorData?.email_fech,
                    telefone_fixo_fech: formIntegratorData?.telefone_fixo_fech,
                    celular_fech: formIntegratorData?.celular_fech
                }
            ],
            endereco_cobranca: [
                {
                    endereco_cobranca: formIntegratorData?.endereco_cobranca,
                    bairro_cobranca: formIntegratorData?.bairro_cobranca,
                    cidade_cobranca: formIntegratorData?.cidade_cobranca,
                    estado_cobranca: formIntegratorData?.estado_cobranca,
                    cep_cobranca: formIntegratorData?.cep_cobranca
                }
            ],
            endereco_nota: [
                {
                    endereco_nf: formIntegratorData?.endereco_nf,
                    bairro_nf: formIntegratorData?.bairro_nf,
                    cidade_nf: formIntegratorData?.cidade_nf,
                    estado_nf: formIntegratorData?.estado_nf,
                    cep_nf: formIntegratorData?.cep_nf
                }
            ]
        }

        const toastUpdateForm = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updateIntegratorData(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUpdateForm, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getIntegrator();
            setUpdataData(false);
        } else {
            toast.update(toastUpdateForm, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        getIntegrator();
    }, []);


    return (
        <Content headerTitle="Detalhes Integradora">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <If condition={!updateData}>
                            <button type="button" className="btn btn-primary" onClick={() => (setUpdataData(prevState => !prevState))}>Alterar</button>
                        </If>
                        <If condition={updateData}>
                            <button type="button" className="btn btn-primary" onClick={submitForm}>Salvar Alterações</button>
                        </If>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Integradora / Contrato</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                            <label htmlFor="nome">Razão Social</label>
                            <input type="text" className="form-control" name="nome" id="nome" value={formIntegratorData?.nome || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} required />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="nomeFantasia">Nome Fantasia</label>
                            <input type="text" className="form-control" name="nome_fantasia" id="nomeFantasia" value={formIntegratorData?.nome_fantasia || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="cnpj">CNPJ</label>
                            <input type="text" className="form-control" name="cnpj" id="cnpj" value={formIntegratorData?.cnpj || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="inscricaoEstadual">Insc. Estadual</label>
                            <input type="text" className="form-control" name="inscricao_estadual" id="inscricaoEstadual" value={formIntegratorData?.inscricao_estadual || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="inscricaoMunicipal">Insc. Municipal</label>
                            <input type="text" className="form-control" name="inscricao_municipal" id="inscricaoMunicipal" value={formIntegratorData?.inscricao_municipal || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5><i className='fas fa-dot-circle'></i> Endereço Principal</h5>
                            </div>
                        </div>
                        <Cep
                            formData={formIntegratorData}
                            setFormData={setFormIntegratorData}
                            formDataNames={{ cep: 'cep_principal', estado: 'estado_principal', cidade: 'cidade_principal', bairro: 'bairro_principal', logradouro: 'endereco_principal' }}
                            readOnly={!updateData}
                            >
                        </Cep>
                    </div>
                    <div className="col-lg-4 col-md-12 border-right border-left">
                        <div className="row">
                            <div className="col-12">
                                <h5><i className='fas fa-dot-circle'></i> Endereço Cobrança</h5>
                            </div>
                        </div>
                        <Cep
                            formData={formIntegratorData}
                            setFormData={setFormIntegratorData}
                            formDataNames={{ cep: 'cep_cobranca', estado: 'estado_cobranca', cidade: 'cidade_cobranca', bairro: 'bairro_cobranca', logradouro: 'endereco_cobranca' }}
                            readOnly={!updateData}
                            >
                        </Cep>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5><i className='fas fa-dot-circle'></i> Endereço Nota Fiscal</h5>
                            </div>
                        </div>
                        <Cep
                            formData={formIntegratorData}
                            setFormData={setFormIntegratorData}
                            formDataNames={{ cep: 'cep_nf', estado: 'estado_nf', cidade: 'cidade_nf', bairro: 'bairro_nf', logradouro: 'endereco_nf' }}
                            readOnly={!updateData}
                            >
                        </Cep>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5><i className='fas fa-dot-circle'></i> Contato Financeiro</h5>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="nomeFin">Nome</label>
                                <input type="text" name="nome_fin" id="nomeFin" className="form-control" value={formIntegratorData?.nome_fin || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="emailFin">Email</label>
                                <input type="email" name="email_fin" id="emailFin" className="form-control" value={formIntegratorData?.email_fin || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="telefoneFixoFin">Telefone Fixo</label>
                                <input type="text" name="telefone_fixo_fin" id="telefoneFixoFin" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formIntegratorData?.telefone_fixo_fin || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="celularFin">Celular</label>
                                <input type="text" name="celular_fin" id="celularFin" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formIntegratorData?.celular_fin || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 border-right border-left">
                        <div className="row">
                            <div className="col-12">
                                <h5><i className='fas fa-dot-circle'></i> Contato Acionamento</h5>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="nomeAcio">Nome</label>
                                <input type="text" name="nome_acio" id="nomeAcio" className="form-control" value={formIntegratorData?.nome_acio || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="emailAcio">Email</label>
                                <input type="email" name="email_acio" id="emailAcio" className="form-control" value={formIntegratorData?.email_acio || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="telefoneFixoAcio">Telefone Fixo</label>
                                <input type="text" name="telefone_fixo_acio" id="telefoneFixoAcio" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formIntegratorData?.telefone_fixo_acio || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="celularAcio">Celular</label>
                                <input type="text" name="celular_acio" id="celularAcio" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formIntegratorData?.celular_acio || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5><i className='fas fa-dot-circle'></i> Contato Fechamento</h5>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="nomeFech">Nome</label>
                                <input type="text" name="nome_fech" id="nomeFech" className="form-control" value={formIntegratorData?.nome_fech || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="emailFech">Email</label>
                                <input type="email" name="email_fech" id="emailFech" className="form-control" value={formIntegratorData?.email_fech || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="telefoneFixoFech">Telefone Fixo</label>
                                <input type="text" name="telefone_fixo_fech" id="telefoneFixoFech" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formIntegratorData?.telefone_fixo_fech || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label htmlFor="celularFech">Celular</label>
                                <input type="text" name="celular_fech" id="celularFech" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formIntegratorData?.celular_fech || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="descricao">Observações</label>
                            <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formIntegratorData?.descricao || ''} onChange={(e) => (setFormIntegratorData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData}></textarea>
                        </div>
                    </div>
                </div>
            </Card>
        </Content>
    );
}