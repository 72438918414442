import React, { useRef, useState } from 'react';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';

export default function FloatSearchButton(props) {
    const [searchDivState, setSearchDivState] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    
    const fabRef = useRef();
    const inputRef = useRef(null);
    const searchDivRef = useRef(null);

    function handleSearchDiv(){
        if(searchDivState){
            fabRef.current.style.opacity = 0.5;
            setSearchInput('');
            searchDivRef.current.style.zIndex = '-1';
            setSearchDivState(false);
        }

        if(!searchDivState){
            fabRef.current.style.opacity = 1;
            setSearchDivState(true);
            searchDivRef.current.style.zIndex = '9999';
            setTimeout(()=>{
                inputRef.current.focus();
            }, 50);
        }
    }

    function handleSearch(){
        handleSearchDiv();
        setSearchInput('');
    }

    return (
        <div>
            <Fab ref={fabRef} size='small' color="primary" aria-label="pesquisar" sx={{ position: 'fixed', top: 125, right: 10, opacity: 0.5, '&:hover': { opacity: 1}}} onClick={()=>handleSearchDiv()}>
                <i className='fas fa-search'></i>
            </Fab>
            <div style={{ position: 'fixed', top: 125, right: 60 }} className={searchDivState ? 'd-block' : 'd-none'} ref={searchDivRef}>
                <div className='input-group'>
                    <input type='text' ref={inputRef} placeholder='Digite o nº do chamado' className='form-control' value={searchInput} onInput={(e)=>setSearchInput(e.target.value)}></input>
                    <Link to={`/chamados/dados/all?data_inicial=2023-01-01&num_chamado=${searchInput}`} type='button' className='btn btn-primary ml-2' onClick={()=>handleSearch()}>Pesquisar</Link>
                </div>
            </div>
        </div>);
}