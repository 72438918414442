import Echo from 'laravel-echo';
import socket from 'socket.io-client';

window.io = socket;

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: `${process.env.REACT_APP_WEBSOCKET || window.location.hostname}:6001`,
    transports: ["websocket", "polling", "flashsocket"],
    auth: {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token') // Adicione autenticação, se necessário
        }
    }
});

export default window.Echo;