import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Table from '../../../../common/Layout/Table/Table';
import Card from '../../../../common/Layout/Card';
import { deleteProduct, duplicateProduct, getAllProducts } from '../../../../services/Product';
import { toast } from 'react-toastify';
import CustomMessageData from '../../../../common/Layout/CustomMessageData';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import CreateProduct from './CreateProduct';
import ProductDetails from './ProductDetails/ProductDetails';
import Swal from 'sweetalert2';
import BulkUpdate from './BulkUpdate';
import CreateShipment from './CreateShipment';
import If from '../../../../common/Layout/If';

export default function ShowProducts() {
    const [loading, setLoading] = useState(true);
    const [productsData, setProductsData] = useState([])
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
    const [productId, setProductId] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    const [isCreateShipmentModalOpen, setIsCreateShipmentModalOpen] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const columns = [
        {
            id: 'id_produto',
            name: 'ID',
            selector: row => row.id_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_produto',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openProductModal(row.id_produto, 'view')}>Ver Produto</button>
                        <button type='button' className="dropdown-item" onClick={() => showDuplicateProductSwal(row.id_produto)}>Duplicar</button>
                        <button type='button' className="dropdown-item" onClick={() => openProductModal(row.id_produto, 'edit')}>Editar</button>
                        {
                            ['spare_tecnico', 'em_estoque'].includes(row.chave_opcao_status_produto) &&
                            (<button type='button' className="dropdown-item" onClick={() => openCreateShipmentModal(row.id_produto)}>Criar Remessa</button>)
                        }
                        <button type='button' className="dropdown-item" onClick={() => showDeleteProductSwal(row.id_produto)}>Deletar</button>
                    </div>
                </div>
            ),
        },
        {
            id: 'status_produto',
            name: 'Status Produto',
            selector: row => row.status_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
            selector: row => row.nome_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_produto',
            name: 'Modelo',
            selector: row => row.modelo_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'serial_produto',
            name: 'Serial',
            selector: row => row.serial_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'mac_produto',
            name: 'MAC',
            selector: row => row.mac_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'imei_produto',
            name: 'IMEI',
            selector: row => row.imei_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'patrimonio_produto',
            name: 'Patrimônio',
            selector: row => row.patrimonio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'codigo_sap_produto',
            name: 'Codigo SAP',
            selector: row => row.codigo_sap_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'nfe_entrada_produto',
            name: 'NFe Entrada',
            selector: row => row.nfe_entrada_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_nfe_entrada_produto',
            name: 'Data NFe Entrada',
            selector: row => row.data_nfe_entrada_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'peso_equipamento_produto',
            name: 'Peso Equipamento',
            selector: row => row.peso_equipamento_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'preco_equipamento_produto',
            name: 'Preço Equipamento',
            selector: row => row.preco_equipamento_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'kit_spare_produto',
            name: 'Kit Spare',
            selector: row => row.kit_spare_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'fabricante_produto',
            name: 'Fabricante',
            selector: row => row.fabricante_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'integradora_produto',
            name: 'Integradora',
            selector: row => row.integradora_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'contrato_produto',
            name: 'Contrato',
            selector: row => row.contrato_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'valorado_produto',
            name: 'Valorado',
            selector: row => row.valorado_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'local_produto',
            name: 'Local',
            selector: row => row.local_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'corredor_produto',
            name: 'Corredor',
            selector: row => row.corredor_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'estante_produto',
            name: 'Estante',
            selector: row => row.estante_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tecnico_produto',
            name: 'Técnico',
            selector: row => row.tecnico_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_tecnico_produto',
            name: 'Tipo do Técnico',
            selector: row => row.tipo_tecnico_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'email_produto',
            name: 'Email',
            selector: row => row.email_produto,
            sortable: true,
            wrap: true
        }
    ];

    async function handleChange(selectedRows) {
        let ids = selectedRows?.selectedRows?.map((item, idx) => (item.id_produto));
        setSelectedRows(ids);
    }

    async function duplicateProductById(id) {

        const toastDuplicate = toast.loading("Duplicando dados, aguarde...");

        let dataReturn = await duplicateProduct(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastDuplicate, { render: 'Produto duplicado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getProducts();
            openProductModal(dataReturn?.data?.data?.new_product?.id, 'edit');
        } else {
            toast.update(toastDuplicate, { render: 'Ocorreu um erro ao duplicar o produto!', type: "warning", isLoading: false, autoClose: 1500 });
            console.log(dataReturn);
        }
    }

    async function showDuplicateProductSwal(id) {
        let data = await Swal.fire({
            title: 'Deseja mesmo duplicar este produto?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (data.isConfirmed) {
            duplicateProductById(id);
        }
    }

    async function showDeleteProductSwal(id) {
        let data = await Swal.fire({
            title: 'Deseja mesmo excluir este produto?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (data.isConfirmed) {
            deleteProductById(id);
        }
    }

    async function deleteProductById(id) {
        const toastDuplicate = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deleteProduct(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastDuplicate, { render: 'Produto excluido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getProducts();
        } else {
            toast.update(toastDuplicate, { render: 'Ocorreu um erro ao excluido o produto!', type: "warning", isLoading: false, autoClose: 1500 });
            console.log(dataReturn);
        }
    }

    async function getProducts() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getAllProducts();

        if (dataReturn?.data?.response === 'success') {

            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let products = dataReturn?.data?.data.map((item, idx) => {
                let tipoTecnico = "";

                if (item.technician?.id_representante !== null) {
                    tipoTecnico = item.technician?.id_representante === 1 ? 'CLT' : 'Parceiro';
                }

                return (
                    {
                        id_produto: item.id,
                        status_produto: item.status?.valor_opcao || '',
                        nome_produto: item.nome_produto,
                        modelo_produto: item.modelo || '',
                        serial_produto: item.serial,
                        mac_produto: item.mac || '',
                        imei_produto: item.imei || '',
                        patrimonio_produto: item.patrimonio || '',
                        codigo_sap_produto: item.codigo_sap || '',
                        nfe_entrada_produto: item.nfe_entrada || '',
                        data_nfe_entrada_produto: item.data_nfe_entrada || '',
                        peso_equipamento_produto: item.peso_equipamento || '',
                        preco_equipamento_produto: item.preco_equipamento || '',
                        kit_spare_produto: item.kit_spare || '',
                        fabricante_produto: '',
                        integradora_produto: item.integrator?.nome || '',
                        contrato_produto: item.contract?.contrato || '',
                        valorado_produto: item.valorado || '',
                        local_produto: item.local || '',
                        corredor_produto: item.hallway?.valor_opcao || '',
                        estante_produto: item.stand?.valor_opcao || '',
                        tecnico_produto: item.technician?.nome_tec || '',
                        tipo_tecnico_produto: tipoTecnico,
                        email_produto: item.technician?.email_tec || '',
                        chave_opcao_status_produto: item?.status?.chave_opcao || ''
                    }
                );
            });

            setProductsData(products);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setProductsData([]);
        }
        setLoading(false);
    }

    function openProductModal(id, mode) {
        setProductId(id);
        setViewMode(mode);
        setIsUpdateProductModalOpen(true);
    }

    function openCreateShipmentModal(id) {
        setProductId(id);
        setIsCreateShipmentModalOpen(true);
    }

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <Content headerTitle="Gerenciamento de Produtos">
            <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <CreateProduct isOpen={isAddProductModalOpen} setModalOpen={() => setIsAddProductModalOpen(!isAddProductModalOpen)} setUpdateProducts={getProducts}></CreateProduct>
            <ProductDetails isOpen={isUpdateProductModalOpen} setModalOpen={() => setIsUpdateProductModalOpen(!isUpdateProductModalOpen)} productId={productId} viewMode={viewMode} setUpdateProducts={getProducts}></ProductDetails>
            <BulkUpdate isOpen={isBulkUpdateModalOpen} setModalOpen={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)} setUpdateProducts={getProducts} handleClearRows={() => setToggledClearRows(!toggledClearRows)} ids={[...selectedRows]}></BulkUpdate>
            <CreateShipment isOpen={isCreateShipmentModalOpen} setIsOpen={() => setIsCreateShipmentModalOpen(!isCreateShipmentModalOpen)} productId={productId} setUpdateProducts={getProducts} setUpdateLogistic={setUpdateLogistic}></CreateShipment>
            <Card title="Todos os Produtos">
                <div className='row'>
                    <div className='col-12 d-flex justify-content-end flex-wrap'>
                        <If condition={selectedRows?.length > 0}>
                            <div className="dropdown d-inline m-1">
                                <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações em Massa</button>
                                <div className="dropdown-menu">
                                    <button type='button' className="dropdown-item" onClick={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)}>Editar Selecionados</button>
                                </div>
                            </div>
                        </If>
                        <button className='btn btn-primary m-1' onClick={() => setIsAddProductModalOpen(!isAddProductModalOpen)}><i className='fas fa-plus'></i> Adicionar Produto</button>
                    </div>
                    <div className='col-12'>
                        <If condition={loading}>
                            <CustomMessageData></CustomMessageData>
                        </If>
                        <If condition={!loading}>
                            <Table columns={columns} data={productsData} selectableRows={true} id="table" onSelectableRowsChange={handleChange} clearSelectedRows={toggledClearRows}></Table>
                        </If>
                    </div>
                </div>
            </Card>
        </Content>
    )
}