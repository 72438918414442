import interceptorInstance from "./Interceptor";

export async function getTechnicianById(id){
    try{
         let data = await interceptorInstance.get('technician/' + id);
         return data;
    }catch(e){
        return e.response;
    }
}

export async function changeTechnicianCredentialById(id, status){
    try{
        let data = await interceptorInstance.patch(`technician/credencial/${id}/${status}`, {});
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getBestTechnicianByAvailability(params){
    try{
        let data = await interceptorInstance.post(`technician/availability`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getBestTechnicianByAvailabilityInTransit(params){
    try{
        let data = await interceptorInstance.post(`technician/availability-in-transit`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getBestTechnicianByAvailabilityHomeAddress(params){
    try{
        let data = await interceptorInstance.post(`technician/availability-by-home-address`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPartnersByTechnicianId(id){
    try{
        let data = await interceptorInstance.get(`technician/search/${id}/partners`);
        return data;
    }catch(e){
        return e.response;
    }
}