import axios from "axios";

const api = process.env.REACT_APP_API_VIACEP || 'https://viacep.com.br/ws/'

export async function getDataByCep(cep){
    try{
        let dados = await axios.get(api + cep + "/json");
        return dados;
    }catch(e){
        return e.response;
    }
}

