import React from 'react';
import Card from '../../../common/Layout/Card';

export default function CircuitoCard({ detailsData = {}, setDetailsData = null}) {
    return (<Card title={"Circuito"} icon={"fa-dot-circle"}>
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="">Circuito</label>
                    <input type="text" name="info_adicional_circuito" className="form-control" value={detailsData?.info_adicional_circuito} onChange={(e) => setDetailsData(prevState => ({ ...prevState, info_adicional_circuito: e.target.value }))} />
                </div>
            </div>
        </div>
    </Card>)
}