import React, { useEffect, useState } from 'react';
import Table from '../../../../../common/Layout/Table/Table';
import { deletePartnerValuesById, getPartnersValuesByPartnerId, insertPartnerValues } from '../../../../../services/PartnerValues';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PartnerValuesDetails from './PartnerValuesDetails';
import If from '../../../../../common/Layout/If';
import usePermissions from '../../../../../hooks/usePermissions';
import SpecialPartnerTable from '../SpecialPartnerValues/SpecialPartnerTable';
import CreateSpecialPartnerValue from '../SpecialPartnerValues/CreateSpecialPartnerValue';
import CreateParnerValues from './CreatePartnerValues';

export default function PartnerValuesTable({ id_representante = '', disableData = true }) {

    const [data, setData] = useState([]);
    const [specialValuesData, setSpecialValueData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCreateSpecialValueOpen, setIsCreateSpecialValueOpen] = useState(false);
    const [isCreateValueOpen, setIsCreateValueOpen] = useState(false);
    const [idValorRepresentante, setIdValorRepresentante] = useState('');
    const [mode, setMode] = useState("update");
    const { checkPermissionGroup } = usePermissions();

    const columns = [
        {
            id: 'action_valores_contrato',
            name: 'Ação',
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled={disableData}>
                        Ações
                    </button>
                    <div className="dropdown-menu">
                        <button type="button" className='dropdown-item' onClick={() => openPartnerValuesDetails(row.id_valores_contrato, 'update')}>Editar</button>
                        <If condition={(checkPermissionGroup(['gerente_operacoes', 'diretoria'])) && ['reprovado', 'pendente'].includes(row.status_aprovacao_contrato)}>
                            <button type="button" className='dropdown-item' onClick={() => openPartnerValuesDetails(row.id_valores_contrato, 'approve')}>Aprovar</button>
                        </If>
                        <If condition={row.status_aprovacao_contrato === 'aprovado'}>
                            <button type="button" className="dropdown-item" onClick={() => openCreateSpecialValues(row.id_valores_contrato)}>Criar Valor Especial</button>
                        </If>
                        <button type="button" className='dropdown-item' onClick={() => showDeletePartnerValuesSwal(row.id_valores_contrato)}>Excluir</button>
                    </div>
                </div>
            ),
            sortable: true,
            wrap: true
        },
        {
            id: 'status_aprovacao_contrato',
            name: 'Status Aprovação',
            selector: row => row.status_aprovacao_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'contrato_valores_contrato',
            name: 'Contrato',
            selector: row => row.contrato_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'escopo_valores_contrato',
            name: 'Escopo',
            selector: row => row.escopo_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'valor_chamado_valores_contrato',
            name: 'R$ Chamado',
            selector: row => row.valor_chamado_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'valor_fora_valores_contrato',
            name: 'Valor Fora Hrs Comercial',
            selector: row => row.valor_fora_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'chamado_improdutivo_valores_contrato',
            name: 'Chamado Improdutivo',
            selector: row => row.chamado_improdutivo_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'h_a_diurna_valores_contrato',
            name: 'H.A (Diurna) Após 3h31m',
            selector: row => row.h_a_diurna_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'h_a_noturna_valores_contrato',
            name: 'H.A (Noturna) Após 3h31m',
            selector: row => row.h_a_noturna_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'valor_apos_51_km_valores_contrato',
            name: 'R$ KM Após 51 Km',
            selector: row => row.valor_apos_51_km_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'observacoes_valores_contrato',
            name: 'Observações',
            selector: row => row.observacoes_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'motivo_alteracoes_valores_contrato',
            name: 'Motivo Alterações Valores de Contrato',
            selector: row => row.motivo_alteracoes_valores_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'motivo_reprovacao_contrato',
            name: 'Motivo Reprovação',
            selector: row => row.motivo_reprovacao_contrato,
            sortable: true,
            wrap: true
        },
        {
            id: 'tem_valores_especiais',
            name: 'Possui Valores Especiais?',
            selector: row => row.tem_valores_especiais,
            sortable: true,
            wrap: true
        }
    ];

    async function getPartnerValues() {

        setSpecialValueData([]);

        let dataReturn = await getPartnersValuesByPartnerId(id_representante);
        if (dataReturn?.data?.response === 'success') {
            let partnerValues = dataReturn?.data?.data?.map((item, idx) => {

                if (item?.special_partner_value?.length > 0) {
                    adjustSpecialValuesData(item?.special_partner_value, item?.contract_value?.escopo, item?.contract_value?.contract?.contrato);
                }

                return {
                    id_valores_contrato: item?.id || '',
                    contrato_valores_contrato: item?.contract_value?.contract?.contrato || '',
                    escopo_valores_contrato: item?.contract_value?.escopo || '',
                    valor_chamado_valores_contrato: item?.valor_chamado || '0,00',
                    valor_fora_valores_contrato: item?.valor_fora_hr_comercial || '0,00',
                    chamado_improdutivo_valores_contrato: item?.valor_chamado_improdutivo || '0,00',
                    h_a_diurna_valores_contrato: item?.valor_ha_diurna || '0,00',
                    h_a_noturna_valores_contrato: item?.valor_ha_noturna || '0,00',
                    valor_apos_51_km_valores_contrato: item?.valor_km_apos_51 || '0,00',
                    observacoes_valores_contrato: item?.observacoes || '',
                    status_aprovacao_contrato: item?.aprovado || '',
                    motivo_alteracoes_valores_contrato: item?.descricao_valor_alto || '',
                    motivo_reprovacao_contrato: item?.descricao_reprovado || '',
                    tem_valores_especiais: item?.special_partner_value?.length > 0 ? 'Sim' : 'Não'
                }
            });
            setData(partnerValues);
        } else {
            setData([]);
        }
    }

    function adjustSpecialValuesData(values = [], escopo = '', contrato = '') {
        let data = values.filter(item => item?.status === 1).map((item, idx) => ({
            id_valores_especiais: item?.id || '',
            contrato_valores_especiais: contrato || '',
            escopo_valores_especiais: escopo || '',
            valor_chamado_valores_especiais: item?.valor_chamado || '0,00',
            valor_fora_valores_especiais: item?.valor_fora_hr_comercial || '0,00',
            chamado_improdutivo_valores_especiais: item?.valor_improdutivo || '0,00',
            h_a_diurna_valores_especiais: item?.valor_ha_diurna || '0,00',
            h_a_noturna_valores_especiais: item?.valor_ha_noturna || '0,00',
            valor_apos_51_km_valores_especiais: item?.valor_km_apos_51 || '0,00',
            motivo_valores_especiais: item?.observacoes || '',
            status_aprovacao_valores_especiais: item?.aprovado || '',
            motivo_reprovacao_valores_especiais: item?.descricao_reprovado || '',
            num_chamado_valores_especiais: item?.ticket?.num_chamado || '',
            id_chamado: item?.id_chamado || ''
        }));

        setSpecialValueData(prevState => ([...prevState, ...data]));
    }

    async function showDeletePartnerValuesSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente excluir estes valores?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            deletePartnerValues(id);
        }
    }

    async function deletePartnerValues(id) {
        const toastValues = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deletePartnerValuesById(id);

        if (dataReturn?.status === 204) {
            toast.update(toastValues, { render: 'Dados excluidos com sucesso!', type: 'success', isLoading: false, autoClose: 1500 });
            getPartnerValues();
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao excluir os dados!', type: 'warning', isLoading: false, autoClose: 1500 });
        }
    }

    function openPartnerValuesDetails(id, mode) {
        setIdValorRepresentante(id);
        setMode(mode);
        setIsOpen(true);
    }

    function openCreateSpecialValues(id) {
        setIdValorRepresentante(id);
        setIsCreateSpecialValueOpen(true);
    }

    useEffect(() => {
        if (!['', undefined, null].includes(id_representante)) {
            getPartnerValues();
        }
    }, [id_representante]);

    return (<>
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Valores Contrato</h5>
            </div>
            <div className="col-12">
                <button type="button" className="btn btn-primary" onClick={() => setIsCreateValueOpen(prevState => !prevState)} disabled={disableData}>Cadastrar</button>
            </div>
            <div className="col-12">
                <Table columns={columns} data={data} id='contract_values_table'></Table>
            </div>
        </div>
        <If condition={specialValuesData.length > 0}>
            <SpecialPartnerTable specialData={specialValuesData} disableData={disableData} updateParent={getPartnerValues}></SpecialPartnerTable>
        </If>
        <CreateSpecialPartnerValue isOpen={isCreateSpecialValueOpen} setModalOpen={() => setIsCreateSpecialValueOpen(!isCreateSpecialValueOpen)} id_valor_representante={idValorRepresentante} updateParent={getPartnerValues}></CreateSpecialPartnerValue>
        <PartnerValuesDetails isOpen={isOpen} setModalOpen={() => setIsOpen(!isOpen)} id_valor_representante={idValorRepresentante} updateParent={getPartnerValues} mode={mode}></PartnerValuesDetails>
        <CreateParnerValues isOpen={isCreateValueOpen} setIsOpen={() => setIsCreateValueOpen(!isCreateValueOpen)} updateParent={getPartnerValues} id_representante={id_representante}></CreateParnerValues>
    </>)
}