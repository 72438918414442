import React, { useEffect, useState } from 'react';
import Card from '../../../common/Layout/Card';
import Table from '../../../common/Layout/Table/Table';
import { getStatusHistoryByTicketId } from '../../../services/Ticket';

export default function HistoricoStatusCard({id = ''}){

    const [statusHistoryData, setStatusHistoryData] = useState([]);

    const statusHistoryColumns = [
        {
            id: 'status_status_history',
            name: 'Status',
            sortable: true,
            wrap: true,
            selector: row => row.status_status_history,
        },
        {
            id: 'data_inicio_status_history',
            name: 'Início do Status',
            sortable: true,
            wrap: true,
            selector: row => row.data_inicio_status_history,
        },
        {
            id: 'data_fim_status_history',
            name: 'Fim do Status',
            sortable: true,
            wrap: true,
            selector: row => row.data_fim_status_history,
        },
        {
            id: 'duracao_status_status_history',
            name: 'Duração Status',
            sortable: true,
            wrap: true,
            selector: row => row.duracao_status_status_history,
        },
        {
            id: 'nome_usuario_status_history',
            name: 'Login',
            sortable: true,
            wrap: true,
            selector: row => row.nome_usuario_status_history,
        }
    ];

    const getStatusHistory = async () => {
        let dataReturn = await getStatusHistoryByTicketId(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let statusHistoryData = dataReturn?.data?.data;
            let statusHistoryArray = [];

            statusHistoryArray = statusHistoryData.map((item, idx) => {
                return ({
                    status_status_history: item.status,
                    data_inicio_status_history: item.data_inicio,
                    data_fim_status_history: item.data_fim,
                    duracao_status_status_history: item.duracao_status,
                    nome_usuario_status_history: item.nome
                });
            });
            setStatusHistoryData(statusHistoryArray);
        } else {
            setStatusHistoryData([]);
        }
    }

    useEffect(()=>{
        getStatusHistory();
    }, [])

    return (<Card title="Histórico de Status" icon="fa-map-marker" collapseId="historicoStatusBody">
        <Table columns={statusHistoryColumns} data={statusHistoryData || []} needExport={false} id="statusHistoryTable"></Table>
    </Card>);
}