import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import ContratosMultiSelect from '../../../common/Selects/ContratosMultiSelect';
import UsuariosAtivosSelect from '../../../common/Selects/UsuariosAtivosSelect';
import { toast } from 'react-toastify';
import { createSupportUser } from '../../../services/SupportGroup';

export default function CreateTicketGroup() {

    const initialState = {
        id_usuario: '',
        contratos: '',
        nome: '',
        descricao: ''
    }

    const [formData, setFormData] = useState(initialState);

    async function submitForm(e){
        e.preventDefault();

        const contratos = formData?.contratos?.filter(item => item.value !== 'todos').map((item, idx)=>{
            return item.value;
        }) || [];

        let params = {
            ...formData, 
            contratos: contratos,
            id_usuario: formData?.id_usuario?.value || '',
            nome: formData?.id_usuario?.label || ''
        }

        const toastSubmit = toast.loading("Cadastrando dados, aguarde...");

        let dataReturn = await createSupportUser(params);
        if(dataReturn?.data?.response === 'success'){
            toast.update(toastSubmit, { render: 'Grupo de atendimento cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(initialState);
        }else if(dataReturn?.data?.message === 'The id usuario has already been taken.'){
            toast.update(toastSubmit, { render: 'Este usuario já possui um grupo de atendimento!', type: "warning", isLoading: false, autoClose: 1500 });
        }else{
            toast.update(toastSubmit, { render: 'Ocorreu um erro ao cadastrar o grupo de atendimento!', type: "warning", isLoading: false, autoClose: 1500 });
        }

    }

    return (<Content headerTitle='Cadastrar Grupo de Atendimento'>
        <SubHeaderSpecific subHeaderConfig='grupos_atendimento'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Integradora/Contrato</h5>
                    </div>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='id_usuario' cols={[12, 12, 6, 6]} required={true}></UsuariosAtivosSelect>
                    <ContratosMultiSelect formData={formData} setFormData={setFormData} fieldName='contratos' id_integradora={[]} cols={[12, 12, 6, 6]} required={true}></ContratosMultiSelect>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Detalhamento</h5>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="descricao">Observações</label>
                            <textarea rows={5} className="form-control" name="descricao" id="descricao" placeholder='...' value={formData.descricao} onChange={e => setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))}></textarea>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button type="submit" className='btn btn-primary'>Cadastrar</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}