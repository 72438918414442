import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { login } from '../../services/Auth';

import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import LoginCard from '../../common/Layout/LoginCard';

export default function Login({ setToken }) {
    let currentLocation = useLocation();
    let navigate = useNavigate();
    const [showPwd, setShowPwd] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (currentLocation.pathname === "/login") {
            setToken("");
            localStorage.clear();
        }
    }, [currentLocation, setToken]);

    function showPassword() {
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        const toastLogin = toast.loading("Efetuando login, aguarde...");
        setDisabledBtn(true);
        let user = await login({ login: username, password: password });
        if (user?.status === 200) {
            setToken(user.data.data);
            if (currentLocation.pathname === "/login") {
                navigate("/");
                toast.update(toastLogin, { render: "Login Efetuado com sucesso!", type: "success", isLoading: false, autoClose: 1500 })
            }
        } else {
            toast.update(toastLogin, { render: "Ocorreu um erro ao efetuar o login!", type: "warning", isLoading: false, autoClose: 1500 })
        }
        setDisabledBtn(false);

    }

    return (
        <LoginCard>
            <form onSubmit={handleLogin}>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Usuário" value={username} onChange={t => setUsername(t.target.value)} required />
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <span className="fas fa-user" />
                        </div>
                    </div>
                </div>
                <div className="input-group mb-3">
                    <input type={!showPwd ? "password" : "text"} className="form-control" placeholder="Senha" value={password} onChange={t => setPassword(t.target.value)} required />
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => showPassword()} style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button type="submit" className="btn btn-primary btn-block" disabled={disabledBtn}>Entrar</button>
                    </div>
                </div>
            </form>
        </LoginCard>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}