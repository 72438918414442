import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import FloatSearchButton from '../../../common/Layout/FloatSearchButton';
import Card from '../../../common/Layout/Card';
import Table from '../../../common/Layout/Table/Table';
import GenericSelect from '../../../common/Selects/GenericSelect';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTickets } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import { N2StatusChamadoOptions } from '../../../configs/GenericOptions';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import If from '../../../common/Layout/If';

export default function N2Tickets(){
    const INITIAL_STATE = {
        data_inicial: '',
        data_final: '',
        status_chamado: [{value: '', label: 'Todos'}]
    }
    const arrayFields = ['status_chamado', 'contrato', 'remove_contrato', 'integradora'];
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const columns = [
        {
            id: 'action',
            name: 'Ação',
            button: "true",
            cell: (row) => (
                <Link to={'/chamados/detalhes-chamado/' + row.id}><i className='fas fa-eye'></i></Link>
            ),
            width: '50px'
        },
        {
            id: 'num_chamado',
            name: 'ID Ticket Cli. / Nº Chamado',
            selector: row => row.num_chamado,
            sortable: true,
            wrap: true,
        },
        {
            id: 'status_chamado',
            name: 'Status Chamado',
            selector: row => row.status_chamado,
            sortable: true,
            wrap: true,
        },
        {
            id: 'tipo_atividade',
            name: 'Tipo Atividade',
            selector: row => row.tipo_atividade,
            sortable: true,
            wrap: true,
        },
        {
            id: 'usuario_responsavel_suporte',
            name: 'Nome do Analista Responsável',
            selector: row => row.usuario_responsavel_suporte,
            sortable: true,
            wrap: true,

        },
        {
            id: 'data_entrada_suporte',
            name: 'Data / Hora de Entrada na Fila',
            selector: row => row.data_entrada_suporte,
            sortable: true,
            wrap: true,

        },
        {
            id: 'data_assumido_pelo_suporte',
            name: 'Data / Hora Designação de Analista',
            selector: row => row.data_assumido_pelo_suporte,
            sortable: true,
            wrap: true,

        },
        {
            id: 'contrato',
            name: 'Contrato',
            selector: row => row.contrato,
            sortable: true,
            wrap: true,

        },
        {
            id: 'integradora',
            name: 'Integradora',
            selector: row => row.integradora,
            sortable: true,
            wrap: true,

        },

        {
            id: 'tecnico',
            name: 'Técnico',
            selector: row => <Link to={'/cadastros/tecnicos/detalhes/' + row.id_tecnico}>{row.tecnico}</Link>,
            sortable: true,
            wrap: true,

        },
        {
            id: 'contato_tecnico',
            name: 'Telefone',
            selector: row => row.contato_tecnico,
            sortable: true,
            wrap: true,

        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.cor_row === 'vermelho',
            style: {
                backgroundColor: 'rgb(255 165 156)',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.cor_row === 'verde',
            style: {
                backgroundColor: 'rgb(203 255 198)',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.cor_row === 'amarelo',
            style: {
                backgroundColor: 'rgb(254 255 196)',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.cor_row === 'azul',
            style: {
                backgroundColor: 'rgba(138, 196, 255, 0.36)',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    async function getTicketsByFilter(e) {
        e.preventDefault();
        let params = {};

        params['data_inicial'] = formData.data_inicial;
        params['data_final'] = formData.data_final;
        params['status_chamado'] = formData.status_chamado.map((item) => (item?.value === undefined ? '' : item.value));
        let url = `?`;

        for (let p in params) {
            if (arrayFields.includes(p)) {
                for (let c = 0; c < params[p].length; c++) {
                    url += (`${p}=${params[p][c]}&`);
                }
            } else {
                url += (`${p}=${params[p]}&`);
            }
        }

        url = url.slice(0, -1);

        navigate(url);
    }

    async function getTicketsByParam() {
        const toastTicket = toast.loading("Carregando, aguarde...");
        try {
            let urlParams = new URLSearchParams(location.search);
            let params = {}
            let booleanFields = ['gts', 'key_ticket_cliente_null']

            urlParams.forEach((item, idx) => {

                if (item !== "") {
                    if (arrayFields.includes(idx)) {
                        if (!params[idx]) {
                            params[idx] = [];
                        }
                        params[idx].push(item);
                    } else if (booleanFields.includes(idx)) {
                        params[idx] = item === "true" ? true : false;
                    } else {
                        params[idx] = item;
                    }
                }
            });

            let dataReturn = await getTickets(params);

            if (dataReturn?.data?.response === 'success') {
                setData(dataReturn?.data?.data);
            } else {
                setData([]);
            }
            setIsLoading(false);
        } catch (e) {
            setData([]);
            setIsLoading(false);
        }
        toast.update(toastTicket, { render: 'Pesquisa realizada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
    }

    useEffect(()=>{
        getTicketsByParam();
    }, [location]);

    useEffect(()=>{
        if(formData?.status_chamado.filter(item => item.value === '').length > 0){
            let options = N2StatusChamadoOptions.filter(item => item.value !== '' );
            setFormData(prevState => ({...prevState, status_chamado: options}))
        }
    }, [formData?.status_chamado]);

    return (<Content headerTitle='Chamados'>
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <FloatSearchButton></FloatSearchButton>
        <Card hideCardHeader={true}>
            <form onSubmit={getTicketsByFilter}>
                <div className="row">
                    <div className="col">
                        <label htmlFor="data_inicial">Data Início</label>
                        <input type="date" name="data_inicial" id="data_inicial" className="form-control" value={formData?.data_inicial || ''} onChange={(e)=>setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))}/>
                    </div>
                    <div className="col">
                        <label htmlFor="data_inicial">Data Fim</label>
                        <input type="date" name="data_final" id="data_final" className="form-control" value={formData?.data_final || ''} onChange={(e)=>setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))}/>
                    </div>
                    <GenericSelect formData={formData} setFormData={setFormData} cols={[12, 12, 3, 3]} fieldName={'status_chamado'} title={'Status Chamado'} required={true} genericOption='N2StatusChamadoOptions' isMulti={true}></GenericSelect>
                    <div className="col d-flex align-items-end mt-1">
                        <button type="submit" className="btn btn-primary">Filtrar Chamados</button>
                    </div>
                </div>
            </form>
        </Card>
        <Card hideCardHeader={true}>
            <If condition={isLoading}>
                <CustomMessageData></CustomMessageData>  
            </If>
            <If condition={!isLoading}>
                <Table columns={columns} data={data} conditionalRowStyles={conditionalRowStyles}></Table>
            </If>
        </Card>
    </Content>)
}