import React from 'react';
import Card from '../../../common/Layout/Card';
import IntegradoraSelect from '../../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../../common/Selects/ContratosSelect';

export default function NovoContratoCard({
    detailsData = {},
    setDetailsData = null
}){

    function setIntegradora(value, name) {
        setDetailsData(prevState => ({ ...prevState, [name]: value, id_contrato: '' }));
    }

    return  (<Card title={"Nova Integradora (Cliente) / Contrato"} icon={"fa-dot-circle"}>
        <div className="row">
            <IntegradoraSelect formData={detailsData} setFormData={setDetailsData} cols={[6,6,6,6]} advancedSetFormData={setIntegradora} fieldName='id_integradora'></IntegradoraSelect>
            <ContratosSelect formData={detailsData} setFormData={setDetailsData} cols={[6, 6, 6, 6]} dependsOnIntegradora={true} fieldName='id_contrato' id_integradora_string={[detailsData?.id_integradora?.value].toString()}></ContratosSelect>
        </div>
    </Card>);
}