import React from "react";
import FloatChatButton from "../Chat/FloatChatButton";
import If from "./If";

export default function Content({headerTitle = "", showChat=true, children}) {

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1 className="m-0">{headerTitle}</h1>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <If condition={showChat}>
                        <FloatChatButton></FloatChatButton>
                    </If>
                    {children}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    );
}