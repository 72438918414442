import React from 'react';
import Card from '../../../common/Layout/Card';
import FabricanteSelect from '../../../common/Selects/FabricanteSelect';

export default function FabricanteCard({detailsData = {}, setDetailsData = null}){

    function advancedSetForm(value, name){
        let fabricantes = "";

        for(let v of value){
            fabricantes = fabricantes + v.label + ", ";
        }

        fabricantes = fabricantes.slice(0, -2);

        setDetailsData(prevState => ({...prevState, fabricantes: fabricantes, [name]: value}))
    }

    return (<Card title={'Fabricante'} icon={'fa-dot-circle'}>
        <div className="row">
            <FabricanteSelect formData={detailsData} setFormData={setDetailsData} fieldName='fabricante' advancedSetFormData={advancedSetForm} isMulti={true}></FabricanteSelect>
        </div>
    </Card>);
}