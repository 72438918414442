import React, { useContext } from 'react';
import { Context } from '../../../App';
import Card from '../../../common/Layout/Card';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import GenericSelect from '../../../common/Selects/GenericSelect';
import If from '../../../common/Layout/If';

export default function StatusCard({
    originalData = {},
    detailsData = {},
    setDetailsData = null,
    showUpdateBtn = false }) {
    const userData = useContext(Context);
    return (
        <Card title={`Status`} icon={'fa-dot-circle'}>
            <If condition={userData?.userDataState?.cliente === 0}>
                <p><b>Status: </b>{!showUpdateBtn && (<span style={{ color: 'green', fontWeight: 'bold' }}>{originalData?.status_chamado}</span>)}</p>
                <If condition={showUpdateBtn}>
                    <StatusChamadoSelect
                        formData={detailsData}
                        setFormData={setDetailsData}
                        fieldName='status_chamado'
                    ></StatusChamadoSelect>
                </If>
            </If>
            <If condition={!showUpdateBtn}>
                <p><b>Status Atendimento Técnico: </b>{originalData?.status_tec_atendimento}</p>
            </If>
            <If condition={originalData?.status_chamado === "Fechado"}>
                <p><b>Ocasião: </b>{originalData?.ocasiao_fechamento}</p>
            </If>
            <If condition={(originalData?.status_chamado === "Fechado" && originalData?.ocasiao_fechamento === "Improdutivo")}>
                <p><b>Motivo Improdutivo: </b>{originalData?.motivo_improdutivo}</p>
            </If>
            <If condition={!showUpdateBtn}>
                <p><b>Urgência/Prioridade: </b>{originalData?.urgencia} - {originalData?.prioridade}</p>
            </If>
            <If condition={(showUpdateBtn && userData?.userDataState?.cliente === 0)}>
            <div className='row'>
                    <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'urgencia'} title={'Urgência'} genericOption='UrgenciaOptions' cols={[6, 6, 6, 6]} required={true}></GenericSelect>
                    <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'prioridade'} title={'Prioridade'} genericOption='PrioridadeOptions' cols={[6, 6, 6, 6]} required={true}></GenericSelect>
                </div>
            </If>
            <p><b>Data e Hora do Fechamento: </b>{originalData?.data_encerramento} {originalData?.hora_encerramento}</p>
            <p><b>Documentação Entregue: </b>{originalData?.documentacao_entregue || ''}</p>
            <p><b>Pagamento: </b>{originalData?.liberar_pagamento || ''}</p>
            <p><b>Envolve Logística: </b>{originalData?.envolve_logistica || ''}</p>
            <p><b>Responsável Suporte: </b>{originalData?.support_responsible_user?.nome || ''}</p>
            <p><b>Responsável Chamado: </b>{originalData?.ticket_responsible_user?.nome || ''}</p>
            <p><b>Responsável Parcerias: </b>{originalData?.partnership_responsible_user?.nome || ''}</p>
            <p><b>Aberto por: </b>{originalData?.user?.nome || ''}</p>
        </Card>
    );
}