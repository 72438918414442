import React from 'react';
import Card from '../../../common/Layout/Card';

export default function NumeroChamadoCard({ detailsData = {}, setDetailsData = null }) {
    return (<Card title={"Novo Número de Chamado"} icon={"fa-dot-circle"}>
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="">ID / Ticket Cliente / Nº Chamado</label>
                    <input type="text" name="num_chamado" className='form-control' value={detailsData?.num_chamado} onChange={e => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                </div>
            </div>
        </div>
    </Card>);
}