import React, { useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import { assignPartnerTicket } from '../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import ChamadosSelect from '../../common/Selects/ChamadosSelect';
import UsuariosAtivosSelect from '../../common/Selects/UsuariosAtivosSelect';

export default function ResponsavelParceria() {
    const initialState = {
        responsavel_parceria: '',
        chamados_nao_designados: '',
        chamados_todos_dia: '',
    }

    const [responsavelParceriaForm, setResponsavelParceriaForm] = useState(initialState);
    const [updateData, setUpdateData] = useState(false);

    async function submitAssignForm(e) {
        e.preventDefault();
        let chamadosArray = [...responsavelParceriaForm?.chamados_nao_designados, ...responsavelParceriaForm?.chamados_todos_dia];

        if (chamadosArray.length === 0) {
            toast.warning("Por favor, selecione pelo menos um chamado");
            return;
        }

        let ids_chamado = chamadosArray.map((item, idx) => {
            return item?.value
        });

        let formData = {
            ids_chamado: ids_chamado,
            id_responsavel: responsavelParceriaForm?.responsavel_parceria?.value || ''
        }

        const ticketToast = toast.loading("Atualizando chamados, aguarde...");

        let dataReturn = await assignPartnerTicket(formData);

        if (dataReturn?.data?.message === 'Tickets atualizados com sucesso!') {
            toast.update(ticketToast, { render: 'Chamado Atualizado com Sucesso!', type: "success", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(ticketToast, { render: 'Ocorreu um erro ao atualizar o chamado!', type: "warning", isLoading: false, autoClose: 1500 });
        }
        setResponsavelParceriaForm(initialState);
        setUpdateData(true);
    }

    return (<Content headerTitle="Chamados - Responsável Parceria">
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitAssignForm}>
                <div className='row'>
                    <UsuariosAtivosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='responsavel_parceria'
                        required={true}
                        title='Responsável Parceria'
                        userType='partners'
                    ></UsuariosAtivosSelect>
                    <ChamadosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='chamados_nao_designados'
                        isMulti={true}
                        title='Chamados Não Designados (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Atendimento)'
                        type='partner unassigned'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                    <ChamadosSelect
                        formData={responsavelParceriaForm}
                        setFormData={setResponsavelParceriaForm}
                        fieldName='chamados_todos_dia'
                        isMulti={true}
                        title='Chamados Todos do dia (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Atendimento)'
                        type='today'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                    <div className="col-12 mt-1">
                        <button type='submit' className="btn btn-primary">Designar responsável ao(s) chamado(s)</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}