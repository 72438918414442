import React, { useEffect, useState } from 'react';

export default function SearchField({ placeholder = 'Pesquise pelo nome do canal', searchFunction }) {
    const [search, setSearch] = useState('');

    useEffect(()=>{
        searchFunction(search);
    }, [search]);

    return (
        <div className="input-group mb-3">
            <input type="text" className="form-control" name='pesquisa' placeholder={placeholder} value={search} onChange={(e) => setSearch(e.target.value)} aria-label="Pesquisar" aria-describedby="button-addon2" />
        </div>
    );
}