import interceptorInstance from "./Interceptor";

export async function getLeavesByEmployeeId(id){
    try{
        let dados = await interceptorInstance.get(`employee-leaves/technician/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function insertLeave(params){
    try{
        let dados = await interceptorInstance.post(`employee-leaves/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function cancelLeave(id){
    try{
        let dados = await interceptorInstance.delete(`employee-leaves/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function uploadLeaveAttachment(id, params){
    try{
        let dados = await interceptorInstance.post(`employee-leaves/upload/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}