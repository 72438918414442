import React, { useContext, useState } from 'react';
import { chatLogin } from '../../services/Auth';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../App';
import LoginCard from '../../common/Layout/LoginCard';

export default function ExternalChatLogin({ setShowChat = null }) {
    const INITIAL_STATE = {
        login: '',
        password: ''
    }
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [showPwd, setShowPwd] = useState(false);
    const userData = useContext(Context);
    const { id } = useParams();

    async function submitForm(e) {
        e.preventDefault();

        const toastLogin = toast.loading("Efetuando login, aguarde...");

        let params = {
            ...formData,
            channel_id: id
        }

        let dataReturn = await chatLogin(params);

        if (dataReturn?.status === 200) {
            toast.update(toastLogin, { render: "Login Efetuado com sucesso!", type: "success", isLoading: false, autoClose: 1500 })
            localStorage.setItem('token', JSON.stringify({ 'access_token': dataReturn?.data?.data?.access_token }));
            userData?.setUserDataState(prevState => ({ ...prevState, id_usuario: dataReturn?.data?.data?.user_id, type: 'externo' }))
            setShowChat(true);
        } else {
            toast.update(toastLogin, { render: "Você não está autorizado a fazer o login!", type: "warning", isLoading: false, autoClose: 1500 });
            console.log(dataReturn);
        }
    }

    return (
        <LoginCard>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <input type="email" name="login" id="login" className="form-control" placeholder='Email' value={formData?.login} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input type={!showPwd ? "password" : "text"} className="form-control" name='password' id='password' placeholder="Senha" value={formData?.password} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setShowPwd(!showPwd)} style={{ cursor: "pointer" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className='btn btn-primary btn-block'>Entrar</button>
                    </div>
                </div>
            </form>
        </LoginCard>
    );
}