import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import { createTicket, getTechnicianDisponibility } from '../../../services/Ticket';
import { withMask } from 'use-mask-input';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getTechnicianById } from '../../../services/Technician';
import { useNavigate } from 'react-router-dom';
import useGenerics from '../../../hooks/useGenerics';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import If from '../../../common/Layout/If';
import ValoresRepresentanteSelect from '../../../common/Selects/ValoresRepresentantesSelect';
import TiposAtividadesOptions from '../../../common/Selects/TiposAtividadesOptions';
import GenericSelect from '../../../common/Selects/GenericSelect';
import Cep from '../../../common/Cep/Cep';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import FabricanteSelect from '../../../common/Selects/FabricanteSelect';
import ModalEnvioSelect from '../../../common/Selects/ModalEnvioSelect';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../common/Selects/TecnicosSelect';
import IntegradoraSelect from '../../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../../common/Selects/ContratosSelect';
import EnvolveLogisticaSelect from '../../../common/Selects/EnvolveLogisticaSelect';
import BestTechnician from './BestTechnician';
import ProductsTable from './Products';

export default function CreateTicket() {
    const { getCurrentDate, getCurrentTime } = useGenerics();

    const initialState = {
        data_solicitacao: getCurrentDate(),
        hora_solicitacao: getCurrentTime(),
        data_agendamento: getCurrentDate(),
        hora_agendamento: getCurrentTime(),
        id_ticket_cliente: '',
        tipo_atividade: { value: 'Ativação', label: 'Ativação' },
        urgencia: { value: 'Agendado', label: 'Agendado' },
        prioridade: { value: 'Baixa', label: 'Baixa' },
        envolve_logistica: { value: 'Não', label: 'Não' },
        status_chamado: { value: 'Agendado', label: 'Agendado' },
        informacao_adicional: '',
        fabricante: '',
        info_adicional_circuito: '',
        descricao: '',
        nome_cliente: '',
        nome_contato_cliente: '',
        telefone_contato_cliente: '',
        email_contato_cliente: '',
        endereco_cliente: '',
        numero_complemento: '',
        cep_endereco_cliente: '',
        bairro_endereco_cliente: '',
        estado_endereco_cliente: '',
        cidade_endereco_cliente: '',
        destinatario_remessa: '',
        logradouro_endereco_logistica: '',
        compl_ref_obs_endereco_logistica: '',
        cep_endereco_logistica: '',
        bairro_endereco_logistica: '',
        estado_endereco_logistica: '',
        cidade_endereco_logistica: '',
        logradouro_endereco_partida: '',
        numero_endereco_partida: '',
        compl_ref_obs_endereco_partida: '',
        cep_endereco_partida: '',
        bairro_endereco_partida: '',
        estado_endereco_partida: '',
        cidade_endereco_partida: '',
        id_representante: '',
        id_tecnico: '',
        status_tec_atendimento: '',
        id_contrato: '',
        id_integradora: '',
        nome_solicitante: '',
        km_tecnico: '',
        numero_rma: '',
        servicos_contrato_array: [],
        local_partida_tecnico: '',
        tem_conflito_chamado: false,
        produtos: [],
        produtos_estoque: '',
        produtos_contrato: '',
        modal_envio: '',
        id_representante_valor_comercial: ''
    }

    const tipoAtividadesLockValues = ["Teste RMA", "Teste Interno"];

    const [createTicketForm, setCreateTicketForm] = useState(initialState);
    const [productData, setProductData] = useState([]);
    const [statusChamadoDisabled, setStatusChamadoDisabled] = useState(false);
    const [cadastrarBtnDisabled, setCadastrarBtnDisabled] = useState(false);
    const [disableBestTechnician, setDisableBestTechnician] = useState(true);
    const [openBestTechnician, setOpenBestTechnician] = useState(false);
    const mySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const handleFormInputs = (e) => {
        setCreateTicketForm({ ...createTicketForm, [e.target.name]: e.target.value });
    }

    const handleTechnician = async (value, name) => {
        if(value === ''){
            return;
        }

        if (createTicketForm.status_chamado?.value === 'Rascunho') {
            setCreateTicketForm({ ...createTicketForm, [name]: value, tem_conflito_chamado: false });
            return;
        }

        let params = {
            id_tecnico: parseInt(value?.value),
            data_agendamento: createTicketForm.data_agendamento,
            hora_agendamento: createTicketForm.hora_agendamento
        }

        let dataReturn = await getTechnicianDisponibility(params);

        if (dataReturn?.data?.response === 'Nenhum atendimento conflitante encontrado para o técnico.') {

            setCreateTicketForm({ ...createTicketForm, [name]: value, tem_conflito_chamado: false });

        } else if (dataReturn?.data?.response === 'Técnico já possui atendimentos para esse dia e período.') {

            let listComponent = dataReturn.data.data.tickets.map((item, idx) => {
                return (
                    <div key={idx}>
                        <p><b>Número do Chamado:</b> {item.num_chamado}</p>
                        <p><b>Data/Hora Agendamento:</b> {item.data_agendamento} {item.hora_agendamento}</p>
                        <hr></hr>
                    </div>)
            });

            toast.warning(dataReturn?.data?.response);

            let response = await mySwal.fire({
                titleText: `Atenção, este técnico possui chamados!`,

                html: (
                    <>
                        <p>Deseja alocar este técnico e alterar o status do chamado para Aprovação do Responsável?</p>
                        <h6 style={{ textTransform: 'capitalize' }}>Técnico: {value.label}</h6>
                        <div style={{ textAlign: 'left' }}>{listComponent}</div>
                    </>),
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonColor: 'red',
                confirmButtonText: `Sim`,
                cancelButtonText: `Não`,
            });

            if (response.isConfirmed) {
                setCreateTicketForm({ ...createTicketForm, [name]: value, tem_conflito_chamado: true });
            } else {
                setCreateTicketForm({ ...createTicketForm, [name]: '', tem_conflito_chamado: false });
            }

        } else {
            toast.warning("Ocorreu um erro ao buscar a disponibilidade do técnico!");
            setCreateTicketForm({ ...createTicketForm, [name]: '', tem_conflito_chamado: false });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let tipoAtividade = createTicketForm.tipo_atividade?.value;

        let values = {
            ...createTicketForm,
            envolve_logistica: createTicketForm.envolve_logistica?.value,
            prioridade: createTicketForm.prioridade?.value,
            status_chamado: createTicketForm.status_chamado?.value,
            tipo_atividade: createTicketForm.tipo_atividade?.value,
            urgencia: createTicketForm.urgencia?.value,
            id_representante_valor_comercial: createTicketForm?.id_representante_valor_comercial?.value
        };

        if (tipoAtividadesLockValues.includes(tipoAtividade)) {
            values = {
                ...values,
                id_integradora: 28,
                id_contrato: 44438,
                nome_cliente: "Maminfo",
                nome_contato_cliente: "Interno Maminfo",
                telefone_contato_cliente: "(19) 3751-3100",
                cep_endereco_cliente: "13098335",
                estado_endereco_cliente: "SP",
                cidade_endereco_cliente: "Campinas",
                bairro_endereco_cliente: "Loteamento Alphaville Campinas",
                endereco_cliente: "Rua Açu",
                numero_complemento: "10",
                id_representante: 188,
                id_tecnico: 78533,
                km_tecnico: 1.0,
                local_partida_tecnico: 'Casa do Técnico',
                cep_endereco_partida: "13098335",
                estado_endereco_partida: "SP",
                cidade_endereco_partida: "Campinas",
                bairro_endereco_partida: "Loteamento Alphaville Campinas",
                logradouro_endereco_partida: "Rua Açu",
                compl_ref_obs_endereco_partida: "10",
                fabricante: ""
            }
        }

        if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            let cepClienteValidationFields = ["estado_endereco_cliente", "cidade_endereco_cliente"];
            let cepLocalPartidaTecnico = ["estado_endereco_partida", "cidade_endereco_partida", "bairro_endereco_partida", "logradouro_endereco_partida", "compl_ref_obs_endereco_partida"];
            let cepEnderecoLogistica = ["estado_endereco_logistica", "cidade_endereco_logistica"];
            let fabricantes = '';

            values = {
                ...values,
                id_integradora: createTicketForm.id_integradora?.value || '',
                id_contrato: createTicketForm.id_contrato?.value || '',
                id_representante: createTicketForm.id_representante?.value || '',
                id_tecnico: createTicketForm.id_tecnico?.value || '',
                local_partida_tecnico: createTicketForm.local_partida_tecnico?.value || ''
            };

            for (let v of cepClienteValidationFields) {
                if (values[v] === '') {
                    toast.warning('Preencha o CEP do Cliente corretamente');
                    return;
                }
            }

            if (values.cep_endereco_partida !== '') {
                for (let v of cepLocalPartidaTecnico) {
                    if (values[v] === '') {
                        toast.warning("Preencha corretamente os dados de partida");
                        return;
                    }
                }
            }

            if (["Não", "Sim - Retirada"].includes(values.envolve_logistica) === false) {
                for (let v of cepEnderecoLogistica) {
                    if (values[v] === '') {
                        toast.warning('Preencha o CEP da Logística corretamente');
                        return;
                    }
                }
            }

            if (values.tem_conflito_chamado) {
                values = { ...values, status_chamado: 'Aprovação do Responsável' }
            }

            if (typeof values.fabricante !== 'string' && values.fabricante.length > 0) {
                values.fabricante.map((item, idx) => {
                    fabricantes += item.value + ", ";
                });
                fabricantes = fabricantes.slice(0, -2);
            }

            values.fabricante = fabricantes;

        }

        values.produtos = productData.map((item, idx) => {
            let modalEnvio = createTicketForm?.envolve_logistica?.value === 'Sim - Retirada' ? "Em Mãos" : createTicketForm?.modal_envio?.value;
            return ({
                id: item.value,
                status: 'aguardando_envio',
                categoria_envio: 'Remessa',
                modal_envio: modalEnvio
            });
        });

        if (values.produtos.length > 0 && values.numero_rma === "") {
            toast.warning("Digite o Número RMA!");
            return;
        }

        if (values.id_tecnico !== undefined) {
            values = { ...values, status_tec_atendimento: 'Aguardando aceite' }
        }

        if (values.id_tecnico === '' || values.id_tecnico === 'A definir') {
            values = { ...values, status_tec_atendimento: 'A definir' }
        }

        values.km_tecnico = +values.km_tecnico;

        const toastTicket = toast.loading("Carregando, aguarde...");
        setCadastrarBtnDisabled(true);
        let dataReturn = await createTicket(values);

        if (dataReturn?.status === 201) {
            toast.update(toastTicket, { render: 'Chamado Criado com Sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setCadastrarBtnDisabled(false);

            let mensagem = values.tem_conflito_chamado === false ? 'Chamado Criado com Sucesso!' : 'Chamado Criado para Aprovação do Responsável!';

            if (navigator.clipboard && navigator.clipboard.writeText) {

                navigator.clipboard.writeText(dataReturn?.data?.data?.num_chamado).then(() => {
                    console.log('Texto copiado com sucesso!');
                }).catch((e) => {
                    const textarea = document.createElement('textarea');
                    textarea.value = dataReturn?.data?.data?.num_chamado;
                    document.body.appendChild(textarea);

                    textarea.select();
                    document.execCommand('copy');

                    document.body.removeChild(textarea);
                });

            } else {

                console.warn('Clipboard API indisponível, usando método legado');
                const textarea = document.createElement('textarea');
                textarea.value = dataReturn?.data?.data?.num_chamado;
                document.body.appendChild(textarea);

                textarea.select();
                document.execCommand('copy');

                document.body.removeChild(textarea);
            }

            let response = await mySwal.fire({
                titleText: mensagem,
                html: (
                    <>
                        <h2>Ticket: {dataReturn?.data?.data?.num_chamado}</h2>
                        <div style={{ textAlign: 'left' }}>
                            <p>Ticket copiado com sucesso!</p>
                            <p>Deseja voltar para Chamados ou abrir um novo chamado?</p>
                        </div>
                    </>),
                showConfirmButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                cancelButtonColor: 'red',
                confirmButtonText: `Abrir Novo Chamado`,
                cancelButtonText: `Voltar Para Visualização de Chamados`,
                didClose: () => {
                    window.scrollTo(0, 0);
                }
            });

            if (response.isConfirmed) {
                setCreateTicketForm(initialState);
            } else {
                navigate("/chamados/dados/all");
            }
        } else {
            toast.update(toastTicket, { render: 'Ocorreu um erro ao abrir o chamado', type: "warning", isLoading: false, autoClose: 1500 });
            setCadastrarBtnDisabled(false);
        }
    }

    async function getTechnicianDataById(id) {
        let dataReturn = await getTechnicianById(id);

        if (dataReturn?.data?.response === "success") {
            let dataTech = dataReturn?.data?.data;
            let cepTech = dataTech?.cep_end?.replace(/[^0-9a-zA-Z]+/g, "");

            setCreateTicketForm({
                ...createTicketForm,
                cep_endereco_partida: cepTech || '',
                bairro_endereco_partida: dataTech.bairro_end || '',
                cidade_endereco_partida: dataTech.cidade_end || '',
                estado_endereco_partida: dataTech.estado_end || '',
                logradouro_endereco_partida: dataTech.logradouro_end || '',
                compl_ref_obs_endereco_partida: dataTech.numero_complemento_endereco || ''
            });
        }
    }

    async function setLogisticAddressByTechnicianId(id) {
        let dataReturn = await getTechnicianById(id);

        if (dataReturn?.data?.response === "success") {
            let dataTech = dataReturn.data.data;
            let cepTech = dataTech.cep_end.replace(/[^0-9a-zA-Z]+/g, "");

            setCreateTicketForm(prevState => ({
                ...prevState,
                cep_endereco_logistica: cepTech || '',
                estado_endereco_logistica: dataTech.bairro_end || '',
                cidade_endereco_logistica: dataTech.cidade_end || '',
                bairro_endereco_logistica: dataTech.estado_end || '',
                logradouro_endereco_logistica: dataTech.logradouro_end || '',
                compl_ref_obs_endereco_logistica: dataTech.numero_complemento_endereco || ''
            }));
        }
    }

    function handlePartner(value, name) {
        setCreateTicketForm(prevState => ({
            ...prevState,
            [name]: value,
            id_tecnico: '',
            id_representante_valor_comercial: ''
        }))
    }

    function handleIntegrator(value, name){
        let tipoAtividade = createTicketForm.tipo_atividade?.value;
        if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            setCreateTicketForm(prevState => ({
                ...prevState,
                [name]: value,
                id_contrato: ''
            }))
        }
    }

    useEffect(() => {
        let tipoAtividade = createTicketForm.tipo_atividade?.value;

        if (tipoAtividadesLockValues.includes(tipoAtividade)) {
            setCreateTicketForm({
                ...createTicketForm,
                status_chamado: {
                    value: 'Suporte interno (Testes)',
                    label: 'Suporte interno (Testes)'
                }
            });
            setStatusChamadoDisabled(true);
        } else {
            setCreateTicketForm({
                ...createTicketForm,
                status_chamado: {
                    value: "Agendado",
                    label: "Agendado"
                }
            });
            setStatusChamadoDisabled(false);
        }
    }, [createTicketForm.tipo_atividade]);

    useEffect(() => {
        let statusChamado = createTicketForm.status_chamado?.value;

        if (statusChamado === "Rascunho") {
            setCreateTicketForm({ ...createTicketForm, tem_conflito_chamado: false });
        }

    }, [createTicketForm.status_chamado]);

    useEffect(() => {
        let id_tecnico = createTicketForm.id_tecnico?.value;

        if ((createTicketForm.data_agendamento !== "" || createTicketForm.hora_agendamento !== "") && createTicketForm.status_chamado?.value !== "Rascunho" && !tipoAtividadesLockValues.includes(createTicketForm.tipo_atividade?.value) && id_tecnico !== undefined) {
            handleTechnician(createTicketForm.id_tecnico, 'id_tecnico');
        }
    }, [createTicketForm.hora_agendamento, createTicketForm.data_agendamento]);

    useEffect(() => {
        let id_tecnico = createTicketForm.id_tecnico?.value;
        let local_partida = createTicketForm.local_partida_tecnico?.value;
        let tipoAtividade = createTicketForm.tipo_atividade?.value;

        if (!tipoAtividadesLockValues.includes(tipoAtividade)) {
            if (id_tecnico !== undefined && local_partida === "Casa do Técnico") {
                getTechnicianDataById(id_tecnico);
            }

            if (id_tecnico === undefined) {
                setCreateTicketForm({
                    ...createTicketForm,
                    cep_endereco_partida: '',
                    bairro_endereco_partida: '',
                    cidade_endereco_partida: '',
                    estado_endereco_partida: '',
                    logradouro_endereco_partida: '',
                    compl_ref_obs_endereco_partida: ''
                })
            }
        }
    }, [createTicketForm.local_partida_tecnico, createTicketForm.id_tecnico]);

    useEffect(() => {
        let envolve_logistica = createTicketForm.envolve_logistica?.value;

        if (envolve_logistica === 'Sim - Envio Cliente') {
            setCreateTicketForm(prevState => ({
                ...prevState,
                cep_endereco_logistica: createTicketForm.cep_endereco_cliente,
                estado_endereco_logistica: createTicketForm.estado_endereco_cliente,
                cidade_endereco_logistica: createTicketForm.cidade_endereco_cliente,
                bairro_endereco_logistica: createTicketForm.bairro_endereco_cliente,
                logradouro_endereco_logistica: createTicketForm.endereco_cliente,
                compl_ref_obs_endereco_logistica: createTicketForm?.numero_complemento
            }));
        }

        if (envolve_logistica === 'Sim - Envio Técnico') {
            let idTecnico = createTicketForm?.id_tecnico?.value

            if (idTecnico !== undefined) {
                setLogisticAddressByTechnicianId(idTecnico);
            }
        }

    }, [createTicketForm.envolve_logistica]);

    useEffect(() => {
        let formData = {
            data_agendamento: createTicketForm.data_agendamento || '',
            hora_agendamento: createTicketForm.hora_agendamento || '',
            logradouro_cliente: createTicketForm.endereco_cliente || '',
            bairro_cliente: createTicketForm.bairro_endereco_cliente || '',
            cidade_cliente: createTicketForm.cidade_endereco_cliente || '',
            estado_cliente: createTicketForm.estado_endereco_cliente || '',
            cep_cliente: createTicketForm.cep_endereco_cliente || '',
            numero_complemento: createTicketForm.numero_complemento || ''
        }

        for (let key in formData) {
            if (formData[key] === '') {
                setDisableBestTechnician(true);
                return;
            }
        }

        setDisableBestTechnician(false);

    }, [createTicketForm.data_agendamento, createTicketForm.hora_agendamento, createTicketForm.endereco_cliente, createTicketForm.bairro_endereco_cliente, createTicketForm.cidade_endereco_cliente, createTicketForm.estado_endereco_cliente, createTicketForm.cep_endereco_cliente, createTicketForm.numero_complemento]);

    return (
        <div>
            <Content headerTitle="Abertura de Chamados">
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                <div className='card'>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='card-header'><h6><i className='fas fa-calendar-check'></i> Agendamento</h6></div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="idTicketCliente">ID / Ticket Cliente</label>
                                        <input type="text" className="form-control" name="id_ticket_cliente" id="idTicketCliente" value={createTicketForm.id_ticket_cliente} onChange={handleFormInputs} placeholder='...' />
                                    </div>
                                </div>
                                <TiposAtividadesOptions formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} fieldName='tipo_atividade' title='Tipo da Atividade'></TiposAtividadesOptions>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="dataSolicitacao">Data da Solicitação</label>
                                        <input type="date" className="form-control" name="data_solicitacao" id="dataSolicitacao" value={createTicketForm.data_solicitacao} onChange={handleFormInputs} required />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="horaSolicitacao">Hora da Solicitação</label>
                                        <input type="time" className="form-control" name="hora_solicitacao" id="horaSolicitacao" value={createTicketForm.hora_solicitacao} onChange={handleFormInputs} required />
                                    </div>
                                </div>
                                <GenericSelect fieldName={'urgencia'} formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} genericOption='UrgenciaOptions' title='Urgência' required={true}></GenericSelect>
                                <GenericSelect fieldName={'prioridade'} formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} genericOption='PrioridadeOptions' title='Prioridade' required={true}></GenericSelect>
                                <If condition={createTicketForm.status_chamado?.value !== "Rascunho"}>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label htmlFor="dataAgendamento">Data do Agendamento</label>
                                            <input type="date" className="form-control" name="data_agendamento" id="dataAgendamento" value={createTicketForm.data_agendamento} onChange={handleFormInputs} required />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label htmlFor="horaAgendamento">Hora do Agendamento</label>
                                            <input type="time" className="form-control" name="hora_agendamento" id="horaAgendamento" value={createTicketForm.hora_agendamento} onChange={handleFormInputs} required />
                                        </div>
                                    </div>
                                </If>
                                <If condition={createTicketForm.status_chamado?.value === "Rascunho"}>
                                    <div className='col-md-6'>{/*É vazio mesmo*/}</div>
                                </If>
                                <EnvolveLogisticaSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 3, 3, 3]} fieldName='envolve_logistica' required={true}></EnvolveLogisticaSelect>
                                <StatusChamadoSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12,3,3,3]} fieldName='status_chamado' required={true} isDisabled={statusChamadoDisabled}></StatusChamadoSelect>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor="nomeSolicitante">Nome do Solicitante</label>
                                        <input type="text" className="form-control" name="nome_solicitante" id="nomeSolicitante" value={createTicketForm.nome_solicitante} onChange={handleFormInputs} placeholder='...' />
                                    </div>
                                </div>
                            </div>
                            <If condition={!tipoAtividadesLockValues.includes(createTicketForm.tipo_atividade?.value)}>
                                <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Integradora / Contrato</h6></div>
                                <div className='row'>
                                    <IntegradoraSelect 
                                        formData={createTicketForm} 
                                        setFormData={setCreateTicketForm} 
                                        cols={[12, 4, 4, 4]}
                                        fieldName='id_integradora'
                                        advancedSetFormData={handleIntegrator}
                                        required={true}
                                    ></IntegradoraSelect>
                                    <ContratosSelect 
                                        formData={createTicketForm} 
                                        setFormData={setCreateTicketForm} 
                                        cols={[12, 4, 4, 4]} 
                                        dependsOnIntegradora={true}  
                                        id_integradora_string={[createTicketForm?.id_integradora?.value].toString()} 
                                        fieldName='id_contrato'
                                    ></ContratosSelect>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="infoAdicionalCircuito">Circuito - Contrato</label>
                                            <input type="text" className="form-control" name="info_adicional_circuito" id="infoAdicionalCircuito" value={createTicketForm.info_adicional_circuito} onChange={handleFormInputs} placeholder='Circuito - Contrato' />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="informacaoAdicional">Informação Adicional</label>
                                            <input type="text" className="form-control" name="informacao_adicional" id="informacaoAdicional" value={createTicketForm.informacao_adicional} onChange={handleFormInputs} placeholder='PEP, Observações, etc...' />
                                        </div>
                                    </div>
                                    <FabricanteSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 4, 4, 4]} fieldName='fabricante' isMulti={true}></FabricanteSelect>
                                </div>
                                <div className='card-header'><h6><i className='fas fa-building'></i> Cliente</h6></div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="nomeCliente">Cliente</label>
                                            <input type="text" className="form-control" name="nome_cliente" id="nomeCliente" value={createTicketForm.nome_cliente} onChange={handleFormInputs} placeholder='...' required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="nomeContatoCliente">Nome Contato</label>
                                            <input type="text" className="form-control" name="nome_contato_cliente" id="nomeContatoCliente" value={createTicketForm.nome_contato_cliente} onChange={handleFormInputs} placeholder='...' required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="telefoneContatoCliente">Telefone</label>
                                            <input type="tel" className="form-control" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} name="telefone_contato_cliente" id="telefoneContatoCliente" value={createTicketForm.telefone_contato_cliente} onChange={handleFormInputs} placeholder='...' required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='form-group'>
                                            <label htmlFor="emailContatoCliente">E-mail</label>
                                            <input type="email" className="form-control" name="email_contato_cliente" id="emailContatoCliente" value={createTicketForm.email_contato_cliente} onChange={handleFormInputs} placeholder='...' required />
                                        </div>
                                    </div>
                                </div>
                                <div className='card-header'><h6><i className='fas fa-map-marker'></i> Endereço do Cliente</h6></div>
                                <Cep 
                                    formData={createTicketForm} 
                                    setFormData={setCreateTicketForm}
                                    formDataNames={{
                                        bairro: 'bairro_endereco_cliente',
                                        cep: 'cep_endereco_cliente',
                                        cidade: 'cidade_endereco_cliente',
                                        complemento: 'numero_complemento',
                                        estado: 'estado_endereco_cliente',
                                        logradouro: 'endereco_cliente'
                                    }}
                                    hasComplemento={true}
                                ></Cep>
                                <div className='card-header'><h6><i className='fas fa-user'></i> Técnico | <button type='button' className='btn btn-primary' onClick={() => setOpenBestTechnician(prev => !prev)} disabled={disableBestTechnician}>{disableBestTechnician === true ? 'Preencha corretamente os campos acima para ver os melhores técnicos' : 'Ver Melhores Técnicos'}</button></h6></div>
                                <div className="row">
                                    <RepresentanteSelect 
                                        formData={createTicketForm} 
                                        setFormData={setCreateTicketForm} 
                                        fieldName='id_representante'
                                        required={true}
                                        advancedSetFormData={handlePartner}
                                        cols={[12, 4, 4, 4]}
                                    ></RepresentanteSelect>
                                    <TecnicosSelect 
                                        formData={createTicketForm}
                                        setFormData={setCreateTicketForm}
                                        cols={[12, 4, 4, 4]}
                                        fieldName='id_tecnico'
                                        id_representante={createTicketForm?.id_representante?.value}
                                        required={true}
                                        advancedSetFormData={handleTechnician}
                                    ></TecnicosSelect>
                                    <ValoresRepresentanteSelect formData={createTicketForm} setFormData={setCreateTicketForm} title='Escopo' fieldName='id_representante_valor_comercial' id_representante={createTicketForm.id_representante?.value} cols={[12, 4, 4, 4]} required={true}></ValoresRepresentanteSelect>
                                    <div className="col-md-4">
                                        <div className='form-group'>
                                            <label htmlFor="km_tecnico">Km do Técnico para o Contrato</label>
                                            <input type="number" className="form-control" name="km_tecnico" id="km_tecnico" value={createTicketForm.km_tecnico} onChange={handleFormInputs} placeholder='...' />
                                        </div>
                                    </div>
                                </div>
                                <div className='card-header'><h6><i className='fas fa-map-marker'></i> Ponto de Partida</h6></div>
                                <div className="row">
                                    <GenericSelect formData={createTicketForm} setFormData={setCreateTicketForm} fieldName={'local_partida_tecnico'} cols={[12, 12, 12, 12]} genericOption='PontoPartidaOptions' title='Selecione o local de partida do técnico'></GenericSelect>
                                </div>
                                <Cep
                                    formData={createTicketForm}
                                    setFormData={setCreateTicketForm}
                                    formDataNames={{
                                        bairro: 'bairro_endereco_partida',
                                        cep: 'cep_endereco_partida',
                                        cidade: 'cidade_endereco_partida',
                                        complemento: 'compl_ref_obs_endereco_partida',
                                        estado: 'estado_endereco_partida',
                                        logradouro: 'logradouro_endereco_partida'
                                    }}
                                    hasComplemento={true}
                                    required={true}
                                ></Cep>
                            </If>
                            <If condition={["Não", "Sim - Retirada"].includes(createTicketForm.envolve_logistica?.value) === false}>
                                <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Logística - Endereço de Envio</h6></div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className='form-group'>
                                            <label htmlFor="destinatarioRemessa">Destinatário</label>
                                            <input type="text" className="form-control" name="destinatario_remessa" id="destinatarioRemessa" value={createTicketForm.destinatario_remessa} onChange={handleFormInputs} placeholder='Destinatário Remessa' required />
                                        </div>
                                    </div>
                                    <ModalEnvioSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 4, 4, 4]} fieldName='modal_envio' required={true}></ModalEnvioSelect>
                                </div>
                                <Cep 
                                    formData={createTicketForm} 
                                    setFormData={setCreateTicketForm} 
                                    formDataNames={{
                                        bairro: 'bairro_endereco_logistica',
                                        cep: 'cep_endereco_logistica',
                                        cidade: 'cidade_endereco_logistica',
                                        complemento: 'compl_ref_obs_endereco_logistica',
                                        estado: 'estado_endereco_logistica',
                                        logradouro: 'logradouro_endereco_logistica'
                                    }}
                                    hasComplemento={true}
                                ></Cep>
                            </If>
                            <If condition={createTicketForm?.envolve_logistica?.value !== "Não"}>
                                <ProductsTable createTicketForm={createTicketForm} setCreateTicketForm={setCreateTicketForm} productData={productData} setProductData={setProductData}></ProductsTable>
                            </If>
                            <div className='card-header'><h6><i className='fas fa-sticky-note'></i> Detalhamento</h6></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor='descricaoChamado'>Descrição do Chamado</label>
                                        <textarea className='form-control' rows={'10'} placeholder='...' id='descricaoChamado' value={createTicketForm.descricao} onChange={handleFormInputs} name='descricao' required></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type='submit' className='btn btn-primary float-right' disabled={cadastrarBtnDisabled}>Cadastrar</button>
                        </form>
                    </div>
                </div>
            </Content>
            <BestTechnician isOpen={openBestTechnician} setIsOpen={()=>setOpenBestTechnician(!openBestTechnician)} createTicketForm={createTicketForm} setCreateTicketForm={setCreateTicketForm}></BestTechnician>
        </div>);
}