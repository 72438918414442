import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getPartnerUnassignedTickets, getTickets, getUnassignedTickets } from '../../services/Ticket';
import { toast } from 'react-toastify';
import useGenerics from '../../hooks/useGenerics';

export default function ChamadosSelect({ formData, setFormData, update = false, setUpdate = null, fieldName = 'chamados', title = 'Chamados', type = '', cols = [12, 12, 12, 12], isMulti = false, isDisabled = false, required = false, data_inicial = '', data_final = '' }) {
    const [ticketsOptions, setTicketsOptions] = useState([]);
    const { getCurrentDate } = useGenerics();

    async function getTicketsData() {
        let params = {
            data_inicial: data_inicial || getCurrentDate(0, 0, 0),
            data_final: data_final || getCurrentDate()
        }

        const toastTicket = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getTickets(params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastTicket, { render: 'Pesquisa realizada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let tickets = dataReturn?.data.data.map((item, idx) => {
                return { value: item.id, label: `${item.num_chamado} - ${item.status_chamado}` }
            });
            setTicketsOptions(tickets);
        } else {
            toast.update(toastTicket, { render: 'Ocorreu um erro ao pesquisar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setTicketsOptions([]);
        }
    }

    async function getUnassignedTicketsOptions() {
        let dataReturn = await getUnassignedTickets();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data) {
            let unassignedData = dataReturn?.data?.data;
            let unassignedArray = unassignedData.map((item, idx) => {
                return ({ value: item.id, label: `${item.chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.customer?.nome_cliente || ''}|${item?.integrator?.nome || ''}|${item?.contract?.contrato || ''}|` })
            });
            setTicketsOptions(unassignedArray);
        } else {
            setTicketsOptions([]);
        }
    }

    async function getPartnerUnassignedTicketsOptions() {
        let dataReturn = await getPartnerUnassignedTickets();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data) {
            let unassignedData = dataReturn?.data?.data;
            let unassignedArray = unassignedData.map((item, idx) => {
                return ({ value: item.id, label: `${item.chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.customer?.nome_cliente || ''}|${item?.integrator?.nome || ''}|${item?.contract?.contrato || ''}|` })
            });
            setTicketsOptions(unassignedArray);
        } else {
            setTicketsOptions([]);
        }
    }

    async function getTodayTickets() {
        let dataReturn = await getTickets();
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let tickets = dataReturn.data.data.map((item, idx) => {
                return ({ value: item.id, label: `${item.num_chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.cliente || ''}|${item?.integradora || ''}|${item?.contrato || ''}|${item?.responsavel_atendimento}` })
            })
            setTicketsOptions(tickets);
        } else {
            setTicketsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    function getData() {
        switch (type) {
            case "unassigned":
                getUnassignedTicketsOptions();
                break;
            case "partner unassigned":
                getPartnerUnassignedTicketsOptions();
                break;
            case "today":
                getTodayTickets();
                break;
            default:
                getTicketsData();
                break;
        }
    }

    useEffect(() => {
        if (update){
            getData();
            setUpdate(false);
        }
    }, [update]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>{title}</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={[{ value: '', label: 'Selecione...' }, ...ticketsOptions]}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}