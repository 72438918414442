import React from 'react';
import Modal from '../Layout/Modal';

export default function ImgMessageCarousel({ isOpen, setIsOpen, imgSrc = '' }) {
    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={() => setIsOpen(!isOpen)}
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                maxWidth: '90vw',
                height: 'auto',
                maxHeight: '90vh', // Adjust this value as needed
            }}
        >
            <div style={{width:'100%'}}>
                <a role="button" className="btn btn-info btn-lg" style={{ position: 'absolute', bottom: 5, right: 35, zIndex: '1000', opacity: '0.8' }} href={imgSrc} download={true}><i className='fas fa-download'></i></a>
                <div style={{width:'100%'}}>
                    <img src={imgSrc} alt="img" style={{width: '100%', borderRadius: '10px'}}/>
                </div>
            </div>
        </Modal>);
}