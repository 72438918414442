import React, { useEffect, useState } from 'react';
import Modal from '../../../common/Layout/Modal';
import Table from '../../../common/Layout/Table/Table';
import useGenerics from '../../../hooks/useGenerics';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getBestTechnicianByAvailability, getBestTechnicianByAvailabilityHomeAddress, getBestTechnicianByAvailabilityInTransit, getPartnersByTechnicianId } from '../../../services/Technician';
import { toast } from 'react-toastify';

export default function BestTechnician(
    {
        isOpen = false,
        setIsOpen = null,
        setCreateTicketForm = null,
        createTicketForm = {}
    }
) {
    const { openNewTab } = useGenerics();
    const [dataToTables, setDataToTables] = useState({});
    const mySwal = withReactContent(Swal);

    const columnsTecnicosAgendamento = [
        {
            id: 'nome_tecnico_agendamento',
            name: 'Nome do Técnico',
            cell: (row) => (
                <a onClick={() => openNewTab('/cadastros/tecnicos/detalhes/' + row.id_tecnico_agendamento)} href="javascript:void(0)">{row.nome_tecnico_agendamento}</a>
            ),
            sortable: true
        },
        {
            id: 'distancia_tecnico_agendamento',
            name: 'Distância do Técnico(Km)',
            selector: row => row.distancia_tecnico_agendamento,
            sortable: true
        },
        {
            id: 'numero_chamado_agendamento',
            name: 'Nº Chamado Atual',
            cell: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_agendamento)} href="javascript:void(0)">{row.numero_chamado_agendamento}</a>),
            sortable: true
        },
        {
            id: 'action_agendamento',
            name: 'Ação',
            button: 'true',
            cell: (row) => (
                <button type='button' className='btn btn-primary' onClick={() => showSelectRepresentanteSwal({ nome_tecnico: row.nome_tecnico_agendamento, id_tecnico: row.id_tecnico_agendamento, distancia_tecnico: row.distancia_tecnico_agendamento })}>Alocar Técnico</button>
            )
        },
    ];

    const columnsTecnicosDeslocamento = [
        {
            id: 'nome_tecnico_deslocamento',
            name: 'Nome do Técnico',
            cell: (row) => (
                <a onClick={() => openNewTab('/cadastros/tecnicos/detalhes/' + row.id_tecnico_deslocamento)} href="javascript:void(0)">{row.nome_tecnico_deslocamento}</a>
            ),
            sortable: true
        },
        {
            id: 'distancia_tecnico_deslocamento',
            name: 'Distância do Técnico(Km)',
            selector: row => row.distancia_tecnico_deslocamento,
            sortable: true
        },
        {
            id: 'numero_chamado_deslocamento',
            name: 'Nº Chamado Atual',
            cell: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_deslocamento)} href="javascript:void(0)">{row.numero_chamado_deslocamento}</a>),
            sortable: true
        },
        {
            id: 'action_deslocamento',
            name: 'Ação',
            button: 'true',
            cell: (row) => (
                <button type='button' className='btn btn-primary' onClick={() => showSelectRepresentanteSwal({ nome_tecnico: row.nome_tecnico_deslocamento, id_tecnico: row.id_tecnico_deslocamento, distancia_tecnico: row.distancia_tecnico_deslocamento })}>Alocar Técnico</button>
            )
        },
    ];

    const columnsTecnicosComChamados = [
        {
            id: 'nome_tecnico_com_chamados',
            name: 'Nome do Técnico',
            cell: (row) => (
                <a onClick={() => openNewTab('/cadastros/tecnicos/detalhes/' + row.id_tecnico_com_chamados)} href="javascript:void(0)">{row.nome_tecnico_com_chamados}</a>
            ),
            sortable: true
        },
        {
            id: 'distancia_tecnico_com_chamados',
            name: 'Distância do Técnico(Km)',
            selector: row => row.distancia_tecnico_com_chamados,
            sortable: true
        },
        {
            id: 'numero_chamado_com_chamados',
            name: 'Nº Chamado Atual',
            cell: (row) => {
                const links = row.numero_chamado_com_chamados.map((item, idx) => (
                    <a onClick={() => openNewTab('/chamados/detalhes-chamado/' + item.id_chamado)} href="javascript:void(0)" key={idx}>
                        {item.num_chamado}
                    </a>
                ));
                const content = links.length > 0 ? links.reduce((prev, curr) => [prev, ', ', curr]) : null;

                return (
                    <div>
                        {content}
                    </div>
                );
            },
            sortable: true
        },
        {
            id: 'action_com_chamados',
            name: 'Ação',
            button: 'true',
            cell: (row) => (
                <button type='button' className='btn btn-primary' onClick={() => showSelectRepresentanteSwal({ nome_tecnico: row.nome_tecnico_com_chamados, id_tecnico: row.id_tecnico_com_chamados, distancia_tecnico: row.distancia_tecnico_com_chamados })}>Alocar Técnico</button>
            )
        },
    ];

    const columnsTecnicosSemChamados = [
        {
            id: 'nome_tecnico_sem_chamados',
            name: 'Nome do Técnico',
            cell: (row) => (
                <a onClick={() => openNewTab('/cadastros/tecnicos/detalhes/' + row.id_tecnico_sem_chamados)} href="javascript:void(0)">{row.nome_tecnico_sem_chamados}</a>
            ),
            sortable: true
        },
        {
            id: 'distancia_tecnico_sem_chamados',
            name: 'Distância do Técnico(Km)',
            selector: row => row.distancia_tecnico_sem_chamados,
            sortable: true
        },
        {
            id: 'action_sem_chamados',
            name: 'Ação',
            button: 'true',
            cell: (row) => (
                <button type='button' className='btn btn-primary' onClick={() => showSelectRepresentanteSwal({ nome_tecnico: row.nome_tecnico_sem_chamados, id_tecnico: row.id_tecnico_sem_chamados, distancia_tecnico: row.distancia_tecnico_sem_chamados })}>Alocar Técnico</button>
            )
        },
    ];

    async function bestTechnician() {
        const toastBestTechnician = toast.loading('Carregando dados, aguarde...');
        
        let formData = {
            data_agendamento: createTicketForm.data_agendamento || '',
            hora_agendamento: createTicketForm.hora_agendamento || '',
            logradouro_cliente: createTicketForm.endereco_cliente || '',
            bairro_cliente: createTicketForm.bairro_endereco_cliente || '',
            cidade_cliente: createTicketForm.cidade_endereco_cliente || '',
            estado_cliente: createTicketForm.estado_endereco_cliente || '',
            cep_cliente: createTicketForm.cep_endereco_cliente || '',
            numero_complemento: createTicketForm.numero_complemento || ''
        }

        let data = {
            agendamento: [],
            deslocamento: [],
            residenciaComChamado: [],
            residenciaSemChamado: []
        }

        data.agendamento = await getTechnicianByAvailability(formData);
        data.deslocamento = await getTechnicianByAvailabilityInTransit(formData);
        let dadosTecnicosPorResidencia = await getTechnicianByAvailabilityHomeAddress(formData);

        data.residenciaComChamado = dadosTecnicosPorResidencia.comChamado;
        data.residenciaSemChamado = dadosTecnicosPorResidencia.semChamado;

        setDataToTables(data);
        
        toast.update(toastBestTechnician, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
    }

    async function getTechnicianByAvailability(params) {
        let dataReturn = await getBestTechnicianByAvailability(params);

        if (dataReturn?.data?.response === 'success') {
            let agendamento = dataReturn?.data?.data?.melhor_tecnico_por_geolocalizacao_pela_agenda?.map((item, idx) => {
                return ({
                    id_tecnico_agendamento: item?.id_tecnico || '',
                    nome_tecnico_agendamento: item?.nome_tecnico || '',
                    distancia_tecnico_agendamento: item?.distancia_km || '',
                    numero_chamado_agendamento: item?.num_chamado || '',
                    id_chamado_agendamento: item?.id_chamado || '',
                });
            });
            return agendamento || [];
        } else {
            return [];
        }
    }

    async function getTechnicianByAvailabilityInTransit(params) {
        let dataReturn = await getBestTechnicianByAvailabilityInTransit(params);
        if (dataReturn?.data?.response === 'success') {
            let deslocamento = dataReturn?.data?.data?.tecnicos_em_deslocamento?.map((item, idx) => {
                return ({
                    id_tecnico_deslocamento: item?.id_tecnico || '',
                    nome_tecnico_deslocamento: item?.nome_tecnico || '',
                    distancia_tecnico_deslocamento: item?.distancia_km || '',
                    numero_chamado_deslocamento: item?.num_chamado || '',
                    id_chamado_deslocamento: item?.id_chamado || ''
                });
            });
            return deslocamento || [];
        } else {
            return [];
        }
    }

    async function getTechnicianByAvailabilityHomeAddress(params) {
        let dataReturn = await getBestTechnicianByAvailabilityHomeAddress(params);

        let tecnicosPorResidencia = {
            comChamado: [],
            semChamado: []
        }

        if (dataReturn?.data?.response === 'success') {
            tecnicosPorResidencia.comChamado = dataReturn?.data?.data?.melhor_tecnico_por_endereco_residencial?.map((item, idx) => {
                let numerosChamados = item?.chamados.map((item, idx) => {
                    return { id_chamado: item?.id, num_chamado: item?.num_chamado };
                }) || [];

                return ({
                    id_tecnico_com_chamados: item?.id_tecnico || '',
                    nome_tecnico_com_chamados: item?.nome_tecnico || '',
                    distancia_tecnico_com_chamados: item?.distancia_km || '',
                    numero_chamado_com_chamados: numerosChamados || [],
                });
            }) || [];

            tecnicosPorResidencia.semChamado = dataReturn?.data?.data?.melhor_tecnico_por_endereco_residencial_sem_chamados?.map((item, idx) => {
                return ({
                    id_tecnico_sem_chamados: item?.id_tecnico || '',
                    nome_tecnico_sem_chamados: item?.nome_tecnico || '',
                    distancia_tecnico_sem_chamados: item?.distancia_km || '',
                });
            }) || [];
        }

        return tecnicosPorResidencia;
    }

    async function showSelectRepresentanteSwal({ nome_tecnico = '', id_tecnico = '', distancia_tecnico = '' }) {
        mySwal.close();
        let representantes = await getPartnersByTechnician(id_tecnico);

        let swal = await mySwal.fire({
            title: `Selecione o representando do ${nome_tecnico}`,
            input: 'select',
            inputOptions: representantes,
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Confirmar técnico',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Cancelar',
            showDenyButton: true,
            denyButtonColor: 'purple',
            denyButtonText: 'Selecionar outro técnico'
        });

        if (swal.isConfirmed === true && swal.value !== '') {
            let representanteSplit = swal.value.split("||");
            setCreateTicketForm(prevState => (
                {
                    ...prevState,
                    id_representante: { value: +representanteSplit[0], label: representanteSplit[1] },
                    id_tecnico: null,
                    id_representante_valor_comercial: ''
                }
            ))

            setTimeout(()=>{
                setCreateTicketForm(prevState => (
                    {
                        ...prevState,
                        id_tecnico: { value: id_tecnico, label: nome_tecnico }
                    }
                ))
            },0)

            setIsOpen(false);
        }
    }

    async function getPartnersByTechnician(id) {
        let dataReturn = await getPartnersByTechnicianId(id);

        if (dataReturn?.data?.response === 'success') {
            let partners = dataReturn?.data?.data?.reduce((obj, item) => ({ ...obj, [`${item.id}||${item.nome}`]: item.nome }), {}) || {};
            return partners;
        } else {
            return {};
        }
    }

    useEffect(()=>{
        if(isOpen){
            bestTechnician();
        }
    }, [isOpen]);

    return (<Modal
        isOpen={isOpen}
        setModalOpen={setIsOpen}
        title='Melhores Técnicos'
        customStyle={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            height: '95vh',
        }}
    >
        <div>
            <ul className="nav nav-tabs" id="technicianTab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="agendamentoTab" data-toggle="tab" href="#agendamento" role="tab" aria-controls="agendamento" aria-selected="true">Geolocalização(Agendamento)</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="deslocamentoTab" data-toggle="tab" href="#deslocamento" role="tab" aria-controls="deslocamento" aria-selected="true">Geolocalização(Em Deslocamento)</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="residenciaChamadosTab" data-toggle="tab" href="#residenciaChamados" role="tab" aria-controls="residencia-com-chamados" aria-selected="true">Residência</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="residenciaSemChamadosTab" data-toggle="tab" href="#residenciaSemChamados" role="tab" aria-controls="residencia-sem-chamados" aria-selected="true">Residência(Sem Chamados)</a>
                </li>
            </ul>
            <div className="tab-content" id="tachnicianTabContent">
                <div className="tab-pane fade show active" id="agendamento" role="tabpanel" aria-labelledby="agendamento-tab">
                    <Table columns={columnsTecnicosAgendamento} data={dataToTables?.agendamento || []} needFilter={true} id='agendamento_table' />
                </div>
                <div className="tab-pane fade" id="deslocamento" role="tabpanel" aria-labelledby="deslocamento-tab">
                    <Table columns={columnsTecnicosDeslocamento} data={dataToTables?.deslocamento || []} needFilter={true} id='deslocamento_table' />
                </div>
                <div className="tab-pane fade" id="residenciaChamados" role="tabpanel" aria-labelledby="residencia-com-chamados-tab">
                    <Table columns={columnsTecnicosComChamados} data={dataToTables?.residenciaComChamado || []} needFilter={true} id='residencia_com_chamado_table' />
                </div>
                <div className="tab-pane fade" id="residenciaSemChamados" role="tabpanel" aria-labelledby="residencia-sem-chamados-tab">
                    <Table columns={columnsTecnicosSemChamados} data={dataToTables?.residenciaSemChamado || []} needFilter={true} id='residencia_sem_chamado_table' />
                </div>
            </div>
        </div>
    </Modal>);
}