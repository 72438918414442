import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Table from '../../../common/Layout/Table/Table';
import { Link } from 'react-router-dom';
import Card from '../../../common/Layout/Card';
import { deleteChamadoAcionamento, getAllTicketsAcionamento } from '../../../services/MamSolutions';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import useGenerics from '../../../hooks/useGenerics';

export default function AcionamentosManagement() {

    const INITIAL_STATE = {
        data_inicial: '',
        data_final: ''
    }

    const [acionamentoTableData, setAcionamentoTableData] = useState([]);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const { openNewTab } = useGenerics();
    const columns = [
        {
            id: 'numero_lote',
            name: 'Lote',
            selector: row => row.numero_lote,
            sortable: true,
            wrap: true
        },
        {
            id: 'num_chamado',
            name: 'Chamado ID_VTV',
            selector: row => row.num_chamado,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_registro',
            name: 'Data de Registro Maminfo',
            selector: row => row.data_registro,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_agendamento',
            name: 'Agendamento',
            selector: row => row?.data_agendamento,
            sortable: true,
            wrap: true
        },
        {
            id: 'nome_cliente',
            name: 'Cliente',
            selector: row => row.nome_cliente,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_visita',
            name: 'Tipo de Visita',
            selector: row => row.tipo_visita,
            sortable: true,
            wrap: true
        },
        {
            id: 'tecnico',
            name: 'Técnico',
            selector: row => row.tecnico,
            sortable: true,
            wrap: true
        },
        {
            id: 'status',
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            wrap: true
        },
        {
            id: 'action',
            name: 'Ação',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type='button' className='dropdown-item' onClick={() => openNewTab(`/chamados/detalhes-chamado/${row.id_chamado}`)}>Ir para Detalhes do Chamado</button>
                        <a role='button' href={`${row?.url_arquivo}`} className='dropdown-item' target='_blank' download={true} rel='noreferrer'>Download</a>
                        <button type='button' className="dropdown-item" onClick={() => showDeleteChamadoSwal(row.id_chamado)}>Excluir Chamado</button>
                    </div>
                </div>
            ),
        },
    ];

    async function showDeleteChamadoSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo excluir o chamado?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            deleteChamado(id);
        }
    }

    async function deleteChamado(id) {
        let toastDelete = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deleteChamadoAcionamento(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastDelete, { render: 'Dados excluidos com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getTableData();
        } else {
            toast.update(toastDelete, { render: 'Ocorreu um erro ao excluir os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    const getTableData = async (data_inicial = '', data_final = '') => {
        const toastGet = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllTicketsAcionamento(data_inicial, data_final);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastGet, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data?.map((item, idx) => ({
                id_acionamento: item?.id || '',
                id_chamado: item?.id_chamado || '',
                numero_lote: item?.numero_lote || '',
                num_chamado: item?.ticket?.num_chamado || '',
                data_registro: item?.data_registro || '',
                data_agendamento: item?.ticket?.data_agendamento || '',
                nome_cliente: item?.ticket?.customer?.nome_cliente || '',
                tipo_visita: item?.ticket?.tipo_visita || '',
                tecnico: item?.ticket?.technician?.nome_tec || '',
                status: item?.ticket?.status_chamado || '',
                url_arquivo: item?.url_arquivo || ''
            }));

            setAcionamentoTableData(data);
        } else {
            toast.update(toastGet, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function submitForm(e) {
        e.preventDefault();

        getTableData(formData?.data_inicial, formData?.data_final);
    }

    useEffect(() => {
        getTableData();
    }, [])

    return (
        <Content headerTitle='Gerenciar Acionamento'>
            <SubHeaderSpecific subHeaderConfig='acionamento'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="data_inicial">Data Inicial</label>
                            <input type="date" name="data_inicial" id="data_inicial" className='form-control' value={formData?.data_inicial} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label htmlFor="data_final">Data Final</label>
                            <input type="date" name="data_final" id="data_final" className='form-control' value={formData?.data_final} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                        </div>
                        <div className="col-md-4 col-sm-12 mt-1 d-flex align-items-end">
                            <button type="submit" className="btn btn-primary">Filtrar</button>
                        </div>
                    </div>
                </form>
            </Card>
            <Card hideCardHeader={true}>
                <div className='row'>
                    <div className="col-12">
                        <Table columns={columns} data={acionamentoTableData} id='gerenciar_acionamento'></Table>
                    </div>
                </div>
            </Card>
        </Content>
    );
}