import React, { useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import { assignTicket } from '../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import UsuariosAtivosSelect from '../../common/Selects/UsuariosAtivosSelect';
import ChamadosSelect from '../../common/Selects/ChamadosSelect';

export default function ResponsavelAtendimento(props) {
    const initialState = {
        responsavel_atendimento: '',
        chamados_nao_designados: '',
        chamados_todos_dia: ''
    }

    const [responsavelAtendimentoForm, setResponsavelAtendimentoForm] = useState(initialState);
    const [updateData, setUpdateData] = useState(false);

    async function submitAssignForm(e){
        e.preventDefault();
        let chamadosArray = [...responsavelAtendimentoForm?.chamados_nao_designados, ...responsavelAtendimentoForm?.chamados_todos_dia];

        if(chamadosArray.length === 0){
            toast.warning("Por favor, selecione pelo menos um chamado");
            return;
        }

        let ids_chamado = chamadosArray.map((item, idx)=>{
            return item?.value
        });

        let formData = {
            ids_chamado: ids_chamado,
            id_responsavel: responsavelAtendimentoForm?.responsavel_atendimento?.value || ''
        }
        const ticketToast = toast.loading("Atualizando chamados, aguarde...");

        let dataReturn = await assignTicket(formData);

        if(dataReturn?.data?.data?.message === 'Tickets atualizados com sucesso!'){
            toast.update(ticketToast, { render: 'Chamado Atualizado com Sucesso!', type: "success", isLoading: false, autoClose: 1500 });
        }else{
            toast.update(ticketToast, { render: 'Ocorreu um erro ao atualizar o chamado!', type: "warning", isLoading: false, autoClose: 1500 });
        }
        setResponsavelAtendimentoForm(initialState);
        setUpdateData(true);
    }

    return (<Content headerTitle="Chamados - Responsável Atendimento">
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitAssignForm}>
                <div className='row'>
                    <UsuariosAtivosSelect 
                        formData={responsavelAtendimentoForm} 
                        setFormData={setResponsavelAtendimentoForm} 
                        fieldName='responsavel_atendimento' 
                        required={true} 
                        title='Responsável Atendimento' 
                        userType='support'
                    ></UsuariosAtivosSelect>
                    <ChamadosSelect 
                        formData={responsavelAtendimentoForm}
                        setFormData={setResponsavelAtendimentoForm}
                        fieldName='chamados_nao_designados'
                        isMulti={true}
                        title='Chamados Não Designados (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Atendimento)'
                        type='unassigned'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                   <ChamadosSelect 
                        formData={responsavelAtendimentoForm}
                        setFormData={setResponsavelAtendimentoForm}
                        fieldName='chamados_todos_dia'
                        isMulti={true}
                        title='Chamados Todos do dia (Nº do chamado, Agendamento, Cliente, Integradora, Contrato, Responsável Atendimento)'
                        type='today'
                        update={updateData}
                        setUpdate={setUpdateData}
                    ></ChamadosSelect>
                    <div className="col-12 mt-1">
                        <button type='submit' className="btn btn-primary">Designar responsável ao(s) chamado(s)</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}