import interceptorInstance from "./Interceptor";

export async function getAllIntegrators(){
    try{
        let dados = await interceptorInstance.get('integrator/all/1');
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getIntegratorsByStatus(status){
    try{
        let dados = await interceptorInstance.get(`integrator/all/${status}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function changeIntegratorStatus(id, status){
    try{
        let dados = await interceptorInstance.patch(`integrator/enable-disable/${id}/${status}`, {});
        return dados;
    }catch(e){
        return e.response;
    } 
}

export async function insertIntegrator(params){
    try{
        let dados = await interceptorInstance.post(`integrator/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getIntegratorById(id){
    try{
        let dados = await interceptorInstance.get(`integrator/search/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function updateIntegratorData(id, params){
    try{
        let dados = await interceptorInstance.patch(`integrator/update/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}