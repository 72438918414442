import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTechniciansByPartnerId } from '../../services/Partners';

export default function TecnicosSelect({ formData, setFormData, fieldName = 'tecnico', cols = [12, 12, 12, 12], isDisabled = false, required = false, id_representante = '', advancedSetFormData = null }) {
    const [techniciansOptions, setTechniciansOptions] = useState([]);

    async function getTechniciansByPartner(id) {
        let dataReturn = await getTechniciansByPartnerId(id);
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let tecnicos = dataReturn.data.data.map((item, idx) => ({ value: item.id, label: item.nome_tec }));
            setTechniciansOptions(prevState => ([...tecnicos]));
        } else {
            setTechniciansOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        handleSelectForm('', fieldName)
        let id = id_representante;
        if (id !== undefined && id !== '') {
            getTechniciansByPartner(id);
        } else {
            setTechniciansOptions([]);
        }

    }, [id_representante]);

    return (
        <div className={cols.length > 0 ? `col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}` : 'col'}>
            <label htmlFor={fieldName}>Técnicos</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={[{ value: '', label: 'Selecione...' }, ...techniciansOptions]}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
                isClearable={true}
            ></Select>
        </div>
    );
}