import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../../common/Layout/Modal';
import { addGed } from '../../../../services/Ged';
import { toast } from 'react-toastify';

export default function AddGed({isOpen, setModalOpen, productId='', setUpdateData=null, submodule = 'produtos'}){

    const INITIAL_STATE = {
        titulo: '',
        arquivo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const arquivoRef = useRef();

    async function submitForm(e){
        e.preventDefault();

        const toastAddGed = toast.loading('Adicionando documento, aguarde...');

        let params = new FormData();

        params.append('files', formData?.arquivo);
        params.append('titulo', formData?.titulo);
        params.append('submodulo', submodule);

        let dataReturn = await addGed(productId, params);

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastAddGed, { render: 'Documento adicionado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(INITIAL_STATE);
            setUpdateData();
            arquivoRef.current.value = '';
        }else{
            toast.update(toastAddGed, { render: 'Ocorreu um erro ao adicionar o documento!', type: "warning", isLoading: false, autoClose: 1500 });
            console.log(dataReturn);
        }        
    }

    useEffect(()=>{
        if(isOpen){
            setFormData(INITIAL_STATE);
        }
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title='Adicionar Documento'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="titulo">Título</label>
                        <input type="text" name="titulo" id="titulo" className="form-control" value={formData?.titulo || ''} onChange={(e)=>setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))} required/>
                    </div>
                    <div className="col-12">
                        <label htmlFor="arquivo">Arquivo</label>
                        <input type="file" name="arquivo" id="arquivo" className='form-control' onChange={(e)=>setFormData(prevState => ({...prevState, [e.target.name]: e.target.files[0]}))} ref={arquivoRef} required/>
                    </div>
                    <div className="col-12">
                        <button type="submit" className='btn btn-primary mt-1'>Salvar Documento</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}