import React, { useEffect, useState } from 'react';
import Table from '../../../../common/Layout/Table/Table';
import { changeRentedVehicleStatus, createRentedVehicle, getRentedVehicles } from '../../../../services/Employee';
import { toast } from 'react-toastify';
import CustomMessageData from '../../../../common/Layout/CustomMessageData';
import Swal from 'sweetalert2';
import If from '../../../../common/Layout/If';

export default function RentedVehicles({ technicianId = '', readOnly = true }) {
    const INITIAL_STATE = {
        veiculo_locado: '',
        modelo_veiculo_locado: '',
        placa_veiculo_locado: '',
        cor_veiculo_locado: '',
        data_locacao_locado: '',
        data_expiracao_locado: '',
        autonomia_locado: ''
    }

    const [formRentedVehicles, setFormRentedVehicles] = useState(INITIAL_STATE);
    const [rentedVehicleData, setRentedVehicleData] = useState([]);

    const columns = [
        {
            id: 'veiculo_locacao_table',
            name: 'Veículo',
            selector: row => row?.veiculo_locacao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_locacao_table',
            name: 'Modelo',
            selector: row => row?.modelo_locacao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'cor_locacao_table',
            name: 'Cor',
            selector: row => row?.cor_locacao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'placa_locacao_table',
            name: 'Placa',
            selector: row => row?.placa_locacao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_locacao_table',
            name: 'Data de Locação',
            selector: row => row?.data_locacao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_expiracao_locacao_table',
            name: 'Data de Expiração',
            selector: row => row?.data_expiracao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_locacao_table',
            name: 'Status',
            selector: row => row?.status_locacao_table,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_locacao_table',
            name: 'Ação',
            cell: (row) => {
                return row.status_locacao_table === "Em Uso" ?
                    (<div className='align-items-center'>
                        <button type="button" className='btn btn-danger' onClick={() => showCancelRentedVehicleSwal(row.id_veiculo_locacao_table)} disabled={readOnly}><i className='fas fa-ban'></i></button>
                    </div>)
                    :
                    (<></>)
            },
            width: '50px'
        }
    ]

    async function createVehicle(e) {
        e.preventDefault();

        let formData = {
            id_tecnico: technicianId,
            veiculo: formRentedVehicles?.veiculo_locado,
            modelo_veiculo: formRentedVehicles?.modelo_veiculo_locado,
            placa_veiculo: formRentedVehicles?.placa_veiculo_locado,
            cor_veiculo: formRentedVehicles?.cor_veiculo_locado,
            data_locacao: formRentedVehicles?.data_locacao_locado,
            data_expiracao_locacao: formRentedVehicles?.data_expiracao_locado,
            autonomia: formRentedVehicles?.autonomia_locado
        }

        if (new Date(formRentedVehicles?.data_locacao_locado) > new Date(formRentedVehicles?.data_expiracao_locado)) {
            toast.warning("A data de locação não pode ser maior que a data de expiração!");
            return;
        }

        const toastVehicle = toast.loading("Cadastrando veículo, aguarde...");

        let dataReturn = await createRentedVehicle(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastVehicle, { render: 'Veículo cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormRentedVehicles(INITIAL_STATE);
            getVehicles();
        } else if (dataReturn?.data?.message === 'Você já tem um veículo locado!') {
            toast.update(toastVehicle, { render: dataReturn?.data?.message, type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastVehicle, { render: 'Ocorreu um erro ao cadastrar o veículo!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getVehicles() {
        const toastVehicle = toast.loading("Carregando veículos, aguarde...");

        let dataReturn = await getRentedVehicles(technicianId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastVehicle, { render: 'Veículos encontrados', type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data?.map((item, idx) => {
                let currentDate = new Date();
                let expirationDate = new Date(item?.data_expiracao_locacao);
                let statusByDate = new Date(expirationDate.toDateString()) >= new Date(currentDate.toDateString()) ? 'Em Uso' : 'Expirado';
                return {
                    id_veiculo_locacao_table: item?.id || '',
                    veiculo_locacao_table: item?.veiculo || '',
                    modelo_locacao_table: item?.modelo_veiculo || '',
                    cor_locacao_table: item?.cor_veiculo || '',
                    placa_locacao_table: item?.placa_veiculo || '',
                    data_locacao_table: item?.data_locacao || '',
                    data_expiracao_table: item?.data_expiracao_locacao || '',
                    status_locacao_table: item?.status === 0 ? 'Cancelado' : statusByDate
                }
            });

            setRentedVehicleData(data);
        } else {
            toast.update(toastVehicle, { render: 'Ocorreu um erro ao procurar os veículos!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function cancelRentedVehicle(vehicleId) {
        let formData = {
            status: 0
        }
        const toastVehicle = toast.loading("Atualizando status, aguarde...");

        let dataReturn = await changeRentedVehicleStatus(vehicleId, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastVehicle, { render: 'Status atualizado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getVehicles();
        } else {
            toast.update(toastVehicle, { render: 'Ocorreu um erro ao atualizar o status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showCancelRentedVehicleSwal(vehicleId){
        let response = await Swal.fire({
            title: 'Deseja realmente cancelar este veículo?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            confirmButtonText:  'Sim',
            confirmButtonColor: 'green'
        });

        if(response.isConfirmed){
            cancelRentedVehicle(vehicleId);
        }
    }

    useEffect(() => {
        if (technicianId !== '') {
            getVehicles();
        }
    }, [technicianId]);

    return (
        <div className="row">
            <div className="col-12">
                <h5><i className="fas fa-dot-circle"></i> Veículos Alugados</h5>
            </div>
            <div className="col-12">
                <form onSubmit={createVehicle}>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="veiculo_locado">Veículo</label>
                            <input type="text" name="veiculo_locado" id="veiculo_locado" className="form-control" value={formRentedVehicles?.veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="modelo_veiculo">Modelo</label>
                            <input type="text" name="modelo_veiculo_locado" id="modelo_veiculo_locado" className="form-control" value={formRentedVehicles?.modelo_veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="cor_veiculo_locado">Cor</label>
                            <input type="text" name="cor_veiculo_locado" id="cor_veiculo_locado" className="form-control" value={formRentedVehicles?.cor_veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <label htmlFor="placa_veiculo_locado">Placa</label>
                            <input type="text" name="placa_veiculo_locado" id="placa_veiculo_locado" className="form-control" value={formRentedVehicles?.placa_veiculo_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="autonomia_locado">Autonomia</label>
                            <input type="number" min={'0'} step={'0.01'} name="autonomia_locado" id="autonomia_locado" className="form-control" value={formRentedVehicles?.autonomia_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <label htmlFor="data_locacao_locado">Data de Locação</label>
                            <input type="date" name="data_locacao_locado" id="data_locacao_locado" className="form-control" value={formRentedVehicles?.data_locacao_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="data_expiracao_locado">Data de Expiração</label>
                            <input type="date" name="data_expiracao_locado" id="data_expiracao_locado" className="form-control" value={formRentedVehicles?.data_expiracao_locado || ''} onChange={(e) => (setFormRentedVehicles(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={readOnly} required />
                        </div>
                        <div className="col-lg-2 col-md-12 d-flex align-items-end mt-1">
                            <button type="submit" className="btn btn-primary" disabled={readOnly}>Salvar</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-12">
                <If condition={rentedVehicleData?.length > 0}>
                    <Table columns={columns} data={rentedVehicleData} id='veiculos_locados_table'></Table>
                </If>
                <If condition={rentedVehicleData?.length === 0}>
                    <CustomMessageData message='Sem dados'></CustomMessageData>
                </If>
            </div>
        </div>
    )
}