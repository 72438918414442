import React from "react";
import Content from "../../common/Layout/Content";

function Main(props) {
    return (
        <div>
            <Content headerTitle="Bem-vindo ao Maminfo ERP">
                <p>Selecione um módulo ao lado</p>
            </Content>
        </div>
    );
}

export default Main;