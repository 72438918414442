import interceptorInstace from './Interceptor';

export async function getUserData(){
    try{
        let data = await interceptorInstace.get('user/logged');
        return data;
    }catch(e){  
        return e.response;
    }
}

export async function getPermissions(){
    try{
        let data = await interceptorInstace.get('user/permissions');
        return data;
    }catch(e){  
        return e.response;
    }  
}

export async function getAllUsers(status){
    try{
        let data = await interceptorInstace.get(`user/get-all/${status}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function enableDisableUser(id, status){
    try{
        let data = await interceptorInstace.patch(`user/enable-disable/${id}/${status}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createUser(params){
    try{
        let data = await interceptorInstace.post(`user/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getUserDataById(id){
    try{
        let data = await interceptorInstace.get(`user/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateUser(id, params){
    try{
        let data = await interceptorInstace.patch(`user/update/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateUserProfilePhoto(id, params){
    try{
        let data = await interceptorInstace.post(`user/upload-picture/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

