import axios from 'axios';
import { getNewToken } from './Auth';
import Swal from 'sweetalert2';

// Criação da fila e variáveis de controle
let isTokenRefreshing = false;
let failedQueue = [];

const baseUrl = process.env.REACT_APP_API_MAMERP || "http://localhost:8000/api/";

const interceptorInstance = axios.create({
    baseURL: baseUrl,
    timeout: 60000,
    maxContentLength: Infinity,
    maxBodyLength: Infinity
})

// Helper function to process the failed queue
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

interceptorInstance.interceptors.request.use(
    async (config) => {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        if (userToken?.access_token) {
            config.headers.Authorization = `Bearer ${userToken?.access_token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

interceptorInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error?.response?.data?.message === "Unauthenticated." && !originalRequest._retry) {

            const location = window.location.pathname;

            if(location.includes('/external-chat')){
                await goToLogin('warning', 'Login Expirado', `Seu login expirou! Por favor, clique em OK para retornar no login e se autenticar!`);
                return;
            }

            if(isTokenRefreshing){
                return new Promise(function (resolve, reject){
                    failedQueue.push({resolve, reject});
                }).then((token)=>{
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return interceptorInstance(originalRequest);
                }).catch((error)=>{
                    return Promise.reject(error);
                });
            }

            originalRequest._retry = true;
            isTokenRefreshing = true;

            return new Promise(async (resolve, reject)=>{
                try{
                    let resp = await getNewToken();
           
                    if (resp.status === 201) {
                        const newToken = resp.data?.data?.new_access_token
                        localStorage.setItem('token', JSON.stringify({ access_token:  newToken}));
                        interceptorInstance.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
                        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
                        processQueue(null, newToken);
                        resolve(interceptorInstance(originalRequest));
                    } else {
                        await goToLogin('warning', 'Não Autenticado', `Por favor, clique em OK para retornar no login e se autenticar!`);
                        processQueue(new Error('Falha na autenticação'));
                        reject(resp);
                    }
                }catch(error){
                    processQueue(error, null);
                    await goToLogin('warning', 'Erro de Autenticação', `Ocorreu um erro ao tentar se autenticar. Por favor, faça login novamente!`);
                    reject(error);
                }finally{
                    isTokenRefreshing = false;
                }
            });
            
        }

        if (["Unauthorized", "Error processing token.", "Invalid Token"].includes(error?.response?.data?.errors?.error) || error?.response?.status === 500) {
            await goToLogin('warning', 'Não Autenticado', `Por favor, clique em OK para retornar no login e se autenticar!`);
            return error.response;
        }

        if(error.response.status === 422){
            return error.response;
        }

        if(error.response.status === 500 && error.response.data.message === 'count(): Argument #1 ($value) must be of type Countable|array, null given'){
            return error.response;
        }

        if(["ECONNABORTED", "ERR_NETWORK"].includes(error.code)){
            await goToLogin();
        }

        return Promise.reject(error);
    }
);

async function goToLogin(icon = 'error', title = 'Oops...', html =  `Ocorreu um erro que torna necessário redirecionar para o login.<br>Em caso de reincidência, por favor contatar a equipe de desenvolvimento!`){
    let response = await Swal.fire({
        icon: icon,
        title: title,
        html: html
    })

    if(response){
        localStorage.clear();
        window.location.reload(true);
    }
}

export default interceptorInstance;