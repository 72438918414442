import React from 'react';

import maminfo_logo from '../../resources/imgs/logo/logo_star_maminfo.png';
import maminfo_sede from '../../resources/imgs/sede.jpeg';

export default function LoginCard({ children }) {
    return (<div className='hold-transition login-page' style={{ backgroundImage: `url(${maminfo_sede})`, backgroundSize: "100% 100%" }}>
        <div className='login-box'>
            <div className="card card-outline card-primary" style={{ opacity: "0.9" }}>
                <div className='card-header text-center'>
                    <img src={maminfo_logo} alt="Logo"></img>
                    <h1 style={{ color: "#D1D1D1" }}><b>mam<span style={{ color: "#1869A7" }}>info</span></b> ERP</h1>
                </div>
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Entre com seu usuário e senha para continuar</p>
                    {children}
                </div>
            </div>
        </div>
    </div>)
}