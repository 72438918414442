import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate, useParams } from 'react-router-dom';
import { withMask } from 'use-mask-input';
import { getPartnerById, updatePartner } from '../../../../services/Partners';
import Cep from '../../../../common/Cep/Cep';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';
import PartnerValuesTable from './PartnerValues/PartnerValuesTable';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import If from '../../../../common/Layout/If';

export default function PartnerDetails() {

    const { id } = useParams();
    const navigate = useNavigate();
    const initialState = {
        representante_confirmado: { label: 'NÃO', value: 'NÃO' },
        nome_representante: '',
        orgao_representante: '',
        ie_representante: '',
        cnpj_representante: '',
        logradouro_end: '',
        bairro_end: '',
        cidade_end: '',
        estado_end: '',
        cep_end: '',
        nome_responsavel: '',
        telefone_fixo_responsavel: '',
        celular_responsavel: '',
        email_responsavel: '',
        telefone_comercial: '',
        profissao: '',
        veiculo: '',
        cor_veiculo: '',
        modelo_veiculo: '',
        placa_veiculo: '',
        banco: '',
        tipo_conta_banco: '',
        num_conta_banco: '',
        titular_banco2: '',
        cpf_titular2: '',
        agencia_banco: '',
        descricao: '',
        ativo: '',
        data_vencimento: '',
        id_usuario_gestor_representante: ''
    }

    const [formPartnerData, setFormPartnerData] = useState(initialState);
    const [updateData, setUpdataData] = useState(false);

    async function getPartner() {
        const partnerDataToast = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getPartnerById(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(partnerDataToast, { render: `Dados carregados com sucesso!`, type: "success", isLoading: false, autoClose: 1500 });
            let partnerData = dataReturn?.data?.data || initialState;
            setFormPartnerData({
                ...partnerData,
                representante_confirmado: { value: partnerData?.representante_confirmado, label: partnerData?.representante_confirmado },
                id_usuario_gestor_representante: { value: partnerData?.partner_manager?.id || '', label: partnerData?.partner_manager?.nome || '' }
            });
        } else if (dataReturn?.data?.message.includes("Partner not found!")) {
            toast.update(partnerDataToast, { render: `Este registro não existe!`, type: "warning", isLoading: false, autoClose: 1500 });
            navigate("/cadastros/representantes/dados");
        } else {
            toast.update(partnerDataToast, { render: `Ocorreu um erro ao carregar os dados!`, type: "warning", isLoading: false, autoClose: 1500 });
            setFormPartnerData(initialState);
        }
    }

    async function submitForm() {

        let formData = {
            id: id,
            representante_confirmado: formPartnerData?.representante_confirmado?.value || 'NÃO',
            nome_representante: formPartnerData?.nome_representante || '',
            orgao_representante: formPartnerData?.orgao_representante || '',
            ie_representante: formPartnerData?.ie_representante || '',
            cnpj_representante: formPartnerData?.cnpj_representante || '',
            logradouro_end: formPartnerData?.logradouro_end || '',
            bairro_end: formPartnerData?.bairro_end || '',
            cidade_end: formPartnerData?.cidade_end || '',
            estado_end: formPartnerData?.estado_end || '',
            cep_end: formPartnerData?.cep_end || '',
            nome_responsavel: formPartnerData?.nome_responsavel || '',
            telefone_fixo_responsavel: formPartnerData?.telefone_fixo_responsavel || '',
            celular_responsavel: formPartnerData?.celular_responsavel || '',
            email_responsavel: formPartnerData?.email_responsavel || '',
            telefone_comercial: formPartnerData?.telefone_comercial || '',
            profissao: formPartnerData?.profissao || '',
            veiculo: formPartnerData?.veiculo || '',
            cor_veiculo: formPartnerData?.cor_veiculo || '',
            modelo_veiculo: formPartnerData?.modelo_veiculo || '',
            placa_veiculo: formPartnerData?.placa_veiculo || '',
            banco: formPartnerData?.banco || '',
            tipo_conta_banco: formPartnerData?.tipo_conta_banco || '',
            num_conta_banco: formPartnerData?.num_conta_banco || '',
            titular_banco2: formPartnerData?.titular_banco2 || '',
            cpf_titular2: formPartnerData?.cpf_titular2 || '',
            agencia_banco: formPartnerData?.agencia_banco || '',
            descricao: formPartnerData?.descricao || '',
            ativo: formPartnerData?.ativo,
            data_vencimento: formPartnerData?.data_vencimento || '',
            id_usuario_gestor_representante: formPartnerData?.id_usuario_gestor_representante?.value || ''
        }

        const toastUpdateForm = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updatePartner(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUpdateForm, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getPartner();
            setUpdataData(false);
        } else {
            toast.update(toastUpdateForm, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        getPartner();
    }, []);


    return (
        <Content headerTitle="Detalhes Representante">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <If condition={!updateData}>
                            <button type="button" className="btn btn-primary" onClick={() => (setUpdataData(prevState => !prevState))}>Alterar</button>
                        </If>
                        <If condition={updateData}>
                            <button type="button" className="btn btn-primary" onClick={submitForm}>Salvar Alterações</button>
                        </If>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Representante</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                            <label htmlFor="nomeRepresentante">Nome de Representante</label>
                            <input type="text" className="form-control" name="nome_representante" id="nomeRepresentante" value={formPartnerData?.nome_representante || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} required />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="orgaoRepresentante">Órgão</label>
                            <input type="text" className="form-control" name="orgao_representante" id="orgaoRepresentante" value={formPartnerData?.orgao_representante || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="ieRepresentante">I.E</label>
                            <input type="text" className="form-control" name="ie_representante" id="ieRepresentante" value={formPartnerData?.ie_representante || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="cnpjRepresentante">CNPJ</label>
                            <input type="text" className="form-control" name="cnpj_representante" id="cnpjRepresentante" ref={withMask(["99.999.999/9999-99"])} value={formPartnerData?.cnpj_representante || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} required />
                        </div>
                    </div>
                    <UsuariosAtivosSelect formData={formPartnerData} setFormData={setFormPartnerData} cols={[12, 12, 2, 2]} fieldName='id_usuario_gestor_representante' title='Gestor Representante' userType='all' required={true} isDisabled={!updateData}></UsuariosAtivosSelect>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Endereço</h5>
                    </div>
                </div>
                <Cep
                    formData={formPartnerData}
                    setFormData={setFormPartnerData}
                    formDataNames={{ cep: 'cep_end', estado: 'estado_end', cidade: 'cidade_end', bairro: 'bairro_end', logradouro: 'logradouro_end' }}
                    readOnly={!updateData}
                >
                </Cep>
                <div className='row'>
                    <GenericSelect formData={formPartnerData} setFormData={setFormPartnerData} fieldName={'representante_confirmado'} title={'Contrato de Parceria'} cols={[12, 12, 4, 4]} genericOption='SimNaoOptions' isDisabled={!updateData} required={true}></GenericSelect>
                    <If condition={formPartnerData?.representante_confirmado?.value === 'SIM'}>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="dataVencimento">Data de Vencimento</label>
                            <input type="date" name="data_vencimento" id="dataVencimento" className="form-control" value={formPartnerData?.data_vencimento || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </If>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="dataRegistro">Data Registro</label>
                        <input type="text" name="data_registro" className='form-control' id="dataRegistro" placeholder='...' value={formPartnerData?.data_registro || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly />
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Contato</h5>
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <label htmlFor="nomeResponsavel">Nome</label>
                        <input type="text" name="nome_responsavel" id="nomeResponsavel" className="form-control" value={formPartnerData?.nome_responsavel || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <label htmlFor="emailResponsavel">Email</label>
                        <input type="email" name="email_responsavel" id="emailResponsavel" className="form-control" value={formPartnerData?.email_responsavel || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <label htmlFor="telefoneFixoResponsavel">Telefone Fixo</label>
                        <input type="text" name="telefone_fixo_responsavel" id="telefoneFixoResponsavel" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formPartnerData?.telefone_fixo_responsavel || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <label htmlFor="celularResponsavel">Celular</label>
                        <input type="text" name="celular_responsavel" id="celularResponsavel" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formPartnerData?.celular_responsavel || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Veículo</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="veiculo">Veículo</label>
                        <input type="text" name="veiculo" id="veiculo" className="form-control" value={formPartnerData?.veiculo || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="modeloVeiculo">Modelo</label>
                        <input type="text" name="modelo_veiculo" id="modeloVeiculo" className="form-control" value={formPartnerData?.modelo_veiculo || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="corVeiculo">Cor</label>
                        <input type="text" name="cor_veiculo" id="corVeiculo" className="form-control" value={formPartnerData?.cor_veiculo || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="placaVeiculo">Placa</label>
                        <input type="text" name="placa_veiculo" id="placaVeiculo" className="form-control" value={formPartnerData?.placa_veiculo || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Dados Bancários</h5>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="banco">Banco</label>
                        <input type="text" name="banco" id="banco" className="form-control" value={formPartnerData?.banco || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="tipo_conta_banco">Tipo Conta</label>
                        <input type="text" name="tipo_conta_banco" id="tipoContaBanco" className="form-control" value={formPartnerData?.tipo_conta_banco || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="agenciaConta">Agência</label>
                        <input type="text" name="agencia_banco" id="agenciaConta" className="form-control" value={formPartnerData?.agencia_banco || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="num_conta_banco">Nº Conta</label>
                        <input type="text" name="num_conta_banco" id="numContaBanco" className="form-control" value={formPartnerData?.num_conta_banco || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="titularBanco2">Titular 2</label>
                        <input type="text" name="titular_banco2" id="titularBanco2" className="form-control" value={formPartnerData?.titular_banco2 || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="cpfTitular2">CPF Titular 2</label>
                        <input type="text" name="cpf_titular2" id="cpfTitular2" className="form-control" ref={withMask(['999.999.999-99'])} value={formPartnerData?.cpf_titular2 || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="descricao">Observações</label>
                            <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formPartnerData?.descricao || ''} onChange={(e) => (setFormPartnerData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData}></textarea>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <PartnerValuesTable id_representante={id} disableData={!updateData}></PartnerValuesTable>
            </Card>
        </Content>
    );
}