import React from 'react';
import If from './If';

export default function Card({ hideCardHeader = false, collapseId = '', icon = '', title = '', children }) {
    return (
        <div className="card">
            <If condition={!hideCardHeader}>
                <div className={`card-header`}
                    data-toggle={collapseId ? "collapse" : undefined}
                    data-target={collapseId ? `#${collapseId}` : undefined}
                    style={collapseId ? { cursor: 'pointer' } : undefined}>
                    <h5>{icon && (<i className={`fas ${icon}`}></i>)} {title}</h5>
                </div>
            </If>
            <div className={`card-body ${collapseId ? "collapse" : ""}`} id={collapseId ? collapseId : undefined}>
                {children}
            </div>
        </div>
    );
}