import { useContext } from 'react';
import { Context } from '../App';

export default function usePermissions(){
    const userData = useContext(Context);

    const checkPermission = (modulo = '', submodulo = '', permissao = '') => {
        let permissoes = userData?.userDataState?.permissoes;
        let modulos = [];
        let submodulos = [];

        for (let p of permissoes) {
            for (let m of p.modules) {
                if (m.module_name === modulo) {
                    modulos.push(m);
                }
            }
        }

        if (modulos.length === 0) {
            return false;
        }

        for (let m of modulos) {
            for (let s of m.submodules) {
                if (s.submodule_name === submodulo) {
                    submodulos.push(s);
                }
            }
        }

        if (submodulos.length === 0) {
            return false;
        }

        for (let s of submodulos) {
            for (let p of s.permissions) {
                if (p === permissao) {
                    return true;
                }
            }
        }

        return false;
    }

    const checkPermissionGroup = (groups = []) => {
        let permissoes = userData?.userDataState?.permissoes;
        groups.push("administrador");
        for(let p of permissoes){
            if(groups.includes(p.group_name)){
                return true;
            }
        }

        return false;
    }

    return {
        checkPermission,
        checkPermissionGroup
    }

}