import interceptorInstance from "./Interceptor";

export async function createEmployee(params){
    try{
        let dados = await interceptorInstance.post(`employee/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getAllEmployee(params = {
    'ativo': 1, 
    'categoria': ['Interno', 'Home Office', 'Técnico', 'Residente'],
    'modal_contratacao': ['CLT', 'PJ']
}){
    try{
        let dados = await interceptorInstance.post(`employee/all`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function changeEmployeeStatusById(id, status){
    try{
        let dados = await interceptorInstance.patch(`employee/enable-disable/${id}/${status}`, {});
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getEmployeeById(id){
    try {
        let dados = await interceptorInstance.get(`employee/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function updateEmployeeById(id, params){
    try{
        let dados = await interceptorInstance.patch(`employee/update/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getLendingEquipmentsByEmployeeId(id){
    try{
        let dados = await interceptorInstance.get(`employee/equipment-lending/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function deleteLendingEquipmentById(id){
    try{
        let dados = await interceptorInstance.delete(`employee/equipment-lending/delete/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function insertLendingEquipment(params){
    try{
        let dados = await interceptorInstance.post(`employee/equipment-lending/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getAbseenteismByDataRange(params){
    try{
        let dados = await interceptorInstance.post(`employee/absenteeism`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getAbseenteismByTechnicianId(id){
    try{
        let dados = await interceptorInstance.get(`employee/absenteeism/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function createRentedVehicle(params){
    try{
        let dados = await interceptorInstance.post(`employee/rented-vehicle/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getRentedVehicles(technicianId){
    try{
        let dados = await interceptorInstance.get(`employee/rented-vehicle/${technicianId}`);
        return dados;    
    }catch(e){
        return e.response;
    }
}

export async function changeRentedVehicleStatus(vehicleId, params){
    try{
        let dados = await interceptorInstance.patch(`employee/rented-vehicle/change-status/${vehicleId}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}