import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { setDefaults, fromAddress } from 'react-geocode'
import "./GoogleMaps.css";
import Pin from '../../../../resources/imgs/maps/pin.png';
import Car from '../../../../resources/imgs/maps/carro3.png';
import If from '../../../../common/Layout/If';

export default function GoogleMaps({ positions = [], status = '', customerAddress = '' }) {
    const [startMarker, setStartMarker] = useState(null);
    const [endMarker, setEndMarker] = useState(null);
    const [customerMarker, setCustomerMarker] = useState(null);
    const [startInfoWindowComponent, setStartInfoWindowComponent] = useState(null);
    const [endInfoWindowComponent, setEndInfoWindowComponent] = useState(null);
    const [customerInfoWindowComponent, setCustomerInfoWindowComponent] = useState(null);
    const [showStartInfoWindow, setShowStartInfoWindow] = useState(false);
    const [showEndInfoWindow, setShowEndInfoWindow] = useState(false);
    const [showCustomerInfoWindow, setShowCustomerInfoWindow] = useState(false);
    const [polyline, setPolyline] = useState(null);
    const [center, setCenter] = useState({ lat: -22.820069, lng: -47.035386 });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_MAPS_KEY || "AIzaSyB_aOhT30y3hevB0dXMxRrnyhVQHnpqSvE"
    });

    setDefaults({
        key: process.env.REACT_APP_API_MAPS_KEY || "AIzaSyB_aOhT30y3hevB0dXMxRrnyhVQHnpqSvE",
        language: 'pt-BR',
        region: 'br'
    })

    async function setMapContent() {

        if (positions.length === 0) {
            return;
        }


        if (["Fechado", "Aguardando fechamento", "Aguardando Fechamento", "Técnico em atendimento"].includes(status)) {
            setPolyline(<Polyline
                path={positions}
            ></Polyline>)

            setCenter({
                lat: positions[0].lat,
                lng: positions[0].lng
            });

            setStartMarker(
                <Marker
                    position={{
                        lat: positions[0].lat,
                        lng: positions[0].lng
                    }}
                    options={{
                        title: 'Início - Tecnico',
                        label: {
                            text: 'Início - Técnico',
                            className: 'map-marker',
                        },
                        icon: {
                            url: Car,
                            scaledSize: { width: 40, height: 40 }
                        }
                    }}
                    onClick={() => setShowStartInfoWindow(true)}
                ></Marker>
            )

            setStartInfoWindowComponent(
                <InfoWindow
                    position={{
                        lat: positions[0].lat,
                        lng: positions[0].lng
                    }}
                    onCloseClick={() => setShowStartInfoWindow(false)}>
                    <div>
                        <h6>Início - Técnico</h6>
                        <p>Informações adicionais sobre o ponto final, se necessário</p>
                    </div>
                </InfoWindow>)

            setEndMarker(
                <Marker
                    position={{
                        lat: positions[positions.length - 1].lat,
                        lng: positions[positions.length - 1].lng
                    }}
                    options={{
                        title: 'Fim - Cliente',
                        label: {
                            text: 'Fim - Cliente',
                            className: 'map-marker',
                        },
                        icon: {
                            url: Pin,
                            scaledSize: { width: 40, height: 40 }
                        }
                    }}
                    onClick={() => setShowEndInfoWindow(true)}
                ></Marker>)

            setEndInfoWindowComponent(
                <InfoWindow
                    position={{
                        lat: positions[positions.length - 1].lat,
                        lng: positions[positions.length - 1].lng
                    }}
                    onCloseClick={() => setShowEndInfoWindow(false)}>
                    <div>
                        <h6>Fim - Cliente</h6>
                        <p>Informações adicionais sobre o ponto final, se necessário</p>
                    </div>
                </InfoWindow>)
        }

        if (["Em deslocamento", "Técnico em deslocamento"].includes(status)) {
            let customerAddressData = {};

            setPolyline(<Polyline
                path={positions}
            ></Polyline>)

            setCenter({
                lat: positions[positions.length - 1].lat,
                lng: positions[positions.length - 1].lng
            });

            setStartMarker(
                <Marker position={{
                    lat: positions[0].lat,
                    lng: positions[0].lng
                }} options={{
                    title: 'Ponto inicial do técnico',
                    label: {
                        text: 'Ponto inicial do técnico',
                        className: 'map-marker',
                    },
                    icon: {
                        url: Pin,
                        scaledSize: { width: 40, height: 40 }
                    }
                }}
                ></Marker>);

            setEndMarker(
                <Marker
                    position={{
                        lat: positions[positions.length - 1].lat,
                        lng: positions[positions.length - 1].lng
                    }}
                    options={{
                        title: 'Local atual do técnico',
                        label: {
                            text: 'Local atual do técnico',
                            className: 'map-marker',
                        },
                        icon: {
                            url: Car,
                            scaledSize: { width: 40, height: 40 }
                        }
                    }}
                    onClick={() => setShowEndInfoWindow(true)}
                >
                </Marker>);

            setEndInfoWindowComponent(
                <InfoWindow
                    position={{
                        lat: positions[positions.length - 1].lat,
                        lng: positions[positions.length - 1].lng
                    }}
                    onCloseClick={() => setShowEndInfoWindow(false)}>
                    <div>
                        <h6>Posição Atual - Técnico</h6>
                        <p>Informações adicionais sobre o ponto final, se necessário</p>
                    </div>
                </InfoWindow>)

            if (customerAddress === '') {
                return;
            }

            customerAddressData = await fromAddress(customerAddress);

            if (customerAddressData?.status === 'OK' && customerAddressData?.results.length > 0) {
                let { lat, lng } = customerAddressData.results[0].geometry.location
                setCustomerMarker(<Marker
                    position={{
                        lat: lat,
                        lng: lng
                    }}
                    options={{
                        title: 'Endereço do Cliente',
                        label: {
                            text: 'Endereço do Cliente',
                            className: 'map-marker',
                        }
                    }}
                    onClick={() => setShowCustomerInfoWindow(true)}
                ></Marker>)

                setCustomerInfoWindowComponent(<InfoWindow
                    position={{
                        lat: lat,
                        lng: lng
                    }}
                    onCloseClick={() => setShowCustomerInfoWindow(false)}>
                    <div>
                        <span>Endereço do Cliente: {customerAddress}</span>
                    </div>
                </InfoWindow>);
            }
        }
    }

    useEffect(() => {
        setMapContent();
    }, [positions])

    return (<>
        <If condition={isLoaded}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={center}
                zoom={15}
                onLoad={map => {
                    if (positions.length > 0) {
                        const bounds = new window.google.maps.LatLngBounds();
                        bounds.extend(new window.google.maps.LatLng(positions[0].lat, positions[0].lng));
                        bounds.extend(new window.google.maps.LatLng(positions[positions.length - 1].lat, positions[positions.length - 1].lng));
                        map.fitBounds(bounds);
                    }
                }}
            >
                {polyline}
                {startMarker}
                {endMarker}
                {customerMarker}
                {showStartInfoWindow && startInfoWindowComponent}
                {showEndInfoWindow && endInfoWindowComponent}
                {showCustomerInfoWindow && customerInfoWindowComponent}
            </GoogleMap>
        </If>
        <If condition={!isLoaded}>
            Carregando...
        </If>
    </>)
}