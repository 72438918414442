import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import Table from '../../../../common/Layout/Table/Table';
import Swal from 'sweetalert2';
import Content from '../../../../common/Layout/Content';
import { toast } from 'react-toastify';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import MarkAsReceived from './MarkAsReceived';
import InTransitDetails from './InTransitDetails';

export default function InTransit(){
    const [data, setData] = useState([]);
    const [openInTransitDetails, setOpenInTransitDetails] = useState(false);
    const [inTransitId, setInTransitId] = useState('');
    const [openMarkAsReceived, setOpenMarkAsReceived] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const columns = [
        {
            id: 'id_produto',
            name: 'ID',
            selector: row => row.id_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_produto',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={()=>openInTransitDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <button type='button' className="dropdown-item" onClick={()=>openMarkAsReceivedModal(row.id_produto)}>Marcar como recebido</button>
                        <button type='button' className="dropdown-item" onClick={()=>showReturnToInvoiceSwal(row.id_produto)}>Cancelar Em Trânsito</button>
                    </div>
                </div>
            ),
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
            selector: row => row.nfe_saida_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
            selector: row => row.nome_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_produto',
            name: 'Modelo',
            selector: row => row.modelo_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'serial_produto',
            name: 'Serial',
            selector: row => row.serial_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
            selector: row => row.urgencia_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
            selector: row => row.modal_envio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
            selector: row => row.codigo_rastreio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
            selector: row => row.tipo_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
            selector: row => row.status_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'cep_produto',
            name: 'CEP',
            selector: row => row.cep_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'uf_produto',
            name: 'UF',
            selector: row => row.uf_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'cidade_produto',
            name: 'Cidade',
            selector: row => row.cidade_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'bairro_produto',
            name: 'Bairro',
            selector: row => row.bairro_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'rua_produto',
            name: 'Rua',
            selector: row => row.rua_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'numero_produto',
            name: 'Número',
            selector: row => row.numero_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'complemento_produto',
            name: 'Complemento',
            selector: row => row.complemento_produto,
            sortable: true,
            wrap: true
        },
    ]

    function openInTransitDetailsModal(id, mode){
        setInTransitId(id);
        setViewMode(mode);
        setOpenInTransitDetails(true);
    }

    async function showReturnToInvoiceSwal(id){
        let swal = await Swal.fire({
            title: 'Deseja realmente retornar a remessa para emissão de NFE?',
            text: 'Todos os campos da NFE poderão ser alterados.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if(swal.isConfirmed){
            returnToInvoice(id);
        }
    }

    async function returnToInvoice(id){
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, {status_remessa: 'AGUARDANDO NFE SAÍDA'});

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastProducts, { render: 'Emissão cancelada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getInTransitByStatus();
            setUpdateLogistic(true);
        }else{
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cancelar a emissão!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getInTransitByStatus(){
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('EM TRÂNSITO');

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data || [];
            
            let productData = products.map((item, idx)=> ({
                id_produto: item?.id || '',
                nome_produto: item?.product?.nome_produto || '',
                modelo_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                cep_produto: item?.cep || '',
                uf_produto: item?.uf || '',
                cidade_produto: item?.cidade || '',
                bairro_produto: item?.bairro || '',
                rua_produto: item?.rua || '',
                numero_produto: item?.numero || '',
                complemento_produto: item?.complemento || '',
                codigo_rastreio_produto: item?.rastreamento || ''
            }));

            setData(productData);
        }else{
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
    }

    function openMarkAsReceivedModal(id){
        setInTransitId(id);
        setOpenMarkAsReceived(true);
    }

    useEffect(()=>{
        getInTransitByStatus();
    }, []);

    return (<Content headerTitle='Peças em Trânsito'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <MarkAsReceived isOpen={openMarkAsReceived} setModalOpen={()=>setOpenMarkAsReceived(!openMarkAsReceived)} inTransitId={inTransitId} setUpdateProducts={getInTransitByStatus} setUpdateLogistic={setUpdateLogistic}></MarkAsReceived>
        <InTransitDetails isOpen={openInTransitDetails} setModalOpen={()=>setOpenInTransitDetails(!openInTransitDetails)} inTransitId={inTransitId} setUpdateProducts={getInTransitByStatus}></InTransitDetails>
        <Card title='Cadastro de Produtos - Peças em Trânsito'>
            <div className="row">
                <div className="col-12 d-flex justify-content-end flex-wrap">
                    <button type='button' className="btn btn-primary m-1" onClick={()=>getInTransitByStatus()}>Atualizar Entregas</button>
                </div>
                <div className="col-12">
                    <Table columns={columns} data={data} id='productsInTransitTable'></Table>
                </div>
            </div>
        </Card>
    </Content>)

}