import React from 'react';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import { changeOwnerByTicketId } from '../../../services/Ticket';

export default function SuporteAnalistaResponsavelCard({id = '', originalData = {}, getTicketDetailById = null, getHistory = null}){

    async function changeTicketOwner() {

        const toastTicketOwner = toast.loading("Alterando responsável, aguarde...");

        let dataReturn = await changeOwnerByTicketId(id, { type: 'support' });
        if (dataReturn?.data?.response === 'Ticket updated successfully!') {
            let responsavel = dataReturn?.data?.data?.ticket_responsible_user;
            toast.update(toastTicketOwner, { render: `Responsável pelo chamado alterado com sucesso para ${responsavel?.nome}`, type: "success", isLoading: false, autoClose: 1500 });
            getTicketDetailById();
            getHistory();
        } else {
            toast.update(toastTicketOwner, { render: `Ocorreu um erro ao alterar o responsável pelo chamado`, type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    return(<Card title={"Suporte - Analista Responsável"} icon={"fa-check"}>
        <div className="row">
            <div className="col-12 mb-2">
                <button className="btn btn-secondary" onClick={changeTicketOwner}>Assumir Chamado</button>
            </div>
            <div className="col-12">
                <p><b>Analista Responsável: </b>{originalData?.ticket_responsible_user?.nome || ''}</p>
            </div>
        </div>
    </Card>);
}