import interceptorInstance from "./Interceptor";

export async function getPartnersValuesByPartnerId(id){
    try{
        let data = await interceptorInstance.get(`partner-values/get/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function insertPartnerValues(params){
    try{
        let data = await interceptorInstance.post(`partner-values/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deletePartnerValuesById(id){
    try{
        let data = await interceptorInstance.delete(`partner-values/delete/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPartnerValuesById(id){
    try{
        let data = await interceptorInstance.get(`partner-values/search/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updatePartnerValueById(id, params){
    try{
        let data = await interceptorInstance.put(`partner-values/update/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function approvePartnerValuesById(id, params){
    try{
        let data = await interceptorInstance.patch(`partner-values/approve/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getActivePartnerValues(partner_id){
    try{
        let data = await interceptorInstance.get(`partner-values/get-active/${partner_id}`);
        return data;
    }catch(e){
        return e.response;
    }
}