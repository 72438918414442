import React, { useState } from 'react';
import ProdutosSelect from '../../../common/Selects/ProdutosSelect';
import { toast } from 'react-toastify';
import { getProductByProductId, setProductStatusByProductId } from '../../../services/Product';
import If from '../../../common/Layout/If';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import Table from '../../../common/Layout/Table/Table';

export default function ProductsTable({ createTicketForm = {}, setCreateTicketForm = null, productData = [], setProductData = null }) {
    const [reRender, setReRender] = useState(false);

    const columns = [
        {
            id: 'action',
            name: 'Ação',
            button: 'true',
            cell: (row) => (
                <button type='button' className='btn btn-link' onClick={() => removeProduct(row)}><i className='fas fa-trash'></i></button>
            )
        },
        {
            id: 'value',
            name: 'ID',
            selector: row => row.value,
            sortable: true
        },
        {
            id: 'label',
            name: 'Equipamento Remessa',
            selector: row => row.label,
            sortable: true
        }
    ];

    async function addProduct(name) {
        if (createTicketForm[name] === '') {
            return;
        }

        let product = { ...createTicketForm[name] };

        const toastAddProduct = toast.loading("Validando produto, aguarde...");

        let productStatusData = await getProductByProductId(product['value']);
        let productStatus = '';
        let changeStatusResponse = false;

        if (productStatusData?.data?.response === 'success') {
            productStatus = productStatusData.data.data?.status?.chave_opcao || '';
        }

        if (productStatus === 'peca_disponivel') {
            changeStatusResponse = await setProductStatus(product['value'], 'temporariamente_indisponivel');
        }

        if (changeStatusResponse) {
            toast.update(toastAddProduct, { render: 'Produto adicionado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let id = 0;

            if (productData.length > 0) {
                id = productData[productData.length - 1]['id_temp_table'] + 1;
            }

            product['id_temp_table'] = id;

            setProductData([...productData, product]);
        } else {
            toast.update(toastAddProduct, { render: 'Este produto está indisponível, por favor selecione outro!', type: "warning", isLoading: false, autoClose: 1500 });
        }
        setReRender(prev => !prev);
        setCreateTicketForm({ ...createTicketForm, produtos_estoque: '', produtos_contrato: '' });
    }

    async function removeProduct(row) {
        const toastRemoveProduct = toast.loading("Validando produto, aguarde...");

        let changeStatusResponse = await setProductStatus(row['value'], 'peca_disponivel');

        if (changeStatusResponse) {
            toast.update(toastRemoveProduct, { render: 'Produto removido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let updatedProducts = productData.filter((item, idx) => item.id_temp_table !== row.id_temp_table);
            setProductData(updatedProducts);

        } else {
            toast.update(toastRemoveProduct, { render: 'Este produto não pode ser removido!', type: "warning", isLoading: false, autoClose: 1500 });
        }
        setReRender(prev => !prev);
        setCreateTicketForm({ ...createTicketForm, produtos_estoque: '',  produtos_contrato: '' });
    }

    async function setProductStatus(id, status) {
        let formData = {
            status_produto: status
        }

        let dataReturn = await setProductStatusByProductId(id, formData);

        if (dataReturn?.data?.response === 'success') {
            return true;
        } else {
            return false;
        }
    }

    return (<>
        <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Produtos Envolvidos (Produto/Serial)</h6></div>
        <div className="row">
            <div className="col-md-4">
                <ProdutosSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 12, 12, 12]} dependsOnContrato={true} fieldName='produtos_contrato' idContrato={createTicketForm?.id_contrato?.value} title='Produtos do Contrato Selecionado' reRender={reRender}></ProdutosSelect>
                <button type='button' className='btn btn-sm btn-secondary mt-1' onClick={() => addProduct('produtos_contrato')}>Adicionar Item</button>
            </div>
            <div className="col-md-4">
                <ProdutosSelect formData={createTicketForm} setFormData={setCreateTicketForm} cols={[12, 12, 12, 12]} dependsOnContrato={false} fieldName='produtos_estoque' title='Todos os Produtos do Estoque' reRender={reRender}></ProdutosSelect>
                <button type='button' className='btn btn-sm btn-secondary mt-1' onClick={() => addProduct('produtos_estoque')}>Adicionar Item</button>
            </div>
            <div className="col-md-4">
                <div className='form-group'>
                    <label htmlFor="numeroRMA">Número RMA</label>
                    <input type="text" className="form-control" name="numero_rma" id="numeroRMA" value={createTicketForm.numero_rma} onChange={(e)=>setCreateTicketForm(prev => ({...prev, [e.target.name]: e.target.value}))} placeholder='...' />
                </div>
            </div>
            <div className="col-md-12">
                <If condition={productData.length === 0}>
                    <CustomMessageData message="Sem Dados"></CustomMessageData>
                </If>
                <If condition={productData.length > 0}>
                    <Table columns={columns} data={productData} needExport={false} id="productDataTable"></Table>
                </If>
            </div>
        </div>
    </>);
}