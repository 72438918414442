import React, { useEffect, useRef, useState } from 'react';
import './Table.css';
import DataTable from 'react-data-table-component';

import { utils, writeFile } from 'xlsx';

export default function Table({ columns = [], data = [], needExport = true, needFilter = true, conditionalRowStyles = {}, id = "table", selectableRows = false, onSelectableRowsChange = null, clearSelectedRows = null}) {
    const topRef = useRef();
    const dummyDivRef = useRef();
    const [inputAdded, setInputAdded] = useState(false);
    const [filterRecords, setFilterRecords] = useState(data);

    const handleScroll = (e) => {
        let tableScroll = document.getElementById(id).querySelector('[role="table"]').parentElement.parentElement;
        let dummyScroll = topRef.current.scrollLeft;

        tableScroll.scrollLeft = dummyScroll;
    }

    const handleBottomScroll = (scrollValue) => {
        topRef.current.scrollLeft = scrollValue;
    }

    const handleFilter = () => {
        let documentData = document.getElementById(id).querySelectorAll(`[class="form-control form-control-sm searchColumnFields_${id} mt-2 mb-2"]`);
        let filterData = Array.from(documentData).filter((item) => item.value !== "").map((item) => [item.id, item.value.toLowerCase()]);
        let filteredRecords = [...data];
        if (filterData.length > 0) {
            for (let filter of filterData) {
                filteredRecords = filteredRecords.filter(row => {
                    if (row[filter[0]] !== undefined) {
                        return String(row[filter[0]]).toLowerCase().includes(filter[1])
                    }
                    return false;
                });
            }
        }
        setFilterRecords(filteredRecords);
    }

    const handleXlsxExport = () => {
        const ws = utils.json_to_sheet(filterRecords);

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);

        let date = new Date();

        writeFile(wb, `Export_XLSX_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.xlsx`);
    }

    function preProcessData(data) {
        let processedData = data.map((item, idx) => {
            let processedItem = {};

            Object.keys(item).forEach(key => {
                const value = item[key];
                processedItem[key] = typeof value === 'string' ? value.replace(/(\r\n|\n|\r)/gm, " ") : value;
            });

            return processedItem;
        });

        return processedData;
    }

    const handleCsvExport = () => {
        const data = preProcessData(filterRecords);

        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);

        let date = new Date();

        writeFile(wb, `Export_CSV_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.csv`);
    }

    useEffect(() => {
        let table = document.getElementById(id).querySelector('[role="table"]');
        let tableScroll = table.parentElement.parentElement;

        const tableWidth = table.clientWidth;
        dummyDivRef.current.style.width = `${tableWidth}px`;

        tableScroll.addEventListener('scroll', () => handleBottomScroll(tableScroll.scrollLeft))

        return () => {
            tableScroll.removeEventListener('scroll', () => handleBottomScroll(tableScroll.scrollLeft));
        }

    }, [filterRecords]);

    useEffect(() => {
        handleFilter()
    }, [data]);

    useEffect(() => {
        if (!inputAdded && needFilter !== false) {
            document.getElementById(id).querySelectorAll('[role="columnheader"]').forEach((item) => {
                const divElement = document.createElement('div');
                divElement.style.width = '100%'
                const inputElement = document.createElement('input');
                inputElement.type = 'text';
                inputElement.id = item.getAttribute('data-column-id');

                if (document.getElementById(id).querySelectorAll(`[id="${item.getAttribute('data-column-id')}"]`).length === 0) {
                    if (item.getAttribute('data-column-id').includes('action')) {
                        inputElement.disabled = true;
                    }
                    inputElement.className = `form-control form-control-sm searchColumnFields_${id} mt-2 mb-2`;
                    inputElement.style.width = '100%'
                    inputElement.oninput = () => handleFilter()
                    divElement.appendChild(inputElement);
                    item.parentElement.appendChild(divElement);
                    item.parentElement.style.flexDirection = 'column';
                }

            })
            setInputAdded(true);
        }
    }, [inputAdded])

    return (
        <div id={id}>
            <div style={{ overflowX: 'auto' }} onScroll={handleScroll} ref={topRef}>
                <div style={{ height: '1px' }} ref={dummyDivRef}></div>
            </div>
            <DataTable
                columns={columns}
                data={filterRecords}
                subHeader={true}
                subHeaderComponent={needExport !== false ?
                    (<div className='d-flex flex-row justify-content-start w-100'>
                        <button type='button' className='btn btn-sm btn-secondary mr-2' onClick={handleXlsxExport}>Excel</button>
                        <button type='button' className='btn btn-sm btn-secondary' onClick={handleCsvExport}>CSV</button>
                    </div>) : (<></>)
                }
                pagination={true}
                pointerOnHover={true}
                conditionalRowStyles={conditionalRowStyles || {}}
                highlightOnHover
                fixedHeader
                persistTableHead
                selectableRows={selectableRows}
                onSelectedRowsChange={onSelectableRowsChange}
                clearSelectedRows={clearSelectedRows}
                responsive={true}
                customStyles={{
                    headCells: {
                        style: {
                            paddingLeft: '1', // override the cell padding for head cells
                            paddingRight: '1',
                        },
                    },
                    cells: {
                        style: {
                            minWidth: 'auto',
                            paddingLeft: '1', // override the cell padding for data cells
                            paddingRight: '1',
                        },
                    },
                }}
            />
        </div>
    );
}