import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import ShowAbsenteeism from '../../pages/Dashboard/Technician/Absenteeism/ShowAbsenteeism';
import AbsenteeismDetails from '../../pages/Dashboard/Technician/Absenteeism/AbsenteeismDetails';
import ShowTickets from '../../pages/Tickets/ShowTickets/ShowTickets';
import N2Tickets from '../../pages/Tickets/N2Tickets/N2Tickets';
import CreateTicket from '../../pages/Tickets/CreateTicket/CreateTicket';
import CreateTicketXls from '../../pages/Tickets/CreateTicketXls';
import TicketDetails from '../../pages/Tickets/TicketDetails/TicketDetails';
import ResponsavelAtendimento from '../../pages/Tickets/ResponsavelAtendimento';
import ResponsavelParceria from '../../pages/Tickets/ResponsavelParceria';
import SupportResponsible from '../../pages/Tickets/SupportResponsible';
import ShowTicketGroup from '../../pages/Tickets/TicketsGroup/ShowTicketGroup';
import CreateTicketGroup from '../../pages/Tickets/TicketsGroup/CreateTicketGroup';
import TicketGroupDetails from '../../pages/Tickets/TicketsGroup/TicketGroupDetails';
import ShowPartnerGroup from '../../pages/Tickets/PartnersGroup/ShowPartnerGroup';
import CreatePartnerGroup from '../../pages/Tickets/PartnersGroup/CreatePartnerGroup';
import PartnerGroupDetails from '../../pages/Tickets/PartnersGroup/PartnerGroupDetails';
import TechniciansMonitoring from '../../pages/Tickets/TechniciansMonitoring/TechniciansMonitoring';
import Reports from '../../pages/Reports/Reports';
import ShowProducts from '../../pages/Logistics/Products/ShowProducts/ShowProducts';
import ProductsToSend from '../../pages/Logistics/Products/ProductsToSend/ProductsToSend';
import Invoice from '../../pages/Logistics/Products/Invoice/Invoice';
import InTransit from '../../pages/Logistics/Products/InTransit/InTransit';
import PendingDact from '../../pages/Logistics/Products/PendingDact/PendingDact';
import AwaitingWithdraw from '../../pages/Logistics/Products/AwaitingWithdraw/AwaitingWithdraw';
import ProductsReceived from '../../pages/Logistics/Products/ProductsReceived/ProductsReceived';
import ShowContracts from '../../pages/Registrations/Contracts/ShowContracts';
import ContractDetails from '../../pages/Registrations/Contracts/ContractDetails/ContractDetails';
import CreateContract from '../../pages/Registrations/Contracts/CreateContract';
import ShowIntegrators from '../../pages/Registrations/Integrators/ShowIntegrators';
import IntegratorDetails from '../../pages/Registrations/Integrators/IntegratorDetails';
import CreateIntegrator from '../../pages/Registrations/Integrators/CreateIntegrator';
import ShowPartners from '../../pages/Registrations/Partners/ShowPartners';
import PartnerDetails from '../../pages/Registrations/Partners/PartnerDetails/PartnerDetails';
import CreatePartner from '../../pages/Registrations/Partners/CreatePartner';
import ShowTechnicians from '../../pages/Registrations/Technician/ShowTechnicians';
import TechnicianDetails from '../../pages/Registrations/Technician/TechnicianDetails/TechnicianDetails';
import CreateTechnician from '../../pages/Registrations/Technician/CreateTechnician';
import AcionamentosManagement from '../../pages/Solutions/Acionamentos/AcionamentosManagement';
import CreateAcionamento from '../../pages/Solutions/Acionamentos/CreateAcionamento';
import RetiradaManagement from '../../pages/Solutions/Retirada/RetiradaManagement';
import CreateRetirada from '../../pages/Solutions/Retirada/CreateRetirada';
import RetiradaDetails from '../../pages/Solutions/Retirada/RetiradaDetails';
import ShowUsers from '../../pages/Users/ShowUsers';
import UserDetails from '../../pages/Users/UserDetails';
import CreateUser from '../../pages/Users/CreateUser';
import MainChat from '../../pages/Chat/MainChat';
import ShowRefund from '../../pages/Billing/Refund/ShowRefunds';
import EmployeeRefundsDetails from '../../pages/Billing/Refund/EmployeeRefundsDetails';
import Profile from '../../pages/User/Profile';
import Main from '../../pages/Main/Main';

export default function MainRoutes() {

    return (
        <Routes>
            <Route path='/' element={<Main></Main>}></Route>
            <Route path='/dashboard/tecnicos/absenteismo/dados' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowAbsenteeism></ShowAbsenteeism>
                </ProtectedRoute>
            }></Route>
            <Route path='/dashboard/tecnicos/absenteismo/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <AbsenteeismDetails></AbsenteeismDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/dados/:id?' element={
                <ProtectedRoute permission_group={["gestao_acionamento", "analista_acionamento"]} >
                    <ShowTickets></ShowTickets>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/suporte-n2' element={
                <ProtectedRoute permission_group={["gestao_acionamento", "analista_acionamento"]} >
                    <N2Tickets></N2Tickets>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/abertura-chamados' element={
                <ProtectedRoute permission_group={["gestao_acionamento", "analista_acionamento"]} >
                    <CreateTicket></CreateTicket>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/abertura-chamado-xls' element={
                <ProtectedRoute permission_group={["gestao_acionamento", "analista_acionamento"]} >
                    <CreateTicketXls></CreateTicketXls>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/detalhes-chamado/:id' element={
                <ProtectedRoute permission_group={["gestao_acionamento", "analista_acionamento"]} >
                    <TicketDetails></TicketDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/responsavel-atendimento' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <ResponsavelAtendimento></ResponsavelAtendimento>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/responsavel-parceria' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <ResponsavelParceria></ResponsavelParceria>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/responsavel-suporte' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <SupportResponsible></SupportResponsible>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/grupos-atendimento/dados' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <ShowTicketGroup></ShowTicketGroup>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/grupos-atendimento/cadastrar' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <CreateTicketGroup></CreateTicketGroup>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/grupos-atendimento/detalhes/:id' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <TicketGroupDetails></TicketGroupDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/grupos-parceria/dados' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <ShowPartnerGroup></ShowPartnerGroup>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/grupos-parceria/cadastrar' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <CreatePartnerGroup></CreatePartnerGroup>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/grupos-parceria/detalhes/:id' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <PartnerGroupDetails></PartnerGroupDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/chamados/monitoramento-tecnicos' element={
                <ProtectedRoute permission_group={["gestao_acionamento"]} >
                    <TechniciansMonitoring></TechniciansMonitoring>
                </ProtectedRoute>
            }></Route>
            <Route path='/relatorios' element={
                <ProtectedRoute permission_group={["gestao_acionamento", "analista_acionamento"]} >
                    <Reports></Reports>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/dados' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowProducts></ShowProducts>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/pecas-enviar' element={
                <ProtectedRoute permission_group={[]} >
                    <ProductsToSend></ProductsToSend>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/emissao-nfe' element={
                <ProtectedRoute permission_group={[]} >
                    <Invoice></Invoice>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/em-transito' element={
                <ProtectedRoute permission_group={[]} >
                    <InTransit></InTransit>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/pendente-dact' element={
                <ProtectedRoute permission_group={[]} >
                    <PendingDact></PendingDact>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/aguardando-retirada' element={
                <ProtectedRoute permission_group={[]} >
                    <AwaitingWithdraw></AwaitingWithdraw>
                </ProtectedRoute>
            }></Route>
            <Route path='/logistica/produtos/produtos-recebidos' element={
                <ProtectedRoute permission_group={[]} >
                    <ProductsReceived></ProductsReceived>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/contratos/dados/:status?' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowContracts></ShowContracts>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/contratos/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <ContractDetails></ContractDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/contratos/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreateContract></CreateContract>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/integradoras/dados/:status?' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowIntegrators></ShowIntegrators>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/integradoras/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <IntegratorDetails></IntegratorDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/integradoras/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreateIntegrator></CreateIntegrator>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/representantes/dados/:status?' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowPartners></ShowPartners>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/representantes/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <PartnerDetails></PartnerDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/representantes/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreatePartner></CreatePartner>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/tecnicos/dados/:status?' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowTechnicians></ShowTechnicians>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/tecnicos/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <TechnicianDetails></TechnicianDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/cadastros/tecnicos/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreateTechnician></CreateTechnician>
                </ProtectedRoute>
            }></Route>
            <Route path='/solucoes/acionamentos/dados' element={
                <ProtectedRoute permission_group={[]} >
                    <AcionamentosManagement></AcionamentosManagement>
                </ProtectedRoute>
            }></Route>
            <Route path='/solucoes/acionamentos/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreateAcionamento></CreateAcionamento>
                </ProtectedRoute>
            }></Route>
            <Route path='/solucoes/retirada/dados' element={
                <ProtectedRoute permission_group={[]} >
                    <RetiradaManagement></RetiradaManagement>
                </ProtectedRoute>
            }></Route>
            <Route path='/solucoes/retirada/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreateRetirada></CreateRetirada>
                </ProtectedRoute>
            }></Route>
            <Route path='/solucoes/retirada/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <RetiradaDetails></RetiradaDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/usuarios/dados/:status?' element={
                <ProtectedRoute permission_group={[]} >
                    <ShowUsers></ShowUsers>
                </ProtectedRoute>
            }></Route>
            <Route path='/usuarios/detalhes/:id' element={
                <ProtectedRoute permission_group={[]} >
                    <UserDetails></UserDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/usuarios/cadastrar' element={
                <ProtectedRoute permission_group={[]} >
                    <CreateUser></CreateUser>
                </ProtectedRoute>
            }></Route>
            <Route path='/chat' element={
                <ProtectedRoute permission_group={[]} >
                    <MainChat></MainChat>
                </ProtectedRoute>
            }></Route>
            <Route path='/faturamento/reembolso/dados' element={
                <ProtectedRoute permission_group={["faturamento"]} >
                    <ShowRefund></ShowRefund>
                </ProtectedRoute>
            }></Route>
            <Route path='/faturamento/reembolso/detalhes/:id' element={
                <ProtectedRoute permission_group={["faturamento"]} >
                    <EmployeeRefundsDetails></EmployeeRefundsDetails>
                </ProtectedRoute>
            }></Route>
            <Route path='/perfil/dados' element={<Profile></Profile>}></Route>
            <Route path="*" element={<Main></Main>}></Route>
        </Routes>
    )
}