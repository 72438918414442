import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../common/Layout/Modal';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import Cep from '../../../../common/Cep/Cep';
import { confirmInvoiceIssuance, getShipmentById} from '../../../../services/Shipment';
import useGenerics from '../../../../hooks/useGenerics';
import If from '../../../../common/Layout/If';
import GedTable from '../Ged/GedTable';

export default function InvoiceDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, invoiceId = '', viewMode = 'view', setUpdateLogistic = null }) {
    const { getCurrentDate } = useGenerics();

    const initialState = {
        nome_produto: '',
        serial: '',
        modelo: '',
        urgencia_remessa: '',
        local: '',
        box: '',
        id_representante: '',
        id_tecnico: '',
        id_localidade: '',
        cep: '',
        bairro: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        complemento: '',
        dimensoes: '',
        centro_custo: '',
        peso_kg: '',
        modal_envio: '',
        responsavel_separacao: '',
        destinatario: '',
        data_entrega: getCurrentDate()
    }

    const [formData, setFormData] = useState(initialState);
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(true);

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            ...formData,
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            id_tecnico: formData?.id_tecnico?.value || '',
            modal_envio: formData?.modal_envio?.value || '',
            box: formData?.box?.value || '',
            local: formData?.local?.value || '',
            volume_nfe: formData?.volume_nfe?.value || ''
        }

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await confirmInvoiceIssuance(invoiceId, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);

        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(invoiceId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let shipmentData = dataReturn?.data?.data;

            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.modelo || '',
                local: shipmentData?.product?.local || '',
                box: shipmentData?.product?.box || '',
                id_localidade: shipmentData?.id_localidade || '',
                modal_envio: shipmentData?.modal_envio || '',
                volume_nfe: shipmentData?.volume_nfe || '',
                id_tecnico: shipmentData?.product?.technician?.nome_tec || '',
            }));
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(invoiceId)) {
            setFormData(initialState);
            setShowInvoiceDetails(false);
            getShipment();
        }
    }, [isOpen, invoiceId]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title={viewMode === 'view' ? 'Visualizar Remessa' : 'Atualizar Remessa'}
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '95vh',
            }}
        >
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role='tablist'>
                    <button type="button" className="nav-link active" id="nav-detalhes-tab" data-toggle='tab' data-target='#nav-detalhes' role='tab' aria-controls='nav-detalhes' aria-selected='true'>Detalhes da NFe</button>
                    <button type="button" className="nav-link" id="nav-ged-tab" data-toggle='tab' data-target='#nav-ged' role='tab' aria-controls='nav-ged' aria-selected='true'>GED</button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-detalhes" role='tabpanel' aria-labelledby='nav-detalhes-tab'>
                    <form onSubmit={submitForm}>
                        <div className="row">
                            <div className="col-12">
                                <h5>{formData?.modal_envio?.value !== 'Em Mãos' ? `Preencha todos os dados da NFe` : `Suba o arquivo assinado pelo técnico para confirmar a entrega`}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <input type="text" name="nome_produto" id="nome_produto" className="form-control" placeholder='Nome Produto' value={formData?.nome_produto || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12 mt-1">
                                <input type="text" name="serial" id="serial" className="form-control" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12 mt-1">
                                <input type="text" name="modelo" id="modelo" className="form-control" placeholder='Modelo Produto' value={formData?.modelo || ''} readOnly />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-12">
                                <h6 onClick={() => setShowInvoiceDetails(prevState => !prevState)} style={{ cursor: 'pointer' }}>Detalhes da Remessa (Clique para {showInvoiceDetails ? 'Esconder' : 'Revelar'})</h6>
                            </div>
                        </div>
                        <div style={{ display: `${showInvoiceDetails ? 'block' : 'none'}` }}>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="urgencia_remessa">Urgência Remessa</label>
                                    <input type="text" name="urgencia_remessa" id="urgencia_remessa" placeholder='...' className="form-control" value={formData?.urgencia_remessa || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="local">Local</label>
                                    <input type="text" name="local" id="local" placeholder='...' className="form-control" value={formData?.local || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="box">Box</label>
                                    <input type="text" name="box" id="box" placeholder='...' className="form-control" value={formData?.box || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="id_tecnico">Técnico</label>
                                    <input type="text" name="id_tecnico" id="id_tecnico" placeholder='...' className="form-control" value={formData?.id_tecnico || ''} readOnly={true} />
                                </div>
                            </div>
                            <Cep
                                formData={formData}
                                setFormData={setFormData}
                                formDataNames={{ bairro: 'bairro', cep: 'cep', cidade: 'cidade', estado: 'uf', logradouro: 'rua' }}
                                required={true}
                                readOnly={true}
                            ></Cep>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="numero">Número</label>
                                    <input type="text" name="numero" id="numero" placeholder='...' className="form-control" value={formData?.numero || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="complemento">Complemento</label>
                                    <input type="text" name="complemento" id="complemento" placeholder='...' className="form-control" value={formData?.complemento || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="destinatario">Destinatário</label>
                                    <input type="text" name="destinatario" id="destinatario" placeholder='...' className="form-control" value={formData?.destinatario || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="dimensoes">Dimensões</label>
                                    <input type="text" name="dimensoes" id="dimensoes" placeholder='...' className="form-control" value={formData?.dimensoes || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="centro_custo">Centro Custo</label>
                                    <input type="text" name="centro_custo" id="centro_custo" placeholder='...' className="form-control" value={formData?.centro_custo || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="peso_kg">Peso (kg)</label>
                                    <input type="number" step={"0.01"} min={"0"} name="peso_kg" id="peso_kg" placeholder='...' className="form-control" value={formData?.peso_kg || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="modal_envio">Modal de Envio</label>
                                    <input type="text" name="modal_envio" id="modal_envio" placeholder='...' className="form-control" value={formData?.modal_envio || ''} readOnly={true} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="responsavel_separacao">Responsável pela Separação</label>
                                    <input type="text" name="responsavel_separacao" id="responsavel_separacao" className="form-control" placeholder='...' value={formData?.responsavel_separacao || ''} readOnly={true} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <h6>Detalhes da NFe</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="rastreamento">Código de Rastreio</label>
                                <input type="text" name="rastreamento" id="rastreamento" className="form-control" placeholder='Rastreamento' value={formData?.rastreamento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="nfe_saida">NFe de Saída</label>
                                <input type="text" name="nfe_saida" id="nfe_saida" className="form-control" placeholder='NFe de Saída' value={formData?.nfe_saida || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="prazo_entrega">Prazo de Entrega</label>
                                <input type="date" name="prazo_entrega" id="prazo_entrega" className="form-control" placeholder='Prazo de Entrega' value={formData?.prazo_entrega || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="valor_equipamento_nf">Valor do Equipamento na NFe</label>
                                <input type="number" name="valor_equipamento_nf" id="valor_equipamento_nf" className="form-control" placeholder='Valor do Equipamento NFe' value={formData?.valor_equipamento_nf || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                            <GenericSelect fieldName={'volume_nfe'} formData={formData} setFormData={setFormData} title={'Volume da NFe'} cols={[12, 12, 4, 4]} genericOption='VolumeNfeOptions' required={true} isDisabled={viewMode === 'view'}></GenericSelect>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="data_postagem_remessa">Data da Postagem</label>
                                <input type="date" name="data_postagem_remessa" id="data_postagem_remessa" className="form-control" placeholder='Data da Postagem' value={formData?.data_postagem_remessa || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={viewMode === 'view'} />
                            </div>
                        </div>
                        <If condition={viewMode === 'edit'}>
                            <div className="row mt-1">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                                </div>
                            </div>
                        </If>
                    </form>
                </div>
                <div className="tab-pane fade" id="nav-ged" role='tabpanel' aria-labelledby='nav-ged-tab'>
                    <GedTable addTitle='Novo Documento' idProduto={formData?.id_produto} submodule='remessas' viewMode={viewMode}></GedTable>
                </div>
            </div>
        </Modal>);
}