import React, { useEffect, useState } from 'react';
import Card from '../../../common/Layout/Card';
import { getEquipmentsByTicketIdAndStatus, removeEquipmentByIds, submitEquipmentReturn } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import Table from '../../../common/Layout/Table/Table';
import GenericSelect from '../../../common/Selects/GenericSelect';
import If from '../../../common/Layout/If';

export default function EquipamentoRetornoCard({
    id = '',
    getHistory = null,
    showAddEquipamentoRetorno = false,
    setShowAddEquipamentoRetorno = null
}) {
    const initialEquipamentoRetornoState = {
        equipamento_retorno_spare_select: '',
        equipamento_retorno_spare: '',
        equipamento_retorno: '',
        serial_equipamento_retorno: '',
    }
    const [equipamentoRetornoData, setEquipamentoRetornoData] = useState(initialEquipamentoRetornoState);
    const [equipmentsReturnData, setEquipmentsReturnData] = useState([]);

    const equipmentsReturnColumns = [
        {
            id: 'action_equipamento_retorno',
            name: 'Ação',
            sortable: true,
            wrap: true,
            cell: (row) => (
                <div className="align-items-center">
                    <button className='btn btn-secondary' onClick={() => deleteEquipment(row, 'Reversa')}><i className='fas fa-trash'></i></button>
                </div>
            )
        },
        {
            id: 'product_equipamento_retorno',
            name: 'Produto',
            sortable: true,
            wrap: true,
            selector: row => row.product_equipamento_retorno
        },
        {
            id: 'serial_equipamento_retorno',
            name: 'Serial',
            sortable: true,
            wrap: true,
            selector: row => row.serial_equipamento_retorno
        },
        {
            id: 'status_produto_equipamento_retorno',
            name: 'Status Produto',
            sortable: true,
            wrap: true,
            selector: row => row.status_produto_equipamento_retorno
        },
        {
            id: 'data_reversa_equipamento_retorno',
            name: 'Data da Reversa',
            sortable: true,
            wrap: true,
            selector: row => row.data_reversa_equipamento_retorno
        },
        {
            id: 'reversa_equipamento_retorno',
            name: 'Reversa',
            sortable: true,
            wrap: true,
            selector: row => row.reversa_equipamento_retorno
        }
    ];

    async function submitEquipmentReturnForm(e) {
        e.preventDefault();

        let formData = {
            nome_produto: equipamentoRetornoData?.equipamento_retorno,
            status_produto: equipamentoRetornoData?.equipamento_retorno_spare?.value,
            tipo: 'Reversa',
            serial: equipamentoRetornoData?.serial_equipamento_retorno,
        }

        const toastEquipmentReturn = toast.loading("Atualizando equipamentos de retorno, aguarde...");

        let dataReturn = await submitEquipmentReturn(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastEquipmentReturn, { render: `Equipamentos de retorno atualizado com sucesso!`, type: "success", isLoading: false, autoClose: 1500 });
            getEquipmentsByIdStatus("Reversa");
            setEquipamentoRetornoData(initialEquipamentoRetornoState);
        } else {
            toast.update(toastEquipmentReturn, { render: `Ocorreu um erro ao atualizar os equipamentos de retorno!`, type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function deleteEquipment(rowData) {

        let formData = {
            id_remessa: rowData.id_remessa,
            id_produto: rowData.id_produto,
            id_equipamento_chamado: rowData.id_equipamento_chamado
        }

        const deleteToast = toast.loading("Removendo produto do chamado, aguarde...");

        let dataReturn = await removeEquipmentByIds(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(deleteToast, { render: dataReturn?.data?.data?.message, type: "success", isLoading: false, autoClose: 1500 });
            getHistory();
            getEquipmentsByIdStatus('Reversa');
        } else {
            toast.update(deleteToast, { render: 'Ocorreu um erro ao remover o produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    const getEquipmentsByIdStatus = async () => {
        let dataReturn = await getEquipmentsByTicketIdAndStatus(id, 'Reversa');
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let dadosEquipamentos = dataReturn.data.data;
            let dadosEquipamentosArray = [];

            dadosEquipamentosArray = dadosEquipamentos.map((item, idx) => {
                return ({
                    id_produto: item?.id_produto || '',
                    id_remessa: item?.shipment?.id || '',
                    id_equipamento_chamado: item?.id || '',
                    product_equipamento_retorno: item?.product.nome_produto || '',
                    serial_equipamento_retorno: item?.product.serial || '',
                    status_produto_equipamento_retorno: item?.product?.status?.valor_opcao || '',
                    data_reversa_equipamento_retorno: '',
                    reversa_equipamento_retorno: ''
                });
            });

            setEquipmentsReturnData(dadosEquipamentosArray);

        } else {
            setEquipmentsReturnData([]);
        }
    }

    useEffect(() => {
        getEquipmentsByIdStatus();
    }, []);

    return (<Card title="Equipamento de Retorno" icon="fa-keyboard">
        <div className="row">
            <If condition={!showAddEquipamentoRetorno}>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={() => setShowAddEquipamentoRetorno(prevState => !prevState)}>Adicionar Equipamento de Retorno</button>
                </div>
            </If>
            <If condition={showAddEquipamentoRetorno}>
                <div className='col-12'>
                    <form onSubmit={submitEquipmentReturnForm}>
                        <div className='row'>
                            <GenericSelect formData={equipamentoRetornoData} fieldName={'equipamento_retorno_spare'} setFormData={setEquipamentoRetornoData} cols={[3, 3, 3, 3]} genericOption='EquipamentoRetorno'></GenericSelect>
                            <div className="col-3">
                                <input type="text" name="equipamento_retorno" className="form-control" placeholder='Equipamento de Retorno' value={equipamentoRetornoData?.equipamento_retorno} onChange={(e) => setEquipamentoRetornoData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-3">
                                <input type="text" name="serial_equipamento_retorno" className="form-control" placeholder='Serial Equipamento de Retorno' value={equipamentoRetornoData?.serial_equipamento_retorno} onChange={(e) => setEquipamentoRetornoData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-3">
                                <button type="submit" className="btn btn-secondary">Add. Equipamento de Retorno</button>
                            </div>
                        </div>
                    </form>
                </div>
            </If>
            <div className="col-12">
                <Table columns={equipmentsReturnColumns} data={equipmentsReturnData} needExport={false} id="equipmentReturnTable"></Table>
            </div>
        </div>
    </Card>);
}