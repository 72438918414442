import React, { useEffect, useState } from 'react';
import useGenerics from '../../hooks/useGenerics';
import Modal from '../Layout/Modal';
import { getUserDataById } from '../../services/Users';
import { toast } from 'react-toastify';


export default function ContactDetails({ isOpen, setModalOpen, contactId = '' }) {
    const initialState = {
        id: '',
        name: '',
        img: '',
        email: '',
        tel: ''
    }

    const { onImageError } = useGenerics();

    const [contactData, setContactData] = useState(initialState);

    async function getContactData(){
        const toastContact = toast.loading("Carregando dados, aguarde...");
        let contact = await getUserDataById(contactId);
        if(contact?.data?.response === 'success'){
            toast.update(toastContact, { render: "Dados encontrados!", type: "success", isLoading: false, autoClose: 1500 });
            setContactData(contact?.data?.data);
        }else{
            toast.update(toastContact, { render: "Ocorreu um erro ao procurar os dados!", type: "warning", isLoading: false, autoClose: 1500 });

        }
    }    
    
    useEffect(() => {
        if(contactId !== ''){
            getContactData();
        }
    }, [contactId]);

    return (
        <Modal
            title={'Detalhes do Contato'}
            isOpen={isOpen}
            setModalOpen={setModalOpen}>
            <div style={{ minWidth: '50vw' }}>
                <div className="card" style={{ backgroundColor: '#F4F6F9' }}>
                    <div className="card-header d-flex justify-content-center">
                        <img src={contactData?.profile_picture_url || ''} className="img-circle mr-1" alt="Teste" style={{ cursor: 'pointer' }} width={100} height={100} onError={onImageError} />
                    </div>
                    <div className="card-body text-center">
                        <h6 className='d-inline'>{contactData?.nome}</h6>
                    </div>
                </div>
                <div className="card" style={{ backgroundColor: '#F4F6F9' }}>
                    <div className="card-header text-center">
                        <h6 className='d-inline'>Detalhes</h6>
                    </div>
                    <div className="card-body">
                        <p><b>Email: </b>{contactData?.email || 'Não Cadastrado'}</p>
                        <p><b>Telefone: </b>{contactData?.telefone || 'Não Cadastrado'}</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
}