import React, { useState } from 'react';
import MainChatComponent from '../../common/Chat/MainChatComponent';
import ExternalChatLogin from './ExternalChatLogin';
import If from '../../common/Layout/If';

export default function ExternalChat() {
    const [showChat, setShowChat] = useState(false);
    return (<div style={{ backgroundColor: '#BDBCBC' }}>
        <If condition={showChat}>
            <div className='d-flex align-items-center justify-content-end' style={{ height: '100vh', width: '90%', margin: '0 auto' }}>
                <MainChatComponent></MainChatComponent>
            </div>
        </If>
        <If condition={!showChat}>
            <ExternalChatLogin setShowChat={setShowChat}></ExternalChatLogin>
        </If>
    </div>)
}