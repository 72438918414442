import interceptorInstance from "./Interceptor";

export async function createTicketsAcionamento(params){
    try{
        let data = await interceptorInstance.post(`mamsolutions/create-tickets-acionamento`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllTicketsAcionamento(data_inicial = '', data_final = ''){
    try{
        let params = '';

        if(data_inicial !== '' && data_final !== ''){
            params = `?data_inicial=${data_inicial}&data_final=${data_final}`;
        }

        let data = await interceptorInstance.get(`mamsolutions${params}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteChamadoAcionamento(id){
    try{
        let data = await interceptorInstance.delete(`mamsolutions/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}