import React from 'react';

export default function NotificationItem({ content = {} }) {
    function setIcon() {
        switch (content?.type) {
            case "danger":
                return "fas fa-radiation fa-2x d-inline text-danger";
            default:
                return "fas fa-exclamation-triangle fa-2x d-inline";
        }
    }
    return (<>
        <div className={`d-flex flex-columns list-group-item-action align-items-center justify-content-center p-2`}>
            <i className={setIcon()}></i>
            <p className='text-wrap p-2'>{content?.content || ''}</p>
        </div>
        <div className="dropdown-divider" />
    </>)
}