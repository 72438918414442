import interceptorInstance from "./Interceptor";

export async function deleteSpecialPartnerValue(id){
    try{
        let data = await interceptorInstance.delete(`special-partner-values/delete/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getSpecialValueById(id){
    try{
        let data = await interceptorInstance.get(`special-partner-values/search/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function approveSpecialValueById(id, params){
    try{
        let data = await interceptorInstance.patch(`special-partner-values/approve/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function createSpecialValue(params){
    try{
        let data = await interceptorInstance.post(`special-partner-values/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}