import interceptorInstance from "./Interceptor";

export async function getAllSupportUsers(){
    try{
        let dados = await interceptorInstance.get('support-group/users');
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function createSupportUser(params){
    try{
        let dados = await interceptorInstance.post(`support-group/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function deleteSupportUserById(id){
    try{
        let dados = await interceptorInstance.delete(`support-group/delete/${id}`, {});
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getGroupDetailsById(id){
    try{
        let dados = await interceptorInstance.get(`support-group/search/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function removeContractFromSupportGroup(id, params){
    try{
        let dados = await interceptorInstance.delete(`support-group/${id}/contracts`, {params});
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function updateSupportGroup(id, params){
    try{
        let dados = await interceptorInstance.patch(`support-group/update/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}