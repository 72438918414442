import React, { useEffect, useState } from 'react';
import Modal from '../../../../../common/Layout/Modal';
import { approveContractValueById, getContractValuesById, updateContractValueById } from '../../../../../services/ContractValues';
import { toast } from 'react-toastify';
import If from '../../../../../common/Layout/If';
import GenericSelect from '../../../../../common/Selects/GenericSelect';

export default function ContractValuesDetails({ isOpen = false, setModalOpen = null, id_valor_contrato = '', updateParent = null, mode = 'update' }) {
    const INITIAL_STATE = {
        id_contrato: '',
        escopo: '',
        valor_chamado: '',
        valor_fora_hr_comercial: '',
        valor_chamado_improdutivo: '',
        valor_ha_diurna: '',
        valor_ha_noturna: '',
        valor_km_apos_51: '',
        observacoes: '', 
        aprovado: '',
        descricao_reprovado: ''
    }

    const [formValoresTabela, setFormValoresTabela] = useState(INITIAL_STATE);

    async function getContractValuesData() {
        const toastValues = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getContractValuesById(id_valor_contrato);
        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data;
            setFormValoresTabela(prevState => ({ 
                ...prevState, 
                ...data, 
                aprovado: {value: data?.aprovado || '', label: data?.aprovado || ''}
            }));
            toast.update(toastValues, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }
    }

    function submitForm(e) {
        e.preventDefault();

        if (mode === 'update') {
            updateValues();
        }

        if (mode === 'approve') {
            approveValues();
        }
    }

    async function updateValues() {
        const toastValues = toast.loading('Atualizando dados, aguarde...');

        let params = {
            ...formValoresTabela
        }

        delete params.aprovado;

        let dataReturn = await updateContractValueById(id_valor_contrato, params);
        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data;
            toast.update(toastValues, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormValoresTabela(prevState => ({...prevState, 
                id: dataReturn?.data?.data?.id, 
                aprovado: {value: data?.aprovado || '', label: data?.aprovado || ''}})
            );
            updateParent();
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function approveValues() {
        const toastValues = toast.loading("Alterando o status dos valores, aguarde...");

        let params = {
            ...formValoresTabela,
            aprovado: formValoresTabela?.aprovado?.value || '',
            descricao_reprovado: formValoresTabela?.aprovado?.value === 'aprovado' ? '' : formValoresTabela?.descricao_reprovado
        }

        let dataReturn = await approveContractValueById(id_valor_contrato, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastValues, { render: 'Status atualizado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            updateParent();
            setModalOpen(false);
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao atualizar o status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(id_valor_contrato)) {
            getContractValuesData();
        }

        if (!isOpen) {
            setFormValoresTabela(INITIAL_STATE);
        }
    }, [isOpen, id_valor_contrato]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title={mode === 'update' ? 'Atualizar Valores Contrato' : 'Aprovar Valores'}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="escopo">Escopo</label>
                        <input type="text" name="escopo" id="escopo" className="form-control" value={formValoresTabela?.escopo || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' readOnly={mode === 'approve'} />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="observacoes">Observações Diversas</label>
                        <input type="text" name="observacoes" id="observacoes" className="form-control" value={formValoresTabela?.observacoes || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' readOnly={mode === 'approve'} />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="valor_chamado">R$ Chamado</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado" id="valor_chamado" className='form-control' value={formValoresTabela?.valor_chamado || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="valor_fora_hr_comercial">Valor Fora Hrs Comercial</label>
                        <input type="number" min={"0"} step={".01"} name="valor_fora_hr_comercial" id="valor_fora_hr_comercial" className='form-control' value={formValoresTabela?.valor_fora_hr_comercial || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="valor_chamado_improdutivo">Chamado Improdutivo</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado_improdutivo" id="valor_chamado_improdutivo" className='form-control' value={formValoresTabela?.valor_chamado_improdutivo || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} />
                    </div >
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="valor_ha_diurna">H.A (Diurna) Após 3h31m</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_diurna" id="valor_ha_diurna" className='form-control' value={formValoresTabela?.valor_ha_diurna || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} />
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="valor_ha_noturna">H.A (Noturna) Após 3h31m</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_noturna" id="valor_ha_noturna" className='form-control' value={formValoresTabela?.valor_ha_noturna || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} />
                    </div >
                    <div className="col-md-12 col-lg-4">
                        <label htmlFor="valor_km_apos_51">R$ KM Após 51 Km</label>
                        <input type="number" min={"0"} step={".01"} name="valor_km_apos_51" id="valor_km_apos_51" className='form-control' value={formValoresTabela?.valor_km_apos_51 || ''} placeholder='...' onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} />
                    </div>
                </div >
                <If condition={mode === 'approve'}>
                    <GenericSelect formData={formValoresTabela} setFormData={setFormValoresTabela} fieldName={'aprovado'} cols={[12, 12, 12, 12]} title='Status Aprovação' genericOption='AprovadoValorComercialOptions' required={true}></GenericSelect>
                    <If condition={formValoresTabela?.aprovado?.value === 'reprovado'}>
                        <div className="col-12">
                            <label htmlFor="descricao_reprovado">Motivo da Reprovação</label>
                            <textarea name="descricao_reprovado" id="descricao_reprovado" rows="10" className='form-control' value={formValoresTabela?.descricao_reprovado} onChange={(e) => setFormValoresTabela(prevState => ({...prevState, [e.target.name]: e.target.value}))} required={true}></textarea>
                        </div>
                    </If>
                </If>
                <div className="col-12 d-flex align-items-center mt-1">
                    <button type="submit" className='btn btn-primary'>Atualizar</button>
                </div>
            </form >
        </Modal >
    )
}