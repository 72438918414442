import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import Table from '../../../../common/Layout/Table/Table';
import { getShipmentsByDateAndStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import ProductsReceivedDetails from './ProductReceivedDetails';
import useGenerics from '../../../../hooks/useGenerics';

export default function ProductsReceived(){
    const [data, setData] = useState([]);
    const [productReceivedId, setProductReceivedId] = useState('');
    const [openProductsReceivedDetails, setOpenProductsReceivedDetails] = useState(false);
    const {getCurrentDate} = useGenerics();

    const columns = [
        {
            id: 'id_produto',
            name: 'ID',
            selector: row => row.id_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_produto',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={()=>openProductsReceivedModal(row.id_produto)}>Ver</button>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
            selector: row => row.nome_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_produto',
            name: 'Modelo',
            selector: row => row.modelo_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'serial_produto',
            name: 'Serial',
            selector: row => row.serial_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
            selector: row => row.modal_envio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
            selector: row => row.codigo_rastreio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
            selector: row => row.tipo_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
            selector: row => row.status_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
            selector: row => row.nfe_saida_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
            selector: row => row.data_entrega_produto,
            sortable: true,
            wrap: true
        }
    ];

    async function getAwaitingWithdraw(){
        const toastProducts = toast.loading("Carregando dados, aguarde...");
        const data_inicial = getCurrentDate(0, 0, 0);
        const data_final = getCurrentDate(1);
        let dataReturn = await getShipmentsByDateAndStatus({ data_inicial: data_inicial, data_final: data_final});

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data || [];

            let productData = products.map((item, idx)=> ({
                id_produto: item?.id || '',
                nome_produto: item?.product?.nome_produto || '',
                modelo_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || ''
            }));

            setData(productData);
        }else{
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
    }

    function openProductsReceivedModal(id){
        setProductReceivedId(id);
        setOpenProductsReceivedDetails(true);
    }

    useEffect(()=>{
        getAwaitingWithdraw();
    }, []);

    return (<Content headerTitle='Produtos Recebidos'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica'></SubHeaderSpecific>
        <ProductsReceivedDetails isOpen={openProductsReceivedDetails} setModalOpen={()=>setOpenProductsReceivedDetails(!openProductsReceivedDetails)} productReceivedId={productReceivedId}></ProductsReceivedDetails>
        <Card title='Cadastro de Produtos - Produtos Recebidos'>
            <Table columns={columns} data={data} id='awaitingWithdrawTable'></Table>
        </Card>
    </Content>);
}