import React, { useContext, useEffect, useState } from 'react';
import useGenerics from '../../hooks/useGenerics';
import { getContactsList } from '../../services/Chat';
import Modal from '../Layout/Modal';
import { toast } from 'react-toastify';
import { Context } from '../../App';
import SearchField from './SearchField';
import { ChatContext } from './MainChatComponent';


export default function Contacts({ isOpen, setModalOpen, title = '', updateChannel=false, setMembers }) {
    const { onImageError } = useGenerics();

    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const userData = useContext(Context);
    const {chatState} = useContext(ChatContext);

    function addSelectedContactToArray(newContact) {
        setSelectedContacts(prevState => {
            if (prevState.some(contact => contact.id === newContact.id)) {
                return prevState.filter(contact => contact.id !== newContact.id);
            } else {
                return [...prevState, newContact];
            }
        });
    }

    async function getContacts() {
        const toastContacts = toast.loading("Carregando contatos, aguarde...");

        let contactsArray = await getContactsList();
        if (contactsArray?.data?.response === 'success') {
            toast.update(toastContacts, { render: "Dados encontrados!", type: "success", isLoading: false, autoClose: 1500 });

            let contactsData = contactsArray?.data?.data.filter(item => item.id !== userData?.userDataState?.id_usuario) || [];

            if(updateChannel){
                let currentMembers = chatState?.members.map((item, idx)=>{
                    return item.id;
                });
                contactsData = contactsData.filter(item => !currentMembers.includes(item.id));
            }

            setContacts(contactsData);
            setFilteredContacts(contactsData);
        } else {
            toast.update(toastContacts, { render: "Ocorreu um erro ao encontrar os dados!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function getContactListComponent(){
        return (filteredContacts.map((item, idx) => {
            return (
                <li className={`list-group-item ${selectedContacts.includes(item) ? 'active' : ''}`} aria-current="true" onClick={() => addSelectedContactToArray(item)} style={{ cursor: 'pointer' }} key={item.id}>
                    <div className="row">
                        <div className="col-12 text-collapse">
                            <img src={item?.url_foto_perfil || ''} className="img-circle mr-1" alt={item.id + "_image"} width={40} height={40} onError={onImageError} />
                            <span><b>{item?.nome}</b></span>
                        </div>
                    </div>
                </li>)
        }))
    }

    function searchContact(search){
        let contactsList = contacts.filter(item => item.nome.toLowerCase().includes(search.toLowerCase()));

        setFilteredContacts(contactsList);
    }

    useEffect(() => {
        if(isOpen){
            getContacts();
        }
        setSelectedContacts([]);
    }, [isOpen])

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            setModalOpen={setModalOpen}>
            <div style={{ minWidth: '50vw' }}>
                <button type="button" className="btn btn-success btn-lg" style={{ position: 'fixed', bottom: 95, right: 35, zIndex: '1000', opacity: '0.8' }} onClick={() => setMembers(selectedContacts)}><i className='fas fa-check'></i></button>
                <div className="card-header">
                    <div className="row">
                        <div className="col-12">
                           <SearchField placeholder='Pesquise pelo nome do contato' searchFunction={searchContact}></SearchField>
                        </div>
                    </div>
                </div>
                <div className="card-body" style={{ overflowY: 'auto' }}>
                    <div className='row'>
                        <div className="col-12">
                            <ul className="list-group">
                                {
                                    getContactListComponent()
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}