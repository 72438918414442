import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../common/Layout/Modal';
import Cep from '../../../../common/Cep/Cep';
import { getShipmentById } from '../../../../services/Shipment';
import useGenerics from '../../../../hooks/useGenerics';
import If from '../../../../common/Layout/If';
import GedTable from '../Ged/GedTable';

export default function ProductsReceivedDetails({ isOpen = false, setModalOpen = null, productReceivedId = '' }) {

    const initialState = {
        nome_produto: '',
        serial: '',
        modelo: '',
        urgencia_remessa: '',
        local: '',
        box: '',
        id_representante: '',
        id_tecnico: '',
        id_localidade: '',
        cep: '',
        bairro: '',
        uf: '',
        cidade: '',
        rua: '',
        numero: '',
        complemento: '',
        dimensoes: '',
        centro_custo: '',
        peso_kg: '',
        modal_envio: '',
        responsavel_separacao: '',
        destinatario: '',
        data_entrega: ''
    }

    const [formData, setFormData] = useState(initialState);
    const { onImageError } = useGenerics();

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(productReceivedId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let shipmentData = dataReturn?.data?.data;
            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.modelo || '',
                local: shipmentData?.product?.local || '',
                box: shipmentData?.product?.box || '',
                id_localidade: shipmentData?.id_localidade || '',
                modal_envio: shipmentData?.modal_envio || '',
                volume_nfe: shipmentData?.volume_nfe || '',
                id_tecnico: shipmentData?.product?.technician?.nome_tec || '',
            }));

        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen && ![undefined, null, ''].includes(productReceivedId)) {
            setFormData(initialState);
            getShipment();
        }
    }, [isOpen, productReceivedId]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title={'Visualizar Dados do Produto'}
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '95vh',
            }}
        >
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role='tablist'>
                    <button type="button" className="nav-link active" id="nav-detalhes-tab" data-toggle='tab' data-target='#nav-detalhes' role='tab' aria-controls='nav-detalhes' aria-selected='true'>Detalhes da NFe</button>
                    <button type="button" className="nav-link" id="nav-ged-tab" data-toggle='tab' data-target='#nav-ged' role='tab' aria-controls='nav-ged' aria-selected='true'>GED</button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-detalhes" role='tabpanel' aria-labelledby='nav-detalhes-tab'>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <h5>Dados do Produto</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12 mt-1">
                                <label htmlFor="">Nome Produto</label>
                                <input type="text" name="nome_produto" id="nome_produto" className="form-control" placeholder='Nome Produto' value={formData?.nome_produto || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12 mt-1">
                                <label htmlFor="">Serial</label>
                                <input type="text" name="serial" id="serial" className="form-control" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12 mt-1">
                                <label htmlFor="">Modelo</label>
                                <input type="text" name="modelo" id="modelo" className="form-control" placeholder='Modelo Produto' value={formData?.modelo || ''} readOnly />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-12">
                                <h6>Detalhes da Remessa</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="urgencia_remessa">Urgência Remessa</label>
                                <input type="text" name="urgencia_remessa" id="urgencia_remessa" placeholder='...' className="form-control" value={formData?.urgencia_remessa || ''} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="id_tecnico">Técnico</label>
                                <input type="text" name="id_tecnico" id="id_tecnico" placeholder='...' className="form-control" value={formData?.id_tecnico || ''} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="tipo_remessa">Tipo Remessa</label>
                                <input type="text" name="tipo_remessa" id="tipo_remessa" className="form-control" placeholder='Tipo Remessa' value={formData?.tipo_remessa || ''} readOnly={true} />
                            </div>
                        </div>
                        <Cep
                            formData={formData}
                            setFormData={setFormData}
                            formDataNames={{ bairro: 'bairro', cep: 'cep', cidade: 'cidade', estado: 'uf', logradouro: 'rua' }}
                            required={true}
                            readOnly={true}
                        ></Cep>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" placeholder='...' className="form-control" value={formData?.numero || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" placeholder='...' className="form-control" value={formData?.complemento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="destinatario">Destinatário</label>
                                <input type="text" name="destinatario" id="destinatario" placeholder='...' className="form-control" value={formData?.destinatario || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="dimensoes">Dimensões</label>
                                <input type="text" name="dimensoes" id="dimensoes" placeholder='...' className="form-control" value={formData?.dimensoes || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="centro_custo">Centro Custo</label>
                                <input type="text" name="centro_custo" id="centro_custo" placeholder='...' className="form-control" value={formData?.centro_custo || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="peso_kg">Peso (kg)</label>
                                <input type="number" step={"0.01"} min={"0"} name="peso_kg" id="peso_kg" placeholder='...' className="form-control" value={formData?.peso_kg || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="modal_envio">Modal de Envio</label>
                                <input type="text" name="modal_envio" id="modal_envio" placeholder='...' className="form-control" value={formData?.modal_envio || ''} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="responsavel_separacao">Responsável pela Separação</label>
                                <input type="text" name="responsavel_separacao" id="responsavel_separacao" className="form-control" placeholder='...' value={formData?.responsavel_separacao || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="data_recebido">Data de Recebimento</label>
                                <input type="text" name="data_recebido" id="data_recebido" className="form-control" placeholder='Data de Recebimento' value={formData?.data_recebido || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="">Local</label>
                                <input type="text" name="local" id="local" className="form-control" placeholder='Local' value={formData?.local || ''} readOnly />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="">Box</label>
                                <input type="text" name="box" id="box" className="form-control" placeholder='Box' value={formData?.box || ''} readOnly />
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className="col-12">
                                <h6>Detalhes da NFe</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="rastreamento">Código de Rastreio</label>
                                <input type="text" name="rastreamento" id="rastreamento" className="form-control" placeholder='Rastreamento' value={formData?.rastreamento || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="nfe_saida">NFe de Saída</label>
                                <input type="text" name="nfe_saida" id="nfe_saida" className="form-control" placeholder='NFe de Saída' value={formData?.nfe_saida || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="prazo_entrega">Prazo de Entrega</label>
                                <input type="date" name="prazo_entrega" id="prazo_entrega" className="form-control" placeholder='Prazo de Entrega' value={formData?.prazo_entrega || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="valor_equipamento_nf">Valor do Equipamento na NFe</label>
                                <input type="number" name="valor_equipamento_nf" id="valor_equipamento_nf" className="form-control" placeholder='Valor do Equipamento NFe' value={formData?.valor_equipamento_nf || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="volume_nfe">Volume da NFe</label>
                                <input type="number" name="volume_nfe" id="volume_nfe" className="form-control" placeholder='Volume da NFe' value={formData?.volume_nfe || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="data_postagem_remessa">Data da Postagem</label>
                                <input type="date" name="data_postagem_remessa" id="data_postagem_remessa" className="form-control" placeholder='Data da Postagem' value={formData?.data_postagem_remessa || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required readOnly={true} />
                            </div>
                        </div>
                        <If condition={formData?.modal_envio === 'Azul'}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="dact">DACT</label>
                                    <input type="text" name="dact" id="dact" className="form-control" placeholder='DACT' value={formData?.dact || ''} readOnly={true} />
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="valor_dact">Valor DACT</label>
                                    <input type="text" name="valor_dact" id="valor_dact" className="form-control" placeholder='Valor DACT' value={formData?.valor_dact || ''} readOnly={true} />
                                </div>
                            </div>
                        </If>
                        <If condition={formData?.modal_envio === 'Em Mãos'}>
                            <hr></hr>
                            <div className="row">
                                <div className="col-12">
                                    <h6>Dados de Assinatura</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="nome_recebedor">Nome Recebedor</label>
                                    <input type="text" name="nome_recebedor" id="nome_recebedor" className="form-control" placeholder='Nome Recebedor' value={formData?.nome_recebedor || ''} readOnly={true} />
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="cpf_recebedor">CPF Recebedor</label>
                                    <input type="text" name="cpf_recebedor" id="cpf_recebedor" className="form-control" placeholder='CPF Recebedor' value={formData?.cpf_recebedor || ''} readOnly={true} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="">Assinatura</label>
                                </div>
                                <div className="col-12" style={{ backgroundColor: '#E9ECEF', padding: '10px', borderRadius: '15px' }}>
                                    <img src={formData?.url_assinatura} alt="Sign" style={{ width: '100%', height: '250px', borderRadius: '10px' }} onError={(e) => onImageError(e, 'sign')} />
                                </div>
                            </div>
                        </If>
                    </form>
                </div>
                <div className="tab-pane fade" id="nav-ged" role='tabpanel' aria-labelledby='nav-ged-tab'>
                    <GedTable addTitle='Novo Documento' idProduto={formData?.id_produto} submodule='remessas' viewMode='view'></GedTable>
                </div>
            </div>
        </Modal>);
}