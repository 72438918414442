import interceptorInstance from "./Interceptor";

export async function getExternalMessages(id){
    try{
        let data = await interceptorInstance.get(`external-chat/channel/${id}/message`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function sendExternalMessages(id, params){
    try{
        let data = await interceptorInstance.post(`external-chat/channel/${id}/message`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function seenExternalMessage(id, params){
    try{
        let data = await interceptorInstance.patch(`external-chat/message/${id}/seen`, params);
        return data;
    }catch(e){
        return e.response;
    }
}