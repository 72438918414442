import React, { useContext, useState } from 'react';
import Card from '../../../common/Layout/Card';
import useGenerics from '../../../hooks/useGenerics';
import { withMask } from 'use-mask-input';
import GenericSelect from '../../../common/Selects/GenericSelect';
import { addNoteByTicketId } from '../../../services/Ticket';
import MotivosImprodutivosSelect from '../../../common/Selects/MotivosImprodutivosSelect';
import { Context } from '../../../App';
import { toast } from 'react-toastify';
import { createChannel, sendMessageByChannelId } from '../../../services/Chat';
import { createNotification } from '../../../services/Notifications';
import If from '../../../common/Layout/If';

export default function RegistroTrabalhoCard({
    detailsData = {},
    setDetailsData = null,
    originalData = {},
    id = '',
    getHistory = null,
    setChannelId = null,
    setFloatChatOpened = null,
    historico = '',
    historicoExternoCliente = '',
    historicoTecnico = '',
    imgArray = [],
    imgThumbArray = []
}) {
    const { getCurrentDate, getCurrentTime } = useGenerics();
    const userData = useContext(Context);
    const currentDate = getCurrentDate();
    const currentTime = getCurrentTime();
    const [showNote, setShowNote] = useState(false);

    async function addExternalNote(e) {
        e.preventDefault();

        let formData = {
            descricao: detailsData?.nota_externa_cliente,
            tipo: 'externa',
            status: originalData?.status_chamado
        }

        const toastNote = toast.loading("Adicionando nota, aguarde...");

        let dataReturn = await addNoteByTicketId(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastNote, { render: 'Nota adicionada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getHistory();
            setDetailsData(prevState => ({ ...prevState, nota_externa_cliente: '' }));
        } else {
            toast.update(toastNote, { render: 'Ocorreu um erro ao adicionar a nota!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function addInternalNote(e) {
        e.preventDefault();

        let formData = {
            descricao: detailsData?.nota_chamado,
            tipo: 'interna',
            status: originalData?.status_chamado
        }

        const toastNote = toast.loading("Adicionando nota, aguarde...");

        let dataReturn = await addNoteByTicketId(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastNote, { render: 'Nota adicionada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getHistory();
            setDetailsData(prevState => ({ ...prevState, nota_chamado: '' }));
        } else {
            toast.update(toastNote, { render: 'Ocorreu um erro ao adicionar a nota!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function createChannelByResponsibleType(type, name = '', description = '') {
        const toastCreateChannel = toast.loading("Criando canal, aguarde...");
        let formData = {
            channel_name: name,
            ticket_id: originalData?.id,
            channel_description: description,
            users: [userData?.userDataState?.id_usuario],
            type: 'channel'
        }

        if ([undefined, null, ''].includes(originalData?.[type]?.id)) {
            toast.update(toastCreateChannel, { render: `Responsável não encontrado!`, type: "warning", isLoading: false, autoClose: 1500 });
            return;
        }

        if (originalData?.[type]?.id === userData?.userDataState?.id_usuario) {
            toast.update(toastCreateChannel, { render: "Você não pode adicionar a si mesmo!", type: "warning", isLoading: false, autoClose: 1500 });
            return;
        }

        formData.users = [...formData.users, originalData?.[type]?.id];

        let dataReturn = await createChannel(formData);

        if (dataReturn?.data?.response === 'success') {

            if (detailsData?.nota_chamado?.length) {
                let formData = new FormData();
                formData.append('message', detailsData?.nota_chamado);

                let messageReturn = await sendMessageByChannelId(dataReturn?.data?.data?._id, formData);

                if (messageReturn?.data?.response !== 'success') {
                    toast.warning("Ocorreu um erro ao enviar a mensagem!");
                }
            }

            let formData = {
                type: 'danger',
                users: [originalData?.[type]?.id],
                module_id: originalData?.id,
                module: 'ticket',
                event: 'help'
            }

            await createNotification(formData);

            toast.update(toastCreateChannel, { render: `Canal Criado, aguarde a abertura dele!`, type: "warning", isLoading: false, autoClose: 1500 });

            setChannelId(dataReturn?.data?.data?._id);
            setFloatChatOpened(true);

        } else {
            toast.update(toastCreateChannel, { render: `Ocorreu um erro ao criar o canal!`, type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    return (<Card title={`Registro de Trabalho`}>
        <div className="row" id="registroTrabalhoFechamento">
            <If condition={(detailsData?.status_chamado?.value === "Fechado" && originalData?.status_chamado !== "Fechado")}>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Data do Início do Atendimento</label>
                        <input type="date" name="data_inicio_atendimento" className="form-control" value={detailsData?.data_inicio_atendimento || currentDate} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Hora do Início do Atendimento</label>
                        <input type="time" name="hora_inicio_atendimento" className="form-control" value={detailsData?.hora_inicio_atendimento || currentTime} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Dt. Encerramento do Atendimento</label>
                        <input type="date" name="data_encerramento" className="form-control" value={detailsData?.data_encerramento || currentDate} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Hr. Encerramento do Atendimento</label>
                        <input type="time" name="hora_encerramento" className="form-control" value={detailsData?.hora_encerramento || currentTime} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="">Senha Integradora</label>
                        <input type="text" name="senha_integradora" className="form-control" value={detailsData?.senha_integradora || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Nome do Contato do Cliente</label>
                        <input type="text" name="nome_analista_integracao" className="form-control" placeholder='...' value={detailsData?.nome_analista_integracao || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Telefone do Contato no Cliente</label>
                        <input type="tel" className="form-control" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} name="tel_contato_analista_integracao" placeholder='...' value={detailsData?.tel_contato_analista_integracao || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="">Documento/Matrícula do Contato no Cliente</label>
                        <input type="text" name="documento_matricula" className="form-control" placeholder='...' value={detailsData?.documento_matricula || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                </div>
                <GenericSelect formData={detailsData} setFormData={setDetailsData} fieldName={'ocasiao_fechamento'} title={'Ocasião Fechamento'} cols={[6, 6, 6, 6]} genericOption={'OcasiaoFechamento'} required={true}></GenericSelect>
                <If condition={detailsData?.ocasiao_fechamento?.value === "Improdutivo"}>
                    <MotivosImprodutivosSelect formData={detailsData} setFormData={setDetailsData} cols={[6, 6, 6, 6]} fieldName='motivo_improdutivo'></MotivosImprodutivosSelect>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="">Justificativa de fechamento improdutivo</label>
                            <textarea name="justificativa_ocasiao_fechamento" cols="30" rows="10" className="form-control" placeholder='...' value={detailsData?.justificativa_ocasiao_fechamento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                        </div>
                    </div>
                </If>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Descrição do Fechamento</label>
                        <textarea name="descricao_fechamento" cols="30" rows="10" className="form-control" value={detailsData?.descricao_fechamento || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                    </div>
                </div>
            </If>
        </div>
        <div className="row">
            <div className="col-12 mb-2">
                <button className="btn btn-primary" onClick={() => setShowNote(prevState => !prevState)}>Adicionar Nota Externa Cliente</button>
            </div>
            <If condition={showNote}>
                <div className="col-12">
                    <form onSubmit={addExternalNote}>
                        <div className='row'>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Adicionar Nota Externa Cliente</label>
                                    <textarea name="nota_externa_cliente" id="notaExternaCliente" placeholder="..." rows={10} className="form-control" value={detailsData?.nota_externa_cliente} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <button type='submit' className="btn btn-secondary" disabled={!detailsData?.nota_externa_cliente?.length}>Adicionar Nota Externa</button>
                            </div>
                        </div>
                    </form>
                </div>
            </If>
            <If condition={originalData?.status_chamado !== "Fechado"}>
                <div className="col-12">
                    <form onSubmit={addInternalNote}>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label htmlFor="notaChamado">Adicionar Nota Interna Maminfo</label>
                                    <textarea name="nota_chamado" id="notaChamado" cols="30" rows="10" className="form-control" placeholder="..." value={detailsData?.nota_chamado} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-6 mt-1'>
                                <button type='submit' className='btn btn-secondary btn-block' disabled={!detailsData?.nota_chamado?.length}>Adicionar Nota Interna</button>
                            </div>
                            <div className="col-lg-2 col-md-6 mt-1">
                                <button type="button" className="btn btn-primary btn-block" onClick={() => createChannelByResponsibleType('user', 'Acionamento', 'Acionamento')}>MAM Talks Acionamento</button>
                            </div>
                            <div className="col-lg-2 col-md-6 mt-1">
                                <button type="button" className="btn btn-primary btn-block" onClick={() => createChannelByResponsibleType('ticket_responsible_user', 'Resp. Chamado', 'Resp. Chamado')}>MAM Talks Resp. Chamado</button>
                            </div>
                            <div className="col-lg-2 col-md-6 mt-1">
                                <button type="button" className="btn btn-primary btn-block" onClick={() => createChannelByResponsibleType('partnership_responsible_user', 'Resp. Parcerias', 'Resp. Parcerias')}>MAM Talks Resp. Parcerias</button>
                            </div>
                            <div className="col-lg-2 col-md-6 mt-1">
                                <button type="button" className="btn btn-primary btn-block" onClick={() => createChannelByResponsibleType('support_responsible_user', 'Resp. Suporte', 'Resp. Suporte')}>MAM Talks Resp. Suporte</button>
                            </div>
                        </div>
                    </form>
                </div>
            </If>
            <If condition={userData.userDataState.cliente === 0}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico Maminfo</label>
                        <textarea className='form-control' rows={10} readOnly value={historico}></textarea>
                    </div>
                </div>
            </If>
            <If condition={(userData.userDataState.id_contrato !== '' && userData.userDataState.cliente === 1)}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico {userData.userDataState.id_contrato !== '' ? '' : 'Notas Externas Cliente'}</label>
                        <textarea className='form-control' rows={10} readOnly value={historicoExternoCliente}></textarea>
                    </div>
                </div>
            </If>
            <If condition={historicoTecnico !== ""}>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Histórico Técnicos</label>
                        <textarea cols="30" rows="10" className="form-control" readOnly value={historicoTecnico}></textarea>
                    </div>
                </div>
            </If>
            <If condition={imgArray.length > 0}>
                <div className="col-12">
                    <label htmlFor="">Imagens</label>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div id="imgCarousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    {imgArray}
                                </div>
                                <button type="button" className="carousel-control-prev" data-target="#imgCarousel" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </button>
                                <button type="button" className="carousel-control-next" data-target="#imgCarousel" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12" id="slider-thumbs">
                            <ul className='list-inline'>
                                {imgThumbArray}
                            </ul>
                        </div>
                    </div>
                </div>
            </If>
        </div>
    </Card>);
}