import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { getContractById, updateContractData } from '../../../../services/Contracts';
import { toast } from 'react-toastify';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './ContractDetails.css'
import useGenerics from '../../../../hooks/useGenerics';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import IntegradoraSelect from '../../../../common/Selects/IntegradoraSelect';
import TiposContratosSelect from '../../../../common/Selects/TiposContratosSelect';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import ContractValuesTable from './ContractValues/ContractValuesTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import POP from './POP';

export default function ContractDetails() {
    const { id } = useParams();
    const { isJsonValid } = useGenerics();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const navigate = useNavigate();
    const { checkPermissionGroup } = usePermissions();

    const initialState = {
        id: '',
        id_integradora: '',
        contrato_confirmado: '',
        contrato: '',
        email: '',
        tipo: '',
        responsavel_equipamento: '',
        descricao: '',
        mascara_email_abertura: '',
        mascara_email_fechamento: '',
        escopo_atendimento: '',
        ativo: '',
        data_registro: '',
        data_modificacao: '',
        centro_custo: '',
        conta_contabil: '',
        km_maximo: '',
        data_vencimento: '',
        id_usuario: '',
        rat_contrato: '',
        resumo_pop: '',
        arquivo_planta: '',
        arquivo_pop: '',
        url_arquivo_planta: '',
        url_arquivo_pop: '',
        integrator: ''
    }

    const [updateData, setUpdataData] = useState(false);
    const [formContractData, setFormContractData] = useState(initialState);

    async function getContractDataById(reloadType = '') {
        const contractDataToast = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getContractById(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(contractDataToast, { render: `Dados carregados com sucesso!`, type: "success", isLoading: false, autoClose: 1500 });
            let contractData = dataReturn?.data?.data || initialState;

            if (reloadType === '') {
                setFormContractData({
                    ...contractData,
                    id_integradora: { value: contractData?.integrator?.id || '', label: contractData?.integrator?.nome || '' },
                    tipo: { value: contractData?.tipo || '', label: contractData?.tipo || '' },
                    contrato_confirmado: { value: contractData?.contrato_confirmado || '', label: contractData?.contrato_confirmado || '' },
                    responsavel_equipamento: { value: contractData?.responsavel_equipamento || '', label: contractData?.responsavel_equipamento || '' }
                });

                if (contractData?.resumo_pop !== null && contractData?.resumo_pop !== '') {
                    let dados = contractData?.resumo_pop
                    if (isJsonValid(dados) === false) {
                        dados = JSON.stringify({ "blocks": [{ "key": "1", "text": contractData?.resumo_pop, "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} });
                    }

                    setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(dados))))
                }
            }

            if (reloadType === 'files') {
                setFormContractData(prevState => ({
                    ...prevState,
                    arquivo_pop: contractData?.arquivo_pop,
                    arquivo_planta: contractData?.arquivo_planta,
                    url_arquivo_planta: contractData?.url_arquivo_planta,
                    url_arquivo_pop: contractData?.url_arquivo_pop
                }));
            }

        } else if (dataReturn?.data?.message.includes("No query results for model")) {
            toast.update(contractDataToast, { render: `Este registro não existe!`, type: "warning", isLoading: false, autoClose: 1500 });
            navigate("/cadastros/contratos/dados");
        } else {
            toast.update(contractDataToast, { render: `Ocorreu um erro ao carregar os dados!`, type: "warning", isLoading: false, autoClose: 1500 });
            setFormContractData(initialState);
        }
    }

    async function submitForm() {

        let formData = {
            id_integradora: formContractData?.id_integradora?.value || '',
            contrato_confirmado: formContractData?.contrato_confirmado?.value || '',
            contrato: formContractData?.contrato || '',
            email: formContractData?.email || '',
            tipo: formContractData?.tipo?.value || '',
            responsavel_equipamento: formContractData?.responsavel_equipamento?.value || '',
            descricao: formContractData?.descricao || '',
            mascara_email_abertura: formContractData?.mascara_email_abertura || '',
            mascara_email_fechamento: formContractData?.mascara_email_fechamento || '',
            escopo_atendimento: formContractData?.escopo_atendimento || '',
            centro_custo: formContractData?.centro_custo || '',
            conta_contabil: formContractData?.conta_contabil || '',
            km_maximo: formContractData?.km_maximo || '',
            data_vencimento: formContractData?.data_vencimento || '',
            resumo_pop: JSON.stringify(convertToRaw(editorState.getCurrentContent())) || '',
            ativo: formContractData?.ativo || '',
        }

        const toastUpdateForm = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updateContractData(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUpdateForm, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getContractDataById();
            setUpdataData(false);
        } else {
            toast.update(toastUpdateForm, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        getContractDataById();
    }, []);

    return (
        <Content headerTitle="Detalhes Contrato">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <If condition={updateData}>
                            <button className="btn btn-primary m-1" onClick={submitForm}>Salvar Alterações</button>
                        </If>
                        <If condition={!updateData}>
                            <button className="btn btn-primary m-1" onClick={() => setUpdataData(prevState => !prevState)}>Alterar</button>
                        </If>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Integradora / Contrato</h5>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="nomeContrato">Nome do Contrato</label>
                            <input type="text" className="form-control" name="contrato" id="nomeContrato" value={formContractData?.contrato || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </div>
                    <IntegradoraSelect formData={formContractData} setFormData={setFormContractData} cols={[12, 12, 6, 6]} fieldName='id_integradora' isDisabled={!updateData} required={true}></IntegradoraSelect>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="text" className="form-control" name="email" id="email" value={formContractData?.email || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </div>
                    <TiposContratosSelect formData={formContractData} setFormData={setFormContractData} cols={[12, 12, 6, 6]} fieldName='tipo' required={true} isDisabled={!updateData}></TiposContratosSelect>
                    <GenericSelect formData={formContractData} setFormData={setFormContractData} fieldName={'contrato_confirmado'} title={'Contrato Confirmado?'} cols={[12, 12, 3, 3]} genericOption='ContratoConfirmadoOptions' isDisabled={!updateData} required={true}></GenericSelect>
                    <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="centroCusto">Centro de Custo</label>
                            <input type="text" className="form-control" name="centro_custo" id="centroCusto" value={formContractData?.centro_custo || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="contaContabil">Conta Contábil</label>
                            <input type="text" className="form-control" name="conta_contabil" id="contaContabil" value={formContractData?.conta_contabil || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="kmMaximo">Km Máximo</label>
                            <input type="number" min="0" className="form-control" name="km_maximo" id="kmMaximo" value={formContractData?.km_maximo || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="dataVencimentoContrato">Data de Vencimento do Contrato</label>
                            <input type="date" className="form-control" name="data_vencimento" id="dataVencimentoContrato" value={formContractData?.data_vencimento || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                        </div>
                    </div>
                    <GenericSelect formData={formContractData} setFormData={setFormContractData} fieldName={'responsavel_equipamento'} title={'Responsável Equipamento'} cols={[12, 12, 3, 3]} genericOption='ResponsavelEquipamentoOptions' isDisabled={!updateData} required={true}></GenericSelect>
                </div>
                {/*<hr></hr>
                 <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-sticky-note"></i> Serviços</h5>
                    </div>
                    <div className="col-12">
                        <form>
                            <div className="row">
                                <div className="col-md-8 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="incluir_servico_input" id="incluirServico" className="form-control" placeholder='Incluir Serviço' readOnly={!updateData} required />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <button type="button" className="btn btn-secondary" disabled={!updateData}>Inserir</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                        data.length === 0 ?
                            (
                                <CustomMessageData message="Sem Dados"></CustomMessageData>
                            )
                            :
                            (<div className='col-12'>
                                <Table columns={columns} data={data} needExport={false} id="table"></Table>
                            </div>)
                    }
                </div> */}
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="escopoAtendimento">Escopo de Atendimento</label>
                            <textarea rows={5} className="form-control" name="escopo_atendimento" id="escopoAtendimento" value={formContractData?.escopo_atendimento || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData}></textarea>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="descricao">Observações</label>
                            <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formContractData?.descricao || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData}></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="mascaraEmailAbertura">Máscara de Email - Abertura</label>
                            <textarea rows={10} className="form-control" name="mascara_email_abertura" id="mascaraEmailAbertura" value={formContractData?.mascara_email_abertura || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData}></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="mascaraEmailFechamento">Máscara de Email - Fechamento</label>
                            <textarea rows={10} className="form-control" name="mascara_email_fechamento" id="mascaraEmailFechamento" value={formContractData?.mascara_email_fechamento || ''} onChange={(e) => (setFormContractData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData}></textarea>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <If condition={checkPermissionGroup(['parcerias', 'gerente_parcerias', 'faturamento', 'financeiro', 'diretoria'])}>
                    <ContractValuesTable id_contrato={id} disableData={!updateData}></ContractValuesTable>
                    <hr></hr>
                </If>
                <POP 
                    formContractData={formContractData} 
                    editorState={editorState}
                    setEditorState={setEditorState}
                    updateData={updateData}
                    getContractDataById={getContractDataById}
                    idContract={id}
                ></POP>
            </Card>
        </Content>
    )
}