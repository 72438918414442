import React from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import { Link } from 'react-router-dom';
import Table from '../../../common/Layout/Table/Table';
import Card from '../../../common/Layout/Card';

export default function RetiradaManagement() {
    const columns = [
        {
            id: 'lote_retirada',
            name: 'Lote',
            // selector: row => row.num_chamado,
            sortable: true,
            wrap: true
        },
        {
            id: 'chamado_id_vtv_retirada',
            name: 'Chamado ID_VTV',
            // selector: row => row.data_agendamento,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_registro_retirada',
            name: 'Data de Registro Maminfo',
            // selector: row => row.hora_agendamento,
            sortable: true,
            wrap: true
        },
        {
            id: 'agendamento_retirada',
            name: 'Agendamento',
            // selector: row => row.responsavel_atendimento,
            sortable: true,
            wrap: true
        },
        {
            id: 'cliente_retirada',
            name: 'Cliente',
            // selector: row => <b style={{ color: 'red', }}>{row.urgencia}</b>,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_visita_retirada',
            name: 'Tipo de Visita',
            // selector: row => <b style={{ color: 'red', }}>{row.urgencia}</b>,
            sortable: true,
            wrap: true
        },
        {
            id: 'tecnico_retirada',
            name: 'Técnico',
            // selector: row => <b style={{ color: 'red', }}>{row.urgencia}</b>,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_retirada',
            name: 'Status',
            // selector: row => <b style={{ color: 'red', }}>{row.urgencia}</b>,
            sortable: true,
            wrap: true
        },
        {
            id: 'descricao_retirada',
            name: 'Descrição',
            // selector: row => <b style={{ color: 'red', }}>{row.urgencia}</b>,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_retirada',
            name: 'Ação',
            button: "true",
            cell: (row) => (
                <Link to={'/chamados/detalhes-chamado/' + row.id}><i className='fas fa-eye'></i></Link>
            ),
        },
    ];

    return (
        <Content headerTitle='Gerenciar Acionamento de Retirada'>
            <SubHeaderSpecific subHeaderConfig='retirada'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <Table columns={columns} data={[]} id='gerenciar_acionamento'></Table>
                    </div>
                </div>
            </Card>
        </Content>
    );
}