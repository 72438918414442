import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Table from '../../../../common/Layout/Table/Table';
import Card from '../../../../common/Layout/Card';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ShipmentDetails from './ShipmentDetails';

export default function ProductsToSend() {

    const [data, setData] = useState([]);
    const [productId, setProductId] = useState('');
    const [shipmentDetailsMode, setShipmentDetailsMode] = useState('view');
    const [shipmentDetailsModal, setShipmentDetailsModal] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const columns = [
        {
            id: 'id_produto',
            name: 'ID',
            selector: row => row.id_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_produto',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openShipmentDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <button type='button' className="dropdown-item" onClick={() => openShipmentDetailsModal(row.id_produto, 'edit')}>Editar</button>
                        <button type='button' className="dropdown-item" onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Solicitação</button>
                    </div>
                </div>
            ),
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
            selector: row => row.nome_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_produto',
            name: 'Modelo',
            selector: row => row.modelo_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'serial_produto',
            name: 'Serial',
            selector: row => row.serial_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
            selector: row => row.urgencia_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
            selector: row => row.modal_envio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
            selector: row => row.tipo_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
            selector: row => row.status_remessa_produto,
            sortable: true,
            wrap: true
        }
    ];

    async function getProductsToSendByStatus() {
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO ENVIO');

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data || [];

            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                nome_produto: item?.product?.nome_produto || '',
                modelo_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || ''
            }));

            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
    }

    function openShipmentDetailsModal(id, mode) {
        setProductId(id);
        setShipmentDetailsMode(mode);
        setShipmentDetailsModal(true);
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            text: 'O produto voltará para o status anterior ao estoque.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Emissão cancelada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getProductsToSendByStatus();
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cancelar a emissão!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        getProductsToSendByStatus();
    }, []);

    return (
        <Content headerTitle="Peças a Enviar">
            <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <ShipmentDetails isOpen={shipmentDetailsModal} setIsOpen={() => setShipmentDetailsModal(!shipmentDetailsModal)} shipmentId={productId} mode={shipmentDetailsMode} setUpdateShipment={getProductsToSendByStatus} setUpdateLogistic={setUpdateLogistic}></ShipmentDetails>
            <Card title='Cadastro de Produtos - Envio de Peças'>
                <Table columns={columns} data={data} id='productsToSendTable'></Table>
            </Card>
        </Content>
    )
}