import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import Cep from '../../../common/Cep/Cep';
import { useParams } from 'react-router-dom';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';

export default function RetiradaDetails() {

    const {id} = useParams();

    const initialState = {
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
        logradouro: ''
    }

    const [updateData, setUpdataData] = useState(false);
    const [formData, setFormData] = useState(initialState);

    return (<Content headerTitle='Detalhes Acionamento de Retirada'>
        <SubHeaderSpecific subHeaderConfig='retirada'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <div className="row">
                <div className="col-12">
                    {
                        updateData ?
                            (<button className="btn btn-primary m-1">Salvar Alterações</button>)
                            :
                            (<button className="btn btn-primary m-1" onClick={() => setUpdataData(prevState => !prevState)}>Alterar</button>)
                    }
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Acionamento</h5>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">ID_VTV</label>
                        <input type="text" name="" id="" className="form-control" readOnly={!updateData} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Equipamento</label>
                        <input type="text" name="" id="" className="form-control" readOnly={!updateData} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Data Agendamento</label>
                        <input type="date" name="" id="" className="form-control" readOnly={!updateData} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Serial</label>
                        <input type="text" name="" id="" className="form-control" readOnly={!updateData} />
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Endereço Ativ.</h5>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Cliente</label>
                        <input type="text" name="" id="" className="form-control" readOnly={!updateData} />
                    </div>
                </div>
            </div>
            <Cep formData={formData} setFormData={setFormData} readOnly={!updateData}></Cep>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Descrição / Observação</h5>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="">Observação</label>
                        <textarea rows={40} className="form-control" name="" id="" readOnly={!updateData}></textarea>
                    </div>
                </div>
            </div>
            <hr></hr>
        </Card>
    </Content>);
}