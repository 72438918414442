import React, { useContext, useEffect, useState } from 'react';
import Modal from '../Layout/Modal';
import GenericSelect from '../Selects/GenericSelect';
import { getExpenseByExpenseId, updateExpenseById } from '../../services/EmployeeExpense';
import { toast } from 'react-toastify';
import CustomMessageData from '../Layout/CustomMessageData';
import { Context } from '../../App';
import If from '../Layout/If';

export default function ApproveCostsModal({ isOpen = false, setModalOpen = null, costId = '', mode = 'view', setUpdateNewData }) {
    const INITIAL_STATE = {
        status_reembolso: '',
        data_reembolso: '',
        detalhes_aprovacao: '',
        url_anexo: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const userData = useContext(Context);

    async function getCostDataById() {

        const toastForm = toast.loading("Carregando dados, aguarde...");
        let dataReturn = await getExpenseByExpenseId(costId);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data !== null) {
            toast.update(toastForm, { render: 'Dados encontrados!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                status_reembolso: { value: dataReturn?.data?.data?.status_reembolso || '', label: dataReturn?.data?.data?.status_reembolso || '' }
            }));
        } else if (dataReturn?.data?.data === null) {
            toast.update(toastForm, { render: 'Esta nota não existe!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        } else {
            toast.update(toastForm, { render: 'Ocorreu um erro ao procurar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }

        console.log(dataReturn);
    }

    async function submitForm(e) {
        e.preventDefault();

        const toastForm = toast.loading("Atualizando status, aguarde...");

        let params = {
            ...formData,
            status_reembolso: formData?.status_reembolso?.value,
        }

        let dataReturn = await updateExpenseById(costId, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastForm, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setUpdateNewData();
            setModalOpen(false);
            userData?.setUserDataState(prevState => ({ ...prevState, atualizar_qtd_faturamento: true }));
        } else {
            toast.update(toastForm, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        if (costId !== '') {
            getCostDataById();
        }
    }, [costId])

    return (<Modal
        isOpen={isOpen}
        setModalOpen={setModalOpen}
        title='Detalhes Nota'
        customStyle={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            height: '95vh',
        }}
    >
        <div className="row">
            <div className="col-sm-12 col-md-4 d-flex align-items-center">
                <If condition={formData?.url_anexo === ''}>
                    <CustomMessageData message='Sem Imagem'></CustomMessageData>
                </If>
                <If condition={formData?.url_anexo !== ''}>
                    <img src={formData?.url_anexo || ''} alt="details_img" style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }} />
                </If>
            </div>
            <div className="col-sm-12 col-md-8">
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="">Nome</label>
                            <input type="text" className="form-control" disabled={true} value={formData?.employee?.nome_tec || ''} />
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="">Tipo Despesa</label>
                            <input type="text" className="form-control" disabled={true} value={formData?.tipo_despesa || ''} />
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="">Valor</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className='input-group-text'>R$</span>
                                </div>
                                <input type="number" className='form-control' disabled={true} value={formData?.valor || '0.00'} />
                            </div>
                        </div>
                        <GenericSelect formData={formData} setFormData={setFormData} fieldName={'status_reembolso'} title={'Status Reembolso'} cols={[12, 6, 6, 6]} genericOption={'StatusAprovacaoOptions'} required={true} isDisabled={mode === 'view'}></GenericSelect>
                        <If condition={(formData?.status_reembolso?.value === 'aprovado' || formData?.status_reembolso === 'aprovado')}>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="data_reembolso">Data do Reembolso</label>
                                <input type="date" name="data_reembolso" id="data_reembolso" className='form-control' value={formData?.data_reembolso || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} disabled={mode === 'view'} />
                            </div>
                        </If>
                        <div className="col-12">
                            <label htmlFor="detalhes_aprovacao">Detalhes</label>
                            <textarea name="detalhes_aprovacao" id="detalhes_aprovacao" rows="10" className='form-control' placeholder='...' value={formData?.detalhes_aprovacao || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} disabled={mode === 'view'}></textarea>
                        </div>
                    </div>
                    <If condition={mode === 'edit'}>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-warning mt-1 float-right">Lançar Dados</button>
                            </div>
                        </div>
                    </If>
                </form>
            </div>
        </div>
    </Modal>)
}