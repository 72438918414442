import React, { useEffect, useState } from 'react';
import { getExpenseByExpenseId, getExpensesByTechnicianIdTicketId, updateExpenseById } from '../../../services/EmployeeExpense';
import Modal from '../../../common/Layout/Modal';
import { toast } from 'react-toastify';
import './ApproveExpensesModal.css';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import If from '../../../common/Layout/If';
import GenericSelect from '../../../common/Selects/GenericSelect';
import useGenerics from '../../../hooks/useGenerics';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function ApproveExpensesModal({ isOpen = false, setModalOpen = null, params = {}, title = '', setUpdateNewData }) {

    const { onImageError, getCurrentDate } = useGenerics();
    const [expensesData, setExpensesData] = useState([]);
    const [formData, setFormData] = useState({});
    const [dataReembolso, setDataReembolso] = useState(getCurrentDate());
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const mySwal = withReactContent(Swal);

    async function getExpenses() {
        const toastExpense = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getExpensesByTechnicianIdTicketId(params);

        if (dataReturn?.data?.response === 'success') {
            if (dataReturn?.data?.data?.length > 0) {
                toast.update(toastExpense, { render: "Dados carregados!", type: "success", isLoading: false, autoClose: 1500 });
                setExpensesData(dataReturn?.data?.data);
                getExpenseById(dataReturn?.data?.data?.[0]?.id);
            } else {
                toast.update(toastExpense, { render: `Não existem notas para este chamado no status ${params?.status}!`, type: "warning", isLoading: false, autoClose: 1500 });
                setModalOpen(false);
                return;
            }
        } else {
            toast.update(toastExpense, { render: "Ocorreu um erro ao carregar os dados!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function renderTabs() {
        return expensesData.map((item, idx) => {
            return (<button key={item?.id + "_tab"} className={`nav-link ${item?.id === formData?.id ? 'active font-weight-bold' : ''}`} id={item?.id + "_tab"} onClick={() => getExpenseById(item?.id)} data-toggle="tab" type="button" role="tab" aria-controls={item?.id} aria-selected="false">{item?.tipo_despesa || 'Sem Nome'}</button>)
        });
    }

    async function getExpenseById(id) {
        let dataReturn = await getExpenseByExpenseId(id);

        if (dataReturn?.data?.response === 'success') {
            setFormData(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                data_reembolso: params?.status === 'pendente' ? dataReembolso : dataReturn?.data?.data?.data_reembolso,
                status_reembolso: { value: dataReturn?.data?.data?.status_reembolso || '', label: dataReturn?.data?.data?.status_reembolso || '' }
            }));
        } else {
            toast.warning("Ocorreu um erro ao buscar os dados do reembolso!");
        }
    }

    async function showDateCalendarSwal() {
        let response = await mySwal.fire({
            title: 'Selecione a data de reembolso',
            input: 'date',
            inputValue: dataReembolso,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            cancelButtonColor: 'red',
        });

        if (response.isConfirmed) {
            setDataReembolso(response.value);
        } else {
            mySwal.close();
            setModalOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();
        setIsBtnDisabled(prevState => (!prevState));
        if (formData?.status_reembolso?.value === 'pendente') {
            toast.warning('Por favor, selecione um status diferente de pendente');
            return;
        }

        let paramsData = {
            ...formData,
            data_reembolso: ['aprovado', 'pendente'].includes(params?.status) || (!['aprovado', 'pendente'].includes(params?.status) && formData?.status_reembolso?.value === 'aprovado') ? formData?.data_reembolso : '',
            status_reembolso: formData?.status_reembolso?.value || '',
            detalhes_aprovacao: formData?.detalhes_aprovacao || ''
        }
        const toastExpense = toast.loading("Atualizando dados, aguarde...");
        let dataReturn = await updateExpenseById(formData?.id, paramsData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastExpense, { render: "Dados carregados!", type: "success", isLoading: false, autoClose: 1500 });
            await getExpenses();
            setUpdateNewData();
        } else {
            toast.update(toastExpense, { render: "Ocorreu um erro ao carregar os dados!", type: "warning", isLoading: false, autoClose: 1500 });
        }
        setIsBtnDisabled(prevState => (!prevState));

    }

    useEffect(() => {
        setDataReembolso(getCurrentDate());
        if (isOpen && params?.status === 'pendente') {
            showDateCalendarSwal();
        }

        if (isOpen) {
            getExpenses();
        }
        setIsBtnDisabled(false);

    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title={title}
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '95vh'
            }}
        >
            <div className="row mb-2">
                <div className="col-sm-12 col-md-6">
                    <strong>Chamado: </strong>{params?.ticketNumber || ''}
                </div>
                <If condition={params?.status === 'pendente'}>
                    <div className="col-sm-12 col-md-6 form-inline">
                        <strong>Data de Pagamento: </strong><input type='date' name='data_reembolso' className='form-control' id='data_reembolso' value={dataReembolso} onChange={(e) => setDataReembolso(e.target.value)} required></input>
                    </div>
                </If>
            </div>
            <div className="row">
                <nav className='nav nav-tabs' id='tabsExpenses' role='tablist'>
                    {renderTabs()}
                </nav>
            </div>
            <div className="row mt-2">
                <div className="col-sm-12 col-md-4 d-flex align-items-center">
                    <If condition={formData?.url_anexo === ''}>
                        <CustomMessageData message='Sem Imagem'></CustomMessageData>
                    </If>
                    <If condition={formData?.url_anexo !== ''}>
                        <img src={formData?.url_anexo || ''} alt="details_img" style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%', borderRadius: '15px' }} onError={(e) => onImageError(e, 'error')} />
                    </If>
                </div>
                <div className="col-sm-12 col-md-8">
                    <form onSubmit={submitForm}>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="">Nome</label>
                                <input type="text" className="form-control" disabled={true} value={formData?.employee?.nome_tec || ''} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="">Tipo Despesa</label>
                                <input type="text" className="form-control" disabled={true} value={formData?.tipo_despesa || ''} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="">Valor</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>R$</span>
                                    </div>
                                    <input type="number" className='form-control' disabled={true} value={formData?.valor || '0.00'} />
                                </div>
                            </div>
                            <If condition={formData?.tipo_despesa === 'combustivel'}>
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="">Valor Combustível</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='input-group-text'>R$</span>
                                        </div>
                                        <input type="number" className='form-control' disabled={true} value={formData?.valor_combustivel || '0.00'} />
                                    </div>
                                </div>
                            </If>
                            <GenericSelect formData={formData} setFormData={setFormData} fieldName={'status_reembolso'} title={'Status Reembolso'} cols={[12, 6, 6, 6]} genericOption={'StatusAprovacaoOptions'} required={true} isDisabled={params?.type === 'view'}></GenericSelect>
                            <If condition={(params?.status === 'aprovado' || (!['pendente', 'aprovado'].includes(params?.status) && formData?.status_reembolso?.value === 'aprovado'))}>
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="data_reembolso">Data do Reembolso</label>
                                    <input type="date" name="data_reembolso" id="data_reembolso" className='form-control' value={formData?.data_reembolso || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true} disabled={params?.type === 'view'} />
                                </div>
                            </If>

                            <div className="col-12">
                                <label htmlFor="detalhes_aprovacao">Detalhes</label>
                                <textarea name="detalhes_aprovacao" id="detalhes_aprovacao" rows="10" className='form-control' placeholder='...' value={formData?.detalhes_aprovacao || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} disabled={params?.type === 'view'}></textarea>
                            </div>
                        </div>
                        <If condition={params?.type === 'validate'}>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-warning mt-1 float-right" disabled={isBtnDisabled}>Lançar Dados</button>
                                </div>
                            </div>
                        </If>
                    </form>
                </div>
            </div>
        </Modal>)
}
