import React, { useEffect, useState } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useToken from './hooks/useToken';
import defaultUserImage from './resources/imgs/profile/default.jpg';
import Login from './pages/Login/Login';
import { getPermissions, getUserData } from './services/Users';
import Header from './common/Layout/Header/Header';
import SideNav from './common/Layout/Nav/SideNav';
import Footer from './common/Layout/Footer';
import ExternalChat from './pages/Chat/ExternalChat';
import MainRoutes from './common/Routes/MainRoutes';
import If from './common/Layout/If';

const initialState = { nome: '', login: '', email: '', cliente: '', id_contrato: '', permissoes: [], id_usuario: '', url_foto_perfil: defaultUserImage, atualizar_qtd_faturamento: false, type: '' }

export const Context = React.createContext(initialState);

function App() {
  const [userDataState, setUserDataState] = useState(initialState)
  const { token, setToken } = useToken();
  const location = window.location;

  const isLoggedIn = !!token; // Check if token exists


  useEffect(() => {
    // Solicitar permissão para notificações do navegador ao carregar a página
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  async function fetchUserData() {
    let dadosUsuario = await getUserData();
    let dadosPermissoes = await getPermissions();

    setUserDataState(prevState => ({
      ...prevState,
      nome: dadosUsuario?.data?.data?.nome,
      email: dadosUsuario?.data?.data?.email,
      login: dadosUsuario?.data?.data?.login,
      cliente: dadosUsuario?.data?.data?.cliente,
      id_contrato: dadosUsuario?.data?.data?.id_contrato || '',
      permissoes: dadosPermissoes?.data?.data?.permissions,
      id_usuario: parseInt(dadosUsuario?.data?.data?.id),
      url_foto_perfil: `${dadosUsuario?.data?.data?.url_foto_perfil || defaultUserImage}?v=${Date.now()}`,
      atualizar_qtd_faturamento: true,
      type: 'interno'
    }));
  }

  useEffect(() => {
    if (location.pathname.includes('/external-chat')) {
      localStorage.clear();
      return;
    }

    if (isLoggedIn) {
      fetchUserData();
    }

  }, [isLoggedIn, location.pathname]);

  return (
    <Context.Provider value={{ userDataState, setUserDataState }}>
      <div className="wrapper">
        <If condition={location.pathname.includes('/external-chat')}>
          <BrowserRouter>
            <Routes>
              <Route path='/external-chat/:id' element={<ExternalChat></ExternalChat>}></Route>
            </Routes>
            <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
          </BrowserRouter>
        </If>

        <If condition={(!isLoggedIn && !location.pathname.includes('/external-chat'))}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Login setToken={setToken}></Login>}></Route>
              <Route path='/login' element={<Login setToken={setToken}></Login>}></Route>
              <Route path='*' element={<Login setToken={setToken}></Login>}></Route>
            </Routes>
            <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
          </BrowserRouter>
        </If>

        <If condition={(isLoggedIn && !location.pathname.includes('/external-chat'))}>
          <BrowserRouter>
            <Header setToken={setToken}></Header>
            <SideNav></SideNav>
            <MainRoutes></MainRoutes>
            <Footer></Footer>
            <ToastContainer position='top-right' theme='dark' newestOnTop autoClose={1500} closeOnClick></ToastContainer>
          </BrowserRouter>
        </If>
      </div>
    </Context.Provider>
  );
}

export default App;