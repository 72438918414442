import React, { useEffect, useState } from 'react';
import Modal from '../../../../../common/Layout/Modal';
import { toast } from 'react-toastify';
import { approvePartnerValuesById, getPartnerValuesById, updatePartnerValueById } from '../../../../../services/PartnerValues';
import IntegradoraSelect from '../../../../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../../../../common/Selects/ContratosSelect';
import ValoresContratosSelect from '../../../../../common/Selects/ValoresContratosSelect';
import { getContractValuesById } from '../../../../../services/ContractValues';
import If from '../../../../../common/Layout/If';
import GenericSelect from '../../../../../common/Selects/GenericSelect';

export default function PartnerValuesDetails({ isOpen = false, setModalOpen = null, id_valor_representante = '', updateParent = null, mode = 'update' }) {
    const INITIAL_STATE = {
        id_contrato: '',
        integradora: '',
        id_representante: '',
        id_contrato_valor_comercial: '',
        valor_chamado: '',
        valor_fora_hr_comercial: '',
        valor_chamado_improdutivo: '',
        valor_ha_diurna: '',
        valor_ha_noturna: '',
        valor_km_apos_51: '',
        observacoes: '',
        aprovado: '',
        descricao_reprovado: '',
        grupo: '',
        descricao_valor_alto: ''
    }

    const [formValoresTabela, setFormValoresTabela] = useState(INITIAL_STATE);
    const [originalData, setOriginalData] = useState(INITIAL_STATE);
    const [openValueDescription, setOpenValueDescription] = useState(false);
    const fields = ['valor_chamado', 'valor_fora_hr_comercial', 'valor_chamado_improdutivo', 'valor_ha_diurna', 'valor_ha_noturna', 'valor_km_apos_51'];

    async function getPartnerValuesData() {
        const toastValues = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getPartnerValuesById(id_valor_representante);

        if (dataReturn?.data?.response === 'success') {
            let valoresTabelaData = dataReturn?.data?.data;
            let valoresContratoData = dataReturn?.data?.data?.contract_value;

            setFormValoresTabela(prevState => ({
                ...prevState,
                ...valoresTabelaData,
                integradora: { value: dataReturn?.data?.data?.contract_value?.contract?.integrator?.id || '', label: dataReturn?.data?.data?.contract_value?.contract?.integrator?.nome || '' },
                id_contrato: { value: dataReturn?.data?.data?.contract_value?.contract?.id || '', label: dataReturn?.data?.data?.contract_value?.contract?.contrato || '' },
                id_contrato_valor_comercial: { value: dataReturn?.data?.data?.contract_value?.id || '', label: dataReturn?.data?.data?.contract_value?.escopo || '' },
                escopo: dataReturn?.data?.data?.contract_value?.escopo || '',
                aprovado: {value: dataReturn?.data?.data?.aprovado || '', label: dataReturn?.data?.data?.aprovado || ''}
            }));

            setOriginalData(prevState => ({
                ...prevState,
                ...valoresContratoData,
                integradora: { value: dataReturn?.data?.data?.contract_value?.contract?.integrator?.id || '', label: dataReturn?.data?.data?.contract_value?.contract?.integrator?.nome || '' },
                id_contrato: { value: dataReturn?.data?.data?.contract_value?.contract?.id || '', label: dataReturn?.data?.data?.contract_value?.contract?.contrato || '' },
                id_contrato_valor_comercial: { value: dataReturn?.data?.data?.contract_value?.id || '', label: dataReturn?.data?.data?.contract_value?.escopo || '' },
                escopo: valoresContratoData?.escopo || '',
                aprovado: {value: dataReturn?.data?.data?.aprovado || '', label: dataReturn?.data?.data?.aprovado || ''}
            }));

            toast.update(toastValues, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            for (let f of fields) {
                if (valoresTabelaData[f] > valoresContratoData[f]) {
                    let validationMode = valoresTabelaData[f] > (valoresContratoData[f] * 1.1) ? 'diretoria' : 'gerente_operacoes';
                    setOpenValueDescription(true);
                    setFormValoresTabela(prevState => ({ ...prevState, grupo: validationMode }));
                    return;
                }
            }

            setOpenValueDescription(false);

        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }
    }

    function submitForm(e) {
        e.preventDefault();

        if (mode === 'update') {
            updateData();
        }

        if (mode === 'approve') {
            approveData();
        }
    }

    async function approveData() {
        const toastValues = toast.loading('Atualizando status, aguarde...');

        let params = {
            ...formValoresTabela,
            id_contrato_valor_comercial: formValoresTabela?.id_contrato_valor_comercial?.value || '',
            aprovado: formValoresTabela?.aprovado?.value || '',
            descricao_reprovado: formValoresTabela?.aprovado?.value === 'aprovado' ? '' : formValoresTabela?.descricao_reprovado 
        }

        let dataReturn = await approvePartnerValuesById(id_valor_representante, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastValues, { render: 'Status atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            updateParent();
            setModalOpen(false);
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao atualizar os status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function updateData() {
        const toastValues = toast.loading('Atualizando dados, aguarde...');
        
        let params = {
            ...formValoresTabela,
            id_contrato_valor_comercial: formValoresTabela?.id_contrato_valor_comercial?.value || ''

        }

        delete params.aprovado;

        let dataReturn = await updatePartnerValueById(id_valor_representante, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastValues, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormValoresTabela(prevState => ({...prevState, id: dataReturn?.data?.data?.id}));
            updateParent();
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getContractValuesData(id) {

        let dataReturn = await getContractValuesById(id);
        if (dataReturn?.data?.response === 'success') {
            setFormValoresTabela(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                id_contrato: formValoresTabela?.id_contrato,
            }));
            setOriginalData(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                id_contrato: formValoresTabela?.id_contrato,
            }));
            setOpenValueDescription(false);
        } else {
            toast.warning("Ocorreu um erro ao carregar os dados dos valores de contrato!");
        }
    }

    async function handleAdvancedSetFormData(value, name) {
        setFormValoresTabela(prevState => ({ ...prevState, [name]: value }));

        if (name === 'id_contrato_valor_comercial') {
            getContractValuesData(value.value);
        }

        if (name === 'integradora') {
            setFormValoresTabela(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora }));
            setOriginalData(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora }));
        }

        if (name === 'id_contrato') {
            setFormValoresTabela(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora, id_contrato: prevState?.id_contrato }));
            setOriginalData(prevState => ({ ...INITIAL_STATE, integradora: prevState?.integradora, id_contrato: prevState?.id_contrato }));
        }
    }

    function handleValues(value, name) {
        let valueDescription = enableValueDescription(value);
        setFormValoresTabela(prevState => ({ ...prevState, [name]: value, grupo: valueDescription, descricao_valor_alto: valueDescription === '' ? '' : prevState?.descricao_valor_alto }));
    }

    function enableValueDescription(value) {
        for (let f of fields) {
            if (value > originalData[f]) {
                let validationMode = value > (originalData[f] * 1.1) ? 'diretoria' : 'gerente_operacoes';
                setOpenValueDescription(true);
                return validationMode;
            }
        }

        setOpenValueDescription(false);
        return '';
    }


    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(id_valor_representante)) {
            getPartnerValuesData();
        }

        if (!isOpen) {
            setFormValoresTabela(INITIAL_STATE);
            setOriginalData(INITIAL_STATE);
        }
    }, [isOpen, id_valor_representante]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title={mode === 'update' ? 'Atualizar Valores Representante' : 'Aprovar Valores'}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Valores Representante</h5>
                    </div>
                    <IntegradoraSelect formData={formValoresTabela} setFormData={setFormValoresTabela} cols={[12, 12, 4, 4]} fieldName='integradora' required={true} advancedSetFormData={handleAdvancedSetFormData} isDisabled={mode === 'approve'}></IntegradoraSelect>
                    <ContratosSelect formData={formValoresTabela} setFormData={setFormValoresTabela} cols={[12, 12, 4, 4]} dependsOnIntegradora={true} fieldName='id_contrato' required={true} advancedSetFormData={handleAdvancedSetFormData} isDisabled={mode === 'approve'} id_integradora_string={[formValoresTabela?.integradora?.value].toString()}></ContratosSelect>
                    <ValoresContratosSelect formData={formValoresTabela} setFormData={setFormValoresTabela} cols={[12, 12, 4, 4]} fieldName='id_contrato_valor_comercial' id_contrato={formValoresTabela?.id_contrato?.value || ''} required={true} advancedSetFormData={handleAdvancedSetFormData} isDisabled={mode === 'approve'}></ValoresContratosSelect>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="escopo">Escopo</label>
                        <input type="text" name="escopo" id="escopo" className="form-control" value={formValoresTabela?.escopo || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={true} placeholder='...' />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <label htmlFor="observacoes">Observações Diversas</label>
                        <input type="text" name="observacoes" id="observacoes" className="form-control" value={formValoresTabela?.observacoes || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} placeholder='...' />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_chamado">R$ Chamado (R$ {originalData?.valor_chamado || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado" id="valor_chamado" className='form-control' value={formValoresTabela?.valor_chamado || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} readOnly={mode === 'approve'} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_fora_hr_comercial">Valor Fora Hrs Comercial (R$ {originalData?.valor_fora_hr_comercial || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_fora_hr_comercial" id="valor_fora_hr_comercial" className='form-control' value={formValoresTabela?.valor_fora_hr_comercial || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} readOnly={mode === 'approve'} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_chamado_improdutivo">Chamado Improdutivo (R$ {originalData?.valor_chamado_improdutivo || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado_improdutivo" id="valor_chamado_improdutivo" className='form-control' value={formValoresTabela?.valor_chamado_improdutivo || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} readOnly={mode === 'approve'} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_ha_diurna">H.A (Diurna) Após 3h31m (R$ {originalData?.valor_ha_diurna || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_diurna" id="valor_ha_diurna" className='form-control' value={formValoresTabela?.valor_ha_diurna || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} readOnly={mode === 'approve'} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_ha_noturna">H.A (Noturna) Após 3h31m (R$ {originalData?.valor_ha_noturna || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_noturna" id="valor_ha_noturna" className='form-control' value={formValoresTabela?.valor_ha_noturna || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} readOnly={mode === 'approve'} required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_km_apos_51">R$ KM Após 51 Km (R$ {originalData?.valor_km_apos_51 || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_km_apos_51" id="valor_km_apos_51" className='form-control' value={formValoresTabela?.valor_km_apos_51 || ''} placeholder='...' onChange={(e) => handleValues(e.target.value, e.target.name)} readOnly={mode === 'approve'} required={true} />
                    </div>
                    <If condition={openValueDescription}>
                        <div className="col-12">
                            <label htmlFor="descricao_valor_alto">Motivo Alteração dos Valores</label>
                            <textarea name="descricao_valor_alto" id="descricao_valor_alto" rows="10" className="form-control" value={formValoresTabela?.descricao_valor_alto || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={mode === 'approve'} required={true}></textarea>
                        </div>
                    </If>
                    <If condition={mode === 'approve'}>
                        <GenericSelect formData={formValoresTabela} setFormData={setFormValoresTabela} fieldName={'aprovado'} cols={[12, 12, 12, 12]} title='Status Aprovação' genericOption='AprovadoValorComercialOptions' required={true}></GenericSelect>
                        <If condition={formValoresTabela?.aprovado?.value === 'reprovado'}>
                            <div className="col-12">
                                <label htmlFor="descricao_reprovado">Motivo da Reprovação</label>
                                <textarea name="descricao_reprovado" id="descricao_reprovado" rows="10" className='form-control' value={formValoresTabela?.descricao_reprovado} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required={true}></textarea>
                            </div>
                        </If>
                    </If>
                    <div className="col-12 d-flex align-items-center mt-1">
                        <button type="submit" className='btn btn-primary'>Atualizar</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}