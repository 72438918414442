import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllUnproductiveOptions } from '../../services/Ticket';

export default function MotivosImprodutivosSelect({ formData, setFormData, fieldName = 'motivo_improdutivo', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [unproductiveOptions, setUnproductiveOptions] = useState([]);

    const getUnproductiveOptions = async () => {
        let dataReturn = await getAllUnproductiveOptions();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let unproductiveData = dataReturn?.data?.data;

            let unproductiveArray = unproductiveData.map((item, idx) => {
                return ({ value: item.descricao, label: item.descricao })
            });

            setUnproductiveOptions(unproductiveArray);
        } else {
            setUnproductiveOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getUnproductiveOptions();
    }, [])

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>Motivo Improdutivo</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={unproductiveOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}