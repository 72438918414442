import React from "react";

function Footer() {
    return (
        <footer className="main-footer mt-1">
            <strong>Maminfo Soluções</strong>
            <div className="float-right d-none d-sm-inline-block">
                <b>Versão</b> 2.0
            </div>
        </footer>
    );
}

export default Footer;