import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getExpenseTypes } from '../../services/ExpenseType';

export default function TiposDespesasSelect({ formData, setFormData, fieldName = 'tipo_despesa', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [costOptions, setCostOptions] = useState([]);

    async function getAllCostsTypes(){
        let dataReturn = await getExpenseTypes();

        if(dataReturn?.data?.response === 'success'){
            let tipoDespesa = dataReturn?.data?.data?.map((item, idx)=>({value: item.descricao, label: item.descricao})) || [];

            setCostOptions(tipoDespesa);
        }else{
            setCostOptions([]);
        }
    }


    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getAllCostsTypes();
    }, [])

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>Tipos de Despesas</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}                
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={costOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}