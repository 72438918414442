import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getProductsByContractId } from '../../services/Contracts';
import { getAvailableProducts } from '../../services/Product';

export default function ProdutosSelect({ formData, setFormData, fieldName = 'produtos', title='Produtos', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, dependsOnContrato = false, idContrato = '', reRender = false }) {
    const [productsOptions, setProductsOptions] = useState([]);
    async function getProductsByContract(id) {
        let dataReturn = await getProductsByContractId(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let products = dataReturn.data.data.map((item, idx) => {
                return ({ value: item.id, label: item.nome_produto });
            });
            setProductsOptions(products);
        } else {
            setProductsOptions([]);
        }
    }

    async function getAllAvailableProducts() {
        let dataReturn = await getAvailableProducts();

        if (dataReturn?.data?.response === "success" && dataReturn?.data?.data.length > 0) {
            let products = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.nome_produto }
            });
            setProductsOptions(products);
        } else {
            setProductsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if(dependsOnContrato){
            if(idContrato !== ''){
                getProductsByContract(idContrato);
            }
        }else{
            getAllAvailableProducts();
        }
    }, [dependsOnContrato, idContrato, reRender]);

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>{title}</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={productsOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}