import React from 'react';
import { getDataByCep } from '../../services/ViaCep';
import { toast } from 'react-toastify';
import If from '../Layout/If';

export default function Cep({
    formDataNames = {
        'cep': 'cep',
        'estado': 'estado',
        'cidade': 'cidade',
        'bairro': 'bairro',
        'logradouro': 'logradouro',
        'complemento': 'complemento'
    },
    formData,
    setFormData,
    readOnly = false,
    required = false,
    hasComplemento = false
}) {

    async function getCepData(cep, campos = {}) {
        let dataReturn = await getDataByCep(cep);
        if (dataReturn?.data?.cep) {
            let data = dataReturn.data

            for (let d in data) {
                if (campos[d] !== undefined) {
                    setFormData(prevState => ({ ...prevState, [campos[d]]: data[d] }))
                }
            }
        } else {
            toast.warning('CEP não encontrado! Por favor, digite um CEP válido.');
            for (let c in campos) {
                setFormData(prevState => ({ ...prevState, [campos[c]]: '' }));
            }
        }
    }

    function handleCepField(e) {
        const { value } = e.target;
        const filteredValue = value.replace(/[^0-9]/g, '');
        e.target.value = filteredValue;
        setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

        //chave = campo da api
        //valor = campo do formulario
        let cepFields = { 'uf': formDataNames['estado'], 'localidade': formDataNames['cidade'], 'bairro': formDataNames['bairro'], 'logradouro': formDataNames['logradouro'] };

        if (value.length === 8) {
            getCepData(value, cepFields);
        }

        if (value.length < 8) {
            for (let c in cepFields) {
                setFormData(prevState => ({ ...prevState, [cepFields[c]]: '' }));
            }
        }
    }

    return (
        <div className="row">
            <div className="col-md-5 col-sm-12">
                <label htmlFor="cep">CEP (Apenas Números)</label>
                <input type="text" name={formDataNames['cep']} className='form-control' id={formDataNames['cep']} minLength={0} maxLength={8} value={formData[formDataNames['cep']] || ''} onChange={handleCepField} placeholder='...' readOnly={readOnly} required={required} />
            </div>
            <div className="col-md-2 col-sm-12">
                <label htmlFor="estado">Estado</label>
                <input type="text" name={formDataNames['estado']} className='form-control' id={formDataNames['estado']} placeholder='...' value={formData[formDataNames['estado']] || ''} readOnly />
            </div>
            <div className="col-md-5 col-sm-12">
                <label htmlFor="cidade">Cidade</label>
                <input type="text" name={formDataNames['cidade']} className='form-control' id={formDataNames['cidade']} placeholder='...' value={formData[formDataNames['cidade']] || ''} readOnly />
            </div>
            <div className={`${hasComplemento ? "col-md-4" : "col-md-6"} col-sm-12`}>
                <label htmlFor="bairro">Bairro</label>
                <input type="text" name={formDataNames['bairro']} className='form-control' id={formDataNames['bairro']} placeholder='...' value={formData[formDataNames['bairro']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={required} />
            </div>
            <div className={`${hasComplemento ? "col-md-4" : "col-md-6"} col-sm-12`}>
                <label htmlFor="logradouro">Logradouro</label>
                <input type="text" name={formDataNames['logradouro']} className='form-control' id={formDataNames['logradouro']} placeholder='...' value={formData[formDataNames['logradouro']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={required} />
            </div>
            <If condition={hasComplemento}>
                <div className="col-md-4 col-sm-12">
                    <label htmlFor="complemento">Número / Compl. / Referência / Obs</label>
                    <input type="text" className="form-control" name={formDataNames["complemento"]} id={formDataNames["complemento"]} value={formData[formDataNames['complemento']] || ''} onChange={(e) => (setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={readOnly} required={required} />
                </div>
            </If>
        </div >
    )
}