import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../../common/Layout/Modal';
import { getPartnerValuesById } from '../../../../../services/PartnerValues';
import ChamadosSelect from '../../../../../common/Selects/ChamadosSelect';
import { createSpecialValue } from '../../../../../services/SpecialPartnerValues';
import useGenerics from '../../../../../hooks/useGenerics';

export default function CreateSpecialPartnerValue({ isOpen = false, setModalOpen = null, id_valor_representante = '', updateParent = null }) {
    const INITIAL_STATE = {
        id_contrato: '',
        integradora: '',
        id_representante: '',
        id_contrato_valor_comercial: '',
        valor_chamado: '',
        valor_fora_hr_comercial: '',
        valor_chamado_improdutivo: '',
        valor_ha_diurna: '',
        valor_ha_noturna: '',
        valor_km_apos_51: '',
        observacoes: '',
        aprovado: '',
        descricao_reprovado: '',
        grupo: '',
        num_chamado: '',
        motivo_valores_especiais: ''
    }

    const [formValoresTabela, setFormValoresTabela] = useState(INITIAL_STATE);
    const [originalData, setOriginalData] = useState(INITIAL_STATE);
    const { getCurrentDate } = useGenerics();

    async function getPartnerValuesData() {        
        let dataReturn = await getPartnerValuesById(id_valor_representante);
        if (dataReturn?.data?.response === 'success') {
            setFormValoresTabela(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                id_contrato: formValoresTabela?.id_contrato,
                motivo_valores_especiais: ''
            }));

            setOriginalData(prevState => ({
                ...prevState,
                ...dataReturn?.data?.data,
                id_contrato: formValoresTabela?.id_contrato,
                motivo_valores_especiais: ''
            }));
        } else {
            toast.warning("Ocorreu um erro ao carregar os dados dos valores de contrato!");
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        const toastValues = toast.loading('Cadastrando valores especiais, aguarde...');

        let params = {
            ...formValoresTabela,
            observacoes: formValoresTabela?.motivo_valores_especiais,
            id_chamado: formValoresTabela?.num_chamado?.value || '',
            valor_improdutivo: formValoresTabela?.valor_chamado_improdutivo,
            id_valor_representante: id_valor_representante
        }

        let dataReturn = await createSpecialValue(params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastValues, { render: 'Valores Especiais cadastrados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            updateParent();
            setModalOpen(false);
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao cadastrar os valores especiais!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function handleValues(value, name) {
        let valueDescription = enableValueDescription(value);
        setFormValoresTabela(prevState => ({ ...prevState, [name]: value, grupo: valueDescription, descricao_valor_alto: valueDescription === '' ? '' : prevState?.descricao_valor_alto }));
    }

    function enableValueDescription(value) {
        let fields = ['valor_chamado', 'valor_fora_hr_comercial', 'valor_chamado_improdutivo', 'valor_ha_diurna', 'valor_ha_noturna', 'valor_km_apos_51'];

        for (let f of fields) {
            if (value > originalData[f]) {
                let validationMode = value > (originalData[f] * 1.1) ? 'diretoria' : 'gerente_operacoes';
                return validationMode;
            }
        }

        return 'gerente_operacoes';
    }

    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(id_valor_representante)) {
            getPartnerValuesData();
        }

        if (!isOpen) {
            setFormValoresTabela(INITIAL_STATE);
        }
    }, [isOpen, id_valor_representante]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title='Cadastrar Valores Especiais'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <ChamadosSelect formData={formValoresTabela} setFormData={setFormValoresTabela} fieldName='num_chamado' required={true} data_inicial={getCurrentDate(0, 0, 0)} data_final={getCurrentDate(0, 2, 0)}></ChamadosSelect>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_chamado">R$ Chamado (R${originalData?.valor_chamado || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado" id="valor_chamado" className='form-control' value={formValoresTabela?.valor_chamado || ''} onChange={(e)=>handleValues(e.target.value, e.target.name)} placeholder='...' required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_fora_hr_comercial">Valor Fora Hrs Comercial (R${originalData?.valor_fora_hr_comercial || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_fora_hr_comercial" id="valor_fora_hr_comercial" className='form-control' value={formValoresTabela?.valor_fora_hr_comercial || ''} onChange={(e)=>handleValues(e.target.value, e.target.name)} placeholder='...' required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_chamado_improdutivo">Chamado Improdutivo (R${originalData?.valor_chamado_improdutivo || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_chamado_improdutivo" id="valor_chamado_improdutivo" className='form-control' value={formValoresTabela?.valor_chamado_improdutivo || ''} onChange={(e)=>handleValues(e.target.value, e.target.name)} placeholder='...' required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_ha_diurna">H.A (Diurna) Após 3h31m (R${originalData?.valor_ha_diurna || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_diurna" id="valor_ha_diurna" className='form-control' value={formValoresTabela?.valor_ha_diurna || ''} onChange={(e)=>handleValues(e.target.value, e.target.name)} placeholder='...' required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_ha_noturna">H.A (Noturna) Após 3h31m (R${originalData?.valor_ha_noturna || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_ha_noturna" id="valor_ha_noturna" className='form-control' value={formValoresTabela?.valor_ha_noturna || ''} onChange={(e)=>handleValues(e.target.value, e.target.name)} placeholder='...' required={true} />
                    </div>
                    <div className="col-md-12 col-lg-2">
                        <label htmlFor="valor_km_apos_51">R$ KM Após 51 Km (R${originalData?.valor_km_apos_51 || '0,00'})</label>
                        <input type="number" min={"0"} step={".01"} name="valor_km_apos_51" id="valor_km_apos_51" className='form-control' value={formValoresTabela?.valor_km_apos_51 || ''} onChange={(e)=>handleValues(e.target.value, e.target.name)} placeholder='...' required={true} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="motivo_valores_especiais">Motivo dos Valores Especiais</label>
                        <textarea name="motivo_valores_especiais" id="motivo_valores_especiais" rows="10" className="form-control" value={formValoresTabela?.motivo_valores_especiais || ''} onChange={(e) => setFormValoresTabela(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true}></textarea>
                    </div>
                    <div className="col-12 d-flex align-items-center mt-1">
                        <button type="submit" className='btn btn-primary'>Cadastrar</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}