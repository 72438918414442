import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllIntegrators } from '../../services/Integrators';

export default function IntegradoraSelect({ formData, setFormData, fieldName = 'integradora', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, advancedSetFormData=null }) {
    const [integratorsOptions, setIntegratorsOptions] = useState([]);

    async function getIntegrators() {
        let dataReturn = await getAllIntegrators();

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let integrators = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.nome }
            });
            setIntegratorsOptions(integrators);
        } else {
            setIntegratorsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        getIntegrators();
    }, [])

    return (
        <div className={cols.length > 0 ? `col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}` : 'col'}>
            <label htmlFor={fieldName}>Integradora</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={[{value: '', label: 'Selecione...'}, ...integratorsOptions]}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}