import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Layout/Modal';
import { getShipmentById, updateInPersonDelivery } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import ReactSignatureCanvas from 'react-signature-canvas';
import { withMask } from 'use-mask-input';
import useGenerics from '../../../../hooks/useGenerics';

export default function SignDetails({ isOpen = false, setModalOpen = null, setUpdateProducts = null, awaitingWithdrawId = '', setUpdateLogistic = null }) {

    const INITIAL_STATE = {
        nome_recebedor: '',
        cpf_recebedor: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [sign, setSign] = useState(null);
    const [url, setUrl] = useState('');
    const {base64toBlob} = useGenerics();

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(awaitingWithdrawId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let shipmentData = dataReturn?.data?.data;

            setFormData(prevState => ({
                ...prevState,
                nome_produto: shipmentData?.product?.nome_produto || '',
                serial: shipmentData?.product?.serial || '',
                modelo: shipmentData?.product?.modelo || '',
            }));
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setModalOpen(false);
        }
    }

    function handleClear(){
        sign.clear();
        setUrl("");
    }

    async function submitForm(e){
        e.preventDefault();

        if(['', undefined, null].includes(url)){
            toast.warning("Por favor, assine o documento!");
            return;
        }

        let blob = base64toBlob(url.split(",")[1], 'image/png');

        let params = new FormData();

        params.append('assinatura_recebedor', blob, blob?.type.replace('/', '.'));
        params.append('nome_recebedor', formData?.nome_recebedor);
        params.append('cpf_recebedor', formData?.cpf_recebedor);

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updateInPersonDelivery(awaitingWithdrawId, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setUpdateProducts();
            setModalOpen(false);
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(()=>{
        if(isOpen && !['', undefined, null].includes(awaitingWithdrawId)){
            setFormData(INITIAL_STATE);
            getShipment();
        }
    }, [isOpen, awaitingWithdrawId]);

    return (<Modal
        isOpen={isOpen}
        setModalOpen={setModalOpen}
        title='Retirada em Mãos'
        customStyle={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
        }}
    >
        <form onSubmit={submitForm}>
            <div className="row">
                <div className="col-12">
                    <h5>Confirmar entrega da remessa:</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 mt-1">
                    <label htmlFor="">Nome Produto</label>
                    <input type="text" name="nome_produto" id="nome_produto" className="form-control" placeholder='Nome Produto' value={formData?.nome_produto || ''} readOnly />
                </div>
                <div className="col-md-4 col-sm-12 mt-1">
                    <label htmlFor="">Serial</label>
                    <input type="text" name="serial" id="serial" className="form-control" placeholder='Serial Produto' value={formData?.serial || ''} readOnly />
                </div>
                <div className="col-md-4 col-sm-12 mt-1">
                    <label htmlFor="">Modelo</label>
                    <input type="text" name="modelo" id="modelo" className="form-control" placeholder='Modelo Produto' value={formData?.modelo || ''} readOnly />
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <label htmlFor="nome_recebedor">Nome Completo</label>
                    <input type="text" name="nome_recebedor" id="nome_recebedor" className="form-control" placeholder='Nome Completo' value={formData?.nome_recebedor || ''} onChange={(e)=>setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))} required/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <label htmlFor="cpf_recebedor">CPF</label>
                    <input type="text" name="cpf_recebedor" id="cpf_recebedor" ref={withMask(['999.999.999-99'])} className="form-control" placeholder='CPF' value={formData?.cpf_recebedor || ''} onChange={(e)=>setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))} required/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-1">
                    <label htmlFor="">Assinatura</label>
                </div>
                <div className="col-12 m-1" style={{backgroundColor: '#E9ECEF', padding: '10px', borderRadius: '15px'}}>
                    <ReactSignatureCanvas 
                        penColor='black'
                        minWidth={1} 
                        canvasProps={{className: 'sigCanvas', style: {width: '100%', height: '250px', borderRadius: '10px'}}}
                        backgroundColor='rgb(255, 255, 255)'
                        ref={(data)=>setSign(data)}
                        onEnd={()=>setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))}
                    ></ReactSignatureCanvas>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-1">
                    <button type="button" className="btn btn-warning m-1" onClick={handleClear}>Limpar Assinatura</button>
                    <button type="submit" className="btn btn-primary m-1">Confirmar Recebimento</button>
                </div>
            </div>
        </form>
    </Modal>)

}