import React, { useContext, useEffect, useRef, useState } from 'react';
import { SubHeaders } from '../../../configs/MenuItemsConfigs';

import { Link, useLocation } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';
import { countShipmentByStatus } from '../../../services/Shipment';
import { Context } from '../../../App';

export default function SubHeaderSpecific({ subHeaderConfig = 'retirada', updateLogistic = false, setUpdateLogistic = null }) {
    const userData = useContext(Context);
    const location = useLocation();
    const [currentPath] = useState(location.pathname);
    const { checkPermissionGroup } = usePermissions();
    const [menuButtons, setMenuButtons] = useState([]);
    const subHeadersConfigs = SubHeaders.find((item) => item.id === subHeaderConfig);
    const milliseconds = 300000;
    // const milliseconds = 10000;
    let interval;

    async function renderSubHeader() {
        let productsCount = {};

        if (subHeaderConfig === 'mam_logistica') {
            let dataReturn = await countShipmentByStatus();
            productsCount = dataReturn?.data?.data || {};
        }

        let menuButtons = subHeadersConfigs?.subheaders.map((item, idx) => {
            if (checkPermissionGroup(item.permission_group)) {
                return (<Link to={item.path} className={`ml-1 mt-1 btn btn-sm btn-primary ${currentPath.includes(item.path.split("?")[0]) ? 'active' : ''}`} key={idx}>{item.name} {subHeaderConfig === 'mam_logistica' && productsCount?.[item?.count_name] ? <>&nbsp;<span className='badge badge-warning float-right'>{productsCount?.[item?.count_name]}</span></> : ''}</Link>)
            }
            return null;
        });

        setMenuButtons(menuButtons);
    }

    useEffect(() => {
        renderSubHeader();

        if (subHeaderConfig === 'mam_logistica') {
            interval = setInterval(() => {
                renderSubHeader();
            }, milliseconds);

            return () => {
                clearInterval(interval);
            }
        }

    }, [userData?.userDataState?.permissoes]);

    useEffect(() => {
        if(updateLogistic === true && subHeaderConfig === "mam_logistica"){
            renderSubHeader();
            setUpdateLogistic(false);
        }
    }, [updateLogistic, subHeaderConfig]);

    return (
        <div className='card'>
            <div className="card-body">
                {menuButtons}
            </div>
        </div>
    )
}