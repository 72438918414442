import axios from "axios";
import interceptorInstace from './Interceptor';

const api = process.env.REACT_APP_API_MAMERP || "http://localhost:8000/api/";
const authApi = api + "auth/"

export async function login(credenciais){
    try{
        let dados = await axios.post(authApi + "login", credenciais);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function logout(){
    try{
        let dados = await interceptorInstace.get("auth/logout");
        return dados;
    }catch(e){
        return e;
    }
}

export async function getNewToken(){
    try{
        let dados = await interceptorInstace.get("auth/refresh");
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function chatLogin(credenciais){
    try{
        let dados = await axios.post(authApi + "chat-login", credenciais);
        return dados;
    }catch(e){
        return e.response;
    }
}

