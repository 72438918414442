import interceptorInstance from "./Interceptor";

export async function getRouteByTicketId(id){
    try{
         let data = await interceptorInstance.get('app-route/' + id);
         return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllRoutesOpen(){
    try{
        let data = await interceptorInstance.get(`app-route/all-open`);
        return data;
    }catch(e){
        return e.response;
    }
}