import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../common/Layout/Card';
import { Context } from '../../../App';
import Table from '../../../common/Layout/Table/Table';
import { toast } from 'react-toastify';
import { addEquipmentByTicketId, getEquipmentsByTicketIdAndStatus, getShippingModeData, removeEquipmentByIds, updateEquipmentStatus } from '../../../services/Ticket';
import ProdutosSelect from '../../../common/Selects/ProdutosSelect';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getProductByProductId } from '../../../services/Product';
import Select from 'react-select';
import { getOptionsByModuleField } from '../../../services/Options';
import If from '../../../common/Layout/If';

export default function EquipamentoRemessaCard({
    showUpdateBtn = false,
    detailsData = {},
    originalData = {},
    getHistory = null,
    id = '',
    showAddEquipamentoRemessa = false,
    setShowAddEquipamentoRemessa = null,
    showUpdateEquipamentoRemessa = false,
    setShowUpdateEquipamentoRemessa = null
}) {

    const initialEquipamentoRemessaState = {
        produto_estoque_select: '',
        produto_contrato_select: '',
    }

    const userData = useContext(Context);
    const [equipmentsData, setEquipmentsData] = useState([]);
    const [statusProdutoOptions, setStatusProdutoOptions] = useState([]);
    const [equipamentoRemessaData, setEquipamentoRemessaData] = useState(initialEquipamentoRemessaState);
    const [modalEnvioOptions, setModalEnvioOptions] = useState([]);

    const mySwal = withReactContent(Swal);

    const equipmentsColumns = [
        {
            id: 'action_equipamento_remessa',
            name: 'Ação',
            sortable: true,
            wrap: true,
            cell: (row) => (
                showUpdateEquipamentoRemessa &&
                (<div className="align-items-center">
                    <button className='btn btn-secondary' onClick={() => deleteEquipment(row)}><i className='fas fa-trash'></i></button>
                </div>)
            )
        },
        {
            id: 'part_number_equipamento_remessa',
            name: 'Part Number',
            sortable: true,
            wrap: true,
            selector: row => row.part_number_equipamento_remessa
        },
        {
            id: 'serial_equipamento_remessa',
            name: 'Serial',
            sortable: true,
            wrap: true,
            selector: row => row.serial_equipamento_remessa
        },
        {
            id: 'status_produto_equipamento_remessa',
            name: 'Status Produto',
            sortable: true,
            wrap: true,
            selector: row => row.status_produto_equipamento_remessa
        },
        {
            id: 'status_produto_final_equipamento_remessa',
            name: 'Status Produto Final',
            sortable: true,
            wrap: true,
            cell: (row) => (
                showUpdateEquipamentoRemessa ?
                    (<div className='align-items-center'>
                        <Select
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            name='status_produto_final_equipamento_remessa_select'
                            placeholder="Selecione uma opção..."
                            value={statusProdutoOptions.find((e => e.label === row.status_produto_final_equipamento_remessa)) || ''}
                            onChange={(e) => updateEquipmentData(row, 'status_produto', e?.value)}
                            options={statusProdutoOptions}
                            className='w-100'
                        ></Select>
                    </div>)
                    :
                    (<>
                        {row.status_produto_final_equipamento_remessa}
                    </>)
            )
        },
        {
            id: 'modal_envio_equipamento_remessa',
            name: 'Modal Envio',
            sortable: true,
            wrap: true,
            cell: (row) => (
                showUpdateEquipamentoRemessa ?
                    (<div className='align-items-center'>
                        <Select
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            name='modal_envio_equipamento_remessa_select'
                            placeholder="Selecione uma opção..."
                            value={modalEnvioOptions.find((e => e.label === row.modal_envio_equipamento_remessa)) || ''}
                            onChange={(e) => updateEquipmentData(row, 'modal_envio', e?.value)}
                            options={modalEnvioOptions}
                            className='w-100'
                        ></Select>
                    </div>)
                    :
                    (<>
                        {row.modal_envio_equipamento_remessa}
                    </>)
            )
        },
        {
            id: 'data_postagem_equipamento_remessa',
            name: 'Data da Postagem',
            sortable: true,
            wrap: true,
            selector: row => row.data_postagem_equipamento_remessa
        },
        {
            id: 'rastreamento_equipamento_remessa',
            name: 'Rastreamento',
            sortable: true,
            wrap: true,
            selector: row => row.rastreamento_equipamento_remessa
        },
        {
            id: 'status_transportadora_equipamento_remessa',
            name: 'Status Transportadora',
            sortable: true,
            wrap: true,
            selector: row => row.status_transportadora_equipamento_remessa
        },
        {
            id: 'prazo_entrega_equipamento_remessa',
            name: 'Prazo de Entrega',
            sortable: true,
            wrap: true,
            selector: row => row.prazo_entrega_equipamento_remessa
        }
    ];

    async function deleteEquipment(rowData, status = 'Remessa') {

        let formData = {
            id_remessa: rowData.id_remessa,
            id_produto: rowData.id_produto,
            id_equipamento_chamado: rowData.id_equipamento_chamado
        }

        const deleteToast = toast.loading("Removendo produto do chamado, aguarde...");

        let dataReturn = await removeEquipmentByIds(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(deleteToast, { render: dataReturn?.data?.data?.message, type: "success", isLoading: false, autoClose: 1500 });
            getHistory();
            getEquipmentsByIdStatus(status);
            setShowAddEquipamentoRemessa(false);
        } else {
            toast.update(deleteToast, { render: 'Ocorreu um erro ao remover o produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function updateEquipmentData(rowData, field, value) {

        let formData = {
            id_remessa: rowData.id_remessa,
            id_produto: rowData.id_produto,
            id_equipamento_chamado: rowData.id_equipamento_chamado,
            status_produto: field === 'status_produto' ? value : statusProdutoOptions.find((e => e.label === rowData.status_produto_final_equipamento_remessa))['value'],
            modal_envio: field === 'modal_envio' ? value : rowData.modal_envio_equipamento_remessa
        }

        const updateToast = toast.loading('Atualizando equipamento, aguarde...');

        let dataReturn = await updateEquipmentStatus(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(updateToast, { render: dataReturn?.data?.data?.message, type: "success", isLoading: false, autoClose: 1500 });
            getEquipmentsByIdStatus();
        } else {
            toast.update(updateToast, { render: 'Ocorreu um erro ao atualizar o produto!', type: "success", isLoading: false, autoClose: 1500 });
        }
    }

    async function addProduct(nameField) {
        if (equipamentoRemessaData[nameField] === '') {
            return;
        }

        let product = { ...equipamentoRemessaData[nameField] };

        const toastAddProduct = toast.loading("Validando produto, aguarde...");

        let productStatusData = await getProductByProductId(product['value']);
        let productStatus = '';
        let addProductResponse = false;

        if (productStatusData?.data?.response === 'success') {
            productStatus = productStatusData.data.data?.status?.chave_opcao || '';
        }

        if (productStatus === 'peca_disponivel') {

            let modalEnvioOptions = await showSelectModalEnvioSwal();

            if (!modalEnvioOptions.response) {
                toast.update(toastAddProduct, { render: 'Adição de produto cancelada', type: "warning", isLoading: false, autoClose: 1500 });
                setEquipamentoRemessaData(prevState => ({ ...prevState, produto_estoque: '', produto_contrato: '' }));
                return;
            }

            let formData = {
                produtos: [
                    {
                        id: product['value'],
                        categoria_envio: 'Remessa',
                        status: 'aguardando_envio',
                        modal_envio: modalEnvioOptions.value
                    }
                ],
                envolve_logistica: detailsData?.envolve_logistica
            }
            addProductResponse = await addEquipmentByTicketId(id, formData)
        }

        if (addProductResponse?.data?.response === 'success') {
            toast.update(toastAddProduct, { render: 'Produto adicionado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastAddProduct, { render: 'Este produto está indisponível, por favor selecione outro!', type: "warning", isLoading: false, autoClose: 1500 });
        }

        setEquipamentoRemessaData(prevState => ({ ...prevState, produto_estoque: '', produto_contrato: '' }));
        setShowAddEquipamentoRemessa(false);
        setShowUpdateEquipamentoRemessa(false);
        getEquipmentsByIdStatus();
    }

    async function showSelectModalEnvioSwal() {

        let modalOptions = modalEnvioOptions.reduce((obj, item) => ({ ...obj, [item.value]: item.label }), {});

        let response = await mySwal.fire({
            titleText: `Selecione a forma de envio!`,
            input: 'select',
            inputOptions: modalOptions,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
        });

        if (response.isConfirmed) {
            return {
                response: true,
                value: response.value
            }
        } else {
            return {
                response: false
            }
        }
    }

    const getEquipmentsByIdStatus = async () => {
        let dataReturn = await getEquipmentsByTicketIdAndStatus(id, 'Remessa');
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let dadosEquipamentos = dataReturn.data.data;
            let dadosEquipamentosArray = [];

            dadosEquipamentosArray = dadosEquipamentos.map((item, idx) => {
                return ({
                    id_produto: item?.id_produto || '',
                    id_remessa: item?.shipment?.id || '',
                    id_equipamento_chamado: item?.id || '',
                    part_number_equipamento_remessa: item?.product?.nome_produto || '',
                    serial_equipamento_remessa: item?.product?.serial || '',
                    status_produto_equipamento_remessa: item?.product?.status?.valor_opcao || '',
                    status_produto_final_equipamento_remessa: item?.status?.valor_opcao || '',
                    modal_envio_equipamento_remessa: item?.modal_envio || '',
                    data_postagem_equipamento_remessa: item?.shipment?.valor_opcao || '',
                    rastreamento_equipamento_remessa: item?.shipment?.rastreamento || '',
                    status_transportadora_equipamento_remessa: item?.status_transportadora || '',
                    prazo_entrega_equipamento_remessa: item?.shipment?.prazo_entrega || ''
                });
            });

            setEquipmentsData(dadosEquipamentosArray);

        } else {
            setEquipmentsData([]);
        }
    }
    
    const getStatusProdutoOptions = async () => {
        let dataReturn = await getOptionsByModuleField('produtos', 'id_status_produto');

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data?.data.length > 0) {
            let optionsData = dataReturn?.data?.data?.data;
            let optionsArray = optionsData.map((item, idx) => {
                return ({ value: item.chave_opcao, label: item.valor_opcao });
            });
            setStatusProdutoOptions(optionsArray);
        } else {
            setStatusProdutoOptions([]);
        }
    }

    const getShippingMode = async () => {
        let dataReturn = await getShippingModeData();

        if (dataReturn?.data?.response === 'success') {
            let shippingMode = dataReturn.data.data;
            let shippingModeArray = shippingMode.map((item, idx) => {
                return ({ value: item.modal, label: item.modal });
            });

            setModalEnvioOptions(shippingModeArray);
        } else {
            setModalEnvioOptions([]);
        }
    }

    useEffect(() => {
        getShippingMode();
        getStatusProdutoOptions();
        getEquipmentsByIdStatus();
    }, []);

    return (<Card title="Equipamento Remessa" icon="fa-keyboard">
        <div className="row">
            <If condition={(showUpdateBtn && !showAddEquipamentoRemessa && userData?.userDataState?.cliente === 0 && detailsData?.status_chamado?.value === 'Agendado')}>
                <div className="col-6">
                    <button type="button" className="btn btn-primary" onClick={() => setShowAddEquipamentoRemessa(prevState => !prevState)}>Adicionar Equipamento de Remessa/Spare</button>
                </div>
            </If>
            <If condition={(equipmentsData.length > 0 && ["Fechado", "Cancelado"].includes(originalData?.status_chamado))}>
                <div className='col-6'>
                    <button className="btn btn-primary" onClick={() => setShowUpdateEquipamentoRemessa(prevState => !prevState)}>Atualizar Equipamento de Remessa / Spare</button>
                </div>
            </If>
        </div>
        <div className='row'>
            <If condition={showAddEquipamentoRemessa}>
                <div className="col-6">
                    <ProdutosSelect formData={equipamentoRemessaData} setFormData={setEquipamentoRemessaData} cols={[12, 12, 12, 12]} dependsOnContrato={true} fieldName='produto_contrato' idContrato={detailsData?.id_contrato?.value} title='Produtos do Contrato Selecionado'></ProdutosSelect>
                    <button type="button" className="btn btn-secondary mt-1" onClick={() => addProduct('produto_contrato')}>Adicionar Item</button>
                </div>
                <div className="col-6">
                    <ProdutosSelect formData={equipamentoRemessaData} setFormData={setEquipamentoRemessaData} cols={[12, 12, 12, 12]} fieldName='produto_estoque' title='Todos os produtos do estoque'></ProdutosSelect>
                    <button type="button" className="btn btn-secondary mt-1" onClick={() => addProduct('produto_estoque')}>Adicionar Item</button>
                </div>
            </If>
            <div className="col-12">
                <Table columns={equipmentsColumns} data={equipmentsData} needExport={false} id="equipmentTable"></Table>
            </div>
        </div>
    </Card>);
}