import React, { useEffect, useState } from 'react';
import Table from '../../../common/Layout/Table/Table';
import GoogleMaps from './GoogleMaps/GoogleMaps';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import { getRouteByTicketId } from '../../../services/AppRoute';
import { fromLatLng, setDefaults } from 'react-geocode';
import Card from '../../../common/Layout/Card';
import If from '../../../common/Layout/If';

export default function MapData({ id = '', originalData = {} }) {
    const [mapPositionsArray, setMapPositionsArray] = useState([]);
    const [mapTableData, setMapTableData] = useState([]);
    const [showMapTable, setShowMapTable] = useState(false);
    const MAP_INTERVAL_TIME = 10000;
    const mapColumns = [
        {
            id: 'status_map',
            name: 'Status do Atendimento',
            sortable: true,
            wrap: true,
            selector: row => row.status_map,
        },
        {
            id: 'endereco_map',
            name: 'Endereço Check-in',
            sortable: true,
            wrap: true,
            selector: row => row.endereco_map,
        },
        {
            id: 'data_map',
            name: 'Data Registro',
            sortable: true,
            wrap: true,
            selector: row => row.data_map,
        },
    ];


    setDefaults({
        key: process.env.REACT_APP_API_MAPS_KEY || "AIzaSyB_aOhT30y3hevB0dXMxRrnyhVQHnpqSvE",
        language: 'pt-BR',
        region: 'br'
    });

    const getMapData = async () => {
        let dataReturn = await getRouteByTicketId(id);
        let positions = [];
        let addressDataArray = [];

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let mapData = dataReturn?.data?.data;

            positions = mapData.map((item, idx) => {
                let lat = parseFloat(item.latitude)
                let lng = parseFloat(item.longitude)

                return ({ lat: lat, lng: lng })
            });

            addressDataArray = await Promise.all(mapData.map(async (item, idx) => {
                let lat = parseFloat(item.latitude)
                let lng = parseFloat(item.longitude)
                let endereco = await fromLatLng(lat, lng);

                if (endereco?.status === 'OK' && endereco?.results.length > 0) {
                    return ({ endereco_map: endereco.results[0].formatted_address, data_map: item.data_registro, status_map: item.status_acao });
                }
            }));
        }

        setMapPositionsArray(positions);
        setMapTableData(addressDataArray);
    }

    useEffect(() => {
        let interval;
        getMapData();

        if (["Em deslocamento", "Técnico em deslocamento"].includes(originalData?.status_chamado)) {
            interval = setInterval(() => {
                console.log('salve');
                getMapData();
            }, MAP_INTERVAL_TIME)

            return () => {
                clearInterval(interval);
            }
        }

        //Adicionar codigo para atualizar o mapa sozinho caso o status do chamado seja deslocamento
    }, [originalData?.status_chamado]);

    return (
        <Card title={"Atendimento Técnico Checkin"} icon={"fa-map-marker"} collapseId={"atendimentoBody"}>
            <If condition={mapPositionsArray.length === 0}>
                <CustomMessageData message="Sem Dados"></CustomMessageData>
            </If>
            <If condition={mapPositionsArray.length > 0}>
                <div className="row">
                    <div className='col-12'>
                        <button type="button" className='btn btn-primary mb-1' onClick={() => setShowMapTable(prevState => !prevState)}>{showMapTable ? 'Ocultar Dados' : 'Exibir Dados'}</button>
                    </div>
                    <If condition={showMapTable}>
                        <div className="col-12">
                            <Table columns={mapColumns} data={mapTableData} needExport={false} id="mapTable"></Table>
                        </div>
                    </If>
                    <hr></hr>
                    <div className='col-12'>
                        <div id='map' style={{ width: '100%', height: '450px' }}>
                            <GoogleMaps positions={mapPositionsArray} status={originalData?.status_chamado} customerAddress={`${originalData?.customer?.endereco_cliente} - ${originalData?.customer?.compl_ref_obs_endereco_cliente}, ${originalData?.customer?.bairro_cliente}, ${originalData?.customer?.cidade_cliente} - ${originalData?.customer?.estado_cliente}`}></GoogleMaps>
                        </div>
                    </div>
                </div>
            </If>
        </Card>);
}