import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import Table from '../../../../common/Layout/Table/Table';
import { toast } from 'react-toastify';
import { getAllPendingDactShipments } from '../../../../services/Shipment';
import PendingDactDetails from './PendingDactDetails';

export default function PendingDact(){
    const [data, setData] = useState([]);
    const [viewMode, setViewMode] = useState('view');
    const [pendingDactId, setPendingDactId] = useState('');
    const [openPendingDactDetails, setOpenPendingDactDetails] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const columns = [
        {
            id: 'id_produto',
            name: 'ID',
            selector: row => row.id_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_produto',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={()=>openPendingDactModal(row.id_produto, 'view')}>Ver</button>
                        <button type='button' className="dropdown-item" onClick={()=>openPendingDactModal(row.id_produto, 'edit')}>Editar Valores DACT</button>
                    </div>
                </div>
            ),
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
            selector: row => row.nome_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modelo_produto',
            name: 'Modelo',
            selector: row => row.modelo_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'serial_produto',
            name: 'Serial',
            selector: row => row.serial_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
            selector: row => row.modal_envio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
            selector: row => row.codigo_rastreio_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
            selector: row => row.tipo_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
            selector: row => row.status_remessa_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
            selector: row => row.nfe_saida_produto,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
            selector: row => row.data_entrega_produto,
            sortable: true,
            wrap: true
        }
    ];

    async function getPendingDactByStatus(){
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllPendingDactShipments();

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data || [];

            let productData = products.map((item, idx)=> ({
                id_produto: item?.id || '',
                nome_produto: item?.product?.nome_produto || '',
                modelo_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || ''
            }));

            setData(productData);
        }else{
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
    }

    function openPendingDactModal(id, mode){
        setPendingDactId(id);
        setViewMode(mode);
        setOpenPendingDactDetails(true);
    }

    useEffect(()=>{
        getPendingDactByStatus()
    }, []);

    return (<Content headerTitle='Pendente DACT'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <PendingDactDetails isOpen={openPendingDactDetails} setModalOpen={()=>setOpenPendingDactDetails(!openPendingDactDetails)} pendingDactId={pendingDactId} viewMode={viewMode} setUpdateProducts={getPendingDactByStatus} setUpdateLogistic={setUpdateLogistic}></PendingDactDetails>
        <Card title='Cadastro de Produtos - Pendente DACT'>
            <Table columns={columns} data={data} id='pendingDactTable'></Table>
        </Card>
    </Content>)
}