import interceptorInstance from "./Interceptor";

export async function getAllPartnerUsers(){
    try{
        let dados = await interceptorInstance.get('partner-group/users');
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function createPartnerUser(params){
    try{
        let dados = await interceptorInstance.post(`partner-group/create`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function deletePartnerUserById(id){
    try{
        let dados = await interceptorInstance.delete(`partner-group/delete/${id}`, {});
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function getGroupDetailsById(id){
    try{
        let dados = await interceptorInstance.get(`partner-group/search/${id}`);
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function removeContractFromPartnerGroup(id, params){
    try{
        let dados = await interceptorInstance.delete(`partner-group/${id}/contracts`, {params});
        return dados;
    }catch(e){
        return e.response;
    }
}

export async function updatePartnerGroup(id, params){
    try{
        let dados = await interceptorInstance.patch(`partner-group/update/${id}`, params);
        return dados;
    }catch(e){
        return e.response;
    }
}