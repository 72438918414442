import React from 'react';
import Card from '../../../common/Layout/Card';

export default function NovoKmCard({
    detailsData = {},
    setDetailsData = null
}) {
    return (<Card title={'Novo KM'}>
        <div className="row">
            <div className="col-12">
                <label htmlFor="km_tecnico">Km do Técnico para o Contrato</label>
                <input type="number" className="form-control" name="km_tecnico" id="km_tecnico" value={detailsData?.km_tecnico || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' />
            </div>
        </div>
    </Card>);
}