import React from 'react';
import Card from '../../../common/Layout/Card';
import Cep from '../../../common/Cep/Cep';
import { withMask } from 'use-mask-input';

export default function DadosClienteCard({
    detailsData = {},
    setDetailsData = null
}) {
    return (<Card title={'Novos Dados Cliente'} icon={'fa-building'}>
        <div className='card-header'><h6><i className='fas fa-building'></i> Cliente</h6></div>
        <div className="row">
            <div className="col-md-3">
                <div className='form-group'>
                    <label htmlFor="nomeCliente">Cliente</label>
                    <input type="text" className="form-control" name="nome_cliente" id="nomeCliente" value={detailsData.nome_cliente} onChange={(e) => setDetailsData(prevState => ({...prevState, [e.target.name]: e.target.value}))} placeholder='...' required />
                </div>
            </div>
            <div className="col-md-3">
                <div className='form-group'>
                    <label htmlFor="nomeContatoCliente">Nome Contato</label>
                    <input type="text" className="form-control" name="nome_contato_cliente" id="nomeContatoCliente" value={detailsData.nome_contato_cliente} onChange={(e) => setDetailsData(prevState => ({...prevState, [e.target.name]: e.target.value}))} placeholder='...' required />
                </div>
            </div>
            <div className="col-md-3">
                <div className='form-group'>
                    <label htmlFor="telefoneContatoCliente">Telefone</label>
                    <input type="tel" className="form-control" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} name="telefone_contato_cliente" id="telefoneContatoCliente" value={detailsData.telefone_contato_cliente} onChange={(e) => setDetailsData(prevState => ({...prevState, [e.target.name]: e.target.value}))} placeholder='...' required />
                </div>
            </div>
            <div className="col-md-3">
                <div className='form-group'>
                    <label htmlFor="emailContatoCliente">E-mail</label>
                    <input type="email" className="form-control" name="email_contato_cliente" id="emailContatoCliente" value={detailsData.email_contato_cliente} onChange={(e) => setDetailsData(prevState => ({...prevState, [e.target.name]: e.target.value}))} placeholder='...' required />
                </div>
            </div>
        </div>

        <div className='card-header'><h6><i className='fas fa-map-marker'></i> Endereço do Cliente</h6></div>
        <Cep 
            formData={detailsData} 
            setFormData={setDetailsData}
            formDataNames={{
                cep: 'cep_endereco_cliente', 
                estado: 'estado_endereco_cliente',
                bairro: 'bairro_endereco_cliente',
                cidade: 'cidade_endereco_cliente',
                logradouro: 'endereco_cliente',
                complemento: 'numero_complemento'
            }}
            hasComplemento={true}
            required={true}
        ></Cep>
    </Card>);
}