import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../../common/Layout/Modal';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import TecnicosSelect from '../../../../common/Selects/TecnicosSelect';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import ModalEnvioSelect from '../../../../common/Selects/ModalEnvioSelect';
import { toast } from 'react-toastify';
import { getProductByProductId } from '../../../../services/Product';
import { createShipment } from '../../../../services/Shipment';
import If from '../../../../common/Layout/If';

export default function CreateShipment({ isOpen = false, setIsOpen = null, productId = '', setUpdateProducts = null, setUpdateLogistic = null }) {
    const INITIAL_STATE = {
        urgencia_remessa: '',
        tipo_remessa: '',
        id_tecnico: '',
        modal_envio: '',
        responsavel_separacao: '',
        local: '',
        box: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [productData, setProductData] = useState(null);

    async function getProductDataById() {
        const toastProducts = toast.loading('Carregando dados do Produto, aguarde...');

        let dataReturn = await getProductByProductId(productId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let product = dataReturn?.data?.data;

            setProductData(prevState => (
                {
                    ...prevState,
                    ...product
                }
            ));
            if (product?.status?.chave_opcao === 'spare_tecnico') {
                setFormData(prevState => ({ ...prevState, tipo_remessa: { value: 'envio_tecnico', label: 'ENVIO TÉCNICO' } }));
            }
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setIsOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            urgencia_remessa: formData?.urgencia_remessa?.value || '',
            chave_tipo_remessa: formData?.tipo_remessa?.value || '',
            id_tecnico: formData?.tipo_remessa?.value === 'envio_tecnico' ? formData?.id_tecnico?.value : '',
            modal_envio: formData?.modal_envio?.value || '',
            responsavel_separacao: formData?.responsavel_separacao || '',
            local: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.local?.value : '',
            box: formData?.tipo_remessa?.value === 'movimentacao_interna' ? formData?.box?.value : ''
        }

        const toastProducts = toast.loading('Cadastrando remessa, aguarde...');

        let dataReturn = await createShipment(productId, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Remessa cadastrada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setUpdateProducts();
            setIsOpen(false);
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cadastrar remessa!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }

        if (productId !== undefined && productId !== '') {
            getProductDataById();
        } else {
            setProductData({});
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title={productData?.status?.chave_opcao === 'spare_tecnico' ? 'Deseja enviar para outro técnico?' : 'Deseja criar uma nova remessa para o produto?'}
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw'
            }}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <input type="text" name="nome_produto" id="nome_produto" className="form-control" value={productData?.nome_produto} disabled />
                    </div>
                    <GenericSelect fieldName={'urgencia_remessa'} formData={formData} setFormData={setFormData} title={'Urgência da Remessa'} cols={[12, 12, 4, 4]} genericOption='UrgenciaRemessaOptions' required={true}></GenericSelect>
                    <If condition={productData?.status?.chave_opcao !== 'spare_tecnico'}>
                        <GenericSelect fieldName={'tipo_remessa'} formData={formData} setFormData={setFormData} title={'Tipo de Remessa'} cols={[12, 12, 2, 2]} genericOption='TipoRemessaOptions' required={true}></GenericSelect>
                    </If>
                    <If condition={formData?.tipo_remessa?.value === 'movimentacao_interna'}>
                        <GenericSelect fieldName={'local'} formData={formData} setFormData={setFormData} title={'UF'} cols={[12, 12, 2, 2]} genericOption='UfOptions' required={true}></GenericSelect>
                        <BoxSelect fieldName={'box'} formData={formData} setFormData={setFormData} cols={[12, 12, 2, 2]} required={true} uf={formData?.local?.value}></BoxSelect>
                    </If>
                    <If condition={formData?.tipo_remessa?.value === 'envio_tecnico'}>
                        <div className="col-md-2 col-sm-12">
                            <label htmlFor="tecnico_responsavel">Técnico Responsável</label>
                            <input type="text" name="tecnico_responsavel" id="tecnico_responsavel" placeholder='Técnico Remetente' className="form-control" value={productData?.technician?.nome_tec || ''} disabled />
                        </div>
                        <RepresentanteSelect formData={formData} setFormData={setFormData} cols={[12, 12, 3, 3]} fieldName='representante' required={true}></RepresentanteSelect>
                        <TecnicosSelect formData={formData} setFormData={setFormData} cols={[12, 12, 3, 3]} fieldName='id_tecnico' id_representante={formData?.representante?.value || ''} required={true}></TecnicosSelect>
                    </If>
                    <ModalEnvioSelect formData={formData} setFormData={setFormData} cols={[12, 12, 3, 3]} fieldName='modal_envio' required={true}></ModalEnvioSelect>
                    <div className="col-md-3 col-sm-12">
                        <label htmlFor="responsavel_separacao">Responsável pela Separação</label>
                        <input type="text" name="responsavel_separacao" id="responsavel_separacao" placeholder='Responsável pela Separação' className="form-control" value={formData?.responsavel_separacao || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar Remessa</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}