import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTickets } from '../../services/Ticket';

export default function ChamadosDoDiaSelect({ formData, setFormData, fieldName = 'todos_chamados_dia', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false }) {
    const [ticketsOptions, setTicketsOptions] = useState([]);

    async function getTicketsData(){
        let dataReturn = await getTickets();

        if (dataReturn?.data?.response === 'success') {
            let tickets = dataReturn?.data.data.map((item, idx)=>{
                return ({value: item.id, label: `${item.num_chamado}|${item.data_agendamento || ''}|${item.hora_agendamento || ''}|${item?.cliente || ''}|${item?.integradora || ''}|${item?.contrato || ''}`})
            });
            setTicketsOptions(tickets);
        } else {
            setTicketsOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getTicketsData();
    }, [])

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>Chamados Todos do Dia (Nº do chamado, Agendamento, Cliente, Integradora, Contrato)</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={[...ticketsOptions]}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}