import React, { useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import {assignSupportTicket } from '../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import ChamadosDoDiaSelect from '../../common/Selects/ChamadosDoDiaSelect';
import UsuariosAtivosSelect from '../../common/Selects/UsuariosAtivosSelect';

export default function SupportResponsible() {
    const initialState = {
        responsavel_suporte: '',
        todos_chamados_dia: [],
    }

    const [formData, setFormData] = useState(initialState);

    async function submitAssignForm(e){
        e.preventDefault();

        if(formData?.chamados_todos_dia?.length === 0){
            toast.warning("Por favor, selecione pelo menos um chamado");
            return;
        }

        let params = {
            ids_chamado: formData?.todos_chamados_dia?.map((item)=>(item?.value)),
            id_responsavel: formData?.responsavel_suporte?.value || ''
        }
         
        const ticketToast = toast.loading("Atualizando chamados, aguarde...");

        let dataReturn = await assignSupportTicket(params);

        if(dataReturn?.data?.response === 'success'){
            toast.update(ticketToast, { render: 'Chamado Atualizado com Sucesso!', type: "success", isLoading: false, autoClose: 1500 });
        }else{
            toast.update(ticketToast, { render: 'Ocorreu um erro ao atualizar o chamado!', type: "warning", isLoading: false, autoClose: 1500 });
        }
        setFormData(initialState);
    }

    return (<Content headerTitle="Chamados - Responsável Suporte">
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitAssignForm}>
                <div className='row'>
                    <UsuariosAtivosSelect formData={formData} setFormData={setFormData} title='Responsável Suporte' fieldName='responsavel_suporte' required={true}></UsuariosAtivosSelect>
                    <ChamadosDoDiaSelect formData={formData} setFormData={setFormData} required={true} isMulti={true}></ChamadosDoDiaSelect>
                    <div className="col-12 mt-1">
                        <button type='submit' className="btn btn-primary">Designar responsável ao(s) chamado(s)</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}