import React, { useEffect, useRef, useState } from 'react';
import Content from '../../common/Layout/Content';
import Card from '../../common/Layout/Card';
import { toast } from 'react-toastify';
import IntegradoraSelect from '../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../common/Selects/ContratosSelect';
import { importTicketDocument } from '../../services/Ticket';
import Swal from 'sweetalert2';
import modelo from '../../resources/modelos_xls/Modelo_Abertura_Chamado_XLS.xlsx';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';

export default function CreateTicketXls() {

    const initialState = {
        integradora: '',
        contrato: '',
        arquivo: ''
    }

    const [formTicketData, setFormTicketData] = useState(initialState);
    const arquivoRef = useRef();

    async function submitFiles(e) {
        e.preventDefault();
        
        const formData = new FormData();

        formData.append('arquivo', formTicketData?.arquivo);
        formData.append('id_integradora', formTicketData?.integradora?.value);
        formData.append('id_contrato', formTicketData?.contrato?.value);

        const toastUpdate = toast.loading("Executando upload, aguarde...");

        let dataReturn = await importTicketDocument(formData);
        if (dataReturn?.data.response === 'success') {
            toast.update(toastUpdate, { render: 'Upload executado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormTicketData(initialState);
            arquivoRef.current.value = '';
            return;
        } 
        
        if(dataReturn?.data?.status === 'error' && dataReturn?.status === 422){
            toast.update(toastUpdate, { render: 'Ocorreu um erro ao executar o upload!', type: "warning", isLoading: false, autoClose: 1500 });
            Swal.fire({
                title: 'Por favor, corrija os erros abaixo antes de tentar efetuar novamente o upload!',
                text: dataReturn?.data?.message
            });
            return;
        }

        toast.update(toastUpdate, { render: 'Ocorreu um erro ao executar o upload!', type: "warning", isLoading: false, autoClose: 1500 });
        return;
    }

    function handleIntegradora(value, name){
        setFormTicketData(prev => ({...prev, [name]: value, contrato: ''}))
    }

    return (<Content headerTitle="Abertura de Chamados XLS">
        <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitFiles}>
                <div className='row'>
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Integradora / Contrato</h5>
                    </div>
                    <IntegradoraSelect formData={formTicketData} setFormData={setFormTicketData} cols={[12, 12, 6, 6]} required={true} advancedSetFormData={handleIntegradora}></IntegradoraSelect>
                    <ContratosSelect formData={formTicketData} setFormData={setFormTicketData} cols={[12, 12, 6, 6]} dependsOnIntegradora={true} required={true} id_integradora_string={[formTicketData?.integradora?.value].toString()}></ContratosSelect>
                </div>
                <hr></hr>
                <div className='row'>
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Abertura de Chamado - Carga XLS</h5>
                    </div>
                    <div className="col-12">
                        <label htmlFor="arquivo">Anexar Acionamento - XLS</label>
                        <input type="file" ref={arquivoRef} name="arquivo" id="arquivo" onChange={e => {setFormTicketData(prevState=> ({...prevState, [e.target.name]: e.target.files[0]}))}} className="form-control" accept='.xls, .xlsx' required/>
                        <span style={{fontSize:'0.8rem'}}>Obs: Para inclusão correta, por favor, verifique a existência de caracteres especiais (desconhecidos) ou aspas simples no arquivo a ser anexado.</span>
                    </div>
                    <div className="col-12">
                        <a href={modelo} className='m-1' target="_blank" download={true}>XLS Modelo - Download</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button type="submit" className='btn btn-primary'><i className='fas fa-upload'></i> Enviar</button>
                    </div>
                </div>
            </form>
        </Card>
    </Content>)
}