import React, { useEffect, useRef, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { withMask } from 'use-mask-input';
import { useNavigate, useParams } from 'react-router-dom';
import Cep from '../../../../common/Cep/Cep';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import { getEmployeeById, updateEmployeeById } from '../../../../services/Employee';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import RentedVehicles from './RentedVehicles';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import UsuariosAtivosSelect from '../../../../common/Selects/UsuariosAtivosSelect';
import If from '../../../../common/Layout/If';
import LendingEquipmentTable from './LendingEquipment/LendingEquipmentTable';
import LeavesTable from './Leaves/LeavesTable';

export default function TechnicianDetails() {

    const initialState = {
        representantes: [],
        nome_tec: '',
        rg_tec_orgao_expedidor: '',
        rg_tec: '',
        cpf_cnpj: '',
        data_nascimento: '',
        estado_civil: '',
        numero_cnh: '',
        cnh_img: '',
        nome_mae: '',
        data_validade_cnh: '',
        data_validade_aso: '',
        data_exame_periodico: '',
        logradouro_end: '',
        numero_complemento_endereco: '',
        bairro_end: '',
        cidade_end: '',
        estado_end: '',
        cep_end: '',
        telefone_fixo_tec: '',
        telefone_recados: '',
        celular_tec: '',
        celular_tec_pessoal: '',
        login_tec: '',
        senha_tec: '',
        email_tec: '',
        email_tec_pessoal: '',
        veiculo: '',
        cor_veiculo: '',
        modelo_veiculo: '',
        placa_veiculo: '',
        banco: '',
        tipo_conta_banco: '',
        agencia_banco: '',
        num_conta_banco: '',
        titular_banco2: '',
        cpf_titular2: '',
        desc_base: '',
        desc_habilidades: '',
        descricao: '',
        ativo: '',
        credenciado: '',
        modal_contratacao: '',
        categoria: "Técnico",
        id_representante: []
    }

    const { id } = useParams();
    const [updateData, setUpdataData] = useState(false);
    const [formTechnicianData, setFormTechnicianData] = useState(initialState);

    const [showPwd, setShowPwd] = useState(false);
    const navigate = useNavigate();

    // const divergenciaTecnicoColumn = [
    //     {
    //         id: 'num_chamado_divergencia',
    //         name: 'Num. Chamado',
    //         cell: (row) => (
    //             <Link to={'/cadastros/tecnicos/detalhes/' + 'row.id_tecnico'}><i className='fas fa-eye'></i></Link>
    //         ),
    //         sortable: true,
    //         wrap: true
    //     },
    //     {
    //         id: 'tipo_divergencia',
    //         name: 'Tipo',
    //         selector: row => row.nome_tecnico,
    //         sortable: true,
    //         wrap: true
    //     },
    //     {
    //         id: 'status_divergencia',
    //         name: 'Status',
    //         selector: row => row.nome_tecnico,
    //         sortable: true,
    //         wrap: true
    //     },
    //     {
    //         id: 'solucionado_por_divergencia',
    //         name: 'Solucionado Por',
    //         selector: row => row.nome_tecnico,
    //         sortable: true,
    //         wrap: true
    //     },
    //     {
    //         id: 'descricao_divergencia',
    //         name: 'Descrição',
    //         selector: row => row.nome_tecnico,
    //         sortable: true,
    //         wrap: true
    //     }
    // ];

    function showPassword() {
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        let representantes = formTechnicianData?.id_representante?.map((item, idx) => {
            return item.value;
        });

        let formData = {
            representantes: representantes,
            nome_tec: formTechnicianData?.nome_tec || '',
            rg_tec_orgao_expedidor: formTechnicianData?.rg_tec_orgao_expedidor || '',
            rg_tec: formTechnicianData?.rg_tec || '',
            cpf_cnpj: formTechnicianData?.cpf_cnpj || '',
            data_nascimento: formTechnicianData?.data_nascimento || '',
            estado_civil: formTechnicianData?.estado_civil?.value || '',
            numero_cnh: formTechnicianData?.numero_cnh || '',
            cnh_img: formTechnicianData?.cnh_img || '',
            nome_mae: formTechnicianData?.nome_mae || '',
            data_validade_cnh: formTechnicianData?.data_validade_cnh || '',
            data_validade_aso: formTechnicianData?.data_validade_aso || '',
            data_exame_periodico: formTechnicianData?.data_exame_periodico || '',
            logradouro_end: formTechnicianData?.logradouro_end || '',
            numero_complemento_endereco: formTechnicianData?.numero_complemento_endereco || '',
            bairro_end: formTechnicianData?.bairro_end || '',
            cidade_end: formTechnicianData?.cidade_end || '',
            estado_end: formTechnicianData?.estado_end || '',
            cep_end: formTechnicianData?.cep_end || '',
            telefone_fixo_tec: formTechnicianData?.telefone_fixo_tec || '',
            telefone_recados: formTechnicianData?.telefone_recados || '',
            celular_tec: formTechnicianData?.celular_tec || '',
            celular_tec_pessoal: formTechnicianData?.celular_tec_pessoal || '',
            login_tec: formTechnicianData?.login_tec || '',
            senha_tec: formTechnicianData?.senha_tec || '',
            email_tec: formTechnicianData?.email_tec || '',
            email_tec_pessoal: formTechnicianData?.email_tec_pessoal || '',
            veiculo: formTechnicianData?.veiculo || '',
            cor_veiculo: formTechnicianData?.cor_veiculo || '',
            modelo_veiculo: formTechnicianData?.modelo_veiculo || '',
            placa_veiculo: formTechnicianData?.placa_veiculo || '',
            banco: formTechnicianData?.banco || '',
            tipo_conta_banco: formTechnicianData?.tipo_conta_banco || '',
            agencia_banco: formTechnicianData?.agencia_banco || '',
            num_conta_banco: formTechnicianData?.num_conta_banco || '',
            titular_banco2: formTechnicianData?.titular_banco2 || '',
            cpf_titular2: formTechnicianData?.cpf_titular2 || '',
            desc_base: formTechnicianData?.desc_base || '',
            desc_habilidades: formTechnicianData?.desc_habilidades || '',
            descricao: formTechnicianData?.descricao || '',
            ativo: formTechnicianData?.ativo || 1,
            credenciado: 1,
            modal_contratacao: representantes.includes(1) ? 'CLT' : 'PJ',
            categoria: "Técnico",
            senha_tecnico: formTechnicianData?.senha_tec || '',
            id_usuario_gestor: representantes.includes(1) ? 47437 : formTechnicianData?.id_usuario_gestor?.value || ''
        }

        const toastUpdateForm = toast.loading("Atualizando técnico, aguarde...");

        let dataReturn = await updateEmployeeById(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUpdateForm, { render: 'Técnico atualizado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getEmployee();
            setUpdataData(false);
        } else {
            toast.update(toastUpdateForm, { render: 'Ocorreu um erro ao atualizar o técnico!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getEmployee() {
        const toastEmployee = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getEmployeeById(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data !== null) {
            let employeeData = dataReturn?.data?.data;

            let partners = employeeData.partners.map((item, idx) => {
                return ({ value: item?.id, label: item?.nome_representante });
            });

            let login_tec = employeeData.tech_user.length > 0 ? employeeData.tech_user[0].login : '';

            toast.update(toastEmployee, { render: 'Dados encontrados!', type: "success", isLoading: false, autoClose: 1500 });
            setFormTechnicianData({
                ...employeeData,
                estado_civil: { value: employeeData?.estado_civil, label: employeeData?.estado_civil },
                senha_tec: '',
                login_tec: login_tec,
                id_representante: [...partners],
                id_usuario_gestor: { value: employeeData?.manager?.id || '', label: employeeData?.manager?.nome || '' }
            });

        } else if (dataReturn?.data?.data === null) {
            toast.update(toastEmployee, { render: 'Este técnico não existe!', type: "warning", isLoading: false, autoClose: 1500 });
            navigate("/cadastros/tecnicos/dados");

        } else {
            toast.update(toastEmployee, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        getEmployee();
    }, []);

    return (
        <Content headerTitle="Detalhes Técnico">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <If condition={!updateData}>
                            <button type="button" className="btn btn-primary" onClick={() => (setUpdataData(prevState => !prevState))}>Alterar</button>
                        </If>
                        <If condition={updateData}>
                            <button type="button" className="btn btn-primary" onClick={submitForm}>Salvar Alterações</button>
                        </If>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Técnico</h5>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="nome_tec">Nome do Técnico</label>
                            <input type="text" className="form-control" name="nome_tec" id="nome_tec" value={formTechnicianData?.nome_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="email_tec">Email Corporativo</label>
                            <input type="email" className="form-control" name="email_tec" id="email_tec" value={formTechnicianData?.email_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="email_tec_pessoal">Email Pessoal</label>
                            <input type="email" className="form-control" name="email_tec_pessoal" id="email_tec_pessoal" value={formTechnicianData?.email_tec_pessoal || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="nome_mae">Nome da Mãe</label>
                            <input type="text" className="form-control" name="nome_mae" id="nome_mae" value={formTechnicianData?.nome_mae || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="form-group">
                            <label htmlFor="rg_tec_orgao_expedidor">Órgão Expeditor</label>
                            <input type="text" className="form-control" name="rg_tec_orgao_expedidor" id="rg_tec_orgao_expedidor" value={formTechnicianData?.rg_tec_orgao_expedidor || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                        </div>
                    </div>
                    <RepresentanteSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName='id_representante' cols={[12, 12, 2, 2]} isMulti={true} required={true} isDisabled={!updateData}></RepresentanteSelect>
                    <If condition={formTechnicianData?.id_representante?.filter((item, idx) => item.label === 'PJ - MAMINFO SOLUÇÕES').length > 0}>
                        <UsuariosAtivosSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName='id_usuario_gestor' cols={[12, 12, 2, 2]} userType='all' required={true} title='Gestor' isDisabled={!updateData}></UsuariosAtivosSelect>
                    </If>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="celular_tec">Celular Corporativo</label>
                        <input type="text" name="celular_tec" id="celular_tec" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formTechnicianData?.celular_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="celular_tec_pessoal">Celular Pessoal</label>
                        <input type="text" name="celular_tec_pessoal" id="celular_tec_pessoal" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formTechnicianData?.celular_tec_pessoal || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="telefone_recados">Telefone Recado</label>
                        <input type="text" name="telefone_recados" id="telefone_recados" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formTechnicianData?.telefone_recados || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="telefone_fixo_tec">Telefone Fixo</label>
                        <input type="text" name="telefone_fixo_tec" id="telefone_fixo_tec" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formTechnicianData?.telefone_fixo_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="rg_tec">RG</label>
                        <input type="text" name="rg_tec" id="rg_tec" className="form-control" value={formTechnicianData?.rg_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="cpf_cnpj">CPF</label>
                        <input type="text" name="cpf_cnpj" id="cpf_cnpj" className="form-control" ref={withMask(['999.999.999-99'])} value={formTechnicianData?.cpf_cnpj || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <GenericSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName={'estado_civil'} title={'Estado Civil'} cols={[12, 12, 2, 2]} genericOption='EstadoCivilOptions' isDisabled={!updateData} ></GenericSelect>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="data_nascimento">Data Nascimento</label>
                        <input type="date" name="data_nascimento" id="data_nascimento" className="form-control" value={formTechnicianData?.data_nascimento || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="numero_cnh">Nº CNH</label>
                        <input type="text" name="numero_cnh" id="numero_cnh" className="form-control" value={formTechnicianData?.numero_cnh || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="data_validade_cnh">Data de Validade CNH</label>
                        <input type="date" name="data_validade_cnh" id="data_validade_cnh" className="form-control" value={formTechnicianData?.data_validade_cnh || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="data_exame_periodico">Data Exame Periódico</label>
                        <input type="date" name="data_exame_periodico" id="data_exame_periodico" className="form-control" value={formTechnicianData?.data_exame_periodico || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="data_validade_aso">Data de Validade ASO</label>
                        <input type="date" name="data_validade_aso" id="data_validade_aso" className="form-control" value={formTechnicianData?.data_validade_aso || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Endereço</h5>
                    </div>
                </div>
                <Cep
                    formData={formTechnicianData}
                    setFormData={setFormTechnicianData}
                    formDataNames={{
                        'cep': 'cep_end',
                        'bairro': 'bairro_end',
                        'cidade': 'cidade_end',
                        'estado': 'estado_end',
                        'logradouro': 'logradouro_end'
                    }}
                    readOnly={!updateData}
                ></Cep>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-dot-circle"></i> Veículo Particular</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="veiculo">Veículo</label>
                        <input type="text" name="veiculo" id="veiculo" className="form-control" value={formTechnicianData?.veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label htmlFor="modelo_veiculo">Modelo</label>
                        <input type="text" name="modelo_veiculo" id="modelo_veiculo" className="form-control" value={formTechnicianData?.modelo_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="cor_veiculo">Cor</label>
                        <input type="text" name="cor_veiculo" id="cor_veiculo" className="form-control" value={formTechnicianData?.cor_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="placa_veiculo">Placa</label>
                        <input type="text" name="placa_veiculo" id="placa_veiculo" className="form-control" value={formTechnicianData?.placa_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                </div>
                <hr></hr>
                <RentedVehicles technicianId={id} readOnly={!updateData}></RentedVehicles>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Dados Bancários</h5>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="banco">Banco</label>
                        <input type="text" name="banco" id="banco" className="form-control" value={formTechnicianData?.banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="tipo_conta_banco">Tipo Conta</label>
                        <input type="text" name="tipo_conta_banco" id="tipo_conta_banco" className="form-control" value={formTechnicianData?.tipo_conta_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="agencia_banco">Agência</label>
                        <input type="text" name="agencia_banco" id="agencia_banco" className="form-control" value={formTechnicianData?.agencia_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="num_conta_banco">Nº Conta</label>
                        <input type="text" name="num_conta_banco" id="num_conta_banco" className="form-control" value={formTechnicianData?.num_conta_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="titular_banco2">Titular 2</label>
                        <input type="text" name="titular_banco2" id="titular_banco2" className="form-control" value={formTechnicianData?.titular_banco2 || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <label htmlFor="cpf_titular2">CPF Titular 2</label>
                        <input type="text" name="cpf_titular2" id="cpf_titular2" className="form-control" ref={withMask(['999.999.999-99'])} value={formTechnicianData?.cpf_titular2 || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData} />
                    </div>
                </div>
                <hr></hr>
                <If condition={formTechnicianData?.tech_user?.length > 0}>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Login App Mobile</h5>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="login_tec">Login</label>
                            <input type="text" name="login_tec" id="login_tec" className="form-control" placeholder='...' value={formTechnicianData?.login_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={true} />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="senha_tec">Senha (Digite a nova senha para alterar ou deixe em branco para manter a senha atual)</label>
                            <div className="input-group mb-3">
                                <input type={!showPwd ? "password" : "text"} name="senha_tec" id="senha_tec" minLength={8} className="form-control" placeholder="..." value={formTechnicianData?.senha_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} readOnly={!updateData} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => showPassword()} style={{ cursor: "pointer" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                </If>
                {/* <div className="row">
                    <div className="col-12">
                        <h5><i className='fas fa-dot-circle'></i> Divergências Técnico</h5>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-secondary" disabled={!updateData}>Zerar Contagem de Divergências</button>
                    </div>
                    <div className="col-12">
                        <Table columns={divergenciaTecnicoColumn} data={[]} id='divergencia_tecnico'></Table>
                    </div>
                </div>
                <hr></hr> */}
                <LendingEquipmentTable id={id} updateData={!updateData}></LendingEquipmentTable>
                <hr></hr>
                <LeavesTable id={id} updateData={!updateData}></LeavesTable>
                <hr></hr>
                <div className="row">
                    <div className="col-12">
                        <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="descricao">Observações</label>
                            <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formTechnicianData?.descricao || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' readOnly={!updateData}></textarea>
                        </div>
                    </div>
                </div>
            </Card>
        </Content >
    );
}