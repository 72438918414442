import React, { useContext } from "react";
import QtChamadosHeader from "./QtdChamadosHeader";
import { Link } from 'react-router-dom';
import { logout } from "../../../services/Auth";
import { toast } from 'react-toastify';
import { Context } from "../../../App";
import NotificationsHeader from "./Notification/NotificationsHeader";

function Header(props) {

    const userData = useContext(Context);

    const handleLogout = async (e) => {
        e.preventDefault();

        const toastLogout = toast.loading("Efetuando logout, aguarde...");

        await logout();

        toast.update(toastLogout, { render: "Logout efetuado com sucesso!", type: "success", isLoading: false, autoClose: 1500 })

        props.setToken("");
        localStorage.clear();
    }


    return (
        <nav className="main-header navbar navbar-expand-lg navbar-dark navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button className="btn btn-link nav-link" data-widget="pushmenu"><i className="fas fa-bars" /></button>
                </li>
            </ul>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#qtdChamadosContent" aria-controls="qtdChamadosContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-start" id="qtdChamadosContent">
                <QtChamadosHeader></QtChamadosHeader>
                <ul className="navbar-nav d-flex flex-row flex-wrap">
                    <NotificationsHeader></NotificationsHeader>
                    <li className="nav-item px-1">
                        <button className="btn btn-link nav-link" data-widget="fullscreen">
                            <i className="fas fa-expand-arrows-alt" />
                        </button>
                    </li>
                    <li className="nav-item dropdown px-1">
                        <button className="btn btn-link nav-link" data-toggle="dropdown">
                            <i className="far fa-user" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <h5 className="dropdown-header">Olá, {userData.userDataState.login}</h5>
                            <div className="dropdown-divider" />
                            <Link to="/perfil/dados" className="dropdown-item">
                                Ver Perfil
                            </Link>
                            <div className="dropdown-divider" />
                            <button className="btn btn-link dropdown-item" onClick={handleLogout}>
                                Sair
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </nav >
    );
}

export default Header;