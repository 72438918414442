import React, { useState } from 'react';
import { Options } from '../../configs/GenericOptions';
import Select from 'react-select';

export default function GenericSelect({ formData, setFormData, fieldName, title = '', genericOption = 'SimNaoOptions', cols= [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false}){
    const [genericOptions] = useState(Options?.[genericOption] || []);

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({...prevState, [name]: value}));
    }

    return (<div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
        {title !== '' && (<label htmlFor={fieldName}>{title}</label>)}
        <Select
            id={fieldName}
            menuPortalTarget={document.body}
            menuPosition='fixed'
            styles={{
                menuPortal: base => ({...base, zIndex: 9999})
            }}
            name={fieldName}
            placeholder="Selecione..."
            options={genericOptions}
            value={formData?.[fieldName] || ''}
            onChange={(e) => handleSelectForm(e, fieldName)}
            isMulti={isMulti}
            isDisabled={isDisabled}
            required={required}
        ></Select>
    </div>)
}