import React, { useContext } from 'react';
import usePermissions from '../../../hooks/usePermissions';
import { Context } from '../../../App';
import Card from '../../../common/Layout/Card';
import If from '../../../common/Layout/If';

export default function ClienteCardData({
    originalData = {},
    showUpdateBtn = false,
    setShowAlterarClienteBtn = null
}) {
    const { checkPermission } = usePermissions();
    const userData = useContext(Context);

    return (<Card title={`Cliente`} icon={'fa-building'}>
        <div className="row">
            <div className="col-12">
                <p><b>Nome do Cliente: </b>{originalData?.customer?.nome_cliente}</p>
                <p><b>Nome do Contato: </b>{originalData?.customer?.nome_contato_cliente}</p>
                <p><b>Telefone do Contato: </b>{originalData?.customer?.telefone_contato_cliente}</p>
                <p><b>Email do Cliente: </b>{originalData?.customer?.email_contato_cliente}</p>
                <p><b>Endereço: </b>{`${originalData?.customer?.endereco_cliente} - ${originalData?.customer?.compl_ref_obs_endereco_cliente}, ${originalData?.customer?.bairro_cliente}, ${originalData?.customer?.cidade_cliente} - ${originalData?.customer?.estado_cliente}`}</p>
            </div>
            <If condition={(showUpdateBtn && userData?.userDataState?.cliente === 0 && checkPermission('chamados', 'cliente', 'e'))}>
                <div className="col-12">
                    <button className="btn btn-secondary" onClick={() => setShowAlterarClienteBtn(prevState => !prevState)}>Alterar Cliente</button>
                </div>
            </If>
        </div>
    </Card>);
}