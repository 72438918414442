import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import If from '../If';
import CustomMessageData from '../CustomMessageData';
import { utils, writeFile } from 'xlsx';

export default function MUITable({
    columns = [],
    rows = [],
    page = 0,
    rowsPerPage = 10,
    count = 0,
    handlePagination = null,
    handleRowPerPage = null,
    loading = true,
    id = 'muitable',
    customStyles = []
}) {
    const topRef = useRef();
    const dummyDivRef = useRef();
    const allDataCount = count > 0 ? count : false;
    const [filteredRecords, setFilteredRecords] = useState(rows);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const handleScroll = (e) => {
        let tableScroll = document.getElementById(`table_container_${id}`);
        let dummyScroll = topRef.current.scrollLeft;

        tableScroll.scrollLeft = dummyScroll;
    }

    const handleBottomScroll = (scrollValue) => {
        topRef.current.scrollLeft = scrollValue;
    }

    function handleFilter(e) {
        let filteredData = [...rows];
        let documentData = document.getElementById(id).querySelectorAll(`[class="form-control search_${id}"]`);
        let filterData = Array.from(documentData).filter((item) => item.value !== "").map((item) => [item.id, item.value.toLowerCase()]);

        if (filterData.length > 0) {
            for (let filter of filterData) {
                filteredData = filteredData.filter(row => {
                    if (row[filter[0]] !== undefined) {
                        return String(row[filter[0]]).toLowerCase().includes(filter[1])
                    }
                    return false;
                })
            }
        }
        setFilteredRecords(filteredData);
    }

    function handleSort(orderByP) {
        const isAsc = orderBy === orderByP && order === 'asc';
        let orderP = isAsc ? 'desc' : 'asc'
        setOrder(orderP);
        setOrderBy(orderByP);
        let filteredData = [...filteredRecords];
        filteredData.sort((a, b) => {
            return orderP === 'asc' ? a[orderByP].localeCompare(b[orderByP]) : b[orderByP].localeCompare(a[orderByP]);
        })
        setFilteredRecords(filteredData);
    }

    const handleXlsxExport = () => {
        const ws = utils.json_to_sheet(filteredRecords);

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);

        let date = new Date();

        writeFile(wb, `Export_XLSX_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.xlsx`);
    }

    function applyCustomStyle(item){
        if(customStyles.length === 0){
            return {};
        }
        for(let c of customStyles){
            if(c.when(item)){
                return c.style;
            }
        }
    }

    function preProcessData(data) {
        let processedData = data.map((item, idx) => {
            let processedItem = {};

            Object.keys(item).forEach(key => {
                const value = item[key];
                processedItem[key] = typeof value === 'string' ? value.replace(/(\r\n|\n|\r)/gm, " ") : value;
            });

            return processedItem;
        });

        return processedData;
    }

    const handleCsvExport = () => {
        const data = preProcessData(filteredRecords);

        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);

        let date = new Date();

        writeFile(wb, `Export_CSV_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.csv`);
    }


    useEffect(() => {
        let table = document.getElementById(`table_${id}`);
        let tableContainer = document.getElementById(`table_container_${id}`);

        const tableWidth = table.clientWidth;
        dummyDivRef.current.style.width = `${tableWidth}px`;

        tableContainer.addEventListener('scroll', () => handleBottomScroll(tableContainer.scrollLeft));

        return () => {
            tableContainer.removeEventListener('scroll', () => handleBottomScroll(tableContainer.scrollLeft));
        }
    }, [filteredRecords]);

    useEffect(() => {
        setFilteredRecords(rows);
    }, [rows])

    return (
        <div id={id}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-sm btn-secondary mr-2" onClick={handleXlsxExport}>XLSX</button>
                        <button type="button" className="btn btn-sm btn-secondary mr-2" onClick={handleCsvExport}>CSV</button>
                    </div>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, allDataCount]}
                    page={page}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    component={'div'}
                    onPageChange={handlePagination}
                    onRowsPerPageChange={handleRowPerPage}
                    showFirstButton={true}
                    showLastButton={true}
                ></TablePagination>
                <div style={{ overflowX: 'auto' }} onScroll={handleScroll} ref={topRef}>
                    <div style={{ height: '1px' }} ref={dummyDivRef}></div>
                </div>
                <TableContainer sx={{ maxHeight: 500 }} id={`table_container_${id}`}>
                    <Table id={`table_${id}`} stickyHeader>
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((item, idx) => (
                                        <TableCell
                                            key={item.id}
                                            style={{
                                                textAlign: 'center',
                                                textAlign: 'center',
                                                maxWidth: '150px',
                                                wordWrap: 'keep-all',
                                                paddingTop: '0',
                                                paddingBottom: '0',
                                                marginTop: '0',
                                                marginBottom: '0',
                                                fontWeight: 'bold'
                                            }}
                                            sortDirection={orderBy === item.id ? order : false}
                                        >
                                            <TableSortLabel
                                                disabled={item.id.includes('action')}
                                                active={orderBy === item.id}
                                                direction={orderBy === item.id ? order : 'asc'}
                                                onClick={() => handleSort(item.id)}
                                            >
                                                {item.name}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow>
                                {
                                    columns.map((item, idx) => (
                                        <TableCell key={`${item.id}_search`} style={{ textAlign: 'center', textAlign: 'center', maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden', wordWrap: 'keep-all' }}>
                                            <input type='text' className={`form-control search_${id}`} name={item.id} id={item.id} placeholder={item.name} onChange={(e) => handleFilter(e)} disabled={item.id.includes('action')}></input>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <If condition={loading}>
                                <TableRow>
                                    <TableCell colSpan={columns.length}>
                                        <CustomMessageData message='Carregando dados...'></CustomMessageData>
                                    </TableCell>
                                </TableRow>
                            </If>
                            <If condition={!loading}>
                                <If condition={filteredRecords.length > 0}>
                                    {
                                        filteredRecords.map((row, idx) => {
                                            return (
                                                <TableRow 
                                                    key={idx}
                                                    sx={{...applyCustomStyle(row)}}
                                                >
                                                    {
                                                        columns.map((column, idx) => {
                                                            let value = row[column.id]
                                                            return (
                                                                <TableCell key={`${column.id}_${idx}`} style={{ 
                                                                    whiteSpace: 'nowrap', 
                                                                    textAlign: 'center', 
                                                                    maxWidth: '150px', 
                                                                    textOverflow: 'ellipsis', 
                                                                    overflow: 'hidden'
                                                                }}>
                                                                    {value}
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </If>
                                <If condition={filteredRecords.length === 0}>
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <CustomMessageData message='Sem Dados'></CustomMessageData>
                                        </TableCell>
                                    </TableRow>
                                </If>
                            </If>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, allDataCount]}
                    page={page}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    component={'div'}
                    onPageChange={handlePagination}
                    onRowsPerPageChange={handleRowPerPage}
                    showFirstButton={true}
                    showLastButton={true}
                ></TablePagination>
            </Paper>
        </div>)
}