import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { generatePasswordByTicketId, getPasswordsDataByTicketId } from '../../../services/Ticket';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import Card from '../../../common/Layout/Card';
import Table from '../../../common/Layout/Table/Table';
import If from '../../../common/Layout/If';

export default function SenhaAtendimentoCard({ getHistory = null, originalData = {}, id = '' }) {
    const initialGeneratePasswordState = {
        descricao_atividade: ''
    }

    const [generatePasswordForm, setGeneratePasswordForm] = useState(initialGeneratePasswordState);
    const [passwordData, setPasswordData] = useState([]);
    const passwordColumns = [
        {
            id: 'senha_password',
            name: 'Senha',
            selector: row => row.senha_password,
            sortable: true,
            wrap: true
        },
        {
            id: 'atividade_password',
            name: 'Atividade',
            selector: row => row.atividade_password,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_criacao_password',
            name: 'Data de Criação',
            selector: row => row.data_criacao_password,
            sortable: true,
            wrap: true
        }
    ];
    async function generatePassword(e) {
        e.preventDefault()

        let formData = {
            descricao_atividade: generatePasswordForm?.descricao_atividade,
            status_chamado: originalData?.status_chamado
        }

        const toastPassword = toast.loading("Gerando Senha, aguarde...");

        let dataReturn = await generatePasswordByTicketId(id, formData);

        if (dataReturn?.data?.response === 'success') {
            let message = dataReturn?.data?.data?.message || 'Senha gerada com sucesso!';
            toast.update(toastPassword, { render: message, type: "success", isLoading: false, autoClose: 1500 });
            getHistory();
            getPasswordsData();
        } else {
            toast.update(toastPassword, { render: 'Ocorreu um erro ao gerar a senha!', type: "warning", isLoading: false, autoClose: 1500 });
        }

        setGeneratePasswordForm(initialGeneratePasswordState);
    }

    const getPasswordsData = async () => {
        let dataReturn = await getPasswordsDataByTicketId(id);

        if (dataReturn?.data?.length > 0) {
            let passwordsData = dataReturn?.data;

            let passwordsTable = passwordsData.map((item, idx) => {
                return ({ senha_password: item.senha_atendimento, atividade_password: item.atividade_senha_atendimento, data_criacao_password: item.data_registro_br })
            });

            setPasswordData(passwordsTable);
        } else {
            setPasswordData([]);
        }
    }

    useEffect(() => {
        getPasswordsData();
    }, [id]);

    return (<Card title={"Senhas de Atendimento"} icon={"fa-keyboard"} collapseId={"passwordsBody"}>
        <div className='row'>
            <div className='col-12'>
                <form onSubmit={generatePassword}>
                    <div className='row'>
                        <div className='col-9'>
                            <input type='text' className='form-control' placeholder='Descrição da Atividade relacionada à senha gerada' value={generatePasswordForm?.descricao_atividade} onChange={(e) => setGeneratePasswordForm(prevState => ({ ...prevState, descricao_atividade: e.target.value }))} required></input>
                        </div>
                        <div className='col-3'>
                            <button type='submit' className='btn btn-secondary w-100'>Gerar e Salvar Senha Atendimento</button>
                        </div>
                    </div>
                </form>
            </div>
            <hr></hr>
            <If condition={passwordData.length === 0}>
                <CustomMessageData message="Sem Dados"></CustomMessageData>
            </If>
            <If condition={passwordData.length > 0}>
                <div className='col-12'>
                    <Table columns={passwordColumns} data={passwordData} needExport={false} id="passwordTable"></Table>
                </div>
            </If>
        </div>
    </Card>);
}