import React, { useEffect, useState } from 'react';
import { getAllPartners } from '../../services/Partners';
import Select from 'react-select';
import { getPartnersByDateRange } from '../../services/EmployeeExpense';

export default function RepresentanteSelect({ formData, setFormData, fieldName = 'representante', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, advancedSetFormData=null, params=null}) {
    const [partnersOptions, setPartnersOptions] = useState([]);

    async function getPartners() {
        let dataReturn = false;
        if(params === null){
            dataReturn = await getAllPartners();
        }

        if(params?.mode === 'date-range' && !['', undefined, null].includes(params?.data_inicial) && !['', undefined, null].includes(params?.data_final)){
            dataReturn = await getPartnersByDateRange(params?.data_inicial, params?.data_final);
        }

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let partners = dataReturn.data.data.map((item, idx) => {
                return { value: item.id, label: item.nome_representante }
            })
            setPartnersOptions(partners);
        } else {
            setPartnersOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        getPartners();
    }, [params]);

    return (
        <div className={cols.length > 0 ? `col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}` : 'col'}>
            <label htmlFor={fieldName}>Representante</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={partnersOptions}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
                isClearable={true}
            ></Select>
        </div>
    );
}