import React from 'react';
import Content from '../../common/Layout/Content';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';

export default function Reports(props){
    return (
        <div>
            <Content headerTitle="Relatórios">
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                Teste
            </Content>
        </div>
    );
}