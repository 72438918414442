import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { withMask } from 'use-mask-input';
import { useNavigate } from 'react-router-dom';
import Cep from '../../../common/Cep/Cep';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import { createEmployee } from '../../../services/Employee';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import GenericSelect from '../../../common/Selects/GenericSelect';
import UsuariosAtivosSelect from '../../../common/Selects/UsuariosAtivosSelect';
import If from '../../../common/Layout/If';


export default function CreateTechnician(props) {

    const initialState = {
        representantes: [],
        nome_tec: '',
        rg_tec_orgao_expedidor: '',
        rg_tec: '',
        cpf_cnpj: '',
        data_nascimento: '',
        estado_civil: '',
        numero_cnh: '',
        cnh_img: '',
        nome_mae: '',
        data_validade_cnh: '',
        data_validade_aso: '',
        data_exame_periodico: '',
        logradouro_end: '',
        numero_complemento_endereco: '',
        bairro_end: '',
        cidade_end: '',
        estado_end: '',
        cep_end: '',
        telefone_fixo_tec: '',
        telefone_recados: '',
        celular_tec: '',
        celular_tec_pessoal: '',
        email_tec: '',
        email_tec_pessoal: '',
        veiculo: '',
        cor_veiculo: '',
        modelo_veiculo: '',
        placa_veiculo: '',
        banco: '',
        tipo_conta_banco: '',
        agencia_banco: '',
        num_conta_banco: '',
        titular_banco2: '',
        cpf_titular2: '',
        desc_base: '',
        desc_habilidades: '',
        descricao: '',
        ativo: '',
        credenciado: '',
        modal_contratacao: '',
        categoria: "Técnico",
        id_representante: '',
        id_usuario_gestor: ''
    }

    const [formTechnicianData, setFormTechnicianData] = useState(initialState);
    const navigate = useNavigate();

    async function submitForm(e) {
        e.preventDefault();

        let formData = {
            representantes: [
                formTechnicianData?.id_representante?.value || ''
            ],
            nome_tec: formTechnicianData?.nome_tec || '',
            rg_tec_orgao_expedidor: formTechnicianData?.rg_tec_orgao_expedidor || '',
            rg_tec: formTechnicianData?.rg_tec || '',
            cpf_cnpj: formTechnicianData?.cpf_cnpj || '',
            data_nascimento: formTechnicianData?.data_nascimento || '',
            estado_civil: formTechnicianData?.estado_civil?.value || '',
            numero_cnh: formTechnicianData?.numero_cnh || '',
            cnh_img: '',
            nome_mae: formTechnicianData?.nome_mae || '',
            data_validade_cnh: formTechnicianData?.data_validade_cnh || '',
            data_validade_aso: formTechnicianData?.data_validade_aso || '',
            data_exame_periodico: formTechnicianData?.data_exame_periodico || '',
            logradouro_end: formTechnicianData?.logradouro_end || '',
            numero_complemento_endereco: formTechnicianData?.numero_complemento_endereco || '',
            bairro_end: formTechnicianData?.bairro_end || '',
            cidade_end: formTechnicianData?.cidade_end || '',
            estado_end: formTechnicianData?.estado_end || '',
            cep_end: formTechnicianData?.cep_end || '',
            telefone_fixo_tec: formTechnicianData?.telefone_fixo_tec || '',
            telefone_recados: formTechnicianData?.telefone_recados || '',
            celular_tec: formTechnicianData?.celular_tec || '',
            celular_tec_pessoal: formTechnicianData?.celular_tec_pessoal || '',
            email_tec: formTechnicianData?.email_tec || '',
            email_tec_pessoal: formTechnicianData?.email_tec_pessoal || '',
            veiculo: formTechnicianData?.veiculo || '',
            cor_veiculo: formTechnicianData?.cor_veiculo || '',
            modelo_veiculo: formTechnicianData?.modelo_veiculo || '',
            placa_veiculo: formTechnicianData?.placa_veiculo || '',
            banco: formTechnicianData?.banco || '',
            tipo_conta_banco: formTechnicianData?.tipo_conta_banco || '',
            agencia_banco: formTechnicianData?.agencia_banco || '',
            num_conta_banco: formTechnicianData?.num_conta_banco || '',
            titular_banco2: formTechnicianData?.titular_banco2 || '',
            cpf_titular2: formTechnicianData?.cpf_titular2 || '',
            desc_base: '',
            desc_habilidades: '',
            descricao: formTechnicianData?.descricao || '',
            ativo: 1,
            credenciado: 1,
            modal_contratacao: formTechnicianData?.id_representante?.value === 1 ? 'CLT' : 'PJ',
            categoria: "Técnico",
            id_usuario_gestor: formTechnicianData?.id_representante?.value === 1 ? 47437 : formTechnicianData?.id_usuario_gestor?.value || ''
        }

        const toastCreateForm = toast.loading("Cadastrando técnico, aguarde...");

        let dataReturn = await createEmployee(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastCreateForm, { render: 'Técnico cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            navigate("/cadastros/tecnicos/dados");
        } else if(dataReturn?.data?.message === 'The cpf cnpj has already been taken.'){
            toast.update(toastCreateForm, { render: 'Este CPF/CNPJ já existe!', type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastCreateForm, { render: 'Ocorreu um erro ao cadastrar o técnico!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    return (
        <Content headerTitle="Cadastrar Técnico">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Técnico</h5>
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <div className="form-group">
                                <label htmlFor="nome_tec">Nome do Técnico</label>
                                <input type="text" className="form-control" name="nome_tec" id="nome_tec" value={formTechnicianData?.nome_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' required />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <div className="form-group">
                                <label htmlFor="email_tec">Email Corporativo</label>
                                <input type="email" className="form-control" name="email_tec" id="email_tec" value={formTechnicianData?.email_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <div className="form-group">
                                <label htmlFor="email_tec_pessoal">Email Pessoal</label>
                                <input type="email" className="form-control" name="email_tec_pessoal" id="email_tec_pessoal" value={formTechnicianData?.email_tec_pessoal || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <div className="form-group">
                                <label htmlFor="nome_mae">Nome da Mãe</label>
                                <input type="text" className="form-control" name="nome_mae" id="nome_mae" value={formTechnicianData?.nome_mae || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <div className="form-group">
                                <label htmlFor="rg_tec_orgao_expedidor">Órgão Expeditor</label>
                                <input type="text" className="form-control" name="rg_tec_orgao_expedidor" id="rg_tec_orgao_expedidor" value={formTechnicianData?.rg_tec_orgao_expedidor || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                            </div>
                        </div>
                        <RepresentanteSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName='id_representante' cols={[12, 12, 2, 2]} isMulti={false} required={true}></RepresentanteSelect>
                        <If condition={formTechnicianData?.id_representante?.label === 'PJ - MAMINFO SOLUÇÕES'}>
                            <UsuariosAtivosSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName='id_usuario_gestor' cols={[12, 12, 2, 2]} userType='all' required={true} title='Gestor'></UsuariosAtivosSelect>
                        </If>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="celular_tec">Celular Corporativo</label>
                            <input type="text" name="celular_tec" id="celular_tec" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formTechnicianData?.celular_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="celular_tec_pessoal">Celular Pessoal</label>
                            <input type="text" name="celular_tec_pessoal" id="celular_tec_pessoal" className="form-control" ref={withMask(['(99) 99999-9999'])} value={formTechnicianData?.celular_tec_pessoal || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="telefone_recados">Telefone Recado</label>
                            <input type="text" name="telefone_recados" id="telefone_recados" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formTechnicianData?.telefone_recados || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="telefone_fixo_tec">Telefone Fixo</label>
                            <input type="text" name="telefone_fixo_tec" id="telefone_fixo_tec" className="form-control" ref={withMask(['(99) 9999-9999'])} value={formTechnicianData?.telefone_fixo_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="rg_tec">RG</label>
                            <input type="text" name="rg_tec" id="rg_tec" className="form-control" value={formTechnicianData?.rg_tec || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="cpf_cnpj">CPF</label>
                            <input type="text" name="cpf_cnpj" id="cpf_cnpj" className="form-control" ref={withMask(['999.999.999-99'])} value={formTechnicianData?.cpf_cnpj || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <GenericSelect formData={formTechnicianData} setFormData={setFormTechnicianData} fieldName={'estado_civil'} title={'Estado Civil'} cols={[12, 12, 2, 2]} genericOption='EstadoCivilOptions'></GenericSelect>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="data_nascimento">Data Nascimento</label>
                            <input type="date" name="data_nascimento" id="data_nascimento" className="form-control" value={formTechnicianData?.data_nascimento || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="numero_cnh">Nº CNH</label>
                            <input type="text" name="numero_cnh" id="numero_cnh" className="form-control" value={formTechnicianData?.numero_cnh || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="data_validade_cnh">Data de Validade CNH</label>
                            <input type="date" name="data_validade_cnh" id="data_validade_cnh" className="form-control" value={formTechnicianData?.data_validade_cnh || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="data_exame_periodico">Data Exame Periódico</label>
                            <input type="date" name="data_exame_periodico" id="data_exame_periodico" className="form-control" value={formTechnicianData?.data_exame_periodico || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="data_validade_aso">Data de Validade ASO</label>
                            <input type="date" name="data_validade_aso" id="data_validade_aso" className="form-control" value={formTechnicianData?.data_validade_aso || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Endereço</h5>
                        </div>
                    </div>
                    <Cep 
                        formData={formTechnicianData} 
                        setFormData={setFormTechnicianData} 
                        formDataNames={{
                            'cep': 'cep_end',
                            'bairro': 'bairro_end',
                            'cidade': 'cidade_end',
                            'estado': 'estado_end',
                            'logradouro': 'logradouro_end'
                        }}
                    ></Cep>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className="fas fa-dot-circle"></i> Veículo</h5>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="veiculo">Veículo</label>
                            <input type="text" name="veiculo" id="veiculo" className="form-control" value={formTechnicianData?.veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="modelo_veiculo">Modelo</label>
                            <input type="text" name="modelo_veiculo" id="modelo_veiculo" className="form-control" value={formTechnicianData?.modelo_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="cor_veiculo">Cor</label>
                            <input type="text" name="cor_veiculo" id="cor_veiculo" className="form-control" value={formTechnicianData?.cor_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="placa_veiculo">Placa</label>
                            <input type="text" name="placa_veiculo" id="placa_veiculo" className="form-control" value={formTechnicianData?.placa_veiculo || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Dados Bancários</h5>
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="banco">Banco</label>
                            <input type="text" name="banco" id="banco" className="form-control" value={formTechnicianData?.banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="tipo_conta_banco">Tipo Conta</label>
                            <input type="text" name="tipo_conta_banco" id="tipo_conta_banco" className="form-control" value={formTechnicianData?.tipo_conta_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="agencia_banco">Agência</label>
                            <input type="text" name="agencia_banco" id="agencia_banco" className="form-control" value={formTechnicianData?.agencia_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="num_conta_banco">Nº Conta</label>
                            <input type="text" name="num_conta_banco" id="num_conta_banco" className="form-control" value={formTechnicianData?.num_conta_banco || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="titular_banco2">Titular 2</label>
                            <input type="text" name="titular_banco2" id="titular_banco2" className="form-control" value={formTechnicianData?.titular_banco2 || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <label htmlFor="cpf_titular2">CPF Titular 2</label>
                            <input type="text" name="cpf_titular2" id="cpf_titular2" className="form-control" ref={withMask(['999.999.999-99'])} value={formTechnicianData?.cpf_titular2 || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...' />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className="fas fa-sticky-note"></i> Detalhamento</h5>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="descricao">Observações</label>
                                <textarea rows={5} className="form-control" name="descricao" id="descricao" value={formTechnicianData?.descricao || ''} onChange={(e) => (setFormTechnicianData(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} placeholder='...'></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Cadastrar</button>
                        </div>
                    </div>
                </form>
            </Card>
        </Content >
    );
}