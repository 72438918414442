import interceptorInstance from "./Interceptor";

export async function getContractsValuesByContractId(id){
    try{
        let data = await interceptorInstance.get(`contract-values/get/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function insertContractValues(params){
    try{
        let data = await interceptorInstance.post(`contract-values/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function deleteContractValuesById(id){
    try{
        let data = await interceptorInstance.delete(`contract-values/delete/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getContractValuesById(id){
    try{
        let data = await interceptorInstance.get(`contract-values/search/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateContractValueById(id, params){
    try{
        let data = await interceptorInstance.put(`contract-values/update/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function approveContractValueById(id, params){
    try{
        let data = await interceptorInstance.patch(`contract-values/approve/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getActiveContractValues(contract_id){
    try{
        let data = await interceptorInstance.get(`contract-values/get-active/${contract_id}`);
        return data;
    }catch(e){
        return e.response;
    }
}