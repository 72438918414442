import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getActivePartnerValues } from '../../services/PartnerValues';

export default function ValoresRepresentanteSelect({ formData, setFormData, fieldName = 'valor_representante', title='Valores Representante', cols = [12, 12, 12, 12], isDisabled = false, required = false, id_representante = '', advancedSetFormData=null }) {
    const [valoresContratoOptions, setValoresContratoOptions] = useState([]);

    const getAllValoresRepresentanteByIdRepresentante = async (id) =>{
        let dataReturn = await getActivePartnerValues(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let contracts = dataReturn.data.data.map((item, idx) => {
                return { value: item?.id, label: item?.contract_value?.escopo }
            });

            setValoresContratoOptions(contracts);
        } else {
            setValoresContratoOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData === null){
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }else{
            advancedSetFormData(value, name);
        }
    }

    useEffect(() => {
        if(!['', undefined, null].includes(id_representante)){
            getAllValoresRepresentanteByIdRepresentante(id_representante);
        }
    }, [id_representante]);

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>{title}</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={[...valoresContratoOptions]}
                value={formData[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={false}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}