import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../Layout/Modal';
import ChamadosSelect from '../Selects/ChamadosSelect';
import { ChatContext } from './MainChatComponent';
import If from '../Layout/If';

export default function ChannelData({ title = '', isOpen, setModalOpen, updateChannel = false, changeChannelData }) {
    const { chatState } = useContext(ChatContext);

    const initialState = useMemo(() => ({
        channel_name: '',
        channel_description: '',
        ticket_id: { value: chatState?.ticketId || '', label: '' }
    }), [chatState?.ticketId]);

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (updateChannel) {
            setFormData(prevState => (
                {
                    ...prevState,
                    channel_name: chatState?.channelData?.channel_name || '',
                    channel_description: chatState?.channelData?.channel_description || '',
                    ticket_id: { value: chatState?.channelData?.ticket_id || '', label: `${chatState?.channelData?.ticket_number || ''} - ${chatState?.channelData?.ticket_status || ''}` }
                }
            ))
        } else {
            setFormData(initialState);
        }
    }, [isOpen, updateChannel, initialState, chatState?.channelData?.channel_name, chatState?.channelData?.channel_description, chatState?.channelData?.ticket_id, chatState?.channelData?.ticket_number, chatState?.channelData?.ticket_status]);

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            setModalOpen={setModalOpen}
        >
            <form>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="channel_name">Nome do Canal</label>
                        <input type="text" name="channel_name" id="channel_name" className="form-control" placeholder='...' value={formData?.channel_name} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <div className="col-12">
                        <label htmlFor="channel_description">Descrição do Canal</label>
                        <input type="text" name="channel_description" id="channel_description" className="form-control" placeholder='...' value={formData?.channel_description} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                    </div>
                    <If condition={chatState?.ticketId === ''}>
                        <ChamadosSelect formData={formData} setFormData={setFormData} fieldName='ticket_id'></ChamadosSelect>
                        <span style={{ fontSize: '0.8rem' }}>Selecione apenas se deseja vincular este canal a um chamado!</span>
                    </If>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="button" className="btn btn-success float-right" onClick={() => changeChannelData(formData)}>Confirmar</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}