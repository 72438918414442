import React, { useContext, useEffect, useRef, useState } from 'react';
import './MessagesChat.css';
import maminfoLogo from '../../resources/imgs/logo/maminfo.png';
import { Context } from '../../App';
import Message from './Message';
import { getChannelDataByChannelId, getChannelsByTicketId, seenMessageByUserId, sendMessageByChannelId, updateChannelMembers, updateChannelTypeById } from '../../services/Chat';
import ChatDetails from './ChatDetails';
import { toast } from 'react-toastify';
import echo from '../../services/LaravelEcho';
import { Fab } from '@mui/material';
import useChat from '../../hooks/useChat';
import { ChatContext } from './MainChatComponent';
import { seenExternalMessage, sendExternalMessages } from '../../services/ExternalChat';
import useGenerics from '../../hooks/useGenerics';
import If from '../Layout/If';

export default function MessagesChat({ style, openContacts }) {
    const initialState = {
        message: ''
    }

    const { getChannelName, getChannelImage, getChatTypeByRoute } = useChat();
    const { openNewTab } = useGenerics();
    const userData = useContext(Context);
    const { chatState, setChatState } = useContext(ChatContext);
    const [messageToSend, setMessageToSend] = useState(initialState);
    const [isOpen, setIsOpen] = useState(false);
    const [scrollOnBottom, setScrollOnBottom] = useState(true);
    const [firstMessageLoad, setFirstMessageLoad] = useState(true);
    const [tabsChannels, setTabsChannels] = useState([]);

    const messageRef = useRef();
    const chatRef = useRef();
    const fabRef = useRef();
    const imgRef = useRef();

    const STYLE = {
        ...style,
        backgroundColor: chatState?.channelId === '' ? '#222D32' : ''
    }

    async function getChannelData() {
        let dataReturn = await getChannelDataByChannelId(chatState?.channelId);

        if (dataReturn?.data?.response === 'success') {
            let messageData = dataReturn?.data?.data;
            setChatState(prevState => (
                {
                    ...prevState,
                    channelData: {
                        ...messageData,
                        worked_channel_name: getChannelName(messageData),
                    }
                })
            );
        } else {
            toast.warning("Ocorreu um erro ao buscar os dados do canal!");
        }
    }

    function renderMessages() {
        return (chatState?.messages?.map((item, idx) => {
            let message = item.message_type === 'text' ? item.message : item.file_url;

            if (item?.sent_by?.id === userData?.userDataState?.id_usuario) {
                let status = item?.seen_by.length > 1 ? 'Visto' : '...'
                return <Message message={message} type='user' status={status} sentName={item?.sent_by?.name} key={idx} messageType={item?.message_type} date={item?.created_at}></Message>
            }
            return (
                <Message message={message} type='other' sentName={item?.sent_by?.name} key={idx} messageType={item?.message_type} date={item?.created_at}></Message>
            )
        }) || []);
    }

    function renderMessageHeader() {
        let channelImg = getChannelImage(chatState?.channelData?.users || [], 40, 19);

        return (
            <div className="d-flex flex-row">
                <div
                    className={`d-flex flex-row ${chatState?.channelData?.users?.length < 3 ? '' : 'flex-wrap'} justify-content-center align-items-center`} style={{
                        border: '1px solid gray',
                        borderRadius: '20px',
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        marginRight: '10px',
                        overflow: 'hidden',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        if (getChatTypeByRoute() !== 'externo') {
                            openContacts()
                        }
                    }}>
                    {channelImg}
                </div>
                <div className="text-truncate">
                    <span><b>{chatState?.channelData?.worked_channel_name}</b></span>
                </div>
            </div>)
    }

    async function changeChannelType(newType = 'channel') {
        let formData = {
            type: newType,
            user_id: [userData?.userDataState?.id_usuario],
            action: 'add'
        }
        let dataReturn = await updateChannelTypeById(chatState?.channelId, formData);
        let updateMembersDataReturn = await updateChannelMembers(chatState?.channelId, formData);

        if (dataReturn?.data?.response === 'success' && updateMembersDataReturn?.data?.response === 'success') {
            let updateMembersData = updateMembersDataReturn?.data?.data;
            setChatState(prevState => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    type: updateMembersData?.type || '',
                    users: updateMembersData?.users || []
                }
            }));
        }
    }

    async function sendMessage() {
        let formData = new FormData();
        formData.append('message', messageToSend?.message);
        formData.append('file', '');

        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await sendExternalMessages(chatState?.channelId, formData);
        } else {
            dataReturn = await sendMessageByChannelId(chatState?.channelId, formData);
        }

        if (dataReturn?.data?.response === 'success') {
            setMessageToSend(initialState);
            messageRef.current.focus();

            let usersId = chatState?.channelData?.users?.map((item, idx) => {
                return item?.id;
            });

            if (chatState?.channelData?.type === 'livechat' && !usersId.includes(userData?.userDataState?.id_usuario)) {
                changeChannelType();
            }
        } else {
            toast.warning("Ocorreu um erro ao enviar a mensagem!");
        }
    }

    async function readMessage(messageId) {
        let formData = {
            user_id: [userData?.userDataState?.id_usuario]
        }

        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await seenExternalMessage(messageId, formData);
        } else {
            dataReturn = await seenMessageByUserId(messageId, formData);
        }


        if (dataReturn?.data?.response === 'success') {
            let message = dataReturn?.data?.data;
            setChatState((prevState) => {
                let idx = prevState.messages.findIndex(obj => obj._id === message._id)

                if (idx !== -1) {
                    const newMessages = [...prevState.messages];
                    newMessages[idx] = message;
                    return {
                        ...prevState,
                        messages: newMessages
                    };
                }

                return prevState;
            })
        }
    }

    function handleScroll() {
        const element = chatRef.current;
        if (element) {
            if ((element.scrollTop + element.clientHeight) >= (element.scrollHeight - 2)) {
                setScrollOnBottom(true);
                workSendMessages();
            } else {
                setScrollOnBottom(false);
            }
        }
    }

    function workSendMessages() {

        chatState?.messages.forEach((item) => {
            let seenId = item.seen_by.map((item2, idx) => {
                return item2.id;
            });

            if (!seenId.includes(userData?.userDataState?.id_usuario)) {
                readMessage(item._id);
            }
        });
    }

    function checkUnreadMessage() {
        let count = 0;
        chatState?.messages.forEach((item, idx) => {

            let seenId = item.seen_by.map((item2, idx) => {
                return item2.id;
            });

            if (!seenId.includes(userData?.userDataState?.id_usuario)) {
                count += 1;
            }
        });

        if (count > 0) {
            return count;
        }
    }

    function goToBottom() {
        const element = chatRef.current;

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }

    function handleImgChange(e) {
        const files = Array.prototype.slice.call(e.target.files);

        for (let f of files) {
            let formData = new FormData();
            formData.append('file', f)

            sendImg(formData);
        }
    }

    async function sendImg(formData) {

        let dataReturn = false;

        if (getChatTypeByRoute() === 'externo') {
            dataReturn = await sendExternalMessages(chatState?.channelId, formData);
        } else {
            dataReturn = await sendMessageByChannelId(chatState?.channelId, formData);
        }

        if (dataReturn?.data?.response !== 'success') {
            toast.warning("Ocorreu um erro ao enviar imagem!");
        }
    }

    async function getTabsChannelsByTicketId(ticketId) {
        let dataReturn = await getChannelsByTicketId(ticketId);

        if (dataReturn?.data?.response === 'success') {
            let channelsData = dataReturn?.data?.data || [];

            setTabsChannels(channelsData);
        }
    }

    function renderTabs() {
        let tabsArray = [...tabsChannels];

        tabsArray[0] = tabsArray.splice(tabsArray.findIndex(item => item._id === chatState?.channelId), 1, tabsArray[0])[0];

        return tabsArray.map((item, idx) => {
            return (<button key={item?._id + "_tab"} className={`nav-link ${item?._id === chatState?.channelId ? 'active font-weight-bold' : ''}`} id={item?._id + "_tab"} onClick={() => setChatState(prevState => ({ ...prevState, channelId: item._id }))} data-toggle="tab" type="button" role="tab" aria-controls={item?._id} aria-selected="false">{item?.channel_name || 'Sem Nome'} {item?.pending_message_count > 0 && <span className="badge badge-warning float-right">{item?.pending_message_count}</span>}</button>)
        });
    }

    useEffect(() => {
        if (chatState?.channelId !== '') {
            const channel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}channel.${chatState?.channelId}`);

            channel.listen('MessageSent', (e) => {
                setChatState((prevState) => ({
                    ...prevState,
                    messages: [...prevState.messages, e.message]
                }))
            });

            channel.listen('MessageRead', (e) => {
                setChatState((prevState) => {
                    let idx = prevState.messages.findIndex(obj => obj._id === e.message._id)

                    if (idx !== -1) {
                        const newMessages = [...prevState.messages];
                        newMessages[idx] = e.message;
                        return {
                            ...prevState,
                            messages: newMessages
                        };
                    }

                    return prevState;
                })
            });

            channel.listen('SpecificChannelUpdate', (e) => {
                if (e.notification === 'channel_update') {
                    getChannelData();
                }
            })

            return () => {
                echo.leave(`${process.env.REACT_APP_WEBSOCKET_PREFIX}channel.${chatState?.channelId}`);
            }
        }
    }, [chatState?.channelId]);

    useEffect(() => {
        if ((![null, undefined, ''].includes(chatState?.channelData?.ticket_id) || chatState?.ticketId !== '') && getChatTypeByRoute() === 'padrao') {
            let ticketId = chatState?.channelData?.ticket_id || chatState?.ticketId;
            getTabsChannelsByTicketId(ticketId);

            const webSocketChannel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);

            webSocketChannel.listen('ChannelUpdate', (e) => {
                if (e.notification === 'channel_update') {
                    getTabsChannelsByTicketId(ticketId);
                }
            })

            return () => {
                webSocketChannel.stopListening(`ChannelUpdate`);
            }
        }
    }, [chatState?.channelData]);

    function handleKeyUp(e) {
        if (e.key === 'Enter' && e.shiftKey) {
            setMessageToSend(prevState => ({ ...prevState, message: e.target.value }))
            return;
        }

        if (e.key === 'Enter') {
            sendMessage();
        }
    }

    useEffect(() => {
        const element = chatRef.current;

        if (element) {
            if (chatState?.messages[chatState?.messages?.length - 1]?.sent_by?.id === userData?.userDataState?.id_usuario || firstMessageLoad || scrollOnBottom) {
                element.scrollTop = element.scrollHeight;
                workSendMessages();
            }

            if (chatState?.messages?.length > 0) {
                setFirstMessageLoad(false);
            }

            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        }
    }, [chatState?.messages]);

    return (
        <div className="card" style={STYLE}>
            <ChatDetails isOpen={isOpen} setModalOpen={() => setIsOpen(!isOpen)}></ChatDetails>
            <If condition={chatState?.channelId !== ''}>
                <If condition={((![null, undefined, ''].includes(chatState?.channelData?.ticket_id) || chatState?.ticketId !== '') && getChatTypeByRoute() === 'padrao')}>
                    <div key='ticketTabs'>
                        <nav className='nav nav-tabs' id='tabsNavMessages' role='tablist'>
                            {renderTabs()}
                        </nav>
                    </div>
                </If>
                <div className="card-header" style={{ height: '60px' }}>
                    <div className="row">
                        <div className="col-10 text-truncate">
                            {renderMessageHeader()}
                        </div>
                        <div className="col-2">
                            <If condition={getChatTypeByRoute() !== 'externo'}>
                                <div className="dropdown float-right">
                                    <button className="btn btn-success dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false' title='Opções...'><i className="fas fa-ellipsis-v"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <button type='button' className='dropdown-item' onClick={() => setIsOpen(prevState => !prevState)}>Dados do Canal</button>
                                        <If condition={chatState?.channelData?.ticket_id}>
                                            <button type='button' className='dropdown-item' onClick={() => openNewTab(`/chamados/detalhes-chamado/${chatState?.channelData?.ticket_id}`)}>Ir para Detalhes do Chamado</button>
                                        </If>
                                    </div>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
                <div
                    className="card-body"
                    ref={chatRef}
                    style={{
                        overflowY: 'scroll',
                        backgroundColor: '#222D32',
                        backgroundImage: `url(${maminfoLogo})`,
                        backgroundSize: '60%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        marginBottom: '2px',
                    }}
                >
                    <div className="row" style={{ height: '100%' }}>
                        <div className="col-12">
                            {
                                renderMessages()
                            }
                        </div>
                        <If condition={scrollOnBottom === false}>
                            <Fab ref={fabRef} size='small' aria-label="new_messages" sx={{ position: 'absolute', bottom: 100, right: 25, opacity: 0.5, '&:hover': { opacity: 1 } }} onClick={goToBottom}>
                                {checkUnreadMessage()}
                                <i className='fas fa-arrow-down'></i>
                            </Fab>
                        </If> 
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="input-group mb-3">
                                    <textarea className="form-control" ref={messageRef} aria-label="Mensagem..." value={messageToSend?.message || ''} onChange={(e) => setMessageToSend(prevState => ({ ...prevState, message: e.target.value }))} onKeyUp={(e) => handleKeyUp(e)} placeholder='Mensagem...' style={{ fieldSizing: 'content', maxHeight: '200px' }}></textarea>
                                    <div className="input-group-append">
                                        <input type='file' accept='image/*' className='d-none' ref={imgRef} id='chatImg' multiple onChange={handleImgChange}></input>
                                        <button className="btn btn-outline-secondary" type="button" onClick={() => { imgRef.current.click(); }}><i className='fas fa-camera'></i></button>
                                    </div>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={sendMessage}><i className='fas fa-paper-plane'></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </If>
            <If condition={chatState?.channelId === ''}>
                <div className="card-body" style={{ overflowY: 'auto' }}>
                    <div className="row" style={{ height: '100%' }}>
                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                            <img alt='img_mamtalks' src={maminfoLogo} style={{ opacity: '0.8', width: '60%' }}></img>
                            <h6 style={{ color: '#ADAFB0' }}>Bem-Vindo ao MAM Talks, o chat empresarial da MAMINFO!</h6>
                            <p style={{ color: '#ADAFB0' }}>Use o menu ao lado para selecionar o canal de conversa.</p>
                        </div>
                    </div>
                </div>
            </If>
        </div>)
}