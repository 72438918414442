import interceptorInstance from "./Interceptor";

export async function createChannel(params){
    try{
        let data = await interceptorInstance.post(`chat/channel/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getContactsList() {
    try{
        let data = await interceptorInstance.get(`chat/contacts`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getChannelDataByChannelId(id){
    try{
        let data = await interceptorInstance.get(`chat/channel/${id}/message`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getChannelsListByUserId(){
    try{
        let data = await interceptorInstance.get(`chat/channel/user`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getChannelsByTicketId(ticketId){
    try{
        let data = await interceptorInstance.get(`chat/ticket/${ticketId}/channel`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function sendMessageByChannelId(id, params){
    try{
        let data = await interceptorInstance.post(`chat/channel/${id}/message`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateChannelDetails(id, params){
    try{
        let data = await interceptorInstance.patch(`chat/channel/${id}/details`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateChannelTicket(id, params){
    try{
        let data = await interceptorInstance.patch(`chat/channel/${id}/ticket`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateChannelMembers(id, params){
    try{
        let data = await interceptorInstance.patch(`chat/channel/${id}/user`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function seenMessageByUserId(id, params){
    try{
        let data = await interceptorInstance.patch(`chat/message/${id}/seen`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPendingMessages(){
    try{
        let data = await interceptorInstance.get(`chat/message/pending-count`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateChannelTypeById(channelId, params){
    try{
        let data = await interceptorInstance.patch(`chat/channel/${channelId}/type`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getPendingMessageByTicketId(ticketId){
    try{
        let data = await interceptorInstance.get(`chat/ticket/${ticketId}/pending-count`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function addExternalUser(id, params){
    try{
        let data = await interceptorInstance.patch(`chat/channel/${id}/external-user`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function renewPassword(id){
    try{
        let data = await interceptorInstance.patch(`chat/external-chat/${id}/renew-password`, {});
        return data;
    }catch(e){
        return e.response;
    }
}

export async function renewExpirationDate(id){
    try{
        let data = await interceptorInstance.patch(`chat/external-chat/${id}/renew-expire-date`, {});
        return data;
    }catch(e){
        return e.response;
    }
}

export async function removeExternalUser(id, params){
    try{
        let data = await interceptorInstance.patch(`chat/channel/${id}/remove-external-user`, params);
        return data;
    }catch(e){
        return e.response;
    }
}